import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as ArrowBack } from "../../assets/arrow_back.svg";
import { RowLine } from "./JoinPotConfirmModal";
import { CounterBtnProps, PotDetailsModalProps } from "../../utils/interface";
import PotDetails from "../../components/chatbox/details";
// import Countdown, { CountdownRenderProps } from "react-countdown";
import { getPotDetails } from "../../utils/chatApi/chatApi";
// import { PotDataDetails } from "../../types";
import Skeleton from "../Skeleton/Skeleton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, setIsPotDetailLoading } from "../../store";

const PotDetailsModal: React.FC<PotDetailsModalProps> = ({
  isOpen,
  onClose,
  chatDataProps,
  isLoading,
  onConfirm,
}) => {
  // Use chatDataProps instead of separate state variable
  const dispatch = useDispatch<AppDispatch>();
  // const [loading, setLoading] = useState(false);
  // const [potDetails, setPotDetails] = useState<PotDataDetails | null>(null);
  const {
    potDetail: { data: potDetails, loading },
  } = useSelector((state: RootState) => state.wallet);
  // const [completed, setCompleted] = useState(false);
  // const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  // const [isStartPotModal, setIsStartPotModal] = useState(false);

  useEffect(() => {
    dispatch(setIsPotDetailLoading(true));
    if (chatDataProps) {
      dispatch(setIsPotDetailLoading(false));
    }
  }, [chatDataProps]);

  const currentPotCode = chatDataProps?.pot?.potCode;

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchPotDetails = async () => {
  //     try {
  //       const response = await getPotDetails(currentPotCode);
  //       const data = await response;
  //       setPotDetails(data);

  //       setLoading(false);
  //     } catch (error: any) {
  //       console.error("Error fetching pot details:", error);
  //       if (error) {
  //         toast.error(error || "Error fetching pot details:", {
  //           position: "bottom-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //       setLoading(false);
  //     }
  //   };

  //   fetchPotDetails();

  //   // Clean up function if needed
  //   return () => {
  //     // Any cleanup code here
  //   };
  // }, [currentPotCode]);

  if (!isOpen) {
    return null;
  }

  // const toggleTooltip = () => {
  //   setIsStartPotModal(true);
  // };

  // const handleToggle = (isActive: boolean) => {};

  // const renderer = ({
  //   days,
  //   hours,
  //   minutes,
  //   seconds,
  //   completed,
  // }: CountdownRenderProps) => {
  //   const timerText = `${days}D : ${hours}H : ${minutes}M : ${seconds}Sec`;

  //   return <CounterBtn completed={completed}>{timerText}</CounterBtn>;
  // };

  const targetDate: Date = new Date(potDetails?.updatedAt);
  targetDate.setDate(targetDate.getDate());

  // const handleCompletion = () => {
  //   setCompleted(true);
  // };

  // const handleMouseEnter = () => {
  //   console.log("hover");
  //   setTooltipActive(true);
  // };
  // const handleMouseLeave = () => {
  //   console.log("leave");
  //   setTooltipActive(false);
  // };
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        {/* <HideInMobile> */}
        <RowLine>
          <MobileHeader>
            <MobileTitle>
              <GoBackArrow onClick={handleClose} style={{ marginRight: 10 }} />
              Pot details
              <CloseIcon onClick={handleClose} />
            </MobileTitle>
          </MobileHeader>
        </RowLine>
        {/* </HideInMobile> */}
        {loading && chatDataProps === null ? (
          <SkeletonWrapper>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </SkeletonWrapper>
        ) : chatDataProps != null ? (
          <>
            <PotDetails
              width={"100%"}
              chatDataProps={chatDataProps}
              borderTop={true}
            />
          </>
        ) : (
          <div style={{ color: "white", textAlign: "center" }}>
            No chat data available
          </div>
        )}
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default PotDetailsModal;

const HideInMobile = styled.div`
  display: none;
  @media (max-width: 475px) {
    display: none;
  }
`;
const MobileHeader = styled.div`
  display: flex;
  min-width: 305px;
  overflow-x: hidden;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #24281b;
  /* sm */
  @media (max-width: 640px) {
    display: flex;
  }
  /* xs */
  @media (max-width: 475px) {
    display: flex;
    min-width: 96%;
    margin-left: 16px;
  }
`;
const MobileTitle = styled.div`
  display: flex;
  align-items: start;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* height: 75%; */
  max-width: 480px;
  max-height: 650px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  border: 1px solid rgba(233, 246, 208, 0.16);
  overflow: hidden; // Ensure no overflow
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 475px) {
    /* max-height: 700px; */
    /* max-height: 880px; */
    border-radius: 20px 20px 0 0;
  }
  /* @media (max-width: 475px) {
    max-height: 550px;
  } */
  /* @media (max-width: 475px) and (min-height: 667px) {
    max-height: 600px;
  }
  @media (max-width: 475px) and (max-height: 666px) {
    max-height: 530px;
  } */
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: flex;
  @media (max-width: 475px) {
    display: none;
  }
`;

const GoBackArrow = styled(ArrowBack)`
  cursor: pointer;
  display: none;
  @media (max-width: 475px) {
    display: flex;
  }
`;

const CounterBtn = styled.button<CounterBtnProps>`
  margin-top: 0.5em;
  cursor: pointer;
  display: flex;
  height: 38px;
  padding: 6px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${(props) =>
    props.completed ? "#FF645D" : "var(--primary, #b1dd40)"};
  /* glow */
  box-shadow: 0px 0px 14px 0px rgba(214, 232, 81, 0.4);

  color: var(--dark, #13160b);
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  //text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.completed ? "" : "afdc3b")};
    box-shadow: ${(props) =>
      props.completed ? "" : "0px 0px 18px rgba(214, 232, 81, 0.4)"};
  }

  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  pointer-events: none;
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;

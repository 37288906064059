import React from "react";
import styled from "styled-components";
import PotDetails from "../../components/chatbox/details";
import { ChatRightBarUIProps } from "../../utils/interface";
const Dashboard: React.FC<ChatRightBarUIProps> = ({ chatDataProps }) => {
  return (
    <Container>
      {/* <> */}
        <PotDetails chatDataProps={chatDataProps} borderTop={false} />
      {/* </> */}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  width: 320px;
  height: 88vh;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  background: #13160b;
  border: 1px solid #373636;
  /* Custom scrollbar */
  /* @media (max-width: 1536px) {
  height: 78vh;
  } */
  /* @media (max-width: 1440px) {
  height: 74vh;
  }  */
  /* @media (max-width: 1366px) {
  height: 70vh;
  } */
  /* @media (max-width: 1280px) {
  height: 74vh;
  } */
  /* @media (max-width: 1080px) {
  height: 65vh;
  } */
  /* @media (max-width: 768px) {
  height: 55vh;
  } */

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  @media (max-width: 475px) {
    width: 100%;
  }
`;

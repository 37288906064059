import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import DefaultImage from "../assets/default_image.svg";
import EarnMountain from "../assets/earn_mountain.svg";
import { ReactComponent as SmallBlastIcon } from "../assets/small_blast_icon.svg";
import { ReactComponent as SmallLogoIcon } from "../assets/small_logo.svg";
import LevelIconBg from "../assets/level_bg.svg";
import ReactSpeedometer from "react-d3-speedometer";
import EarnAndReferalBg from "../assets/earn_background.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ProfileAvatar } from "../assets/pot_member1.svg";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  AppDispatch,
  fetchLeaderboardData,
  getActivities,
  getRewards,
  RootState,
  User,
  getProfileData,
} from "../store";
import { timeAgo } from "../utils/helper";
import { localStorageStates } from "../utils";
import { toast } from "react-toastify";
import Footer from "../components/Footer";
import ProgressBar from "../components/custom/progress";
import HoverButton from "../components/Button/HoverButton";
import theme from "../styles/theme";
import usePageSEO from "../hooks/usePageSEO";

interface Pagination {
  next?: {
    page: number;
    limit: number;
  } | null;
  previous: {
    page: number;
    limit: number;
  } | null;
}

const estimatedItemHeight = 50;
const limit = 50;

const LeaderboardPage = () => {
  const { account, provider, isActive } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState("leaderBoard");
  const [progress, setProgress] = useState(0);
  const [referedCount, setReferedCount] = useState(0);
  const [copyText, setCopyText] = useState("Copy");
  const [listening, setListening] = useState(false);
  const [score, setScore] = useState(0);
  const [userInfo, setUserInfo] = useState({
    username: "",
    profilePicture: "",
    twitterInfo: { username: "" },
    discordInfo: {},
    rank: 0,
    points: 0,
    generatedInviteCodes: [],
    invitedBy: "",
    liquidityPoints: "",
    directInvites: [
      {
        _id: "",
        hasPaidFee: false,
        profilePicture: "",
        username: "",
      },
    ],
    estimatedUSDBFees: 0,
    inviteCode: "",
    quailPoints: "",
  });
  const [page, setPage] = useState<number>(1);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allData, setAllData] = useState<User[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    next: {
      page: 1,
      limit: 50,
    },
    previous: null,
  });
  let eventSource: EventSource | undefined;
  const [currentPage, setCurrentPage] = useState(1);
  const {
    activitiesData: {
      data: activitiesData,
      loading: activitiesLoading,
      activitiyCount,
      pageCount,
    },
    leaderboardRewards: {
      data: leaderboardRewardsData,
      loading: leaderboardRewardsLoading,
    },
  } = useSelector((state: RootState) => state.wallet);
  const sentinelRef = useRef(null);
  const domain = window.location.hostname;

  const handleActiveTabChange = (
    tab: "leaderBoard" | "blackList" | "activities"
  ) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getRewards({}));
      await dispatch(getActivities({ pageNo: currentPage }));
      if (account) {
        const gotProfileData = await dispatch(getProfileData(account));
        if (gotProfileData.meta.requestStatus === "fulfilled") {
          if (gotProfileData?.payload?.username) {
            setUserInfo(gotProfileData?.payload);
            localStorage.setItem(
              localStorageStates.userInfo,
              JSON.stringify(gotProfileData.payload)
            );
          }
        }
      }
      const userInfoInLoacalStorage = JSON.parse(
        localStorage.getItem(localStorageStates.userInfo) || "{}"
      );
      setUserInfo(userInfoInLoacalStorage);
      setScore(userInfoInLoacalStorage?.trustScore);
    })();
  }, [account, activeTab, currentPage, dispatch]);

  useEffect(() => {
    async function fetchData() {
      if (!pagination?.next && page !== 1) return;
      const isBlacklisted = activeTab === "blackList";

      if (page === 1) {
        setInitialLoading(true);
      } else {
        setLoadingMore(true);
      }

      const leaderBoardDataResponse = await dispatch(
        fetchLeaderboardData({
          isBlacklisted,
          page,
          limit,
        })
      );
      if (leaderBoardDataResponse.meta.requestStatus === "fulfilled") {
        setAllData((prevData) => [
          ...prevData,
          ...leaderBoardDataResponse.payload.data,
        ]);
        setPagination({
          next: leaderBoardDataResponse.payload.next || null,
          previous: leaderBoardDataResponse.payload.previous || null,
        });
      }
      if (leaderBoardDataResponse.meta.requestStatus === "rejected") {
        toast.error(
          leaderBoardDataResponse?.payload?.message || "Failed to get data",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setInitialLoading(false);
      setLoadingMore(false);
    }

    fetchData();
  }, [dispatch, page, limit, activeTab]);

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = tableRef.current;
        const scrollBottom = scrollHeight - scrollTop - clientHeight;
        const itemsRemaining = scrollBottom / estimatedItemHeight;

        if (itemsRemaining < 20 && pagination.next) {
          setPage(pagination.next.page);
        }
      }
    };

    const current = tableRef.current;
    current?.addEventListener("scroll", handleScroll);

    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, [pagination]);

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        const halfwayPoint = scrollHeight / 2;
        if (scrollTop >= halfwayPoint && pagination.next) {
          setPage(pagination.next.page);
        }
      }
    };

    const current = tableRef.current;
    current?.addEventListener("scroll", handleScroll);

    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, [pagination]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (
          entry.isIntersecting &&
          !activitiesLoading &&
          (activitiyCount === null || activitiesData?.length < activitiyCount)
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "100px 0px",
        threshold: 0.5,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [activitiesLoading, activitiesData?.length, activitiyCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 10 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(
        `${domain}/?code=${userInfo?.inviteCode}`
      );
      setCopyText("Copied");
    } catch (error: any) {
      console.error("Failed to copy: ", error);
      if (error) {
        toast.error(error || "Failed to copy:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // Memoize the filteredInvites to avoid recalculating on every render
  const filteredInvites = useMemo(() => {
    return userInfo?.directInvites?.filter((invite) => invite?.hasPaidFee);
  }, [userInfo?.directInvites]);

  // Update referedCount whenever filteredInvites changes
  useMemo(() => {
    setReferedCount(filteredInvites?.length);
  }, [filteredInvites]);

  usePageSEO({
    title: "Leaderboard | Quail Finance",
    description:
      "Invite friends and family to join Quail Finance and share your link today! Boost your benefits and unlock USDB rewards with Quail Finance.",
    keywords: [
      "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
    ],
    ogTitle: "Leaderboard | Quail Finance",
    ogDescription:
      "Invite friends and family to join Quail Finance and share your link today! Boost your benefits and unlock USDB rewards with Quail Finance.",
    ogImage: "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    ogUrl: "https://quail.finance/leaderboard",
    twitterTitle: "Leaderboard | Quail Finance",
    twitterDescription:
      "Invite friends and family to join Quail Finance and share your link today! Boost your benefits and unlock USDB rewards with Quail Finance.",
    twitterImage:
      "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    twitterUrl: "https://quail.finance/leaderboard",
  });

  return (
    <>
      <MainContainer>
        <Heading>Refer and earn</Heading>
        <PageContainer>
          <Container>
            <LeaderBoardTopHeader>
              <LeaderBoardProfileBox>
                <BottomSection>
                  <TopBar>
                    <ProfileRow>
                      <Avatar>
                        {userInfo?.profilePicture ? (
                          <>
                            <img
                              src={userInfo?.profilePicture}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = DefaultImage;
                              }}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "100px",
                              }}
                              alt="Avatar"
                            />
                          </>
                        ) : (
                          <ProfileImage
                            src={DefaultImage}
                            alt={"Profile pic"}
                          />
                        )}
                      </Avatar>
                      {/* <Avatar>
                        <ProfileImage src={DefaultImage} alt={"Profile pic"} />{" "}
                      </Avatar> */}
                      <ProfileBox>
                        <ProfileText>@{userInfo.username}</ProfileText>
                        {/* <ProfileAddress>0x29...a20B</ProfileAddress> */}
                        {isActive && account && (
                          <ProfileAddress>
                            {" "}
                            {`${account.substring(0, 4)}...${account.substring(
                              account.length - 4
                            )}`}
                          </ProfileAddress>
                        )}
                      </ProfileBox>

                      <div
                        style={{
                          marginTop: "-30px",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          right: "0px",
                        }}
                      >
                        <ReactSpeedometer
                          maxValue={100}
                          value={score}
                          valueFormat={"d"}
                          segmentColors={[
                            theme.colors.secondaryRed,
                            theme.colors.primary,
                          ]}
                          currentValueText={"${value} TRUST SCORE"}
                          textColor={theme.colors.primary}
                          needleColor="white"
                          customSegmentStops={[0, 50, 100]}
                          height={110}
                          width={160}
                          customSegmentLabels={[
                            { text: "", color: "#333" },
                            { text: "", color: "#333" },
                          ]}
                        />
                      </div>
                    </ProfileRow>
                  </TopBar>
                  {/* <UserLevelBox>
                    <LevelRow>
                      <LevelSectionOne>
                        <LevelIconBgIcon>
                          <LevelNumber>2</LevelNumber>
                        </LevelIconBgIcon>
                      </LevelSectionOne>
                      <LevelSectionTwo>
                        <LevelTitle>Level 2 </LevelTitle>
                        <LevelSubTitle>800 points to next level</LevelSubTitle>
                      </LevelSectionTwo>
                    </LevelRow>
                    <LevelRow>
                      <ProgressBar percentage={progress} />
                    </LevelRow>
                  </UserLevelBox>
                  */}
                  <RankRowFirst>
                    <RankBox>
                      <RankLabel>Ranking</RankLabel>
                      <RankNumber>#{userInfo?.rank}</RankNumber>
                    </RankBox>
                    <RefferalBox>
                      <RankLabel>No. Of Referral</RankLabel>
                      <RankNumber>{referedCount}</RankNumber>
                    </RefferalBox>
                  </RankRowFirst>
                  <RankRow>
                    <TotalQuailPointsBox>
                      <RankLabel>Total Quail points</RankLabel>
                      <RankNumber>
                        {" "}
                        {userInfo?.points?.toLocaleString()}
                      </RankNumber>
                    </TotalQuailPointsBox>
                  </RankRow>
                </BottomSection>
              </LeaderBoardProfileBox>
              <StatsContainer>
                <StatBox>
                  <StatTitle>Est USDB Fees</StatTitle>
                  <StatValue>
                    <SmallBlastIcon style={{ marginRight: "0.25em" }} />
                    {userInfo?.estimatedUSDBFees}
                  </StatValue>
                </StatBox>
                <StatBox>
                  <StatTitle>Est. Blast Rewards</StatTitle>
                  <StatValue>
                    <SmallBlastIcon style={{ marginRight: "0.25em" }} />
                    {userInfo?.liquidityPoints?.toLocaleString()}
                  </StatValue>
                </StatBox>

                <StatBox>
                  <StatTitle>Est. Qal Rewards</StatTitle>
                  <StatValue>
                    <SmallLogoIcon style={{ marginRight: "0.25em" }} />
                    {userInfo?.quailPoints?.toLocaleString()}
                  </StatValue>
                </StatBox>
                <StatBox>
                  <StatTitle>Ends In</StatTitle>
                  <StatValue>November 2024</StatValue>
                </StatBox>
              </StatsContainer>
            </LeaderBoardTopHeader>
            <ReferalContainerMobile>
              <LevelRow>
                <EarnAndReferalBox>
                  <EarnReferalBg>
                    {/* <EarnColums> */}
                    <EarnAndReferalText>
                      Refer & Earn <span>30%</span> Of Fees
                    </EarnAndReferalText>
                    {/* </EarnColums> */}
                    {/* <EarnColums>
                      <EarnImageMountain
                        src={EarnMountain}
                        alt={"EarnMountain"}
                      />{" "}
                    </EarnColums> */}
                  </EarnReferalBg>
                </EarnAndReferalBox>
              </LevelRow>
              <LevelRow>
                <EarnColums>
                  <EarnColumsStraight>
                    <EarnCirclePointer></EarnCirclePointer>
                    <EarnStraightLine></EarnStraightLine>
                    <EarnCirclePointer></EarnCirclePointer>
                  </EarnColumsStraight>
                </EarnColums>
                <EarnColums>
                  <EarnTitle>Share Referral Link</EarnTitle>
                  <EarnSubTitle>
                    Invite Friends & Families to join Quail Finance. Share Your
                    Link Today!
                  </EarnSubTitle>
                  <EarnSecontTitle>Earn Reward</EarnSecontTitle>
                  <EarnSubTitle>
                    Boost your benefits with Quail Finance! Unlock USDB Rewards
                  </EarnSubTitle>
                </EarnColums>
              </LevelRow>
              <SecondLevelRow>
                <EarnTextField>
                  <EarnColums>
                    <LinkTextTitle>Referral Link</LinkTextTitle>
                    <LinkText>{`${domain}/?code=${userInfo?.inviteCode}`}</LinkText>

                    {/* <LinkText>https://quailfinance/?code=QF61A</LinkText> */}
                  </EarnColums>
                  <EarnColums>
                    <LinkCopy onClick={copyCode} height="32px" padding="0 16px">
                      {copyText}
                    </LinkCopy>
                  </EarnColums>
                </EarnTextField>
              </SecondLevelRow>
              <SecondLevelRow>
                <EarnCaption>Terms & Conditions Apply.</EarnCaption>
                <EarnUnderlindedText to="/termsOfService">
                  Learn More
                </EarnUnderlindedText>
              </SecondLevelRow>
            </ReferalContainerMobile>
            <LeaderBoardTitle>Leaderboard</LeaderBoardTitle>
            <LeaderboardAndTableContainer>
              <FullWidthScrollContainer>
                <TabsAndButtons>
                  <Tabs>
                    <Tab
                      isActive={activeTab === "leaderBoard"}
                      onClick={() => handleActiveTabChange("leaderBoard")}
                    >
                      Leaderboard
                    </Tab>
                    {/* <Tab
                      isActive={activeTab === "blackList"}
                      onClick={() => handleActiveTabChange("blackList")}
                    >
                      Blacklist
                    </Tab> */}
                    <MobileTab
                      isActive={activeTab === "activities"}
                      onClick={() => handleActiveTabChange("activities")}
                    >
                      Activities
                    </MobileTab>
                  </Tabs>
                </TabsAndButtons>
              </FullWidthScrollContainer>

              {activeTab !== "activities" && (
                <ScrollableTableContainer ref={tableRef}>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <THRank>Rank</THRank>
                        <THPot>Name</THPot>
                        <THParticipants>Invited By</THParticipants>
                        <THPotSize>Points</THPotSize>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {initialLoading ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <SkeletonLoader key={index} />
                        ))
                      ) : (
                        <>
                          {userInfo?.twitterInfo?.username && (
                            <TR key={Math?.random()}>
                              <TDRank>{userInfo?.rank}</TDRank>
                              <TDNameHighlighted>
                                @{userInfo?.twitterInfo?.username || "N/A"}
                                <YouTag>YOU</YouTag>
                              </TDNameHighlighted>
                              <TDInvitedBy>
                                {userInfo?.invitedBy
                                  ? userInfo?.invitedBy
                                  : "N/A"}
                              </TDInvitedBy>
                              <TDPoints>
                                {userInfo?.points?.toLocaleString()}
                              </TDPoints>
                            </TR>
                          )}
                          {allData.map((user, index) => (
                            <TR key={user._id}>
                              <TDRank>{index + 1}</TDRank>
                              <TDName>{user.username || "N/A"}</TDName>
                              <TDInvitedBy>
                                {user?.invitedBy
                                  ? user?.invitedBy?.username
                                  : "N/A"}
                              </TDInvitedBy>
                              <TDPoints>
                                {user?.points?.toLocaleString()}
                              </TDPoints>
                            </TR>
                          ))}
                          {loadingMore && (
                            <TR>
                              <TDRank
                                colSpan={4}
                                style={{ textAlign: "center" }}
                              >
                                <Loader />{" "}
                              </TDRank>
                            </TR>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </ScrollableTableContainer>
              )}
              {activeTab === "activities" && (
                <ActivityContainerMobile>
                  {activitiesLoading &&
                  activitiesData.length === 0 &&
                  pageCount <= 1
                    ? Array.from({ length: 4 }, (_, index) => (
                        <SkeletonLoader key={index} />
                      ))
                    : activeTab === "activities" &&
                      activitiesData?.map((activity: any, index: any) => (
                        <ActivityRowMobile key={index}>
                          <Avatar>
                            {activity.mentions[0]?.profilePicture ? (
                              <ProfileImage
                                src={activity.mentions[0]?.profilePicture}
                                alt={"Profile pic"}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null;
                                  target.src = DefaultImage;
                                }}
                              />
                            ) : (
                              <ProfileImage
                                src={DefaultImage}
                                alt={"Profile pic"}
                              />
                            )}
                          </Avatar>
                          <ActivityBox>
                            <ActivityText>{activity?.content}</ActivityText>
                            <TimePeriod>
                              {timeAgo(activity?.createdAt)}
                            </TimePeriod>
                          </ActivityBox>
                        </ActivityRowMobile>
                      ))}
                  {activitiesData &&
                    activitiesData?.length > 0 &&
                    activitiesData?.length < activitiyCount && <Loader />}
                  {activitiesData &&
                    activitiesData?.length > 0 &&
                    activitiesData?.length < activitiyCount && (
                      <Sentinel ref={sentinelRef} />
                    )}
                </ActivityContainerMobile>
              )}
            </LeaderboardAndTableContainer>
          </Container>
          {activeTab !== "activities" && (
            <ActivitiesContainer>
              <ReferalContainer>
                <LevelRow>
                  <EarnAndReferalBox>
                    <EarnReferalBg>
                      {/* <EarnColums> */}
                      <EarnAndReferalText>
                        Refer & Earn <span>30%</span> Of Fees
                      </EarnAndReferalText>
                      {/* </EarnColums> */}
                      {/* <EarnColums>
                        <EarnImageMountain
                          src={EarnMountain}
                          alt={"EarnMountain"}
                        />{" "}
                      </EarnColums> */}
                    </EarnReferalBg>
                  </EarnAndReferalBox>
                </LevelRow>
                <LevelRow>
                  <EarnColums>
                    <EarnColumsStraight>
                      <EarnCirclePointer></EarnCirclePointer>
                      <EarnStraightLine></EarnStraightLine>
                      <EarnCirclePointer></EarnCirclePointer>
                    </EarnColumsStraight>
                  </EarnColums>
                  <EarnColums>
                    <EarnTitle>Share Referral Link</EarnTitle>
                    <EarnSubTitle>
                      Invite Friends & Families to join Quail Finance. Share
                      Your Link Today!
                    </EarnSubTitle>
                    <EarnSecontTitle>Earn Reward</EarnSecontTitle>
                    <EarnSubTitle>
                      Boost your benefits with Quail Finance! Unlock USDB
                      Rewards
                    </EarnSubTitle>
                  </EarnColums>
                </LevelRow>
                <SecondLevelRow>
                  <EarnTextField>
                    <EarnColums>
                      <LinkTextTitle>Referral Link</LinkTextTitle>
                      <LinkText>{`${domain}/?code=${userInfo?.inviteCode}`}</LinkText>
                      {/* <LinkText>https://quailfinance/?code=QF61A</LinkText> */}
                    </EarnColums>
                    <EarnColums>
                      <LinkCopy
                        onClick={copyCode}
                        height="32px"
                        padding="0 16px"
                      >
                        {copyText}
                      </LinkCopy>
                    </EarnColums>
                  </EarnTextField>
                </SecondLevelRow>
                <SecondLevelRow>
                  <EarnCaption>Terms & Conditions Apply.</EarnCaption>
                  <EarnUnderlindedText to="/termsOfService">
                    Learn More
                  </EarnUnderlindedText>
                </SecondLevelRow>
              </ReferalContainer>
              <ActivitiesTitle>Activities</ActivitiesTitle>
              <ActivityContainerScroll>
                {activitiesLoading && pageCount <= 1
                  ? Array.from({ length: 4 }, (_, index) => (
                      <SkeletonLoaderForActivities key={index} />
                    ))
                  : activitiesData?.map((activity: any, index: any) => (
                      <ActivityRow key={index}>
                        <Avatar>
                          {activity.mentions[0]?.profilePicture ? (
                            <ProfileImage
                              src={activity.mentions[0]?.profilePicture}
                              alt={"Profile pic"}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = DefaultImage;
                              }}
                            />
                          ) : (
                            <ProfileImage
                              src={DefaultImage}
                              alt={"Profile pic"}
                            />
                          )}
                        </Avatar>
                        <ActivityBox>
                          <ActivityText>{activity.content}</ActivityText>
                          <TimePeriod>{timeAgo(activity.createdAt)}</TimePeriod>
                        </ActivityBox>
                      </ActivityRow>
                    ))}

                {activitiesData &&
                  activitiesData?.length > 0 &&
                  activitiesData?.length < activitiyCount && <Loader />}
                {activitiesData &&
                  activitiesData?.length > 0 &&
                  activitiesData?.length < activitiyCount && (
                    <Sentinel ref={sentinelRef} />
                  )}
              </ActivityContainerScroll>
            </ActivitiesContainer>
          )}
        </PageContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

export default LeaderboardPage;

const Sentinel = styled.div`
  height: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  // margin: 20px auto;
  padding: 26px;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 63vh;
  width: 90%;
  margin: 0 auto;
  overflow-y: auto;

  /* Custom scrollbar */

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
    height: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
    min-height: 70vh;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: Orbitron;
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 47.6px */
  text-transform: uppercase;
  margin: 20px 0 0 0;
  margin-top: 0px;

  /* @media (max-width: 768px) {
    display: none;
  } */
`;

const PageContainer = styled.div`
  display: flex;
  gap: 18px;
  /* margin: 20px auto; */
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const LeaderBoardTopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  @media (max-width: 1236px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const LeaderBoardProfileBox = styled.div`
  /* display: inline-flex;
  flex-direction: column;
  align-items: flex-start; */
  width: 45%;
  @media (max-width: 475px) {
    width: 95%;
  }
`;

const ReferalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #202416;
  min-width: 350px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 24px;
  @media (min-width: 1050px) and (max-width: 1290px) {
    margin-left: -25px;
    min-width: 340px;
  }
  @media (max-width: 400px) {
    width: 0;
    min-width: 300px;
  }
  /* @media (max-width: 1290px) {
    display: none;
  } */
`;
const ReferalContainerMobile = styled(ReferalContainer)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const EarnColums = styled.div`
  display: flex;
  flex-direction: column;
`;

const EarnColumsStraight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -31px;
  margin-right: 16px;
`;

const EarnCirclePointer = styled.div`
  width: 12px;
  height: 12px;
  background: #e9f6d0;
  border: 1px solid #b1dd40;
  border-radius: 100px;
`;
const EarnStraightLine = styled.div`
  height: 83px;
  width: 0px;
  margin: 4px 0;
  /* transform: rotate(-90deg); */
  background: #e9f6d0;
  border: 1px solid #b1dd40;
`;

const EarnTitle = styled.div`
  color: var(--primary, #b1dd40);

  /* desktop/misc/modal--title */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  text-transform: uppercase;
  opacity: 0.9;
  margin-top: 16px;
`;
const EarnSecontTitle = styled(EarnTitle)`
  margin-top: 16px;
`;
const EarnSubTitle = styled.div`
  color: #fff;

  /* desktop/chat/description--number */
  font-family: "Space Mono";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
  opacity: 0.9;
  @media (min-width: 1050px) and (max-width: 1290px) {
    width: 292px;
  }
  @media (max-width: 420px) {
    width: 292px;
  }
`;
const EarnCaption = styled.div`
  color: #fff;

  /* desktop/chat/message-sub-text */
  font-family: "Space Grotesk";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: 0.26px;
  opacity: 0.9;
`;
const EarnUnderlindedText = styled(Link)`
  color: var(--primary, #b1dd40);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  text-transform: uppercase;
  opacity: 0.9;
`;

const LeaderboardAndTableContainer = styled.div`
  flex: 1;
`;

const ActivitiesContainer = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ScrollableTableContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  height: 94vh;
  margin-top: 20px;
  @media (max-width: 768px) {
    height: 65vh;
  }
`;

const ActivityContainerScroll = styled.div`
  overflow-y: auto;
  height: 70vh;
`;

const TR = styled.tr``;

const ActivitiesTitle = styled.h2`
  color: ${(props) => props.theme.colors.light};
  border-bottom: ${(props) => props.theme.borders.light};
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const LeaderBoardTitle = styled.h2`
  color: ${(props) => props.theme.colors.light};
  /* border-bottom: ${(props) => props.theme.borders.light}; */
  padding-bottom: 8px;
  margin-top: 0px;
  margin-bottom: 0;
`;

const ActivityRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ActivityRowMobile = styled(ActivityRow)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const ActivityContainerMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  overflow-y: auto;
  height: 67vh;
  margin-top: 20px;
`;

const Avatar = styled.div`
  // Define your avatar styles here
`;

const ActivityBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityText = styled.span`
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const TimePeriod = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.56;
`;

const TableRow = styled.tr`
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  max-height: 400px;
  overflow-y: auto;

  th,
  tr,
  td {
    padding: 8px;
    text-align: left;
  }
`;

const TableHeader = styled.thead`
  /* background-color: ${(props) => props.theme.colors.darkBlack}; */
  color: ${(props) => props.theme.colors.offWhite};
  background: #25281b;
  border-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 50;

  th {
    padding-top: 13px;
    padding-bottom: 13px;
  }
`;

const THRank = styled.th`
  min-width: 80px;
`;
const THPot = styled.th`
  min-width: 140px;
`;
const THParticipants = styled.th`
  min-width: 120px;
`;
const THPotSize = styled.th`
  min-width: 100px;
`;

const TableBody = styled.tbody`
  /* z-index: -1;  */
`;

const TDRank = styled.td`
  color: #fff;
  font-family: "Space Mono", monospace;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const TDName = styled.td`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

const TDNameHighlighted = styled(TDName)`
  display: flex;
  gap: 8px;
  color: ${(props) => props.theme.colors.primary};
`;

const TDInvitedBy = styled.td`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const TDPoints = styled.td`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Mono", monospace;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const Tab = styled.button<{ isActive: boolean }>`
  flex: 0 0 auto;
  height: 48px;
  border-radius: 100px;
  padding: 0 24px;
  border: 2px solid transparent;
  cursor: pointer;
  background: rgba(233, 246, 208, 0.12);
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  &:hover,
  &.active {
    border-color: #fff;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    height: 42px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    padding: 0 18px;
    height: 38px;
    font-size: 14px;
  }
  ${(props) =>
    props.isActive &&
    `
    border-color: #fff;
  `}
`;

const YouTag = styled.div`
  display: flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: 20px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const FullWidthScrollContainer = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
  scrollbar-width: none; // Hides scrollbar for Firefox

  &::-webkit-scrollbar {
    display: none; // Hides scrollbar for Webkit browsers
  }

  white-space: nowrap; // Prevents wrapping into multiple lines
  display: none;
  @media (max-width: 768px) {
    display: flex; // Only show on mobile
  }
`;

const TabsAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
`;

const MobileTab = styled(Tab)`
  display: none; // Hide by default

  @media (max-width: 768px) {
    display: block; // Only show on mobile
  }
`;

const StatBox = styled.div`
  display: flex;
  height: 133px;
  padding: 0px 21px;
  /* width: 20.5%; */
  /* min-width: 40px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.dropdownBg};

  @media (max-width: 768px) {
    height: 100px;
    padding: 0px 20px;
  }

  @media (max-width: 480px) {
    height: 80px;
    padding: 0px 14px;
  }
`;

const StatTitle = styled.span`
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  opacity: 0.7;
`;

const StatValue = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 25px;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  width: 55%;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 36px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 475px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkeletonLoader = () => (
  <TR>
    <TDRank colSpan={4}>
      <div
        style={{
          height: "30px",
          background:
            "linear-gradient(0deg,rgba(233, 246, 208, 0.08) 0%,rgba(233, 246, 208, 0.08) 100%),#13160b",
          borderRadius: "4px",
          margin: "6px",
          opacity: 0.4,
        }}
      ></div>
    </TDRank>
  </TR>
);

const SkeletonLoaderForActivities = () => (
  <div
    style={{
      width: "100%",
      height: "30px",
      background:
        "linear-gradient(0deg,rgba(233, 246, 208, 0.08) 0%,rgba(233, 246, 208, 0.08) 100%),#13160b",
      borderRadius: "4px",
      margin: "6px",
      // margin-bottom: "26px",
      opacity: 0.4,
    }}
  ></div>
);

const ProfileImage = styled.img`
  width: 100%;
  /* max-width: 52px; */
  /* height: auto; */
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Loader = styled.div`
  border: ${(props) => `4px solid ${props.theme.colors.primary}`};
  border-radius: 50%;
  margin: 0 auto;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BottomSection = styled.div`
  position: relative;
  width: 97%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: rgba(233, 246, 208, 0.06);
  min-width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProfileRow = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  /* justify-content: start; */
  gap: 12px;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 6px;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// const AvatarIconSvg = styled(ProfileAvatar)`
//   height: 44px;
//   width: 44px;
//   margin-right: 12px;
// `;

const ProfileText = styled.span`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const ProfileAddress = styled.div`
  color: #b1dd40;
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const UserLevelBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 16px 16px 8px;
  gap: 8px;
`;

const LevelRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SecondLevelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: 1050px) and (max-width: 1290px) {
  }
  @media (max-width: 420px) {
    justify-content: flex-start;
    gap: 10px;
  }
`;

const LevelSectionOne = styled.div`
  margin-right: 6px;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LevelSectionTwo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;
const LevelIconBgIcon = styled.div`
  width: 100%; /* Set your desired width */
  height: 100%; /* Set your desired height */
  background-image: url(${LevelIconBg});
  background-repeat: no-repeat;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LevelNumber = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b1dd40;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LevelTitle = styled.div`
  color: #fff;

  /* desktop/form/label */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
`;
const LevelSubTitle = styled.div`
  color: var(--light, #e9f6d0);

  /* desktop/chat/time */
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const EarnTextField = styled.div`
  background-color: transparent;
  width: 328px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Dark-2, #333);
  padding: 4px 16px;
  @media (min-width: 1050px) and (max-width: 1290px) {
    width: 282px;
  }
  @media (max-width: 420px) {
    width: 282px;
  }
`;
const LinkTextTitle = styled.span`
  color: var(--Grey, #b8b8b8);
  font-family: "Space Grotesk";
  /* font-family: Manrope; */
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 200% */
`;
const LinkText = styled.span`
  display: flex;
  width: 232px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Select-Border-Style, #fff);
  font-family: "Space Grotesk";
  /* font-family: Manrope; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
`;

const LinkCopy = styled(HoverButton)`
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.primary};
  &:hover {
    background: #b9ff04;
    box-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  }
`;
const EarnAndReferalBox = styled.div`
  width: fit-content;
  max-width: 100%;
`;

const EarnReferalBg = styled.div`
  width: 100%; /* Set your desired width */
  height: 100%; /* Set your desired height */
  width: fit-content;
  min-width: 328px;
  height: 144px;
  background-image: url(${EarnAndReferalBg});
  background-repeat: no-repeat;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  align-items: center;
  @media (min-width: 1790px) {
    min-width: 430px;
  }
  @media (min-width: 1590px) and (max-width: 1790px) {
    min-width: 400px;
  }
  @media (min-width: 1050px) and (max-width: 1290px) {
    min-width: 290px;
  }
  @media (max-width: 420px) {
    min-width: 290px;
  }
`;

const EarnAndReferalText = styled.div`
  width: 217px;
  height: 56px;
  flex-shrink: 0;
  color: #fff;
  font-family: "Space Grotesk";
  /* font-family: Manrope; */
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 118.182% */
  margin-left: 10px;
  & span {
    color: #b1dd40;
  }
  @media (min-width: 1050px) and (max-width: 1290px) {
    width: 190px;
  }
  @media (max-width: 420px) {
    width: 190px;
  }
`;

const EarnImageMountain = styled.img`
  width: 100%;
  max-width: 150px;
  height: 119px;
  flex-shrink: 0;
  margin-left: -5px;
  @media (min-width: 1050px) and (max-width: 1290px) {
    max-width: 130px;
    height: 100px;
  }
  @media (max-width: 420px) {
    max-width: 100px;
    height: 80px;
  }
`;

const RankRow = styled.div`
  display: flex;
  padding: 0px 16px 16px 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  @media (max-width: 350px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const RankRowFirst = styled(RankRow)`
  margin-top: 30px;
`;

const RankBox = styled.div`
  display: flex;
  width: 120px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: rgba(233, 246, 208, 0.06);
`;
const RefferalBox = styled.div`
  display: flex;
  min-width: 130px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: rgba(233, 246, 208, 0.06);
`;
const TotalQuailPointsBox = styled.div`
  display: flex;
  flex: 1;
  // min-width: 120px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: rgba(233, 246, 208, 0.06);
`;

const RankLabel = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.7;
`;
const RankNumber = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckMarkIcon from "../../assets/TickIcon.svg";
import CheckEmailIcon from "../../assets/emailIcon.svg";
import DeleteEmailIcon from "../../assets/deleteIcon.svg";
import EmailNotificationModal from "./EmailNotificationModal";

const ThreeDotsModalContent: React.FC<{
  handleRemove: () => void;
  onClose: () => void;
}> = ({ handleRemove, onClose }) => {
  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent event propagation to the backdrop
  };
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <ModalContent>
          {/* <ModalText>
                        <img style={{marginRight: "0.75em"}} src={CheckMarkIcon} alt={'CheckMarkIcon'}/>
                        Mark all as read
                    </ModalText>
                    <ModalText
                        onClick={handleEmailNotificationClick}
                    >
                        <img style={{marginRight: "0.75em"}} src={CheckEmailIcon} alt={"CheckEmailIcon"}/>
                        Email notification
                    </ModalText> */}
          <ModalText onClick={handleRemove}>
            <img
              style={{
                marginRight: "0.75em",
                width: "14px",
                height: "15.56px",
              }}
              src={DeleteEmailIcon}
              alt={"CheckEmailIcon"}
            />
            Remove notification
          </ModalText>
        </ModalContent>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default ThreeDotsModalContent;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  position: fixed;
  top: 130px;
  right: 160px;
  width: 80vw;
  height: 86vh;
  z-index: 1000;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 768px) {
    right: 100px;
    width: 100%;
    height: 100%;
    top: 20px;
    padding-top: 50px;
  }
  @media (max-width: 475px) {
    right: 10px;
  max-width: 340px;
    top: 60px;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 23%;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 75%;
  }

  /* Custom scrollbar */

  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const ModalContent = styled.div`
  text-align: center;
`;
const ModalText = styled.div`
  color: #fff;
  line-height: 1.5em;
  padding: 1em;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

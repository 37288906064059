import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../assets/Logo.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as UsdIconSvg } from "../../assets/usd_icon.svg";
import NewCoinImg from "../../assets/newCoinImg.svg";
import { dateFormatVal } from "../../utils/helper";
import Skeleton from "../Skeleton/Skeleton";

const ClaimSuccessModal: React.FC<{
  onClose: () => void;
  statusData?: any;
  claimSuccessModalRef?: any;
}> = ({ onClose, statusData, claimSuccessModalRef }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <>
      <MobileModalBackdrop visible={visible} onClick={handleBackdropClick}>
        {!statusData ? (
          <ModalContainer visible={visible}>
            <SkeletonWrapper>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </SkeletonWrapper>
          </ModalContainer>
        ) : (
          <MobileModalContainer visible={visible}>
            <CloseIcon onClick={handleClose} />
            <ModalTitle>You have successfully Claimed your reward</ModalTitle>
            <ModalText>
              Yay! It's Your Turn To Enjoy Collateral-Free Liquidity.
            </ModalText>
            <ContentBox>
              <PotNameRow>
                Pot
                <Highlight>{statusData?.pot?.name}</Highlight>
              </PotNameRow>

              {statusData?.claimedOn ? (
                <DepositedDetailRow>
                  <span>Claimed On</span>
                  <Highlight>
                    {statusData?.claimedOn ? (
                      dateFormatVal(statusData?.claimedOn)
                    ) : (
                      <></>
                    )}
                  </Highlight>
                </DepositedDetailRow>
              ) : (
                <></>
              )}
              <DepositAmountRow>
                <span>Claimed amount</span>
                <AmountBox>
                  <img
                    src={NewCoinImg}
                    alt={"NewCoinImg"}
                    style={{ width: "1.5em", marginRight: "0.5em" }}
                  />{" "}
                  <Highlight>{statusData?.totalClaimed} USDB</Highlight>
                </AmountBox>
              </DepositAmountRow>
              <DepositButton onClick={handleClose}>{"Close"}</DepositButton>
            </ContentBox>
          </MobileModalContainer>
        )}
      </MobileModalBackdrop>

      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <Row>
            <Columns>
              <LogoSvg />
            </Columns>
            <Columns>
              <PopupTitle>
                You have successfully CLAIMED to “{statusData?.pot?.name}”
              </PopupTitle>
              <PopupDate>
                {statusData?.claimedOn ? (
                  dateFormatVal(statusData?.claimedOn)
                ) : (
                  <></>
                )}
              </PopupDate>
              {/* <PopupTitle>You have successfully Claimed</PopupTitle> */}
              <PopupSubTitle>
                Yay! It's Your Turn To Enjoy Collateral-Free Liquidity.
              </PopupSubTitle>
              {/* <PopupSubTitle>
                Your points will be reflected on dashboard in 24hours
              </PopupSubTitle> */}
              {/* <Row>
                <Columns>
                  <PopupHighlight>+200 Points</PopupHighlight>
                </Columns>
              </Row> */}
              <Row>
                <Columns>
                  <PopupTextButton>
                    CLAIMED AMOUNT:{" "}
                    <img
                      src={NewCoinImg}
                      alt={"NewCoinImg"}
                      style={{ width: "24px", height: "24px", margin: "0 8px" }}
                    />{" "}
                    {statusData?.totalClaimed} USDB
                  </PopupTextButton>
                </Columns>
              </Row>
            </Columns>
          </Row>
        </ModalContainer>
      </ModalBackdrop>
    </>
  );
};

export default ClaimSuccessModal;

// Styled Components
const MobileModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align to the bottom in mobile view */
  gap: 32px;
  z-index: 1000;
  top: -48px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (min-width: 768px) {
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 475px) {
    display: flex;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 90px;
  }
`;

const MobileModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 550px;
  padding: 40px;
  /* padding: 48px 48px 40px 48px; */
  border: 1px solid rgba(233, 246, 208, 0.16);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    /* Apply styles for mobile view */
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    max-width: none; /* Remove max-width for full width */
    padding: 24px; /* Adjust padding as needed */
    border-radius: 12px 12px 0 0; /* Rounded corners at the top */
    transform: translateY(0); /* Reset translateY for mobile */
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
    align-items: normal;
  }
  @media (max-width: 475px) {
    display: flex;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 90px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  align-items: flex-start;

  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    display: none;
  }
`;
const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  width: 100%;
  max-width: 700px;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media (max-width: 475px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 30px;
`;
const Columns = styled.div`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  gap: 16px;
`;
const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;
`;
const PopupTitle = styled.div`
  color: #b1dd40;
  max-width: 600px;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
`;
const PopupDate = styled.div`
  color: #7c7c7c;
  /* Font size-7/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: -10px;
`;
const PopupSubTitle = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;
const PopupHighlight = styled.div`
  color: var(--primary, #b1dd40);
  font-family: "Space Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;
const PopupTextButton = styled.div`
  color: var(--primary, #b1dd40);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const UsdIcon = styled(UsdIconSvg)`
  margin-right: 8px;
`;
const AmountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 55%;
  height: 44px;
  padding: 0px 16px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: rgba(233, 246, 208, 0.08);
  border: 1px solid rgba(233, 246, 208, 0.08);
  @media (max-width: 768px) {
    margin-top: 1em;
    width: 90%;
  }
`;

const DepositAmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  // flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 24px 0 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: self-start;
  }
`;
const Highlight = styled.span`
  color: #fff;
  font-family: "Space Grotesk, sans-serif";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 200;
  }
`;
const DepositedDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
const PotNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 24px;
  border-radius: 8px;
  background: rgba(233, 246, 208, 0.08);
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  @media (max-width: 475px) {
    width: 70%;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 768px) {
    width: 343px;
    font-size: 16px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
  opacity: 0.7;
  text-align: center;
  @media (max-width: 768px) {
    width: 300px;
    font-size: 14px;
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const DepositButton = styled.button`
  display: none;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* desktop/misc/button-md */
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 1.5em;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;

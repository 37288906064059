import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import WalletConnectingModal from "./Modal/WalletConnectingModal";
import { localStorageStates } from "../utils";
import { checkUserSigned } from "../utils/helper";
import { useWeb3React } from "@web3-react/core";
import HoverButton from "./Button/HoverButton";

const WalletNotConnected: React.FC = () => {
  const navigate = useNavigate();
  const { isActive } = useWeb3React();
  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;
  const twitterUsername = localStorage.getItem(
    localStorageStates.twitterUsername
  );

  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const handleConnectWalletClick = () => {
    setWalletModalOpen(true);
  };
  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  const handleConnect = () => {
    navigate("/early-access");
  };

  return (
    <Container>
      <HeadingBox>
        {userInfoData?.hasPaidFee && userInfoData?.hasPaidFee === false ? (
          <MainHeading>Create Your Quail Profile</MainHeading>
        ) : (
          <MainHeading>
            {checkUserSigned() && !isActive
              ? "Connect Your Wallet & Twitter Account"
              : "Sign the message"}
          </MainHeading>
        )}
        {userInfoData?.hasPaidFee && userInfoData?.hasPaidFee === false ? (
          <SubHeading>Please Create Your Quail Profile</SubHeading>
        ) : (
          <SubHeading>
            {checkUserSigned()
              ? "Please Connect Your Wallet & Twitter Account to see your dashboard."
              : "Please sign the message from wallet or reload the page to continue."}
          </SubHeading>
        )}
      </HeadingBox>
      {userInfoData?.hasPaidFee && userInfoData?.hasPaidFee === false ? (
        <ButtonBox>
          <ConnectWalletButton
            onClick={handleConnect}
            height="60px"
            padding="8px 28px"
          >
            <span>{"Create Profile"}</span>
          </ConnectWalletButton>
        </ButtonBox>
      ) : (
        <ButtonBox>
          {checkUserSigned() && !isActive ? (
            <ConnectWalletButton
              onClick={handleConnect}
              height="60px"
              padding="8px 28px"
            >
              <span>{"Connect"}</span>
            </ConnectWalletButton>
          ) : (
            !twitterUsername &&
            twitterUsername !== null && (
              <ConnectWalletButton
                onClick={handleConnect}
                height="60px"
                padding="8px 28px"
              >
                <span>{"Connect Twitter"}</span>
              </ConnectWalletButton>
            )
          )}
        </ButtonBox>
      )}
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
    </Container>
  );
};

export default WalletNotConnected;

const Container = styled.div`
  margin: 130px auto;
  margin-bottom: 300px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (min-width: 1800px) {
    height: 80vh;
  }
  @media (min-width: 1700px) {
    height: 50vh;
  }
  @media (min-width: 1000px) {
    height: 40vh;
  }
  @media (max-width: 475px) {
    height: 20vh;
  }
`;

export const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  max-width: 600px;
  font-size: 33px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 11px;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 19px;
    text-align: left;
    max-width: 300px;
  }
`;

export const SubHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 17px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0;
  margin-bottom: 18px;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    text-align: left;
    max-width: 300px;
    justify-content: center;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  /* @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  } */
`;

export const HeadingBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 60px;
  padding: 8px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 9px 24px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 40px;
    padding: 9px 22px;
    font-size: 14px;
  }
`;

export const ConnectWalletButton = styled(HoverButton)<{}>`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  pointer-events: ${(props) => "auto"};
  box-shadow: ${(props) => props.theme.boxShadow.button};
  /* &:hover {
    background: #b9ff04;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  } */
`;

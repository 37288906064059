// components/HoverButton.js
import React, { useState, useRef } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";

interface ButtonProps {
  height?: string;
  padding?: string;
  disabled?: boolean;
}

const HoverButton: React.FC<ButtonProps & { children: React.ReactNode, onClick: any, }> = ({ children, onClick, padding, height, disabled }) => {
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: {
    currentTarget: { getBoundingClientRect: () => any };
    clientX: number;
    clientY: number;
  }) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setHoverPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleClick = () => {
    if (buttonRef.current) {
      const button = buttonRef.current;
      button.classList.remove("ripple");
      void button.offsetWidth; // Trigger reflow to restart the animation
      button.classList.add("ripple");
    }
    onClick();
  };

  return (
    <>
      <GlobalStyle />
      <ButtonWrapper
        ref={buttonRef}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        height={height}
        padding={padding}
      >
        <HoverEffect
          style={{ left: `${hoverPosition.x}px`, top: `${hoverPosition.y}px` }}
        />
        <ButtonText>{children}</ButtonText>
      </ButtonWrapper>
    </>
  );
};

export default HoverButton;

const ripple = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  20% {
    transform: translate(-50%, -50%) scale(25);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(40);
    opacity: 0;
  }
`;

const ButtonWrapper = styled.div<ButtonProps>`
  /* position: relative;
  padding: 0.5rem 1rem;
  background: #3b82f6;
  border-radius: 0.25rem;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  display: inline-block;
  cursor: pointer; */
  cursor: pointer;
  position: relative;
  display: flex;
  overflow: hidden;
  height: ${(props) => props.height || "48px"};
  padding: ${(props) => props.padding || "0 24px"};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  background: ${(props) => props.disabled ? "#ccc" : props.theme.colors.primary};
  color: ${(props) => (props.disabled ? "#666" : props.theme.colors.dark)};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;
  &:hover {
    /* background: #b9ff04; */
    /* box-shadow: ${(props) => props.theme.boxShadow.button}; */
  }
  &:active {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    /* transform: translateY(-0.1rem); */
    /* animation: ${ripple} 1s ease-out; */
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    pointer-events: none;
  }
  &:focus:not(:active)::after {
    animation: ${ripple} 1s ease-out;
  }
`;

const HoverEffect = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background: radial-gradient(circle closest-side, #90bc1f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
  pointer-events: none;
  ${ButtonWrapper}:hover & {
    width: 300px;
    height: 300px;
  }
  ${ButtonWrapper}:active & {
    width: 600px;
    height: 600px;
  }
`;

const ButtonText = styled.span`
  z-index: 10;
  position: relative;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GlobalStyle = createGlobalStyle`
  .ripple::after {
    animation: ${ripple} 1s ease-out;
  }
`;

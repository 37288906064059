// RippleButton.js
import React from "react";
import styled, { keyframes } from "styled-components";
const OutlinedButton: React.FC<{
  outlinedText: React.ReactNode;
  onClick?: any;
}> = ({ outlinedText, onClick }) => {
  const createRipple = (event: {
    currentTarget: any;
    clientX: number;
    clientY: number;
  }) => {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
    onClick();
  };
  return <Button onClick={createRipple}>{outlinedText}</Button>;
};

export default OutlinedButton;
const rippleAnimation = keyframes`
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

const Button = styled.button`
  position: relative;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary, #b1dd40);
  background-color: transparent;
  border: 2px solid #b1dd40;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: rgba(177, 221, 64, 0.1);
    /* transform: translateY(-2px); */
  }

  &:active {
    background-color: rgba(177, 221, 64, 0.2);
    transform: translateY(0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(177, 221, 64, 0.4);
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ${rippleAnimation} 600ms linear;
    background-color: rgba(177, 221, 64, 0.5);
  }
  @media (max-width: 475px) {
  padding: 3px 10px;
  font-size: 14px;
  }
`;

import type { AddEthereumChainParameter } from "@web3-react/types";
import { walletStates } from ".";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { GnosisSafe } from "@web3-react/gnosis-safe";
import { MetaMask } from "@web3-react/metamask";
import type { Connector } from "@web3-react/types";
import { WalletConnect as WalletConnect } from "@web3-react/walletconnect";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

const ETH: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
};

const MATIC: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};

const CELO: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Celo",
  symbol: "CELO",
  decimals: 18,
};

const SepoliaETH: AddEthereumChainParameter["nativeCurrency"] = {
  name: "ETH",
  symbol: "ETH",
  decimals: 18,
};

const BLAST: AddEthereumChainParameter["nativeCurrency"] = {
  name: "BLAST",
  symbol: "BLAST",
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

const getInfuraUrlFor = (network: string) =>
  process.env.REACT_APP_INFURA_KEY
    ? `https://${network}.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    : undefined;
const getAlchemyUrlFor = (network: string) =>
  process.env.REACT_APP_ALCHEMY_KEY
    ? `https://${network}.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_KEY}`
    : undefined;

type ChainConfig = {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
};

export const MAINNET_CHAINS: ChainConfig = {
  81457: {
    urls: ["https://rpc.blast.io"],
    name: "Blast",
    nativeCurrency: SepoliaETH,
    blockExplorerUrls: ["https://blastscan.io"],
  },
  1: {
    urls: [
      getInfuraUrlFor("mainnet"),
      getAlchemyUrlFor("eth-mainnet"),
      "https://cloudflare-eth.com",
    ].filter(Boolean) as string[],
    name: "Mainnet",
  },
  10: {
    urls: [
      getInfuraUrlFor("optimism-mainnet"),
      "https://mainnet.optimism.io",
    ].filter(Boolean) as string[],
    name: "Optimism",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
  },
  42161: {
    urls: [
      getInfuraUrlFor("arbitrum-mainnet"),
      "https://arb1.arbitrum.io/rpc",
    ].filter(Boolean) as string[],
    name: "Arbitrum One",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://arbiscan.io"],
  },
  137: {
    urls: [
      getInfuraUrlFor("polygon-mainnet"),
      "https://polygon-rpc.com",
    ].filter(Boolean) as string[],
    name: "Polygon Mainnet",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  42220: {
    urls: ["https://forno.celo.org"],
    name: "Celo",
    nativeCurrency: CELO,
    blockExplorerUrls: ["https://explorer.celo.org"],
  },
};

export const TESTNET_CHAINS: ChainConfig = {
  5: {
    urls: [getInfuraUrlFor("goerli")].filter(Boolean) as string[],
    name: "Görli",
  },
  420: {
    urls: [
      getInfuraUrlFor("optimism-goerli"),
      "https://goerli.optimism.io",
    ].filter(Boolean) as string[],
    name: "Optimism Goerli",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://goerli-explorer.optimism.io"],
  },
  421613: {
    urls: [
      getInfuraUrlFor("arbitrum-goerli"),
      "https://goerli-rollup.arbitrum.io/rpc",
    ].filter(Boolean) as string[],
    name: "Arbitrum Goerli",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://testnet.arbiscan.io"],
  },
  80001: {
    urls: [getInfuraUrlFor("polygon-mumbai")].filter(Boolean) as string[],
    name: "Polygon Mumbai",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  44787: {
    urls: ["https://alfajores-forno.celo-testnet.org"],
    name: "Celo Alfajores",
    nativeCurrency: CELO,
    blockExplorerUrls: ["https://alfajores-blockscout.celo-testnet.org"],
  },
  11155111: {
    urls: ["https://sepolia.blast.io"],
    name: "Sepolia",
    nativeCurrency: SepoliaETH,
    blockExplorerUrls: ["https://testnet.blastscan.io"],
  },
  168587773: {
    urls: ["https://sepolia.blast.io"],
    name: "BLAST",
    nativeCurrency: BLAST,
    blockExplorerUrls: ["https://testnet.blastscan.io"],
  },
};

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

export function getName(connector: Connector) {
  if (connector instanceof MetaMask) return walletStates.metamask;
  if (connector instanceof WalletConnectV2) return walletStates.walletconnect;
  // if (connector instanceof WalletConnect) return "WalletConnect";
  if (connector instanceof CoinbaseWallet) return walletStates.coinbase;
  // if (connector instanceof GnosisSafe) return "Gnosis Safe";
  return "";
}

import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface WithdrawFromPotTxProps {
  potId: number;
  participantAddress: string;
  isWinner: boolean;
  potCode?: any;
}

export const withdrawFromPotTx = async ({
  potId,
  participantAddress,
  isWinner,
  potCode,
}: WithdrawFromPotTxProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;
  if (Quail_Finance_Contract_Address) {
    try {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(
        Quail_Finance_Contract_Address,
        QuailABI,
        signer
      );

      // First, get the participant index
      const index = await contract.getParticipantIndex(
        potId,
        participantAddress
      );

      // Now, proceed to withdraw from the pot using the index
      const tx = await contract.withdrawFromPot(potId, index, isWinner);
      console.log("Transaction hash:", tx.hash);

      localStorage.setItem(
        "pendingLeavePotTx",
        JSON.stringify({
          transactionHash: tx.hash,
          potCode,
        })
      );
      const receipt = await tx.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
        localStorage.removeItem("pendingLeavePotTx");
      } else if (receipt && receipt.status === 0) {
        localStorage.removeItem("pendingLeavePotTx");
        throw new Error("Transaction failed and was reverted by the EVM");
      }
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Emailsvg } from "../../assets/greenMail.svg";
import { ReactComponent as LogoIcon } from "../../assets/Logo.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import HoverButton from "../Button/HoverButton";

const InvitePotModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <>
      <MobileModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <MobileModalContainer visible={visible}>
          <EmailIcon />
          <ModalTitle>subcription Confirmation</ModalTitle>
          <ModalText>You have successfully subscribed.</ModalText>
          <div style={{ width: "100%", marginTop: "32px" }}>
            <CloseButton
              onClick={() => handleClose()}
              height="44px"
              padding="0"
            >
              Close
            </CloseButton>
          </div>
        </MobileModalContainer>
      </MobileModalBackdrop>

      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <Row>
            <Columns>
              <LogoSvg />
            </Columns>
            <Columns>
              <PopupTitle>subcription Confirmation</PopupTitle>

              <PopupSubTitle>You have successfull subscribed</PopupSubTitle>
            </Columns>
          </Row>
        </ModalContainer>
      </ModalBackdrop>
    </>
  );
};

export default InvitePotModal;

// Styled Componentsyr

const MobileModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  width: 100vw;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 614px) {
    width: 90vw;
    justify-content: flex-end;
  }
  @media (max-width: 475px) {
    display: flex;
  }

  @media (max-width: 297px) {
    width: 100vw;
  }
`;

const MobileModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: none;
  width: 508px;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);

  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 614px) {
    width: 100%;
    padding: 18px 36px 130px 22px;
  }
  @media (max-width: 475px) {
    display: flex;
  }
`;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  align-items: flex-start;

  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    display: none;
  }
`;
const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  width: 100%;
  max-width: 505px;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media (max-width: 475px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 50px;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;
`;
const PopupTitle = styled.div`
  color: #b1dd40;
  max-width: 440px;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
`;
const PopupSubTitle = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const EmailIcon = styled(Emailsvg)`
  width: 32px;
  height: 27.2px;
  margin-bottom: 32px;
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  text-align: center;

  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 614px) {
    font-size: 18px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  opacity: 0.7;
  margin-top: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CloseButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 32px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

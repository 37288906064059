import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as AddCircle } from "../../assets/add_circle.svg";
import { ReactComponent as Groups } from "../../assets/groups.svg";

const AddPotModal: React.FC<{
  onClose: () => void;
  onClickJoinPotHandle: () => void;
  onClickCreatPotHandle: () => void;
  addModalRef: any;
}> = ({
  onClose,
  onClickJoinPotHandle,
  onClickCreatPotHandle,
  addModalRef,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>Add a pot</ModalTitle>
        <ContentRow>
          <ContentBox onClick={onClickCreatPotHandle}>
            <AddCircle />
            <Highlight>Create a new pot</Highlight>
          </ContentBox>
          <ContentBox onClick={onClickJoinPotHandle}>
            <GroupIcon />
            <Highlight>Join a pot</Highlight>
          </ContentBox>
        </ContentRow>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default AddPotModal;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 50px 40px 40px 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  z-index: 1000;
  top: -40px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  flex-shrink: 0;
  @media (min-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 480px;
  /* padding: 48px 48px 40px 48px; */
  padding: 40px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    /* Apply styles for mobile view */
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    max-width: none; /* Remove max-width for full width */
    padding: 24px; /* Adjust padding as needed */
    transform: translateY(0); /* Reset translateY for mobile */
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
  }
  @media (max-width: 400px) {
    padding: 15px 35px 15px 0px;
    border-radius: 8px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  text-align: center;
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 768px) {
    width: 343px;
    font-size: 16px;
  }
`;

const AddCircleIcon = styled(AddCircle)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;
const GroupIcon = styled(Groups)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

const Highlight = styled.span`
  color: #fff;
  text-align: center;

  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 200;
  }
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px
`;
const ContentBox = styled.div`
  cursor: pointer;
  display: flex;
  height: 160px;
  max-width: 186px;
  width: auto;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.12);
  @media (max-width: 400px) {
    /* height: 130px; */
    max-width: 140px;
  }
  @media (max-width: 375px) {
    max-width: 120px;
    height: 140px;
  }
  @media (max-width: 355px) {
    max-width: 100px;
    height: 110px;
  }
`;

import React, { useEffect } from "react";
import EarlyAccessVerification from "../pages/early-access-verification";
import Chats from "../pages/chats";
import { Navigate, useParams } from "react-router-dom";
import { localStorageStates } from "../utils";
import { checkUserSigned } from "../utils/helper";
import { IsRedeemedButNotConnected } from "./isRedeemedButNotConnected";
import { IsRegistered } from "./isRegitered";

export const TokenLengthBasedComponent = () => {
  let { token } = useParams();
  if (token && token.length === 5) {
    return (
      <IsRedeemedButNotConnected>
        <EarlyAccessVerification />
      </IsRedeemedButNotConnected>
    );
  } else if (token && token.length === 6) {
    return (
      <IsRegistered>
        <Chats />
      </IsRegistered>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};

import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as UsdIconSvg } from "../../assets/dollor_blue.svg";

const DistributedSuccessModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <ModalBackdrop>
      <ModalContainer>
        <CloseIcon onClick={onClose} />
        <ModalTitle>You have successfully Distributed risk pool</ModalTitle>
        <ModalText>
          Nulla Lorem mollit cupidatat irure. Laborum magna cillum dolor.
        </ModalText>
        <ContentBox>
          <PotNameRow>
            Pot
            <Highlight>Boawaah Pot</Highlight>
          </PotNameRow>
          <DepositedDetailRow>
            <span>Total Risk Pool</span>
            <RiskHighlight>
              1000
              <RiskUsdIcon />
            </RiskHighlight>
          </DepositedDetailRow>
          <DepositAmountRow>
            <span>Distribution per person</span>
            <AmountBox>
              <UsdIcon />
              <Highlight>100 USDB</Highlight>
            </AmountBox>
          </DepositAmountRow>
          <DepositButton onClick={onClose}>{"Close"}</DepositButton>
        </ContentBox>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default DistributedSuccessModal;

// Styled Componentsyr

const UsdIcon = styled(UsdIconSvg)`
  margin-right: 8px;
`;

const RiskUsdIcon = styled(UsdIconSvg)`
  margin-left: 8px;
`;
const AmountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 44px;
  padding: 0px 16px;
  border-radius: 8px;
  margin-top: 8px;
  border: 1px solid rgba(233, 246, 208, 0.08);
  @media (max-width: 768px) {
    margin-top: 1em;
    width: 90%;
  }
`;

const DepositAmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px 0 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: self-start;
  }
`;
const Highlight = styled.span`
  color: #fff;
  font-family: "Space Mono";
  /* font-family: "Space Grotesk, sans-serif"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 200;
  }
`;

const RiskHighlight = styled(Highlight)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Mono";
`;
const DepositedDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
const PotNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 24px;
  border-radius: 8px;
  background: rgba(233, 246, 208, 0.08);
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
`;
const ModalBackdrop = styled.div`
  position: fixed;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align to the bottom in mobile view */
  gap: 32px;
  z-index: 1000;
  top: -48px;
  @media (min-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 550px;
  padding: 40px;
  /* padding: 48px 48px 40px 48px; */
  border: 1px solid rgba(233, 246, 208, 0.16);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);

  @media (max-width: 768px) {
    /* Apply styles for mobile view */
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    max-width: none; /* Remove max-width for full width */
    padding: 24px; /* Adjust padding as needed */
    border-radius: 12px 12px 0 0; /* Rounded corners at the top */
    transform: translateY(0); /* Reset translateY for mobile */
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 18px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 768px) {
    width: 343px;
    font-size: 16px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
  opacity: 0.7;
  text-align: center;
  @media (max-width: 768px) {
    width: 300px;
    font-size: 14px;
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const DepositButton = styled.button`
  display: none;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* desktop/misc/button-md */
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 1.5em;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

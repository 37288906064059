import React, { lazy } from "react";
import { ReactComponent as XLogo } from "../../assets/x-logo.svg";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";

interface AccountInfoProps {
  rewardPoints: number;
  profilePic: string;
  userName: string;
  ranking: number;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  rewardPoints,
  profilePic,
  userName,
  ranking,
}) => {
  const { account, isActive } = useWeb3React();
  return (
    <UserProfileRow>
      <UserProfile>
        {/* <Image src={AvatarPic} alt="Avatar" /> */}
        <ProfileImage src={profilePic} alt={"Profile pic"} />

        {/* <Image
          src={profilePic}
          alt="Avatar"
          width={48}
          height={48}
          layout="fixed"
        /> */}
        <UserInfo>
          <UserAccountInfoBox>
            <UserName>@{userName}</UserName>
            {isActive && account && (
              <UserAddress>
                {" "}
                {`${account.substring(0, 10)}...${account.substring(
                  account.length - 8
                )}`}
              </UserAddress>
            )}
          </UserAccountInfoBox>

          <TwitterBox>
            <XLogo />
          </TwitterBox>
        </UserInfo>
      </UserProfile>
      <StatsContainer>
        <StatBox>
          <StatTitle>Ranking</StatTitle>
          <StatValue>#{ranking}</StatValue>
        </StatBox>
        <StatBox>
          <StatTitle>Total points</StatTitle>
          <StatValue>{rewardPoints?.toLocaleString()}</StatValue>
        </StatBox>
      </StatsContainer>
    </UserProfileRow>
  );
};

export default AccountInfo;

const UserProfileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
    // background-color: red;
  }
`;

const UserProfile = styled.div`
  display: flex;
  width: 60%;
  gap: 16px;

  @media (max-width: 768px) {
    width: 100%;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    gap: 26px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const UserAccountInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UserName = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
`;

const UserAddress = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-family: "Space Mono", monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
`;

const TwitterBox = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  margin-top: 8px;
`;
const StatBox = styled.div`
  display: flex;
  padding: 0px 24px;
  width: auto;
  min-width: 128px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0.5em;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.dropdownBg};

  @media (max-width: 768px) {
    min-width: unset;
  }
`;

const StatValue = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;
  word-break: break-all;

  @media (max-width: 768px) {
    word-break: break-word;
  }
`;

const StatTitle = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  max-width: 116px;
  height: auto;
  border-radius: 50%;
`;

import React, { Suspense, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import WalletConnectingModal from "../Modal/WalletConnectingModal";
import Section1Pic from "../../assets/section_1.png";
import Section2Pic from "../../assets/section_2.png";
import Section3Pic from "../../assets/section_3.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { checkUserSigned } from "../../utils/helper";
import HoverButton from "../Button/HoverButton";

interface RotationSectionProps {
  isRegistered: boolean;
}

const RotationSection: React.FC<RotationSectionProps> = ({ isRegistered }) => {
  const navigate = useNavigate();
  const { account, isActive } = useWeb3React();
  const { isSigningMessage } = useSelector((state: RootState) => state.wallet);
  const [wantsEarlyAccess, setWantsEarlyAccess] = useState(false);
  const [isRegisteredInState, setIsRegisteredInState] = useState(false);
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);

  const handleCreatePot = () => {
    navigate("/createPot");
  };

  useEffect(() => {
    setIsRegisteredInState(isRegistered);
  }, [isRegistered]);

  const handleEarlyAccessClick = async () => {
    if (account && isActive) {
      if (!checkUserSigned()) {
        toast.error(
          "Please sign the message from wallet or reload the page to continue",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setWantsEarlyAccess(true);
        return;
      }
      navigate(isRegisteredInState ? "/createPot" : "/early-access");
    } else {
      setWantsEarlyAccess(true);
      setWalletModalOpen(true);
    }
  };

  useEffect(() => {
    if (account && isActive && wantsEarlyAccess && checkUserSigned()) {
      const path = isRegisteredInState ? "/createPot" : "/early-access";
      navigate(path);
      setWantsEarlyAccess(false);
    }
  }, [
    account,
    isActive,
    wantsEarlyAccess,
    isRegisteredInState,
    navigate,
    isSigningMessage,
  ]);

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  return (
    <>
      <Box>
        <SubHeading>How it works</SubHeading>
        <MainHeading>Create, Invite, Rotate</MainHeading>
        {/* <MainHeading>Rotation of Liquidity based on Social Trust</MainHeading> */}
        <ContentBox>
          <SectionBox>
            <MobileImageContainer>
              <img
                src={Section1Pic}
                alt="Section 1"
                style={{ width: "100%", height: "auto" }}
              />
            </MobileImageContainer>
            <ContentInfo>
              <StepNumberContainer>
                <StepNumber>1</StepNumber>
              </StepNumberContainer>
              <HeadingBox>
                <SectionHeading>create a pot</SectionHeading>
                <Content>
                  Quickly set up your pot by choosing the amount, the number of
                  participants, the interest rate, and the rotation cycle. It's
                  like laying the groundwork for a cooperative financial
                  venture.
                </Content>
                <div style={{ maxWidth: "180px" }}>
                  <CreateAPotButton
                    onClick={handleEarlyAccessClick}
                    height="48px"
                    padding="0 16px"
                  >
                    <span>
                      {isRegisteredInState ? "Create POT" : "Create POT"}
                    </span>
                  </CreateAPotButton>
                </div>
              </HeadingBox>
            </ContentInfo>
            <ImageContainer>
              <img
                src={Section1Pic}
                alt="Section 1"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageContainer>
          </SectionBox>
          <SectionBox>
            <MobileImageContainer>
              <Suspense fallback={<div>Loading...</div>}>
                <img
                  src={Section2Pic}
                  alt="Section 2"
                  style={{ width: "100%", height: "auto" }}
                />
              </Suspense>
            </MobileImageContainer>
            <ContentInfo>
              <StepNumberContainer>
                <StepNumber>2</StepNumber>
              </StepNumberContainer>
              <HeadingBox>
                <SectionHeading>invite friends</SectionHeading>
                <Content>
                  Invite peers from your NFT Community, Alpha Groups, or DAO
                  using a simple invite link. It's about building a trusted
                  network for collaborative investing.
                </Content>
              </HeadingBox>
            </ContentInfo>
            <ImageContainer>
              <Suspense fallback={<div>Loading...</div>}>
                <img
                  src={Section2Pic}
                  alt="Section 2"
                  style={{ width: "100%", height: "auto" }}
                />
              </Suspense>
            </ImageContainer>
          </SectionBox>
          <SectionBox>
            <MobileImageContainer>
              <Suspense fallback={<div>Loading...</div>}>
                <img
                  src={Section3Pic}
                  alt="Section 3"
                  style={{ width: "100%", height: "auto" }}
                />
              </Suspense>
            </MobileImageContainer>
            <ContentInfo>
              <StepNumberContainer>
                <StepNumber>3</StepNumber>
              </StepNumberContainer>
              <HeadingBox>
                <SectionHeading>Rotate Liquidity</SectionHeading>
                <Content>
                  Once the pot is active, new entries closes, and the rotation
                  cycle begins. Every participant contributes each cycle, with
                  one member receiving a larger sum in a rotating order.
                </Content>
              </HeadingBox>
            </ContentInfo>
            <ImageContainer>
              <Suspense fallback={<div>Loading...</div>}>
                <img
                  src={Section3Pic}
                  alt="Section 3"
                  style={{ width: "100%", height: "auto" }}
                />
              </Suspense>
            </ImageContainer>
          </SectionBox>
        </ContentBox>
      </Box>
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
    </>
  );
};

export default RotationSection;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 105px auto;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
    justify-content: flex-start;
  }
`;

// export const ImageBox1 = styled.div`
//   height: auto;
//   width: 100%;
//   background-image: url(${Section1Pic});
//   background-size: cover;
//   background-position: center;

//   @media (max-width: 480px) {
//     margin-top: 44px;
//   }
// `;

// export const ImageBox2 = styled.div`
//   height: auto;
//   width: 100%;
//   background-image: url(${Section2Pic});
//   background-size: cover;
//   background-position: center;

//   @media (max-width: 480px) {
//     margin-top: 44px;
//   }
// `;

// export const ImageBox3 = styled.div`
//   height: auto;
//   width: 100%;
//   background-image: url(${Section3Pic});
//   background-size: cover;
//   background-position: center;
//   @media (max-width: 480px) {
//     margin-top: 44px;
//   }
// `;

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    display: none;
  }
`;

export const MobileImageContainer = styled.div`
  width: 50%;
  display: none;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }
`;

export const MainHeading = styled.h1`
  //   width: 45%;
  max-width: 700px;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 45px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 0px;

  @media (max-width: 1300px) {
    width: 60%;
  }

  @media (max-width: 1000px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    font-size: 38px;
    width: 90%;
  }

  @media (max-width: 480px) {
    font-size: 32px;
    text-align: left;
    width: 100%;
    margin-right: auto;
  }
`;

export const SubHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
    margin-right: auto;
  }
`;

export const SectionHeading = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: left;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 26px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0px;
  text-transform: uppercase;
  //   margin-bottom: 18px;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 21px;
    text-align: left;
  }
`;

export const Content = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: left;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin: 40px auto;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingBox = styled.div`
  display: flex;
  flex-direction: column;
  //   align-items: center;
`;

export const SectionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 90px;
  gap: 22px;

  @media (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 70px;
    flex-direction: column; // Stack elements vertically on smaller screens
  }

  @media (max-width: 480px) {
    margin-top: 55px;
    margin-bottom: 55px;
    flex-direction: column; // Ensure vertical stacking on mobile
  }
`;

export const ContentInfo = styled.div`
  width: 50%;
  gap: 30px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    gap: 22px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const StepNumberContainer = styled.div`
  display: flex; // Flex to center the child if needed
  justify-content: center; // Center horizontally in the container
  align-items: center; // Center vertically in the container
`;

const StepNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px; // Keep the circular size
  height: 48px; // Equal to width for a circle
  border-radius: 50%; // Perfect circle
  background: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  // Adjustments for responsive design
  @media (max-width: 768px) {
    width: 44px;
    height: 44px;
    font-size: 15px;
  }
  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 40px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 34px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
    padding: 0px 17px;
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

import React from "react";
import styled, { keyframes } from "styled-components";
import { SkeletonLoaderProps } from "../../utils/interface";

// Define keyframe animation
const slide = keyframes`
    to {
        background-position: -200% 0;
    }
`;

// Define a functional component for the SkeletonLoaderForChats
const SkeletonLoaderForChats: React.FC<SkeletonLoaderProps> = ({
  width = "100%",
  height = "20px",
}) => {
  return <SkeletonLoader width={width} height={height} />;
};

// Create styled component for the skeleton loader
const SkeletonLoader = styled.div<SkeletonLoaderProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: linear-gradient(to right, #f0f0f0 50%, #e0e0e0 50%);
  background-size: 200% 100%;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  opacity: 0.4;
  animation: ${slide} 1.5s infinite linear; // Apply animation
`;

// Define a functional component for the SkeletonLoaderContainer
const SkeletonLoaderContainer: React.FC<{
  children: React.ReactNode;
  key: string;
}> = ({ children, key }) => {
  return <Container key={key}>{children}</Container>;
};

// Create styled component for the SkeletonLoaderContainer
const Container = styled.div`
  margin-bottom: 20px;
`;

// Define a functional component for the SkeletonLoaderList
const SkeletonLoaderList: React.FC = () => {
  return (
    <>
      {Array.from({ length: 2 }, (_, index) => (
        <SkeletonLoaderContainer key={`${index}-SkeletonLoaderContainer`}>
          <SkeletonLoaderForChats width="50%" height="10px" />
          <SkeletonLoaderForChats width="100%" height="10px" />
          <SkeletonLoaderForChats width="100%" height="10px" />
          <SkeletonLoaderForChats width="70%" height="10px" />
        </SkeletonLoaderContainer>
      ))}
    </>
  );
};

export default SkeletonLoaderList;

import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import SendIcon from "../../assets/send.svg";
import BotImage from "../../assets/botImage.svg";
import EyeIcon from "../../assets/eyeIcon.svg";
import defaultImage from "../../assets/default_image.svg";
import MiniTaskBarIcon from "../../assets/miniTaskBar.svg";
import ArrowBack from "../../assets/arrow_back.svg";
import VerticalThreeDotsIcon from "../../assets/vertical_three_dots.svg";
import ThreeDotsIcon from "../../assets/three_dot.svg";
import { ReactComponent as AlertIcon } from "../../assets/alertIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/ArchiveIcon.svg";
import { ChatDisplayUIProps, Message, PinMsgArr } from "../../utils/interface";
import { io } from "socket.io-client";
import ClaimSuccessModal from "../Modal/ClaimSuccessModal";
import DistributedRiskPoolSuccessModal from "../Modal/DistributedRiskPoolSuccessModal";
import DistributedRiskPoolModal from "../Modal/DistributedRiskPoolModal";
import ChatDepositModal from "../Modal/ChatDepositModal";
import PotDetailsModal from "../Modal/PotDetailsModal";
import {
  getSuggestions,
  handleSuggestionClick,
} from "../../utils/chatApi/handleSuggestions";
import { useNavigate } from "react-router-dom";
import {
  dif,
  listMessages,
  markMsgSeen,
  participantJoinedFunc,
  sendMsg,
  setOnMessageReceived,
  setOnMessageSeen,
  setOnPinnedMsgReceived,
  setOnRestartPot,
} from "../../utils/chatApi/chatApi";
import PinChat from "./PinChat";
import MarkMsgAsRead from "./MarkAsRead";
import DropdownMenu from "./DropdownMenu";
import { useLocation, useParams } from "react-router-dom";
import { formatMessageTime } from "../../utils/commonFunctions";
import Skeleton from "../Skeleton/Skeleton";
import {
  AppDispatch,
  RootState,
  archiveApi,
  claimFunds,
  getCommitment,
  getJoinedParticipants,
  getPotDetail,
  getRandomness,
  joinPotSignature,
  leavePot,
  restartPotApi,
  setDepositError,
  setIsArchived,
  setIsDeposited,
  setIsDepositedLoading,
  setJoinedParticipants,
  setWalletConnect,
  syncRotate,
  setIsLeavePotModalOpen,
  setIsLeavePotLoading,
  setLeavePotError,
  setIsLeavePot,
  removeChat,
  checkSyncDetails,
  getChatsData,
} from "../../store";
import { joinPotTx } from "../../utils/transactions/joinAPot";
import { useDispatch, useSelector } from "react-redux";
import { approveUsdbTx } from "../../utils/transactions/approveUsdb";
import { Insufficient_Fund_Error } from "../../utils/errors";
import InsufficientFundModal from "../Modal/InsufficientFundModal";
import { useWeb3React } from "@web3-react/core";
import { rotateLiquidityTx } from "../../utils/transactions/rotateLiquidity";
import { toast } from "react-toastify";
import DepositSuccessModal from "../Modal/DepositSuccessModal";
import { claimRewardsTx } from "../../utils/transactions/claimFunds";
import WinnerSelectedSucessModal from "../Modal/WinnerSelectedSuccessModal";
import { withdrawFromPotTx } from "../../utils/transactions/leavePot";
import WithdrawSuccessModal from "../Modal/WithdrawSuccessModal";
import RotationCycleModal from "../Modal/RotationCycleModal";
import { createPotTx } from "../../utils/transactions/createAPot";
import LeaveModal from "../Modal/LeavePotModal";

const Chat: React.FC<ChatDisplayUIProps> = ({
  chatDataProps,
  onClickMinTaskBar,
  goBackToChatGroup,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    potDetail: { data: potDetails },
    isLeavePotModalOpen,
    joinedParticpants: { data: joinedParticipants, loading },
  } = useSelector((state: RootState) => state.wallet);
  const navigate = useNavigate();
  const TopHeaderRef = useRef<HTMLDivElement>(null);

  const leavePotRef = useRef<HTMLButtonElement>(null);
  const { account, chainId, provider } = useWeb3React();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageSeenBy, setMessageSeenBy] = useState<Message[]>([]);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isRestartLoading, setIsRestartLoading] = useState(false);
  const [messagesList, setMessagesList] = useState<Message[]>([]);
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [socket, setSocket] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isDepositSuccessModal, setIsDepositSuccessModal] = useState(false);
  const [isWithdrawSuccessModal, setIsWithdrawSuccessModal] = useState(false);
  const [isClaimedSuccessModal, setIsClaimedSuccessModal] = useState(false);
  const [isShareRiskPoolModal, setIsShareRiskPoolModal] = useState(false);
  // const [isRotationCycleCompletedModal, setIsRotationCycleCompletedModal] =
  //   useState(false);
  const [isShareRiskPoolSuccessModal, setIsShareRiskPoolSuccessModal] =
    useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [isShareRiskPoolLoding, setIsShareRiskPoolLoding] = useState(false);
  const [isPotDetailsModalOpen, setIsPotDetailsModalOpen] =
    useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]); // Suggestions from API
  // const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isLeaveLoading, setIsLeaveLoading] = useState(false);

  const [dropdownOpenMap, setDropdownOpenMap] = useState<
    Record<string, boolean>
  >({});

  const chatListData = chatDataProps || {
    name: "Pot Name Unavailable",
    participants: [],
    _id: "",
    sender: "",
    profilePicture: "",
    username: "",
    pot: {
      potCode: "",
      potId: null,
      name: "",
    },
  };
  const location = useLocation();
  const [pinnedMessageContent, setPinnedMessageContent] = useState(null);
  const [pinnedMessage, setPinnedMessage] = useState<PinMsgArr[]>([]);
  const [showPinChatModal, setShowPinChatModal] = useState(false);
  const [isStartPotLoading, setIsStartPotLoading] = useState(false);
  const [isSelectWinnerModalOpen, setIsSelectWinnerModalOpen] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedWinner, setSelectedWinner] = useState("");
  const [pinnedMessageCheck, setPinnedMessageCheck] = useState<
    Message[] | null
  >(null);
  const [pinnedMessageId, setPinnedMessageId] = useState<string | null>(null);
  const [pinnedMessageIds, setPinnedMessageIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);
  const [chatIdState, setChatIdState] = useState<string | null>(null);

  const [lastFetchedChatId, setLastFetchedChatId] = useState(null);
  // const [joinedParticipants, setJoinedParticipants] = useState<any>([]);
  const msgRef = useRef(null);
  const pinchatRef = useRef(null);
  const isSocketInitialized = useRef(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [statusData, setStatusData] = useState<any>(null);
  // const [potDetails, setPotDetails] = useState<PotDataDetails | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { chatId } = useParams();
  const authorName = "You";

  const currentPotCode = chatDataProps?.pot?.potCode;

  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;
  const getChatIdFromURL = () => {
    const url = window.location.href;
    const match = url.match(/\/dashboard\/([^/]+)/);
    if (match && match.length > 1) {
      return match[1];
    } else {
      return null;
    }
  };
  const focusInput = () => {
    if (isLeavePotModalOpen === false) {
      inputRef.current?.focus();
    }
  };
  const removeFocus = () => {
    inputRef.current?.blur();
  };
  useEffect(() => {
    if (isPotDetailsModalOpen === false) {
      focusInput();
    }
  }, [isPotDetailsModalOpen]);

  const toggleDropdown = (index: number, isOpen: boolean) => {
    // if (renderThreeDots) {
    setDropdownOpenMap((prev) => ({
      ...prev,
      [index]: isOpen,
    }));
    // }
  };

  const handleDropdownClick = (index: number) => {
    toggleDropdown(index, !dropdownOpenMap[index]);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Close all dropdown menus
        setDropdownOpenMap({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isIdInArray = (id: any, array: any[]) => {
    return array.some((element: { id: any }) => element.id === id);
  };

  useEffect(() => {
    const SOCKET_URI = process.env.REACT_APP_SOCKET_URI;
    if (SOCKET_URI) {
      const authToken = localStorage.getItem("token");
      if (!isSocketInitialized.current) {
        const newSocket = io(SOCKET_URI, {
          auth: { token: authToken },
        });
        setSocket(newSocket);
        isSocketInitialized.current = true;

        newSocket.on("connect", function () {
          newSocket.emit("joinChat", { chatId: chatId }, (response: any) => {});

          participantJoinedFunc(chatId);
        });

        newSocket.on("potRestarted", (data: any) => {
          (async () => {
            await dispatch(getJoinedParticipants({ potCode: data?.potCode }));
          })();
        });
        // newSocket.on("potLeaved", (data: any) => {
        //   console.log("potLeaved event data", data);
        //   (async () => {
        //     await dispatch(getJoinedParticipants({ potCode: data?.potCode }));
        //   })();
        // });

        newSocket.on("messagePinned", (data: any) => {
          setPinnedMessage((prevPinnedMessages) => [
            ...prevPinnedMessages,
            data,
          ]);
          handleClosePinChat(data?._id);
        });
      }

      return () => {
        if (socket) {
          socket.off("connect");
          socket.off("messagePinned");
          socket.disconnect();
          isSocketInitialized.current = false;
        }
      };
    }
  }, [socket]);

  const onArchive = async () => {
    try {
      setIsArchiveLoading(true);
      const archiveResponse = await dispatch(
        archiveApi({
          potId: chatDataProps?.pot.potId,
        })
      );
      if (archiveResponse.meta.requestStatus === "fulfilled") {
        // setIsRotationCycleCompletedModal(false);
        toast.success(
          archiveResponse?.payload?.message || "Successfully Archived",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(setIsArchived(true));
      }
      if (archiveResponse.meta.requestStatus === "rejected") {
        toast.error(
          archiveResponse?.payload?.message || "Failed to archive the pot",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to archive the pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsArchiveLoading(false);
    }
  };

  const onRestart = async () => {
    try {
      setIsRestartLoading(true);
      if (
        chatDataProps?.pot?.amount &&
        chatDataProps?.pot?.noOfParticipants &&
        chatDataProps?.pot?.name &&
        chatDataProps.pot.rotationCycle
      ) {
        const noOfParticipants = chatDataProps.pot.noOfParticipants;
        const potAmount = chatDataProps.pot.amount;
        const potName = chatDataProps?.pot?.name;
        const rotationCycle = chatDataProps.pot.rotationCycle;
        const currentPotId = chatDataProps?.pot?.potId;
        const getCommitmentResponse = await dispatch(getCommitment({}));
        if (getCommitmentResponse.meta.requestStatus === "fulfilled") {
          try {
            await approveUsdbTx({ amount: potAmount.toString() });
          } catch (error: any) {
            if (error?.message.includes(Insufficient_Fund_Error)) {
              setIsInsufficientFundModal(true);
              throw new Error(error?.message);
            }
            throw new Error(error?.message || "Failed to approve USDB");
          }
          const { commitment, randomNumber } = getCommitmentResponse.payload;
          const createPotTxResponse = await createPotTx({
            rotationCycleInSeconds: +rotationCycle,
            interestDenominator: 1000,
            interestNumerator: 0,
            numParticipants: +noOfParticipants,
            amount: potAmount.toString(),
            potName: potName,
            userCommitment: commitment,
          });
          let attempt = 0;
          let restartPotResponse;

          do {
            attempt++;
            restartPotResponse = await dispatch(
              restartPotApi({
                newPotId: createPotTxResponse?.potId,
                currentPotId,
              })
            );

            if (restartPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 1000));
            }
          } while (
            restartPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 5
          );
          if (restartPotResponse.meta.requestStatus === "fulfilled") {
            // onCloseRotationCycleCompletedModal();
            toast.success(
              restartPotResponse?.payload?.message || "Successfully Restarted",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
          if (restartPotResponse.meta.requestStatus === "rejected") {
            toast.error(
              restartPotResponse?.payload?.message || "Failed to restart a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to restart the pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsRestartLoading(false);
    }
  };

  // const onCloseRotationCycleCompletedModal = () => {
  //   setIsRotationCycleCompletedModal(false);
  // };

  useEffect(() => {
    const id =
      getChatIdFromURL() ||
      (location.pathname === "/dashboard"
        ? chatListData?._id
        : chatListData?._id);
    setChatIdState(id);
    dif(id);
  }, [location, chatListData]);

  useEffect(() => {
    // messagePinnedListener();
    // setOnMessageReceived((data) => {
    //   setMessages((prevMessages) => [...prevMessages, data]);
    // });
    // setOnPinnedMsgReceived((data: any) => {
    //   setPinnedMessage((prevPinnedMessages) => [...prevPinnedMessages, data]);
    //   handleClosePinChat(data?._id);
    // });
    setOnRestartPot((data: any) => {
      console.log("restart pot", data);
    });
    setOnMessageReceived((data: any) => {
      const id = data?.chat;
      // if (!isIdInArray(id, allMessages)) {
      //   console.log("ID is not in the  array");
      // }
      if (chatDataProps?._id == id)
        setMessages((prevMessages) => [...prevMessages, data]);
    });
  }, [chatDataProps]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        // @ts-ignore
        msgRef.current?.contains(event.target as Node) === false &&
        // @ts-ignore
        pinchatRef.current?.contains(event.target as Node) === false
      ) {
        handleClosePinChat("");
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Function to fetch message data
    const fetchMessageData = async () => {
      try {
        setIsLoading(true);
        setMessagesList([]);

        // Check if chatId or chatListData?._id is valid before proceeding
        if (chatId || chatListData?._id) {
          const currentChatId = chatId ? chatId : chatListData._id;
          const authToken = localStorage.getItem("token");
          const messages = await listMessages(authToken, currentChatId);
          setMessagesList(messages.data);

          const latestMessage = messages.data[messages.data.length - 1];

          if (latestMessage && latestMessage?._id) {
            markMsgSeen(latestMessage?._id);
          } else {
            console.error("Unable to mark last message as seen.");
          }
          setLastFetchedChatId(currentChatId); // Store the last fetched chatId
          const filteredArray = messages.data.filter(
            (item: { pinnedBy: string | null | undefined }) =>
              item.pinnedBy !== undefined &&
              item.pinnedBy !== null &&
              item.pinnedBy !== ""
          );
          setPinnedMessageCheck(filteredArray);
          // }
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching messages:", error);
      } finally {
        // Set loading to false regardless of success or failure
        setIsLoading(false);
      }
    };

    // Only fetch messages if chatId or chatListData?._id is valid and it's different from the last fetched chatId
    if (
      (chatId !== null && chatId !== undefined) ||
      (chatListData && chatListData._id)
    ) {
      if (chatId !== lastFetchedChatId) {
        fetchMessageData();
      }
    } else {
      setIsLoading(false);
    }
    focusInput();
  }, [chatId || chatListData._id]);

  //   useEffect(() => {
  //     // Set the callback function to handle participant joined events
  //     setOnParticipantJoined((data: any) => {
  //       // Update the state with the new participant data
  //       setJoinedParticipants((prevParticipants: any) => [
  //         ...prevParticipants,
  //         data,
  //       ]);
  //     });
  //   }, []); // Run this effect only once, when the component mounts

  // Combine messages from messagesList and messages

  useEffect(() => {
    setAllMessages([]);
    setMessagesList([]);
    setMessages([]);
  }, [chatId]);

  useEffect(() => {
    const allMessagesTemp = [...messagesList, ...messages];
    allMessagesTemp.sort((a, b) => {
      const dateA = a.timestamp ? new Date(a.timestamp) : new Date(a.createdAt);
      const dateB = b.timestamp ? new Date(b.timestamp) : new Date(b.createdAt);
      return dateA.getTime() - dateB.getTime();
    });
    setAllMessages([...allMessagesTemp]);
  }, [messagesList, messages]);
  // Sort the combined messages by timestamp
  // allMessages.sort((a, b) => {
  //   const dateA = a.timestamp ? new Date(a.timestamp) : new Date(a.createdAt);
  //   const dateB = b.timestamp ? new Date(b.timestamp) : new Date(b.createdAt);
  //   return dateA.getTime() - dateB.getTime();
  // });

  // Scroll to the bottom of the messages container whenever messages change
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [allMessages]);

  const handleClosePinChat = (messageId: any) => {
    setPinnedMessageId(null);
    setPinnedMessageContent(null);
  };
  const openPotDetailsModal = () => {
    removeFocus();
    setIsPotDetailsModalOpen(true);
  };

  // const onCloseWinnerModal = () => {
  //   setIsSelectWinner(false);
  // };
  const onCloseLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(false));
  };
  const openLeavePotModal = () => {
    // setIsLeavePotModal(true);
    dispatch(setIsLeavePotModalOpen(true));
  };

  const onLeave = async () => {
    try {
      if (
        !completed &&
        joinedParticipants?.hasWon &&
        joinedParticipants?.totalClaimed === 0
      ) {
        toast.warn("Please claim the funds first", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      dispatch(setIsLeavePotLoading(true));
      setIsLeaveLoading(true);
      dispatch(
        setIsLeavePot({
          isLeavePot: true,
          hasSynced: false,
        })
      );
      dispatch(setIsLeavePotModalOpen(false));
      const potCode = chatListData?.pot?.potCode;
      if (chatListData?.pot?.potId && account) {
        if (joinedParticipants?.amountDeposited > 0) {
          withdrawFromPotTx({
            potId: chatListData?.pot?.potId,
            participantAddress: account,
            isWinner: joinedParticipants?.hasWon,
            potCode,
          }).catch((error) => {
            console.log("error in leave", error);
            // throw new Error(error?.message);
            dispatch(setLeavePotError(error?.message || "Failed to Leave Pot"));
            toast.error(error?.message || "Failed to Leave Pot", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });

          let attempt = 0;
          let getPotDetailResponse;
          let getPotDetailInResponse;
          let isError;
          // let potRound=potDetail

          do {
            // if(potDetail){
            attempt++;
            getPotDetailResponse = await dispatch(
              getJoinedParticipants({
                potCode: potDetails?.potCode,
              })
            );
            isError = getPotDetailResponse.payload.isError;
            getPotDetailInResponse = getPotDetailResponse.payload;

            if (
              !getPotDetailInResponse?.hasLeave ||
              getPotDetailResponse.meta.requestStatus !== "fulfilled"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            (attempt < 60 && !getPotDetailInResponse?.hasLeave && !isError) ||
            getPotDetailResponse.meta.requestStatus !== "fulfilled"
          );
          // const leavePotResponse = await dispatch(
          //   leavePot({
          //     potCode,
          //   })
          // );
          if (!isError) {
            let leavePotResponse;
            let leaveAttempt = 0;
            do {
              leaveAttempt++;
              leavePotResponse = await dispatch(
                checkSyncDetails({
                  potId: chatListData?.pot?.potId,
                  eventType: "potLeaved",
                })
              );

              if (leavePotResponse.meta.requestStatus !== "fulfilled") {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            } while (
              leavePotResponse.meta.requestStatus !== "fulfilled" &&
              leaveAttempt < 60
            );
            dispatch(
              setJoinedParticipants({
                amountWithdrawn: 1,
              })
            );

            dispatch(
              setIsLeavePot({
                isLeavePot: false,
                hasSynced:
                  isError && !getPotDetailInResponse?.hasLeave ? false : true,
              })
            );
            dispatch(removeChat(potDetails?.groupChat));
            let groupChatResponse = await dispatch(
              getChatsData({
                pageNo: 1,
                isArchived: false,
                hasSynced: false,
              })
            );
            let chatPotId =
              groupChatResponse.payload.data.response[0]?.pot?.groupChat;
            navigate(`/dashboard/${chatPotId}`);
            goBackToChatGroup();
          }
        } else {
          const leavePotResponse = await dispatch(
            leavePot({
              potCode,
            })
          );
          if (leavePotResponse?.payload?.message) {
            goBackToChatGroup();
            dispatch(
              setJoinedParticipants({
                amountWithdrawn: 1,
              })
            );

            dispatch(
              setIsLeavePot({
                isLeavePot: false,
                hasSynced: true,
              })
            );
            dispatch(removeChat(potDetails?.groupChat));
            let groupChatResponse = await dispatch(
              getChatsData({
                pageNo: 1,
                isArchived: false,
                hasSynced: false,
              })
            );

            let chatPotId =
              groupChatResponse.payload.data.response[0]?.pot?.groupChat;
            navigate(`/dashboard/${chatPotId}`);
            goBackToChatGroup();
          }
        }
      }
    } catch (error: any) {
      console.log("error in leave", error);
      dispatch(setLeavePotError(error?.message || "Failed to Leave Pot"));
      toast.error(error?.message || "Failed to Leave Pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      dispatch(setIsLeavePotLoading(false));
      setIsLeaveLoading(false);
    }
  };

  const handleRightClick = (e: any, message: any) => {
    // e.preventDefault();
    // setSelectedMessage(message);
    // setPinnedMessageId(message._id);
    // setPinnedMessageContent(message.text);
  };

  const handleCloseModals = () => {
    setIsDepositModal(false);
    setIsDepositSuccessModal(false);
    setIsShareRiskPoolModal(false);
    setIsShareRiskPoolSuccessModal(false);
    setIsClaimedSuccessModal(false);
  };

  const onCloseDepositModal = () => {
    setIsDepositModal(false);
  };

  const onClickDepositModal = () => {
    setIsDepositModal(true);
  };

  const onDeposit = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      setIsDepositModal(false);

      return;
    }
    dispatch(setIsDepositedLoading(true));
    setIsDepositLoading(true);
    setIsDepositModal(false);
    try {
      const potId = chatDataProps?.pot?.potId;
      const potCode = chatDataProps?.pot?.potCode;

      if (potId && potCode) {
        try {
          await approveUsdbTx({ amount: chatDataProps?.amount?.toString() });
        } catch (error: any) {
          dispatch(setDepositError(error?.message || "Failed to join a pot"));
          console.log("Error during USDB approval:", error?.message);
          if (error?.message.includes(Insufficient_Fund_Error)) {
            onCloseDepositModal();
            setIsInsufficientFundModal(true);
            setIsDepositModal(false);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          await joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: false,
          });
          await new Promise((resolve) => setTimeout(resolve, 2000));
          let joinPotResponse;
          let attempt = 0;
          do {
            attempt++;
            joinPotResponse = await dispatch(
              checkSyncDetails({ potId, eventType: "participantUpdated" })
            );

            if (joinPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            joinPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );
          if (joinPotResponse.meta.requestStatus === "fulfilled") {
            setIsDepositSuccessModal(true);
            dispatch(
              setIsDeposited({
                isDeposited: true,
                data: null,
              })
            );
          } else {
            dispatch(
              setDepositError(
                joinPotResponse?.payload?.message || "Failed to join a pot"
              )
            );
            toast.error(
              joinPotResponse?.payload?.message || "Failed to join a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          // Retry mechanism
          // let attempt = 0;
          // let joinPotResponse;
          // do {
          //   attempt++;
          //   joinPotResponse = await dispatch(
          //     joinPot({ walletAddress: account, potCode })
          //   );

          //   // If the request is not fulfilled, wait for a bit before retrying
          //   if (joinPotResponse.meta.requestStatus !== "fulfilled") {
          //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          //   }
          // } while (
          //   joinPotResponse.meta.requestStatus !== "fulfilled" &&
          //   attempt < 5
          // ); // Retry up to 5 times

          // // Check if it succeeded after retries
          // if (joinPotResponse.meta.requestStatus === "fulfilled") {
          //   setIsDepositModal(false);
          //   setIsDepositSuccessModal(true);
          // } else {
          //   // Handle the failure after all retries here
          //   console.error("Failed to join pot after multiple attempts.");
          // }
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          dispatch(
            setDepositError(
              getJoinPotSignatureResponse?.payload?.message ||
                "Failed to join a pot"
            )
          );
          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      dispatch(setDepositError(error?.message || "Failed to deposit"));
      toast.error(error?.message || "Failed to deposit", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to deposit:", error);
    } finally {
      dispatch(setIsDepositedLoading(false));
      setIsDepositLoading(false);
    }
  };

  const handleError = useCallback((err: any) => {
    if (err)
      toast.error(err || "Failed to use risk pool", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // setError(err);
  }, []);

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  const onConfirmShareRiskPoolModal = () => {
    setIsShareRiskPoolSuccessModal(true);
  };

  const handleOnConfirmStartPot = async () => {
    try {
      setIsStartPotLoading(true);
      // const getCommitmentResponse = await dispatch(getCommitment());
      const getRandomnessResponse = await dispatch(
        getRandomness({ potCode: chatListData?.pot?.potCode })
      );
      if (getRandomnessResponse.meta.requestStatus === "fulfilled") {
        const { userRandom, commitment, providerRandom } =
          getRandomnessResponse.payload;
        if (chatListData.pot?.potId) {
          const response = await rotateLiquidityTx({
            potId: chatListData.pot?.potId,
            userCommitment: commitment,
            userRandom,
            providerRandom,
          });

          await new Promise((resolve) => setTimeout(resolve, 2000));
          let rotateLiquidityResponse;
          let attempt = 0;
          do {
            attempt++;
            rotateLiquidityResponse = await dispatch(
              checkSyncDetails({
                lastWinnerSelectedDate: true,
                potId: chatListData.pot?.potId,
                eventType: "winnerSelected",
              })
            );

            if (rotateLiquidityResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
            }
          } while (
            rotateLiquidityResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );
          if (rotateLiquidityResponse.meta.requestStatus === "fulfilled") {
            setIsPotDetailsModalOpen(false);
            setIsShareRiskPoolSuccessModal(true);
          } else {
            toast.error(
              rotateLiquidityResponse?.payload?.message ||
                "Failed to select the winner",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      }
      if (getRandomnessResponse.meta.requestStatus === "rejected") {
        toast.error(
          getRandomnessResponse?.payload?.message || "Failed to select winner",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to rotate liquidity", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsStartPotLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const onCloseDepositSuccessModal = () => {
    setIsShareRiskPoolModal(false);
    setIsDepositSuccessModal(false);
  };

  const onCloseWithdrawSuccessModal = () => {
    setIsWithdrawSuccessModal(false);
  };

  const onCloseShareRiskPoolModal = () => {
    setIsShareRiskPoolModal(false);
  };
  const onCloseShareRiskPoolSuccessModal = () => {
    setIsShareRiskPoolSuccessModal(false);
  };
  const onCloseSelectWinnerModal = () => {
    setIsSelectWinnerModalOpen(false);
  };
  const onCloseClaimedSuccessModal = () => {
    setIsClaimedSuccessModal(false);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setSuggestions(getSuggestions(value, chatDataProps));
    setOnMessageSeen((data: any) => {
      setMessageSeenBy(data);
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      if (socket && inputValue.trim() !== "" && chatDataProps) {
        let chatId: string | null = null;

        if ("_id" in chatDataProps) {
          chatId = chatDataProps._id as string;
        } else {
          console.error("chatDataProps does not have _id property.");
          toast.error("chatDataProps does not have _id property.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        if (chatId) {
          // Call the sendMsg function with the chatId and inputValue
          sendMsg(chatId, inputValue);
          const message: Message = {
            author: authorName,
            content: inputValue,
            createdAt: Date.now(),
            chat: chatId,
            isAutomated: undefined,
            sender: {
              _id: userInfoData.userId,
              username: userInfoData?.username,
              profilePicture: userInfoData?.profilePicture,
            },
          };
          // if (!isIdInArray(chatId, allMessages)) {
          //   setMessages((prevMessages) => [...prevMessages, message]);
          //   setInputValue("");
          // }
          setInputValue("");
        } else {
          console.log("chatId is null.");
        }
      } else {
        console.log(
          "Socket is not connected, inputValue is empty, or chatDataProps is null."
        );
      }
    } catch (error: any) {
      console.error("Error sending message:", error);
      if (error) {
        toast.error(error || "Error sending message:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //     let isMounted = true;
  //
  //     const fetchTransactionData = async () => {
  //         try {
  //             const transactionStatus = await fetchTransaction(
  //                 currentPotCode,
  //                 authToken
  //             );
  //             if (isMounted) {
  //                 setStatusData(transactionStatus);
  //             }
  //         } catch (error) {
  //         }
  //     };
  //
  //     fetchTransactionData();
  //
  //     return () => {
  //         isMounted = false; // Cleanup function to prevent state updates after unmount
  //     };
  // }, [currentPotCode]);

  const onClaim = async () => {
    try {
      setIsClaimLoading(true);
      const potCode = chatListData?.pot?.potCode;
      if (chatListData?.pot?.potId) {
        const response = await claimRewardsTx({
          potId: chatListData?.pot?.potId,
          potCode,
        });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        let claimFundsResponse;
        let attempt = 0;
        do {
          attempt++;
          claimFundsResponse = await dispatch(
            checkSyncDetails({ potId: chatListData?.pot?.potId })
          );

          if (claimFundsResponse.meta.requestStatus !== "fulfilled") {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        } while (
          claimFundsResponse.meta.requestStatus !== "fulfilled" &&
          attempt < 60
        );
        if (claimFundsResponse.meta.requestStatus === "fulfilled") {
          dispatch(
            setJoinedParticipants({
              totalClaimed: claimFundsResponse.payload.claimedAmount,
            })
          );
          // setJoinedParticipants((prevData: any) => {
          //   return {
          //     ...prevData,
          //     totalClaimed: claimFundsResponse.payload.claimedAmount,
          //   };
          // });
          setIsClaimedSuccessModal(true);
        } else {
          toast.error(
            claimFundsResponse?.payload?.message || "Failed to claim funds",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        // const claimFundsResponse = await dispatch(
        //   checkSyncDetails({
        //     potId: chatListData?.pot?.potId,
        //   })
        // );
        // if (claimFundsResponse.meta.requestStatus === "fulfilled") {
        //   dispatch(
        //     setJoinedParticipants({
        //       totalClaimed: claimFundsResponse.payload.claimedAmount,
        //     })
        //   );
        //   // setJoinedParticipants((prevData: any) => {
        //   //   return {
        //   //     ...prevData,
        //   //     totalClaimed: claimFundsResponse.payload.claimedAmount,
        //   //   };
        //   // });
        //   setIsClaimedSuccessModal(true);
        // }
        // if (claimFundsResponse.meta.requestStatus === "rejected") {
        //   toast.error(
        //     claimFundsResponse?.payload?.message || "Failed to claim funds",
        //     {
        //       position: "bottom-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     }
        //   );
        // }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to claim funds", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsClaimLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const onWithdraw = async () => {
    try {
      setIsWithdrawLoading(true);
      if (chatListData?.pot?.potId && account) {
        const response = await withdrawFromPotTx({
          potId: chatListData?.pot?.potId,
          participantAddress: account,
          isWinner: joinedParticipants?.hasWon,
        });
        const potCode = chatListData?.pot?.potCode;
        const leavePotResponse = await dispatch(
          leavePot({
            potCode,
          })
        );
        if (leavePotResponse.meta.requestStatus === "fulfilled") {
          dispatch(
            setJoinedParticipants({
              amountWithdrawn: 1,
            })
          );
          // setJoinedParticipants((prevData: any) => {
          //   return {
          //     ...prevData,
          //     amountWithdrawn: 1,
          //   };
          // });
          await fetchJoinedParticipants();
          setIsWithdrawSuccessModal(true);
        }
        if (leavePotResponse.meta.requestStatus === "rejected") {
          toast.error(
            leavePotResponse?.payload?.message || "Failed to winthdraw funds",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to withdraw funds", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsWithdrawLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const fetchJoinedParticipants = async () => {
    try {
      if (currentPotCode) {
        const response = await dispatch(
          getJoinedParticipants({ potCode: currentPotCode })
        );
      }
    } catch (error: any) {
      console.error("Error fetching joined participants:", error);
      if (error) {
        toast.error(error || "Error fetching joined participants:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    fetchJoinedParticipants();
  }, [currentPotCode]);

  useEffect(() => {
    const fetchPotDetails = async () => {
      try {
        if (currentPotCode) {
          await dispatch(getPotDetail({ pot: currentPotCode }));
        }
      } catch (error: any) {
        console.error("Error fetching pot details:", error);
        if (error) {
          toast.error(error || "Error fetching pot details:", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // setLoading(false);
      }
    };

    fetchPotDetails();
  }, [currentPotCode]);

  const targetDate = new Date(potDetails?.lastWinnerSelectedDate);

  const secondsToMilliseconds = (seconds: number) => {
    return seconds * 1000;
  };
  const rotationCycleMilliseconds = secondsToMilliseconds(
    potDetails?.rotationCycle
  );

  targetDate.setTime(targetDate.getTime() + rotationCycleMilliseconds);

  // Parse the date strings into Date objects
  const date1 = new Date(targetDate);
  const date2 = new Date();

  useEffect(() => {
    if (
      potDetails?.currentRoundParticipants.length * potDetails?.amount ==
        potDetails?.noOfParticipants * potDetails?.amount &&
      date1 < date2
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [chatListData, potDetails]);
  useEffect(() => {
    if (
      potDetails?.currentRoundParticipants.length * potDetails?.amount ==
        potDetails?.noOfParticipants * potDetails?.amount &&
      date1 < date2
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [date1, date2]);

  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);

  useEffect(() => {
    if (isDepositSuccessModal) {
      setTimeout(() => setIsDepositSuccessModal(false), 5000);
    }
  }, [isDepositSuccessModal]);

  useEffect(() => {
    if (isClaimedSuccessModal) {
      setTimeout(() => setIsClaimedSuccessModal(false), 5000);
    }
  }, [isClaimedSuccessModal]);

  const scrollToBottom = () => {
    if (inputRef.current) {
      const bottomOffset = 100; // Replace this with the desired offset value in pixels
      const bottomPosition =
        inputRef.current.getBoundingClientRect().bottom +
        window.pageYOffset -
        window.innerHeight +
        bottomOffset;

      window.scrollTo({
        top: bottomPosition,
        behavior: "smooth",
      });
    }
  };
  scrollToBottom();

  return (
    <ChatContainer>
      <TopBar>
        <MobileTopBarButton onClick={goBackToChatGroup}>
          <img src={ArrowBack} alt={"back arrow "} />
        </MobileTopBarButton>
        <ImageAndTitle ref={TopHeaderRef} onClick={openPotDetailsModal}>
          <PotHeader>
            <DualAvatar>
              <BoxOne>
                {chatListData.participants.length >= 1 &&
                chatListData?.participants[0]?.profilePicture ? (
                  <img
                    src={chatListData?.participants[0]?.profilePicture}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100px",
                      border: "solid 2px white",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <AvatarOne src={defaultImage} alt="Avatar" />
                )}
              </BoxOne>
              <BoxTwo>
                {chatListData.participants.length >= 2 &&
                chatListData?.participants[1]?.profilePicture ? (
                  <img
                    src={chatListData?.participants[1]?.profilePicture}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100px",
                      border: "solid 2px #13160b",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <AvatarTwo src={defaultImage} alt="Avatar" />
                )}
              </BoxTwo>
            </DualAvatar>
            <MobileDualAvatar>
              <BoxOne>
                {chatListData.participants.length >= 1 &&
                chatListData?.participants[0]?.profilePicture ? (
                  <img
                    src={chatListData?.participants[0]?.profilePicture}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "100px",
                      border: "solid 2px white",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <AvatarOne src={defaultImage} alt="Avatar" />
                )}
              </BoxOne>
              <BoxTwo>
                {chatListData.participants.length >= 2 &&
                chatListData?.participants[1]?.profilePicture ? (
                  <img
                    src={chatListData?.participants[1]?.profilePicture}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "100px",
                      border: "solid 2px #13160b",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <AvatarTwo src={defaultImage} alt="Avatar" />
                )}
              </BoxTwo>
            </MobileDualAvatar>
          </PotHeader>
          <ChatTitle>
            {/*@ts-ignore*/}
            {chatListData && chatListData.name
              ? chatListData.name
              : "Pot Name Unavailable"}{" "}
            | Round {potDetails?.currentRound}
            <Members>
              {/* Check if chatListData and its participants exist */}
              {/*@ts-ignore*/}
              {chatListData && chatListData.participants
                ? `${chatListData.participants.length} members`
                : ""}
            </Members>
          </ChatTitle>
        </ImageAndTitle>
        <RightTopContainer>
          {statusData?.remainingAmount === 0 && (
            <DepositButton onClick={onClickDepositModal}>
              Deposit{" "}
            </DepositButton>
          )}

          {/* {joinedParticipants?.hasWon &&
          joinedParticipants?.totalClaimed === 0 ? (
            <ClaimButton onClick={onClaim}>
              {isClaimLoading ? <Loader /> : "Claim"}
            </ClaimButton>
          ) : (
            <></>
          )}
          {joinedParticipants?.amountDeposited !== 0 &&
          joinedParticipants?.amountWithdrawn === 0 ? (
            <WinthdrawButton onClick={onWithdraw}>
              {isWithdrawLoading ? <Loader /> : "Withdraw"}
            </WinthdrawButton>
          ) : (
            <></>
          )} */}

          <TopBarButton onClick={onClickMinTaskBar}>
            <img src={MiniTaskBarIcon} alt={"minTaskBarIcon"} />
          </TopBarButton>
          <TopBarButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <img src={ThreeDotsIcon} alt={"three dots "} />
          </TopBarButton>
          <MobileTopBarButton
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <img src={VerticalThreeDotsIcon} alt={"three dots "} />
          </MobileTopBarButton>
          {isDropdownOpen && (
            <DropdownMenu
              chatDataProps={chatDataProps}
              handleError={handleError}
              isDropDownItem={false}
              dropdownRef={dropdownRef}
              dropdownOpenMap={dropdownOpenMap}
              toggleDropdown={toggleDropdown}
              onDepositClick={onDeposit}
              onViewDetailsClick={openPotDetailsModal}
            />
          )}
        </RightTopContainer>
      </TopBar>

      {completed ? (
        <ClaimMobileAlertBox onClick={openPotDetailsModal}>
          <ClaimMobileAlertText>
            Please tab here to Select Winner
          </ClaimMobileAlertText>
        </ClaimMobileAlertBox>
      ) : !completed &&
        joinedParticipants?.hasWon &&
        joinedParticipants?.totalClaimed === 0 ? (
        <ClaimMobileAlertBox onClick={openPotDetailsModal}>
          <ClaimMobileAlertText>
            Please tab here to claim your reward
          </ClaimMobileAlertText>
        </ClaimMobileAlertBox>
      ) : !completed && joinedParticipants?.amountDeposited === 0 ? (
        <MobileAlertBox onClick={openPotDetailsModal}>
          <MobileAlertText>
            <AlertIcon style={{ marginRight: "4px" }} /> Please make a Deposit
            for Round {potDetails?.currentRound}
          </MobileAlertText>
        </MobileAlertBox>
      ) : (
        <></>
      )}

      {/* {pinnedMessageCheck && pinnedMessageCheck.length > 0 && (
            <PinnedMessage>
              <VerticalLine>
              <div style={{ background: "#353a29" }}>
              {(pinnedMessage && pinnedMessage.length > 0) ||
                (allMessages && allMessages.length > 0) && (
                  [...(pinnedMessage || []), ...(allMessages || [])].map(
                    (message, index) =>
                      message?.pinnedBy && (
                        <span key={index} style={{ background: "#b1dd40", minHeight: "5px", maxHeight: "60px", display: "flex", flexDirection: "column", marginTop: "3px", marginBottom: "3px" }}>
                          <hr />
                        </span>
                      )))}
                      </div>
            <PinnedMessageBox>
              <PinnedTitle>
                Pinned Message #{pinnedMessageCheck.length}
              </PinnedTitle>
              {(pinnedMessage && pinnedMessage.length > 0) ||
                (allMessages && allMessages.length > 0) ? (
                [...(pinnedMessage || []), ...(allMessages || [])].map(
                  (message, index) =>
                    message?.pinnedBy && (
                      <PinnedText key={index}>
                        <span>{message?.pinnedBy?.username}</span> :
                        {message.content}
                      </PinnedText>
                    )
                )
              ) : (
                <PinnedText>No pinned messages</PinnedText>
              )}
            </PinnedMessageBox>
            <PinnedChatIcon>
              <PinnedChatImg />
            </PinnedChatIcon>
              </VerticalLine>
            </PinnedMessage>
        )} */}

      {isLoading ? (
        <SkeletonWrapper>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </SkeletonWrapper>
      ) : (
        <MessagesContainer ref={messagesContainerRef}>
          {allMessages && allMessages.length > 0 ? (
            allMessages.map((message, index) => {
              // Check if message content is empty
              const isMessageEmpty = !message.content;
              // If message content is empty, don't render the message
              if (isMessageEmpty) {
                return null;
              }
              const createdAt = message?.createdAt;
              const timestamp = message?.timestamp;
              const formattedTime = formatMessageTime({ createdAt, timestamp });
              const userIdToUserInfoMap: {
                [key: string]: { username: string; profilePicture: string };
              } = {};

              chatDataProps?.participants.forEach((participant) => {
                if (participant) {
                  userIdToUserInfoMap[participant._id] = {
                    username: participant.username || "",
                    profilePicture: participant.profilePicture || "",
                  };
                }
              });

              // Get sender's information using the mapping
              const senderId = message?.sender;
              const senderInfo = senderId
                ? userIdToUserInfoMap[senderId]
                : undefined;

              // Get sender's username and profile picture URL
              const senderName = senderInfo?.username;
              const senderProfileImage = senderInfo?.profilePicture;
              const isYou = senderName === chatDataProps?.yourUsername; // Assuming chatDataProps has yourUsername
              // @ts-ignore
              const isFromBot = message?.isAutomated;
              const sender_id = message?.sender?._id
                ? message?.sender?._id
                : message?.sender;
              const isMe = sender_id === userInfoData?.userId;

              // Now you can safely use senderInfo.username and senderInfo.profilePicture

              // Check if message is already pinned
              const isPinned = pinnedMessageIds?.includes(message._id);
              return (
                <ChatHeadContainer
                  key={`${index}-message`}
                  onContextMenu={(e) => handleRightClick(e, message)}
                >
                  {/*@ts-ignore*/}
                  <MessageImageContainer key={`${index}-MessageImageContainer`}>
                    {message?.isAutomated ? (
                      <img
                        src={BotImage}
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    ) : (
                      <img
                        // @ts-ignore
                        src={
                          message?.sender?.profilePicture || senderProfileImage
                        }
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    )}
                  </MessageImageContainer>
                  <MessageContainer
                    key={`${index}-MessageContainer`}
                    isYou={isMe}
                  >
                    <MessageText isMe={isMe}>
                      <MessageTextWrapper>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.25em",
                            marginRight: "5px",
                          }}
                        >
                          <MessageAuthor isFromBot={isFromBot}>
                            {/*// @ts-ignore*/}
                            {message?.sender?.username || senderName}
                          </MessageAuthor>
                          {/*// @ts-ignore*/}

                          {message?.isAutomated ? (
                            <BotSpan>BOT</BotSpan>
                          ) : (
                            <></>
                          )}
                          <MessageTimestamp>{formattedTime}</MessageTimestamp>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <MessageTextValue>
                            {message.content || message.text}
                          </MessageTextValue>
                          <MarkMsgAsRead isChecked={true} />
                        </div>
                        {/*// @ts-ignore*/}
                        {message?.isAutomated ? (
                          <BotBottomContent>
                            {/* <img
                              src={EyeIcon}
                              style={{
                                width: "18px",
                                height: "18px",
                                borderRadius: "50%",
                                marginRight: "1em",
                              }}
                              alt="Avatar"
                            />
                            <BotText>Only you can see this .</BotText> */}

                            {statusData?.remainingAmount === 0 && (
                              <MakeDepositLink
                                onClick={() => setIsDepositModal(true)}
                              >
                                Make Deposit
                              </MakeDepositLink>
                            )}
                          </BotBottomContent>
                        ) : (
                          <></>
                        )}
                      </MessageTextWrapper>
                    </MessageText>
                  </MessageContainer>
                  <div ref={pinchatRef}>
                    {pinnedMessageId === message._id && (
                      <PinChat
                        messageId={message._id}
                        messageContent={pinnedMessageContent}
                        messages={message}
                        onClose={() => handleClosePinChat(message._id)}
                        isPinned={isPinned || false}
                      />
                    )}
                  </div>
                </ChatHeadContainer>
              );
            })
          ) : (
            <div style={{ color: "white", textAlign: "center" }}>
              No chat data available
            </div>
          )}
        </MessagesContainer>
      )}
      {chatDataProps !== null && !chatDataProps?.pot?.archivedAt && (
        <form onSubmit={handleSubmit} style={{ position: "relative" }}>
          <InputContainer>
            <InputField
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
              placeholder={
                chatListData && chatListData.name
                  ? "Message " + chatListData.name
                  : "Pot Name Unavailable"
              }
            />
            {suggestions.length > 0 && (
              <SuggestionsContainer>
                {suggestions.map((suggestion, index) => (
                  <SuggestionItem
                    key={`${index}-suggestions`}
                    onClick={() => {
                      handleSuggestionClick(
                        suggestion,
                        inputValue,
                        setInputValue
                      );
                      setSuggestions([]);
                      focusInput();
                    }}
                  >
                    {suggestion}
                  </SuggestionItem>
                ))}
              </SuggestionsContainer>
            )}
            <img
              src={SendIcon}
              alt="send-icon"
              onClick={handleSubmit}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          </InputContainer>
        </form>
      )}
      {chatDataProps && chatDataProps?.pot?.archivedAt && (
        <ArchiveContainer>
          <ArchiveContainerBox>
            <ArchiveIcon />
            <ArchiveContainerText>
              This particular POT has been moved to the archive ({" "}
              {chatListData && chatListData.name
                ? chatListData.name
                : "Pot Name Unavailable"}{" "}
              ) .
            </ArchiveContainerText>
          </ArchiveContainerBox>
        </ArchiveContainer>
      )}

      {isPotDetailsModalOpen && (
        <PotDetailsModal
          isOpen={isPotDetailsModalOpen}
          onClose={() => setIsPotDetailsModalOpen(false)}
          chatDataProps={chatDataProps}
          isLoading={isStartPotLoading}
          onConfirm={handleOnConfirmStartPot}
        />
      )}
      {isDepositModal && (
        <ChatDepositModal
          onClose={onCloseDepositModal}
          onConfirm={onDeposit}
          isLoading={isDepositLoading}
          potCode={chatDataProps?.pot?.potCode}
          chatDataProps={chatDataProps}
        />
      )}
      {isDepositSuccessModal && (
        <DepositSuccessModal
          statusData={joinedParticipants}
          onClose={onCloseDepositSuccessModal}
        />
      )}
      {isShareRiskPoolModal && (
        <DistributedRiskPoolModal
          onClose={onCloseShareRiskPoolModal}
          onConfirm={onConfirmShareRiskPoolModal}
          isLoading={isShareRiskPoolLoding}
          chatDataProps={chatDataProps}
        />
      )}
      {isShareRiskPoolSuccessModal && (
        <DistributedRiskPoolSuccessModal
          onClose={onCloseShareRiskPoolSuccessModal}
        />
      )}
      {/* {isRotationCycleCompletedModal && (
        <RotationCycleModal
          onClose={onCloseRotationCycleCompletedModal}
          onRestart={onRestart}
          onArchive={onArchive}
          isArchiveLoading={isArchiveLoading}
          isRestartLoading={isRestartLoading}
        />
      )} */}
      {isSelectWinnerModalOpen && (
        <WinnerSelectedSucessModal
          onClose={onCloseSelectWinnerModal}
          winner={selectedWinner}
        />
      )}
      {isClaimedSuccessModal && (
        <ClaimSuccessModal
          onClose={onCloseClaimedSuccessModal}
          statusData={{ ...joinedParticipants, name: chatDataProps?.pot?.name }}
        />
      )}
      {isInsufficientFundModal && (
        <InsufficientFundModal onClose={onCloseInsifficientModal} />
      )}
      {isWithdrawSuccessModal && (
        <WithdrawSuccessModal
          statusData={chatDataProps}
          onClose={onCloseWithdrawSuccessModal}
        />
      )}
      {/* {isSelectWinner && (
        <SelectWinner
          onClose={onCloseWinnerModal}
          currentRoundWinnerData={joinedParticipants}
        />
      )} */}
      {isLeavePotModalOpen && (
        <LeaveModal
          isLoading={isLeaveLoading}
          onClose={onCloseLeavePotModal}
          onConfirm={() => onLeave()}
          chatDataProps={chatListData}
          leavePotRef={leavePotRef}
        />
      )}
    </ChatContainer>
  );
};
export default Chat;

const ArchiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 780px;
  padding: 8px 16px;
  gap: 8px;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  background: rgba(233, 246, 208, 0.04);
  width: 100%;
  @media (max-width: 475px) {
    width: 93%;
  }
`;
const ArchiveContainerBox = styled.div`
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;
const ArchiveContainerText = styled.div`
  display: flex;
  color: var(--neutral-white, #fff);
  font-family: "Space Grotesk";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
  letter-spacing: 0.3px;
  @media (max-width: 475px) {
    font-size: 14px;
  }
`;

const MessageText = styled.div<{ isMe: boolean }>`
  background-color: ${(props) => (props.isMe ? "#495820" : "#35392A")};
  color: #fff;
  max-width: 80%;
  border-radius: 4px 12px 12px 12px;
  padding: 8px 12px 8px 12px;
  gap: 2px;
  margin-left: 1em;

  @media (min-width: 320px) {
    max-width: 88%;
  }
  @media (min-width: 768px) {
    max-width: 90%;
  }
`;

const Members = styled.span`
  font-size: 14px;
  color: #666;
  font-family: Space Grotesk, sans-serif;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 2%;
`;
const ChatTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-left: 1em;
  cursor: pointer;
`;

const ImageAndTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ChatHeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 1em;
`;
const MessageAuthor = styled.div<{ isFromBot: boolean }>`
  color: ${(props) => (!props.isFromBot ? "#fff" : "#B1DD40")};
  font-family: Space Grotesk, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  margin-right: 0.5em;
`;

const PotHeader = styled.div`
  display: flex;
  padding: 4px 4px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  @media (max-width: 475px) {
    padding: 2px 2px;
    gap: 4px;
  }
`;
const DualAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 475px) {
    display: none;
  }
`;

const MobileDualAvatar = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 475px) {
    display: flex;
  }
`;

const BoxOne = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: flex-start;
  gap: 8px;
`;

const HeaderImage = styled.div`
  width: "40px";
  height: "40px";
  border-radius: "100px";
  border: "solid 1px white";
  @media (max-width: 475px) {
    width: "25px";
    height: "25px";
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b;
  width: 13px;
  height: 13px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BoxTwo = styled.div`
  display: flex;
  margin-top: -20px;
  padding-right: 14px;
  align-items: flex-start;
`;

const AvatarOne = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  @media (max-width: 475px) {
    width: 30px;
    height: 30px;
  }
`;
const AvatarTwo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 2px solid #13160b;
  @media (max-width: 475px) {
    width: 30px;
    height: 30px;
  }
`;
const RightTopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //max-width: 200px;
  /* width: 4em; */

  @media (min-width: 375px) {
    justify-content: flex-end;
    width: 10em;
  }
`;
const TopBarButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  gap: 10px;
  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    border-radius: 100px;
    background: rgba(233, 246, 208, 0.12);
  }
`;
const MobileTopBarButton = styled.button`
  cursor: pointer;
  display: none;
  background: transparent;
  border: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;
const DepositButton = styled.button`
  height: 40px;
  width: 90px;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 6px;
  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  letter-spacing: 0.28px;
  text-transform: uppercase;
  margin-right: 1em;
  justify-content: center;
  align-items: center;

  &:hover {
    padding: 0 17px;
    background: #afdc3b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  @media (max-width: 475px) {
    margin-right: 0;
    display: flex;
  }
`;
const ClaimButton = styled.button`
  display: flex;
  height: 40px;
  width: 90px;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 6px;
  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  letter-spacing: 0.28px;
  text-transform: uppercase;
  margin-right: 1em;
  justify-content: center;
  align-items: center;

  &:hover {
    padding: 0 17px;
    background: #afdc3b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  @media (max-width: 475px) {
    margin-right: 0;
    display: flex;
  }
`;

const WinthdrawButton = styled(ClaimButton)``;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 88vh;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  /* Custom scrollbar */

  /* @media (max-width: 1536px) {
    height: 78vh;
  } */
  /* @media (max-width: 1440px) {
    height: 74vh;
  } */
  /* @media (max-width: 1366px) {
    height: 70vh;
  } */
  /* @media (max-width: 1280px) {
    height: 74vh;
  } */
  /* @media (max-width: 1080px) {
    height: 65vh;
  } */
  /* @media (max-width: 768px) {
    height: 55vh;
  } */
  /* @media (max-width: 475px) {
    height: 85vh;
  } */

  /* @media (max-width: 475px) and (min-height: 850px) {
    height: 85vh;
  } */

  @media (max-width: 475px) and (max-height: 850px) and (min-height: 633px) {
    height: 80vh;
  }

  @media (max-width: 475px) and (max-height: 633px) and (min-height: 450px) {
    height: 75vh;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  @media (max-width: 475px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #292f1d;
  color: white;
  border: 1px solid #373636;
  padding: 10px;
  justify-content: space-between;
  /* @media (min-width: 1300px) {
    justify-content: space-between;
  } */
`;

const BoawaahPot = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  border-radius: 10px;
  color: white;
  margin-right: 10px;
  font-family: Space Grotesk, sans-serif;
  line-height: 22.4px;
  letter-spacing: 1%;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #1d2013;
  flex-grow: 1;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 2em;
  max-height: 95vh;
  @media (max-width: 475px) {
    max-height: 65vh;
  }
  /* @media (max-height: 930px) {
    max-height: 63vh;
  }
  @media (max-height: 875px) {
    max-height: 61vh;
  }
  @media (max-height: 830px) {
    max-height: 59vh;
  }
  @media (max-height: 800px) {
    max-height: 57vh;
  }
  @media (max-height: 740px) {
    max-height: 55vh;
  }
  @media (max-height: 725px) {
    max-height: 53vh;
  }
  @media (max-height: 700px) {
    max-height: 50vh;
  }
  @media (max-height: 650px) {
    max-height: 47vh;
  } */
  /* @media (max-height: 605px) {
    max-height: 45vh;
  } */
  /* @media (max-height: 590px) {
    max-height: 40vh;
  } */

  /* @media (max-height: 540px) {
    max-height: 35vh;
  } */
  @media (max-height: 495px) {
    max-height: 30vh;
  }
  /* @media (max-width: 475px) and (max-height: 450px) {
    max-height: 10vh;
  } */
`;

const MessageContainer = styled.div<{ isYou: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5em;
  margin-left: 0.3em;
`;

const MessageImageContainer = styled.div``;

const MessageAuthorTimestamp = styled.span`
  color: #8b8e84;
  font-family: Space Grotesk, sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  flex-grow: 1;
  padding: 10px;
  height: 40px;
  border: transparent;
  background: #24281b;
  color: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  max-width: 240px;
  width: 100%;
  z-index: 999;
  border-radius: 6px;
  border: 1px solid rgba(233, 246, 208, 0.12);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.12) 0%,
      rgba(233, 246, 208, 0.12) 100%
    ),
    #13160b;

  color: #e9f6d0;

  /* desktop/body/medium */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-left: 5px;
`;

const SuggestionItem = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  &:hover {
    background: rgba(233, 246, 208, 0.2);
  }
`;

const AdminTag = styled.span`
  color: #ff5733; /* Adjust color as per your design */
  font-weight: bold;
  margin-right: 0.5em;
`;

export const MessageTimestamp = styled.span`
  font-size: 12px;
  color: #999;
  margin-left: 0.5em;
`;

const DrawerLine = styled.div`
  width: 41%;
  height: 1px;
  background-color: #999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 20%;
`;

// Define the styled component for the DateCheckDivider
const DateCheckDivider = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 5px 0;
  margin-bottom: 1em;
  font-size: 12px;
  position: relative;

  ::before,
  ::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #999;
    position: absolute;
    top: 50%;
    width: 50%;
  }

  ::before {
    left: 0;
    transform: translateY(-50%);
  }

  ::after {
    right: 0;
    transform: translateY(-50%);
  }
`;

const BotSpan = styled.span`
  border-radius: 100px;
  padding: 6px 12px 6px 12px;
  background: #464c3a;
  font-size: 0.8em;
`;
const BotBottomContent = styled.div`
  border-radius: 100px;
  font-size: 0.8em;
  display: flex;
  margin-top: 0.4em;
`;

const BotText = styled.div`
  color: #a2a49d;
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 11px;
  line-height: 18.2px;
`;
const MakeDepositLink = styled.div`
  color: #b1dd40;
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 11px;
  line-height: 18.2px;
  margin-left: 0.5em;
  cursor: pointer;
`;

const PinnedMessage = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  position: sticky;
  height: 70px;
  top: 0px;
  left: 0px;
  padding: 4px 16px 4px 8px;
  z-index: 20;
  background: #464c3a;
  overflow-y: hidden;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  background: #353a29;
  /* @media (min-width: 375px) {
      height: 120px;
    } */
`;
const VerticalLine = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* justify-content: space-between; */
  background: #353a29;
  /* border-left: 3px solid #b1dd40;
    border-radius: 0px;
    padding-left: 5px; */
`;

const DottedLine = styled.div`
  width: 2px;
  background: #b1dd40;
`;
const DottedBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #b1dd40;
`;

const PinnedMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  top: 0px;
  left: 0px;
  padding: 4px 16px 4px 8px;
  z-index: 20;
  background: #353a29;
  overflow-y: hidden;
`;

const ClaimMobileAlertBox = styled.div`
  display: none;
  position: sticky;
  height: 30px;
  top: 0px;
  left: 0px;
  padding: 12px;
  z-index: 20;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  background: #495820;
  @media (max-width: 475px) {
    display: flex;
  }
`;
const ClaimMobileAlertText = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  flex: 1 0 0;
  cursor: pointer;
`;
const MobileAlertBox = styled.div`
  display: none;
  position: sticky;
  height: 30px;
  top: 0px;
  left: 0px;
  padding: 12px;
  z-index: 20;
  border-top: 1px solid rgba(233, 246, 208, 0.12);
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  background: #ef665b;
  @media (max-width: 475px) {
    display: flex;
  }
`;

const MobileAlertText = styled.div`
  display: flex;
  align-items: center;
  color: #610700;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  flex: 1 0 0;
  cursor: pointer;
`;

const PinnedChatIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const PinnedTitle = styled.div`
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.16px;
`;
const PinnedText = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 140%;
  letter-spacing: 0.3px;
  opacity: 0.56;
  cursor: pointer;
`;

const PinnedByText = styled.div`
  color: white;
  font-size: 12px;
`;

const NoDataContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NoChatDataText = styled.h1`
  color: white;
  font-size: 1.5em;
`;
const MessageTextValue = styled.div`
  line-height: 1.5em;
  margin-right: 0.5em;
  @media (min-width: 375px) {
    font-size: 14px;
  }
`;

const MessageTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;

  /* @media (min-width: 375px) { */
  width: auto;
  /* } */
`;

const ClaimPotButton = styled.button`
  margin-top: 16px;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.08);
  background: rgba(233, 246, 208, 0.08);
  color: #afdc3b;
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: 0 17px;
    background: #b9ff04;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }
`;

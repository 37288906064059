"use client";
import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as MakeDepositIcon } from "../../assets/walletIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/cancel.svg";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";

import StartPotModal from "../Modal/StartPotModal";
import ParticipationModal from "../Modal/ParticipantsModal";
import InviteFriendPot from "../Modal/InviteFriendPotModal";
import DepositModal from "../Modal/DepositModal";
import CancelPotModal from "../Modal/CancelPotModal";
import DistributedRiskPoolModal from "../Modal/DistributedRiskPoolModal";
import DistributedRiskPoolSuccessModal from "../Modal/DistributedRiskPoolSuccessModal";
import { useWeb3React } from "@web3-react/core";
import {
  AppDispatch,
  joinPotSignature,
  riskPoolSignature,
  setWalletConnect,
  leavePot,
  setIsDeposited,
  setIsDepositedLoading,
  setDepositError,
  setIsLeavePotModalOpen,
  getJoinedParticipants,
  RootState,
  setJoinedParticipants,
  checkSyncDetails,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useRiskPoolTx } from "../../utils/transactions/useRiskPot";
import { weiToEther } from "../../utils/helper";
import { approveUsdbTx } from "../../utils/transactions/approveUsdb";
import { Insufficient_Fund_Error } from "../../utils/errors";
import { joinPotTx } from "../../utils/transactions/joinAPot";
import DepositSuccessModal from "../Modal/DepositSuccessModal";
import InsufficientFundModal from "../Modal/InsufficientFundModal";
import { toast } from "react-toastify";
import { getJoinedParticipantListData } from "../../utils/chatApi/chatApi";
import { withdrawFromPotTx } from "../../utils/transactions/leavePot";
import { PotDataDetails } from "../../types";
import LeaveModal from "../Modal/LeavePotModal";

const DropdownMenuChat: React.FC<{
  chatDataProps?: any;
  handleError?: any;
  isDropDownItem?: boolean;
  dropdownRef?: any;
  ref?: any;
  dropdownOpenMap?: any;
  toggleDropdown?: any;
  onDepositClick?: any;
  onViewDetailsClick?: any;
  // openLeavePotModal?: any;
}> = ({
  chatDataProps,
  handleError,
  isDropDownItem,
  dropdownRef,
  ref,
  dropdownOpenMap,
  toggleDropdown,
  onDepositClick,
  onViewDetailsClick,
  // openLeavePotModal
}) => {
  const [chatListData, setChatListData] = useState<any>(
    chatDataProps || {
      name: "Pot Name Unavailable",
      participants: [],
      _id: "",
      sender: "",
      profilePicture: "",
      username: "",
      pot: {
        formattedDate: null,
        noOfParticipants: 0,
        createdAt: "2024-04-02T16:43:47.754Z",
        rotationCycle: null,
        potId: null,
        potCode: "",
        currentRound: "",
      },
    }
  );

  const { account, provider } = useWeb3React();
  const leavePotRef = useRef<HTMLButtonElement>(null);
  // const dropdownRef = useRef<HTMLDivElement>(null);
  const domain = window.location.hostname;
  const dispatch = useDispatch<AppDispatch>();
  // const [isDropDownItem, setIsDropDownItem] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);
  const [isPotDetailsModalOpen, setIsPotDetailsModalOpen] = useState(false);
  const [isParticipationModalOpen, setIsParticipationModalOpen] =
    useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isDepositSuccessModal, setIsDepositSuccessModal] = useState(false);
  const [isCancelPotModalOpen, setIsCancelPotModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isDistributedRiskPoolModal, setIsDistributedRiskPoolModal] =
    useState(false);
  const [isShareRiskPoolSuccessModal, setIsShareRiskPoolSuccessModal] =
    useState(false);
  const [riskPoolError, setRiskPoolError] = useState("");
  const [joinPotError, setJoinPotError] = useState("");
  const [isStartPotModalOpen, setIsStartPotModalOpen] = useState(false);
  const [isStartPotLoading, setIsStartPotLoading] = useState(false);
  const [isRiskPoolLoading, setIsRiskPoolLoading] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isLeavePotModal, setIsLeavePotModal] = useState(false);
  const [isLeaveLoading, setIsLeaveLoading] = useState(false);
  const {
    joinedParticpants: { data: joinedParticipants },
  } = useSelector((state: RootState) => state.wallet);

  const [loading, setLoading] = useState(false);
  const [isStartPotModal, setIsStartPotModal] = useState(false);

  const currentPotCode = chatDataProps?.pot?.potCode;

  useEffect(() => {
    setChatListData(chatDataProps);
    setLoading(true);
    if (chatDataProps) {
      setLoading(false);
    }
  }, [chatDataProps]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Close this dropdown menu
        toggleDropdown(-1, false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, toggleDropdown]);
  const openPotDetailsModal = () => {
    setIsPotDetailsModalOpen(true);
  };
  const onCloseDepositModal = () => {
    setIsDepositModalOpen(false);
  };

  const openViewParticipants = () => {
    setIsParticipationModalOpen(true);
  };

  const handleStartPot = () => {
    setIsStartPotModalOpen(true);
  };
  const onCloseStartPotModal = () => {
    setIsStartPotModalOpen(false);
  };

  const onCloseLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(false));
    // setIsLeavePotModal(false);
  };

  const openLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(true));
  };

  const onLeave = async () => {
    try {
      setIsLeaveLoading(true);
      const potCode = chatListData?.pot?.potCode;
      if (chatListData?.pot?.potId && account) {
        const response = await withdrawFromPotTx({
          potId: chatListData?.pot?.potId,
          participantAddress: account,
          isWinner: joinedParticipants?.hasWon,
          potCode,
        });
        const leavePotResponse = await dispatch(
          leavePot({
            potCode,
          })
        );
        if (leavePotResponse.meta.requestStatus === "fulfilled") {
          dispatch(
            setJoinedParticipants({
              amountWithdrawn: 1,
            })
          );
          // setJoinedParticipants((prevData: any) => {
          //   return {
          //     ...prevData,
          //     amountWithdrawn: 1,
          //   };
          // });
          dispatch(setIsLeavePotModalOpen(false));
          await fetchJoinedParticipants();
        }
        if (leavePotResponse.meta.requestStatus === "rejected") {
          toast.error(
            leavePotResponse?.payload?.message || "Failed to winthdraw funds",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to withdraw funds", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLeaveLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const fetchJoinedParticipants = async () => {
    try {
      const response = await dispatch(
        getJoinedParticipants({ potCode: currentPotCode })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onDeposit = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    dispatch(setIsDepositedLoading(true));
    setIsDepositLoading(true);
    onCloseDepositModal();
    try {
      const potId = chatDataProps?.pot?.potId;
      const potCode = chatDataProps?.pot?.potCode;

      if (potId && potCode) {
        try {
          await approveUsdbTx({ amount: chatDataProps?.amount?.toString() });
        } catch (error: any) {
          dispatch(setDepositError(error?.message || "Failed to join a pot"));
          if (error?.message.includes(Insufficient_Fund_Error)) {
            onCloseDepositModal();
            setIsInsufficientFundModal(true);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          await joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: false,
          });
          let joinPotResponse;
          let attempt = 0;
          do {
            attempt++;
            joinPotResponse = await dispatch(
              checkSyncDetails({ potId, eventType: "participantUpdated" })
            );

            if (joinPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            joinPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );
          if (joinPotResponse.meta.requestStatus === "fulfilled") {
            setIsDepositSuccessModal(true);
            dispatch(
              setIsDeposited({
                isDeposited: true,
                data: null,
              })
            );
          } else {
            dispatch(
              setDepositError(
                joinPotResponse?.payload?.message || "Failed to join a pot"
              )
            );
            toast.error(
              joinPotResponse?.payload?.message || "Failed to join a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          // Retry mechanism
          // let attempt = 0;
          // let joinPotResponse;
          // do {
          //   attempt++;
          //   joinPotResponse = await dispatch(
          //     joinPot({ walletAddress: account, potCode })
          //   );

          //   // If the request is not fulfilled, wait for a bit before retrying
          //   if (joinPotResponse.meta.requestStatus !== "fulfilled") {
          //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          //   }
          // } while (
          //   joinPotResponse.meta.requestStatus !== "fulfilled" &&
          //   attempt < 5
          // ); // Retry up to 5 times

          // Check if it succeeded after retries
          // if (joinPotResponse.meta.requestStatus === "fulfilled") {
          //   onCloseDepositModal();
          //   setIsDepositSuccessModal(true);
          // } else {
          //   // Handle the failure after all retries here
          //   console.error("Failed to join pot after multiple attempts.");
          // }
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          dispatch(
            setDepositError(
              getJoinPotSignatureResponse?.payload?.message ||
                "Failed to join a pot"
            )
          );

          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      handleError(error?.message);
      dispatch(setDepositError(error || "Failed to deposit:"));
      console.error("Failed to deposit:", error);
      if (error) {
        toast.error(error || "Failed to deposit:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsDepositLoading(false);
      dispatch(setIsDepositedLoading(false));
    }
  };

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  const handleCancelPot = () => {
    setIsCancelPotModalOpen(true);
  };

  const onCloseCancelPotModal = () => {
    setIsCancelPotModalOpen(false);
  };

  // const onCloseDepositModal = () => {
  //   setIsDepositModalOpen(false);
  // };

  const handleOnConfirmDeletPot = () => {};

  const handleOnConfirmStartPot = () => {};

  const handleRiskPool = () => {
    setIsDistributedRiskPoolModal(true);
  };

  const onCloseRiskPoolModal = () => {
    setIsDistributedRiskPoolModal(false);
  };

  const onConfirmRiskPoolModal = useCallback(async () => {
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsRiskPoolLoading(true);
    try {
      //   const getJoinPotSignatureResponse = await getRotatedLiquidityDetails(
      //     chatDataProps?.pot?.potId
      //   );
      const getRiskPotSignatureResponse = await dispatch(
        riskPoolSignature({
          potCode: chatDataProps?.pot?.potCode,
          amount: chatDataProps?.pot?.amount,
        })
      );
      if (getRiskPotSignatureResponse.meta.requestStatus === "fulfilled") {
        const { signature, nonce } = getRiskPotSignatureResponse.payload;
        const response = await useRiskPoolTx({
          signature,
          nonce,
          potId: chatDataProps?.pot?.potId,
          amount: weiToEther(chatDataProps?.pot?.amount).toString(),
        });
        setIsDistributedRiskPoolModal(false);
        setIsShareRiskPoolSuccessModal(true);
      }
      if (getRiskPotSignatureResponse.meta.requestStatus === "rejected") {
        toast.error(
          getRiskPotSignatureResponse?.payload?.message ||
            "Failed to use risk pool",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      console.error("Failed to use risk pool:===>", error?.message);
      toast.error(error?.message || "Failed to use risk pool", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleError(error?.message);
      if (error instanceof Error) {
        setRiskPoolError(error?.message);
      }
    } finally {
      setIsRiskPoolLoading(false);
    }
  }, [account, provider]);

  const onCloseShareRiskPoolSuccessModal = () => {
    setIsShareRiskPoolSuccessModal(false);
  };

  const shareOnTwitter = () => {
    const inviteCode = `${domain}/${chatDataProps.pot.potCode}`;
    const tweetText = encodeURIComponent(
      "I just created a pot on #QuailFinance, @QuailFinance." +
        "\n" +
        "\n" +
        "Collateral-Free Liquidity Based on Social Trust is real." +
        "\n" +
        "\n" +
        `Join My Pot - ${inviteCode}`
    );
    const twitterIntentURL = `https://twitter.com/intent/tweet?text=${tweetText}`;

    window.open(twitterIntentURL, "_blank");
  };

  const onCloseDepositSuccessModal = () => {
    setIsDepositSuccessModal(false);
  };

  useEffect(() => {
    const handleClickOutsideLeavePotModal = (event: MouseEvent) => {
      if (
        leavePotRef.current &&
        !leavePotRef.current.contains(event.target as Node)
      ) {
        dispatch(setIsLeavePotModalOpen(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutsideLeavePotModal);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideLeavePotModal
      );
    };
  }, []);
  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);

  useEffect(() => {
    if (isDepositSuccessModal) {
      setTimeout(() => setIsDepositSuccessModal(false), 5000);
    }
  }, [isDepositSuccessModal]);

  return (
    <>
      <DropdownMenuContainer>
        <DropdownMenu
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: `45px`,
            right: `0px`,
            zIndex: 100,
          }}
        >
          {/* <DropdownItem>
          <NotificationsOffIcon />
          Disable Notification
        </DropdownItem>
        <DropdownItem onClick={openViewParticipants}>
          <GroupIcon />
          View Participants
        </DropdownItem>

        <DropdownItem onClick={handleRiskPool}>
          <EnterpriseIcon />
          <CancelHighlight> Use Risk Pot</CancelHighlight>
        </DropdownItem> */}

          {isDropDownItem == false && (
            <MobileDropdownItem onClick={onViewDetailsClick}>
              <InfoIcon />
              View Details
            </MobileDropdownItem>
          )}

          <DropdownItem onClick={openLeavePotModal}>
            <CancelIcon />
            <CancelHighlight>Leave Pot</CancelHighlight>
          </DropdownItem>

          {/* {isDropDownItem == true && (
            <DropdownItem>
              <CheckIcon />
              Mark as read
            </DropdownItem>
          )}

          <Divider /> */}
          {isDropDownItem == true && (
            <DropdownItem
              id={"lastSection-scrool-chat"}
              ref={ref}
              onClick={onDepositClick}
            >
              <MakeDepositIcon />
              <DepositHighlight>Make a Deposit</DepositHighlight>
            </DropdownItem>
          )}

          {/* <DropdownItem>
            <NotificationsOffIcon />
            Disable Notification
          </DropdownItem>

          <DropdownItem onClick={openViewParticipants}>
            <GroupIcon />
            View Participants
          </DropdownItem>

          {isDropDownItem == true && (
            <DropdownItem onClick={openPotDetailsModal}>
              <InfoIcon />
              View Details
            </DropdownItem>
          )}

          {isDropDownItem == true && (
            <DropdownItem onClick={() => setInviteModalOpen(true)}>
              <PersonAddDropdownIcon />
              Invite New Participant
            </DropdownItem>
          )}

          {isDropDownItem == true && (
            <DropdownItem onClick={shareOnTwitter}>
              <XLogosDropdownIcon />
              Share on twitter
            </DropdownItem>
          )}

          {isDropDownItem == true && (
            <DropdownItem onClick={handleStartPot}>
              <PlayCircleIcon />
              Start pot
            </DropdownItem>
          )}

          <DropdownItem onClick={handleRiskPool}>
            <EnterpriseIcon />
            <CancelHighlight> Use Risk Pot</CancelHighlight>
          </DropdownItem>

          {isDropDownItem == true && (
            <DropdownItem onClick={handleCancelPot}>
              <CancelIcon />
              <CancelHighlight> Cancel Pot</CancelHighlight>
            </DropdownItem>
          )} */}
        </DropdownMenu>

        {isStartPotModalOpen && (
          <StartPotModal
            isLoading={isStartPotLoading}
            onClose={onCloseStartPotModal}
            onConfirm={() => handleOnConfirmStartPot()}
            chatDataProps={chatDataProps}
            startWinnerRef={""}
          />
        )}

        {/*<ParticipationModal*/}
        {/*    isOpen={isParticipationModalOpen}*/}
        {/*    onClose={() => setIsParticipationModalOpen(false)}*/}
        {/*    chatListData={chatDataProps}*/}
        {/*/>*/}
        <InviteFriendPot
          onClose={() => setInviteModalOpen(false)}
          onConfirm={() => {}}
          isOpen={isInviteModalOpen}
          chatDataProps={chatDataProps}
        />

        {isDepositModalOpen && (
          <DepositModal
            onClose={onCloseDepositModal}
            onConfirm={onDeposit}
            isLoading={isDepositLoading}
            potDetail={chatDataProps?.pot}
          />
        )}
        {isCancelPotModalOpen && (
          <CancelPotModal
            onClose={onCloseCancelPotModal}
            onConfirm={() => handleOnConfirmDeletPot()}
          />
        )}

        {isDistributedRiskPoolModal && (
          <DistributedRiskPoolModal
            chatDataProps={chatDataProps}
            onClose={onCloseRiskPoolModal}
            onConfirm={onConfirmRiskPoolModal}
            isLoading={isRiskPoolLoading}
          />
        )}
        {isDepositSuccessModal && (
          <DepositSuccessModal onClose={onCloseDepositSuccessModal} />
        )}
        {isShareRiskPoolSuccessModal && (
          <DistributedRiskPoolSuccessModal
            onClose={onCloseShareRiskPoolSuccessModal}
          />
        )}
        {isInsufficientFundModal && (
          <InsufficientFundModal onClose={onCloseInsifficientModal} />
        )}

        {/* {isLeavePotModal && (
          <LeaveModal
            isLoading={isLeaveLoading}
            onClose={onCloseLeavePotModal}
            onConfirm={() => onLeave()}
            chatDataProps={chatDataProps}
            leavePotRef={leavePotRef}
          />
        )} */}
      </DropdownMenuContainer>
    </>
  );
};

export default DropdownMenuChat;

const DropdownMenuContainer = styled.div`
  /* background-color: red;
      width: 100; */
`;
const DropdownMenu = styled.div`
  display: flex;
  width: 240px;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.04) 0%,
      rgba(233, 246, 208, 0.04) 100%
    ),
    #2d3123;
  position: absolute;
  right: 0;
  /* top: 100%; */
  top: -100px;
  z-index: 2;
`;

const DropdownItem = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  cursor: pointer;

  @media (max-width: 475px) {
    font-size: 14px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const MobileDropdownItem = styled(DropdownItem)`
  display: none;
  @media (max-width: 475px) {
    display: flex;
  }
`;

const CancelHighlight = styled.span`
  color: ${(props) => props.theme.colors.secondaryRed};
`;

const DepositHighlight = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #8b8e85;
`;

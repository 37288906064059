import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as ExpandMoreIcon } from "../../assets/white_expand_more.svg";
import HoverButton from "../Button/HoverButton";

const InvitePotModal: React.FC<{
  handleRemove: () => void;
  onClose: () => void;
  email?: any;
}> = ({ handleRemove, onClose, email }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const options = [
    {name: "Pot Name1"},
    {name: "Pot Name2"},
    {name: "Pot Name3"},
    {name: "Pot Name4"},
    {name: "Pot Name5"},
    {name: "Pot Name6"},
    {name: "Pot Name7"},
    {name: "Pot Name8"},
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>Remove notification</ModalTitle>
        <ModalText>Select Pot to remove notification and email</ModalText>
        <Row>
          <LeftText>Select the pot</LeftText>
        </Row>
        <CustomDropdown
          ref={dropdownRef}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          POT Name
          {isDropdownOpen && (
            <DropdownOptions>
              {options.map((option) => (
                <DropdownOption
                  key={option.name}
                  onClick={() => setIsDropdownOpen(false)}
                >
                  {option.name}
                </DropdownOption>
              ))}
            </DropdownOptions>
          )}
          <ExpandIcon />
        </CustomDropdown>
        <div style={{ width: "100%", marginTop: "32px" }}>
          <NextButton onClick={() => handleRemove()} height="44px" padding="0">
            Confirm
          </NextButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default InvitePotModal;

// Styled Componentsyr

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  width: 100vw;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 614px) {
    width: 90vw;
    justify-content: flex-end;
  }

  @media (max-width: 297px) {
    width: 100vw;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  width: 480px;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);

  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 614px) {
    width: 100%;
    padding: 18px 36px 130px 22px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 614px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  text-align: center;

  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 614px) {
    font-size: 18px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  opacity: 0.7;
  margin-top: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  /* margin-top: 25px;
  @media (max-width: 614px) {
    margin-top: 10px;
  } */
`;

const LeftText = styled.div`
  display: flex;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-top: 32px;
`;

const Span = styled.div`
  color: #ff645d;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-left: 8px;
  margin-top: -4px;
`;

const NextButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 32px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;
export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CustomDropdown = styled.div`
  position: relative;
  width: 95%;
  /* width: 94%;
  @media (max-width: 507px) {
    width: 95%;
  } */
  height: 28px;
  background: rgba(233, 246, 208, 0.08);
  border: 1px solid rgba(233, 246, 208, 0.4);
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const DropdownOptions = styled.div`
  position: absolute;
  margin-left: -10px;
  top: 100%;
  padding: 0;
  margin-top: 2px;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 999;
  border-radius: 6px;
  border: 1px solid rgba(233, 246, 208, 0.12);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.12) 0%,
      rgba(233, 246, 208, 0.12) 100%
    ),
    #13160b;

  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 10px 16px;

  &:hover {
    background: rgba(233, 246, 208, 0.2);
  }
`;

const ExpandIcon = styled(ExpandMoreIcon)`
  position: absolute;
  right: 10px;
  transform: translateY(-10%);
  width: 45px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HoverButton from "../Button/HoverButton";
// import Image from "next/image";

const ComingSoonModal: React.FC<{}> = ({}) => {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/");
  };

  return (
    <ModalBackdrop>
      <ModalTitle>Coming soon</ModalTitle>
      <ModalText>
        Short description of what the users can do in this page
      </ModalText>
      <ButtonRow>
        <CreateAPotButton onClick={backToHome}  height="48px" padding="0 16px">
          <span>Back to home page</span>
        </CreateAPotButton>
      </ButtonRow>
    </ModalBackdrop>
  );
};

export default ComingSoonModal;

// Styled Componentsyr

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  margin-top: 90px;
  padding: 0px 24px 0px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  background: rgba(19, 22, 11, 0.6);
  backdrop-filter: blur(9px);
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 96%;
  max-width: 470px;
  padding: 48px 48px;
  background: #13160b;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;

  /* modal glow */
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
`;

const ModalTitle = styled.h2`
  color: var(--primary, #b1dd40);
  text-align: center;

  /* glow */
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);

  /* desktop/heading/two */
  font-family: "Orbitron", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 54px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`;

const ModalText = styled.span`
  color: var(--light, #e9f6d0);
  text-align: center;

  /* desktop/body/large */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;

  color: ${(props) => props.theme.colors.primary};
  text-align: center;

  /* desktop/misc/button-lg */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 52px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.boxShadow.button};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
  padding: 0px 17px;
    background: #afdc3b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }
  &:active {
  padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  } */
`;

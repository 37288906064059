import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as GreenStar } from "../../assets/gold.svg";
import { ReactComponent as BuiltOnBlast } from "../../assets/BuiltOnBlast.svg";
import { claimUserPoints, claimUserGolds } from "../../utils/chatApi/chatApi";
import { AppDispatch, getProfileData } from "../../store";
import { toast } from "react-toastify";
import HoverButton from "../Button/HoverButton";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { localStorageStates } from "../../utils";

const BlastPointsModal: React.FC<{
  onClose: () => void;
  BlastRef: any;
  authToken: any;
  userInfoData: any;
  inviteLink: any;
}> = ({ onClose, BlastRef, authToken, userInfoData, inviteLink }) => {
  const { account, isActive } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isBlastClaimed, setIsBlastClaimed] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const [userProfile, setUserProfile] = useState({
    username: "",
    points: 0,
    profilePicture: "",
    twitterInfo: null,
    discordInfo: null,
    generatedInviteCodes: [],
    isBlacklisted: false,
    rank: 0,
  });
  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        event.target instanceof Node &&
        !modalRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const shareOnTwitter = () => {
    const inviteCode = inviteLink;
    const tweetText = encodeURIComponent(
      "This is awesome. 😍" +
        "\n" +
        "\n" +
        `I just claimed ${userInfoData?.allocatedGold} Blast Gold from @Quailfinance, a socialfi dapp that allows people to rotate credit based on social trust. ` +
        "\n" +
        "\n" +
        "I look forward to the next claim. 👀"
    );
    const twitterIntentURL = `https://twitter.com/intent/tweet?text=${tweetText}`;

    window.open(twitterIntentURL, "_blank");
  };

  const fetchClaimPointsData = async () => {
    try {
      const result = await claimUserGolds(authToken);
      // const result = await claimUserPoints(authToken);
      if (result.message === "Golds claimed successfully.") {
        setIsBlastClaimed(true);
      }
      if (account) {
        const gotProfileData = await dispatch(getProfileData(account));
        if (gotProfileData.meta.requestStatus === "fulfilled") {
          if (gotProfileData?.payload?.username) {
            setUserProfile(gotProfileData.payload);
            localStorage.setItem(
              localStorageStates.userInfo,
              JSON.stringify(gotProfileData.payload)
            );
          }
        }
      }
    } catch (error: any) {
      console.error("Failed to claim user points", error);
      if (error) {
        toast.error(error || "Failed to claim user points:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const claimPointsFunc = () => {
    if (userInfoData?.allocatedGold > 0) {
      fetchClaimPointsData();
      shareOnTwitter();
    } else {
      toast.error("You have already Claimed Blast Gold", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <ModalTitle>TOTAL BLAST GOLD</ModalTitle>
          <ModalText>
            It may take up to 24 hours for your Blast Gold to be reflected on
            the Blast airdrop page.
          </ModalText>
          <GreenStar />
          <BlastPoints>
            {(userInfoData?.allocatedGold).toFixed(2)} BLAST Gold
            {/* {Math.ceil(userInfoData?.allocatedGold).toFixed(2)} BLAST Gold */}
          </BlastPoints>
          {isBlastClaimed === true && <ClaimedText>CLAIMED</ClaimedText>}
          {isBlastClaimed === false && <ClaimedText>Claimable Now</ClaimedText>}
          {isBlastClaimed === false && (
            <ShareNowToClaimButton
              onClick={claimPointsFunc}
              height="48px"
              padding="0 16px"
            >
              Share Now To claim
            </ShareNowToClaimButton>
          )}
          {isBlastClaimed === false && (
            <ClaimableText>
              Total Blast gold Claimed {(userInfoData?.claimedGold).toFixed(2)}
              {/* {Math.ceil(userInfoData?.claimedGold).toFixed(2)} */}
            </ClaimableText>
          )}
          <BuiltOnBlast />
        </ModalContainer>
      </ModalBackdrop>
    </>
  );
};

export default BlastPointsModal;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    /* bottom: 50px; */
    align-items: flex-end;
    width: 100%;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  padding: 40px;
  /* padding: 48px; */
  border: 1px solid rgba(233, 246, 208, 0.16);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    max-width: calc(100% - 48px);
    border-radius: 0;
    padding: 24px;
  }
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 768px) {
    width: 343px;
    font-size: 16px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
  opacity: 0.7;
  text-align: center;
  max-width: 450px;
  @media (max-width: 768px) {
    width: 300px;
    font-size: 14px;
  }
`;

const BlastPoints = styled.span`
  color: #e9f6d0;
  /* color: #fff; */
  font-family: Orbitron;
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: 40.8px;
  text-transform: uppercase;
  text-align: center;
  /* margin-top: 0.5em; */
  /* @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 200;
        } */
`;

const ClaimedText = styled.span`
  color: #e9f6d0;
  /* color: #fff; */
  font-family: Orbitron;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 61px;
`;

const ClaimableText = styled.span`
  color: var(--primary, #b1dd40);
  text-align: center;

  /* glow */
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);

  /* desktop/heading/five--number */
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 61px;
  margin-top: 14px;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;

  color: var(--dark, #13160b);
  font-family: ${(props) => props.theme.fontFamily.secondary};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  gap: 10px;
  border-radius: 8px;
`;

export const ShareNowToClaimButton = styled(HoverButton)<{}>`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  pointer-events: ${(props) => "auto"};

  /* &:hover {
    padding: 0px 17px;
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

import React, { forwardRef } from "react";
import styled from "styled-components";

interface QualifiSectionProps {
  data: {
    totalPots: string;
    totalUsers: string;
    commulativeVolume: string;
    yieldDistribution: string;
  };
}

const QualifiSection = forwardRef<HTMLDivElement, QualifiSectionProps>(
  (props, ref) => {
    // Destructure the data object from props for easier access
    const { data } = props;
    const { totalPots, totalUsers, commulativeVolume, yieldDistribution } =
      data;
    //   const sectionRef = useRef(null);
    const handleCreatePotClick = () => {
      console.log("handleCreatePotClick", handleCreatePotClick);
    };
    return (
      <>
        <Box ref={ref}>
          <MainHeading>Quail finance in numbers</MainHeading>
          <SubHeading>
            More and more people are joining the SocialFi Revolution everyday.
          </SubHeading>
        </Box>
        <BoxesContainer>
          <InfoBox>
            <NumberText>{totalPots}</NumberText>
            <DescriptionText>Total pots</DescriptionText>
          </InfoBox>
          <InfoBox>
            <NumberText>{totalUsers?.toLocaleString()}</NumberText>
            <DescriptionText>Total users</DescriptionText>
          </InfoBox>
          <InfoBox>
            <NumberText>$ { commulativeVolume}</NumberText>
            <DescriptionText>Cumulative Volume</DescriptionText>
          </InfoBox>
          {/* <InfoBox>
            <NumberText>{yieldDistribution}</NumberText>
            <DescriptionText>Yield distribution</DescriptionText>
          </InfoBox> */}
        </BoxesContainer>
      </>
    );
  }
);

export default QualifiSection;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 105px auto;
  margin-top: 40px;
  padding-top: 105px;
  border-top: ${(props) => props.theme.borders.light};

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 45px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 52px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
    text-align: left;
  }
`;

export const SubHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0px;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 22px;
  max-width: 1200px;
  margin-bottom: 20px; // Space below the subheading

  @media (max-width: 1450px) {
    gap: 20px;
  }

  @media (min-width: 481px) {
    justify-content: space-around; // Ensures boxes are spread evenly across the container
    width: 90%; // Ensures the container takes full width
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    gap: 18px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center; // Aligns boxes to the center in a column
    gap: 16px;
  }
`;

export const InfoBox = styled.div`
  width: 266px;
  height: 128px;
  border: 1px solid rgba(233, 246, 208, 0.2);
  border-radius: 8px;
  padding: 64px 24px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.darkBlack};
  align-items: center;
  justify-content: center;
  gap: 16px; // Adjust gap between texts as needed

  @media (max-width: 1450px) {
    width: 236px;
    height: 122px;
  }

  @media (min-width: 481px) {
    width: calc(
      25% - 0px
    ); // Adjusts width dynamically to fit 4 boxes in one row
    height: 170px; // Adjusts height automatically based on content
    padding: 32px 24px; // Adjust padding as needed
  }

  @media (max-width: 768px) {
    width: calc(50% - 61px);
    height: 110px;
  }

  @media (max-width: 480px) {
    width: 90%; // Takes up most of the screen width for better readability
    height: auto; // Allows the box to adjust its height based on its content
    padding: 48px 16px;
  }
`;

// export const BoxesContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   gap: 22px;
//   margin-bottom: 20px; // Space below the subheading

//   @media (max-width: 1450px) {
//     gap: 20px;
//   }

//   //   @media (max-width: 768px) {
//   //     justify-content: space-around;
//   //   }

//   @media (max-width: 768px) {
//     flex-direction: row;
//     justify-content: space-between;
//     width: 100%;
//     gap: 18px;
//   }

//   @media (max-width: 480px) {
//     gap: 16px;
//   }
// `;

// export const InfoBox = styled.div`
//   width: 266px;
//   height: 128px;
//   border: 1px solid rgba(233, 246, 208, 0.2);
//   border-radius: 8px;
//   padding: 64px 24px;
//   display: flex;
//   flex-direction: column;
//   background: ${(props) => props.theme.colors.darkBlack};
//   align-items: center;
//   justify-content: center;
//   gap: 16px; // Adjust gap between texts as needed

//   //   @media (max-width: 768px) {
//   //     &:nth-child(n + 3) {
//   //       display: none;
//   //     }
//   //   }

//   @media (max-width: 1450px) {
//     width: 236px;
//     height: 122px;
//   }

//   @media (max-width: 768px) {
//     width: calc(50% - 61px);
//     height: 110px;
//   }

//   @media (max-width: 480px) {
//     width: calc(50% - 59px);
//     height: 60px;
//   }
// `;

export const NumberText = styled.span`
  font-family: "Orbitron", sans-serif;
  font-weight: 900;
  font-size: 45px;
  line-height: 54px;
  letter-spacing: 0.9px; // 2% of 45px
  text-align: center;
  color: ${(props) => props.theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 38px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

export const DescriptionText = styled.span`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.colors.light};

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ProfileAvatar } from "../../assets/pot_member1.svg";
import AddPlus from "../../assets/add.svg";
import AddPotModal from "../../components/Modal/AddPotModal";
import JoinPotModal from "../../components/Modal/InvitePotModal";
import JoinPotConfirmModal from "../../components/Modal/JoinPotConfirmModal";
import JoinPotSuccessModal from "../../components/Modal/JoinPotSucessModal";
import JoinPotInsufficientModal from "../../components/Modal/joinPotFailedModal";
import { ChatData, LeftBarProps } from "../../utils/interface";
import { useNavigate, useParams } from "react-router-dom";
import PotChat from "../chatbox/PotChatContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  checkSyncDetails,
  getChatsData,
  getPotDetail,
  joinPot,
  joinPotSignature,
  resetChats,
  setDepositError,
  setIsArchived,
  setIsDeposited,
  setIsDepositedLoading,
  setPotCode,
  setWalletConnect,
} from "../../store";
import { useWeb3React } from "@web3-react/core";
import { joinPotTx } from "../../utils/transactions/joinAPot";
import { approveUsdbTx } from "../../utils/transactions/approveUsdb";
import InsufficientFundModal from "../Modal/InsufficientFundModal";
import { Insufficient_Fund_Error } from "../../utils/errors";
import { localStorageStates } from "../../utils";
import { toast } from "react-toastify";
import { checkUserSigned } from "../../utils/helper";
import { io } from "socket.io-client";
import HoverButton from "../Button/HoverButton";

interface PotDetail {
  potCode?: string;
  groupChat?: string;
  potId?: number;
  amount?: string;
}

const LeftBar: React.FC<LeftBarProps> = ({
  onClick,
  openChatInFullScreen,
  handleRowClick,
  onDepositClick,
}) => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { account, provider, isActive } = useWeb3React();
  const addModalRef = useRef<HTMLButtonElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const joinPotModalRef = useRef<HTMLButtonElement>(null);
  const joinPotSuccessModalRef = useRef<HTMLDivElement>(null);
  const {
    chats: { isArchived },
  } = useSelector((state: RootState) => state.wallet);
  const dispatch = useDispatch<AppDispatch>();
  const [isJoinPotOpen, setIsJoinPotOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("current");
  const [activeChatList, setActiveChatList] = useState("1");
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isPotDetailLoading, setIsPotDetailLoading] = useState(false);
  const isSocketInitialized = useRef(false);
  const [socket, setSocket] = useState<any>(null);
  const [userProfile, setUserProfile] = useState({
    username: "",
    points: 0,
    profilePicture: "",
    twitterInfo: null,
    discordInfo: null,
    generatedInviteCodes: [],
    isBlacklisted: false,
    rank: 0,
    liquidityPoints: "",
  });
  const [isAddPotOpen, setIsAddPotOpen] = useState(false);
  const [isJoinPotConfirmModal, setIsJoinPotConfirmModal] = useState(false);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);
  const [isJoinPotSuccessModal, setIsJoinPotSuccessModal] = useState(false);
  const [potDetail, setPotDetail] = useState<PotDetail>({});
  const [chatId, setChatId] = useState<string>("");
  const [isJoinPotInsufficientOpen, setIsJoinPotInsufficientOpen] =
    useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    right: 0,
  });
  const [clickedRows, setClickedRows] = useState<Record<string, boolean>>({});
  const [dropdownOpenMap, setDropdownOpenMap] = useState<
    Record<string, boolean>
  >({});
  const [inputValue, setInputValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ChatData[]>([]);
  const {
    chats: { data: chatData },
  } = useSelector((state: RootState) => state.wallet);
  // const [chatData, setChatData] = useState<ChatData[]>([
  //   {
  //     username: "",
  //     profilePicture: "",
  //     time: "",
  //     amount: "",
  //     title: "",
  //     name: "",
  //     participants: [],
  //     pot: {
  //       groupChat: null,
  //       noOfParticipants: 0,
  //       amount: 0,
  //       rotationCycle: null,
  //       riskRate: null,
  //       potId: null,
  //       createdAt: "",
  //       potCode: "",
  //       updatedAt: "",
  //     },
  //     admin: { username: "" },
  //   },
  // ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const domain = window.location.hostname;
  const authToken = localStorage.getItem("token");

  const handleChatListChange = (index: number) => {
    setActiveChatList(index.toString());
  };

  const handlePotsTabChange = (tab: "current" | "past") => {
    setActiveTab(tab);
    const isArchived = tab == "past";
    dispatch(setIsArchived(isArchived));
  };

  useEffect(() => {
    setActiveTab(isArchived ? "past" : "current");
  }, [isArchived]);

  // Function to handle opening the notification modal
  const openNotificationModal = (index: number, chatId: any) => {
    setClickedRowIndex(index);
    setIsNotificationModalOpen(true);
    navigate(`/dashboard/${chatId}`);
  };

  const AddPotButtonHandle = () => {
    // if (!checkUserSigned()) {
    //   toast.error(
    //     "Please sign the message from wallet or reload the page to continue",
    //     {
    //       position: "bottom-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     }
    //   );
    //   return;
    // } else {
    setIsAddPotOpen(true);
    // }
  };

  const CreatPotHandle = () => {
    navigate("/createPot");
  };

  const onClickJoinPotHandle = () => {
    setIsAddPotOpen(false);
    setIsJoinPotOpen(true);
  };

  const JoinPotHandleNext = async (inputText: string) => {
    // const
    try {
      setIsPotDetailLoading(true);
      const tempoken = inputText.split("/").pop();
      if (tempoken) {
        const getPotDetailResponse = await dispatch(
          getPotDetail({ pot: tempoken })
        );
        if (getPotDetailResponse.meta.requestStatus === "fulfilled") {
          const potDetailPayload = getPotDetailResponse.payload;
          setPotDetail(potDetailPayload);
          setIsJoinPotOpen(false);
          setIsJoinPotConfirmModal(true);
        }
        if (getPotDetailResponse.meta.requestStatus === "rejected") {
          toast.error(
            getPotDetailResponse?.payload?.message ||
              "Failed to get pot detail",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error) {
    } finally {
      setIsPotDetailLoading(false);
    }
  };

  const JoinPotHandle = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    dispatch(setIsDepositedLoading(true));
    setIsConfirmLoading(true);
    setIsJoinPotConfirmModal(false);
    setIsJoinPotOpen(false);
    setIsAddPotOpen(false);
    try {
      const potId = potDetail?.potId;
      const potCode = potDetail?.potCode;
      const amount = potDetail?.amount?.toString();
      if (potId && potCode) {
        try {
          if (amount) await approveUsdbTx({ amount });
        } catch (error: any) {
          console.log("Error during USDB approval:", error?.message);
          if (error?.message.includes(Insufficient_Fund_Error)) {
            setIsJoinPotConfirmModal(false);
            setIsInsufficientFundModal(true);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );
        dispatch(
          setIsDeposited({
            isDeposited: false,
            isJoined: false,
            data: null,
            isSyncingJoined: true,
          })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: true,
            potCode: potCode,
          }).catch((error) => {
            dispatch(setDepositError(error?.message || "Failed to join a pot"));
            toast.error(error?.message || "Failed to join a pot", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
          let firstChatPotId = chatData[0]?.pot?.groupChat;
          let attempt = 0;
          let getChatDataResponse;
          let chatDataInResponse;
          let isError;
          do {
            attempt++;
            dispatch(resetChats());
            getChatDataResponse = await dispatch(
              getChatsData({
                pageNo: 1,
                isArchived: false,
                hasSynced: true,
              })
            );
            isError = getChatDataResponse.payload.isError;
            chatDataInResponse = getChatDataResponse.payload.data.response;
            if (
              chatDataInResponse[0].pot.groupChat === firstChatPotId ||
              getChatDataResponse.meta.requestStatus !== "fulfilled"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            (attempt < 60 &&
              chatDataInResponse[0].pot.groupChat === firstChatPotId &&
              !isError) ||
            getChatDataResponse.meta.requestStatus !== "fulfilled"
          );
          if (!isError) {
            let joinPotResponse;
            let attemptJoin = 0;
            do {
              attemptJoin++;
              joinPotResponse = await dispatch(
                checkSyncDetails({ potId, eventType: "participantUpdated" })
              );

              if (joinPotResponse.meta.requestStatus !== "fulfilled") {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            } while (
              joinPotResponse.meta.requestStatus !== "fulfilled" &&
              attemptJoin < 60
            );
            if (joinPotResponse.meta.requestStatus === "fulfilled") {
              dispatch(setIsDepositedLoading(false));
              dispatch(
                setIsDeposited({
                  // isDeposited: true,
                  isJoined: true,
                  data: null,
                  isSyncingJoined: false,
                })
              );
              setIsJoinPotSuccessModal(true);
              const groupChatId = potDetail?.groupChat;
              navigate(`/dashboard/${groupChatId}`);
              // goBackToChatGroup();
              // dif(groupChatId);
              // if (!isSocketInitialized.current) {
              //   console.log("groupChatId1", groupChatId);
              //   const SOCKET_URI = process.env.REACT_APP_SOCKET_URI;
              //   if (SOCKET_URI) {
              //     const newSocket = io(SOCKET_URI, {
              //       auth: { token: authToken },
              //     });
              //     setSocket(newSocket);
              //     isSocketInitialized.current = true;
              //     newSocket.on("connect", function () {
              //       newSocket.emit(
              //         "joinChat",
              //         { chatId: groupChatId },
              //         (response: any) => {
              //           console.log("Join chat emit response:", response);
              //         }
              //       );

              //       // participantJoinedFunc(groupChatId);
              //     });
              //   }
              // }
              // setIsJoinPotConfirmModal(true);
              // createdPot(code);
            } else {
              dispatch(
                setDepositError(
                  joinPotResponse?.payload?.message || "Failed to join a pot"
                )
              );
              toast.error(
                joinPotResponse?.payload?.message || "Failed to join a pot",
                {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }

          // Retry mechanism
          // let attempt = 0;
          // let joinPotResponse;
          // do {
          //   attempt++;
          //   joinPotResponse = await dispatch(
          //     joinPot({ walletAddress: account, potCode })
          //   );

          //   // If the request is not fulfilled, wait for a bit before retrying
          //   if (joinPotResponse.meta.requestStatus !== "fulfilled") {
          //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          //   }
          // } while (
          //   joinPotResponse.meta.requestStatus !== "fulfilled" &&
          //   attempt < 5
          // ); // Retry up to 5 times

          // Check if it succeeded after retries
          // if (joinPotResponse.meta.requestStatus === "fulfilled") {
          //   dispatch(
          //     setIsDeposited({
          //       isDeposited: true,
          //       data: null,
          //     })
          //   );
          //   setIsJoinPotSuccessModal(true);
          //   const groupChatId = joinPotResponse?.payload?.groupChat;

          //   // dif(groupChatId);
          //   if (!isSocketInitialized.current) {
          //     const SOCKET_URI = process.env.REACT_APP_SOCKET_URI;
          //     if (SOCKET_URI) {
          //       const newSocket = io(SOCKET_URI, {
          //         auth: { token: authToken },
          //       });
          //       setSocket(newSocket);
          //       isSocketInitialized.current = true;
          //       newSocket.on("connect", function () {
          //         newSocket.emit(
          //           "joinChat",
          //           { chatId: groupChatId },
          //           (response: any) => {
          //             console.log("Join chat emit response:", response);
          //           }
          //         );

          //         // participantJoinedFunc(groupChatId);
          //       });
          //     }
          //   }
          // }
          // if (joinPotResponse.meta.requestStatus === "rejected") {
          //   dispatch(
          //     setDepositError(
          //       joinPotResponse?.payload?.message || "Failed to join a pot"
          //     )
          //   );
          //   toast.error(
          //     joinPotResponse?.payload?.message || "Failed to join a pot",
          //     {
          //       position: "bottom-right",
          //       autoClose: 5000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //       draggable: true,
          //       progress: undefined,
          //     }
          //   );
          // }
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          dispatch(
            setDepositError(
              getJoinPotSignatureResponse?.payload?.message ||
                "Failed to join a pot"
            )
          );
          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      dispatch(setDepositError(error?.message || "Failed to join a pot"));
      toast.error(error?.message || "Failed to join a pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to join a pot:", error);
    } finally {
      dispatch(setIsDepositedLoading(false));
      setIsConfirmLoading(false);
    }
  };

  const JoinPotCloseHandle = () => {
    setIsJoinPotOpen(false);
  };

  const JoinPotConfirmCloseHandle = () => {
    setIsJoinPotConfirmModal(false);
  };
  const JoinPotConfirmHandle = () => {
    setIsJoinPotConfirmModal(false);
    setIsJoinPotSuccessModal(true);
  };

  const ViewPotHandle = (chatId: any) => {
    setIsJoinPotSuccessModal(false);
    const chatRoute = chatId ? `/dashboard/${chatId}` : "/dashboard";
    navigate(chatRoute);
  };

  const JoinPotSuccessCloseHandle = () => {
    setIsJoinPotSuccessModal(false);
  };

  const JoinPotInsufficientCloseHandle = () => {
    setIsJoinPotInsufficientOpen(false);
  };

  useEffect(() => {
    let userInfoInLocalStorage = JSON.parse(
      localStorage.getItem(localStorageStates.userInfo) || "{}"
    );
    if (userInfoInLocalStorage) setUserProfile(userInfoInLocalStorage);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        addModalRef.current &&
        !addModalRef.current.contains(event.target as Node)
      ) {
        setIsAddPotOpen(false);
      }
    };
    const handleSearchClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setInputValue("");
        setSearchResults([]);
      }
    };
    const handleJoinPotClickOutside = (event: MouseEvent) => {
      if (
        joinPotModalRef.current &&
        !joinPotModalRef.current.contains(event.target as Node)
      ) {
        setIsJoinPotOpen(false);
      }
    };
    const handleJoinPotSuccessClickOutside = (event: MouseEvent) => {
      if (
        joinPotSuccessModalRef.current &&
        !joinPotSuccessModalRef.current.contains(event.target as Node)
      ) {
        setIsJoinPotConfirmModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleSearchClickOutside);
    document.addEventListener("mousedown", handleJoinPotClickOutside);
    document.addEventListener("mousedown", handleJoinPotSuccessClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleSearchClickOutside);
      document.removeEventListener("mousedown", handleJoinPotClickOutside);
      document.removeEventListener(
        "mousedown",
        handleJoinPotSuccessClickOutside
      );
    };
  }, []);

  useEffect(() => {
    if (token) {
      setIsJoinPotOpen(true);
      dispatch(setPotCode(token));
    } else {
      dispatch(setPotCode(""));
    }
    // const fetchData = async () => {
    //   try {
    //     const response = await fetchGroupChats(authToken, activeTab);
    //     const data = response.data; // Assuming response is an object with a data property
    //     setChatData(data); // Update chatData state with fetched data
    //   } catch (error: any) {
    //     toast.error(error?.message || "Error fetching group chats:", {
    //       position: "bottom-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     console.error("Error fetching group chats:", error);
    //   }
    // };

    // fetchData();
  }, []);

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value !== "") {
      const results = chatData.filter((item: any) =>
        // console.log('value Item:', item)
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);

  useEffect(() => {
    if (isJoinPotSuccessModal) {
      // setTimeout(() => setIsJoinPotSuccessModal(false), 5000);
    }
  }, [isJoinPotSuccessModal]);

  return (
    <Container>
      <TopHeader>
        <TopHeaderTitle>my Pots</TopHeaderTitle>
        <AddPotButton
          onClick={() => AddPotButtonHandle()}
          height="40px"
          padding="0 16px"
        >
          add a Pot
        </AddPotButton>
      </TopHeader>
      <SearchBarBox ref={searchRef}>
        <SearchTextfield
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={"Search"}
        />
        {searchResults && searchResults.length > 0 ? (
          <SearchSuggestionContainer>
            {searchResults.map((chat, index) => (
              <SearchSuggestionItem
                key={`${index}-SearchSuggestionItem`}
                onClick={() => {
                  openNotificationModal(index, chat._id);
                  if (handleRowClick) {
                    handleRowClick(index, chat._id);
                    setSelectedRow(chat._id);
                    setInputValue("");
                    setSearchResults([]);
                  }
                }}
              >
                {chat?.name} #{index + 1}
              </SearchSuggestionItem>
            ))}
          </SearchSuggestionContainer>
        ) : inputValue !== "" ? (
          <SearchSuggestionContainer>
            <SearchSuggestionItem>Search No Found</SearchSuggestionItem>
          </SearchSuggestionContainer>
        ) : (
          <></>
        )}
      </SearchBarBox>
      <div>
        <FullWidthScrollContainer>
          <TabsAndButtons>
            <Tabs>
              <Tab
                isactive={activeTab === "current"}
                onClick={() => handlePotsTabChange("current")}
              >
                Active Pots
              </Tab>
              {/* <Tab
                isactive={activeTab === "past"}
                onClick={() => handlePotsTabChange("past")}
              >
                Past Pots
              </Tab> */}
            </Tabs>
          </TabsAndButtons>
        </FullWidthScrollContainer>
      </div>
      <MiddleSection>
        <PotChat
          openNotificationModal={openNotificationModal}
          openChatInFullScreen={openChatInFullScreen}
          renderThreeDots={true}
          onRowClick={handleRowClick}
          onDepositClick={onDepositClick}
          selectedRow={selectedRow}
        />

        <MobileAddPotButton onClick={() => setIsAddPotOpen(!isAddPotOpen)}>
          <img src={AddPlus} alt={"AvatarTwo"} />
        </MobileAddPotButton>

        {isAddPotOpen && (
          <AddPotModal
            onClose={() => setIsAddPotOpen(false)}
            onClickJoinPotHandle={onClickJoinPotHandle}
            onClickCreatPotHandle={CreatPotHandle}
            addModalRef={addModalRef}
          />
        )}
        {isJoinPotOpen && (
          <JoinPotModal
            onClose={JoinPotCloseHandle}
            onConfirm={JoinPotHandleNext}
            isLoading={isPotDetailLoading}
            link={token ? `${domain}/${token}` : ""}
            joinPotModalRef={joinPotModalRef}
          />
        )}

        {isJoinPotConfirmModal && (
          <JoinPotConfirmModal
            onClose={JoinPotConfirmCloseHandle}
            onConfirm={JoinPotHandle}
            isLoading={isConfirmLoading} // Update this according to your logic
            potDetail={potDetail}
            chatDataProps={potDetail}
            joinPotSuccessModalRef={joinPotSuccessModalRef}
          />
        )}
        {isJoinPotSuccessModal && (
          <JoinPotSuccessModal
            onClose={JoinPotSuccessCloseHandle}
            onConfirm={ViewPotHandle}
            potDetail={potDetail}
          />
        )}
        {isJoinPotInsufficientOpen && (
          <JoinPotInsufficientModal onClose={JoinPotInsufficientCloseHandle} />
        )}
      </MiddleSection>
      <BottomSection>
        <ProfileRow>
          <Avatar>
            {userProfile?.profilePicture ? (
              <>
                <img
                  src={userProfile?.profilePicture}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100px",
                  }}
                  alt="Avatar"
                />
              </>
            ) : (
              <AvatarIcon />
            )}
          </Avatar>
          <ProfileBox>
            <ProfileText>@{userProfile.username}</ProfileText>
            {isActive && account && (
              <ProfileAddress>
                {" "}
                {`${account.substring(0, 4)}...${account.substring(
                  account.length - 4
                )}`}
              </ProfileAddress>
            )}
          </ProfileBox>
        </ProfileRow>
        <RankRow>
          <RankBox>
            <RankLabel>Ranking</RankLabel>
            <RankNumber>#{userProfile?.rank}</RankNumber>
          </RankBox>
          <PointsBox>
            <RankLabel>Total Points</RankLabel>
            <RankNumber>{userProfile?.points?.toLocaleString()}</RankNumber>
          </PointsBox>
        </RankRow>
      </BottomSection>
      {isInsufficientFundModal && (
        <InsufficientFundModal onClose={onCloseInsifficientModal} />
      )}
    </Container>
  );
};

export default LeftBar;

const Container = styled.div`
  display: flex;
  max-width: 375px;
  height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #373636;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-right: 1px solid rgba(233, 246, 208, 0.12);

  /* @media (max-width: 1536px) {
    height: 78vh;
  } */
  /* @media (max-width: 1440px) {
    height: 74vh;
  } */
  /* @media (max-width: 1366px) {
    height: 70vh;
  } */
  /* @media (max-width: 1280px) {
    height: 74vh;
  } */
  /* @media (max-width: 1080px) {
    height: 65vh;
  } */
  /* @media (max-width: 768px) {
    height: 55vh;
  } */

  @media (min-width: 1300px) {
    max-width: 600px;
  }
  @media (max-width: 768px) {
    max-width: 275px;
    /* width: 100%; */
    height: 100vh;
  }
  @media (max-width: 475px) {
    max-width: 100%;
    height: 100vh;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    height: 77vh;
  }

  /* Custom scrollbar */

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const TopHeader = styled.div`
  display: flex;
  padding: 12px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media (max-width: 640px) {
    display: none;
  }
`;

const TopHeaderTitle = styled.h1`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
  text-transform: uppercase;
`;

const AddPotButton = styled(HoverButton)`
  /* height: 40px;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 6px;
  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }

  &:active {
    color: grey;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

const SearchBarBox = styled.div`
  position: relative;
  padding: 12px 16px 8px 16px;
  width: 100%;
  @media (max-width: 360px) {
    /* display: flex;
  justify-content: center;
  align-items: center; */
  }
`;
const SearchTextfield = styled.input`
  display: flex;
  width: 324px;
  width: 85%;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);

  color: var(--light, #e9f6d0);

  /* desktop/body/medium */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  &:focus {
    outline: none;
  }

  @media (min-width: 1300px) {
    width: 324px;
  }
  @media (min-width: 1100px) {
    width: 300px;
  }
  @media (min-width: 900px) {
    width: 280px;
  }
  @media (min-width: 769px) {
    width: 260px;
  }
  @media (min-width: 475px) and (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 385px) {
    width: 300px;
  }
  @media (max-width: 360px) {
    max-width: 280px;
  }
  @media (max-width: 340px) {
    max-width: 260px;
  }
  @media (max-width: 320px) {
    max-width: 240px;
  }
  @media (max-width: 300px) {
    max-width: 220px;
  }
`;

const SearchSuggestionContainer = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  /* width: 323px; */
  width: 90%;
  /* height: 200px;
  overflow-y: auto; */
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid rgba(233, 246, 208, 0.12);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.12) 0%,
      rgba(233, 246, 208, 0.12) 100%
    ),
    #13160b;
  margin-top: 8px;
`;
const SearchSuggestionItem = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--light, #e9f6d0);

  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  &:hover {
    background: rgba(233, 246, 208, 0.2);
  }
`;

const FullWidthScrollContainer = styled.div`
  overflow-x: auto;
  margin-top: 16px;
  z-index: 1200;
  -webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
  scrollbar-width: none; // Hides scrollbar for Firefox

  &::-webkit-scrollbar {
    display: none; // Hides scrollbar for Webkit browsers
  }
`;

const TabsAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 1em;
  padding-right: 1em;
`;

const Tab = styled.button<{ isactive: boolean }>`
  flex: 0 0 auto;
  height: 40px;
  padding: 0px 16px;
  border-radius: 100px;
  border: 2px solid transparent;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.12) 0%,
      rgba(233, 246, 208, 0.12) 100%
    ),
    #13160b;
  cursor: pointer;
  background: rgba(233, 246, 208, 0.12);

  color: #fff;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;

  &:hover,
  &.active {
    border-color: #e9f6d0;
  }

  @media (max-width: 475px) {
    font-size: 14px;
  }
  ${(props) =>
    props.isactive &&
    `
    border-color: #e9f6d0;
  `}
`;

const MiddleSection = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  //overflow-y: auto;

  /* Custom scrollbar */

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  @media (max-width: 475px) {
    margin-top: 1em;
  }
`;

const Avatar = styled.div`
  width: 44px;
  height: 44px;
`;

const BottomSection = styled.div`
  width: 96%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: start;
  gap: 12px;
  align-self: stretch;
  background: rgba(233, 246, 208, 0.06);
  /* sm */
  @media (max-width: 640px) {
    display: none;
  }

  /* xs */
  @media (max-width: 475px) {
    display: none;
  }
`;
const MobileAddPotButton = styled.div`
  display: none;
  position: fixed;
  bottom: 80px;
  right: 40px;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--primary, #b1dd40);
  z-index: 2;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const ProfileRow = styled.div`
  display: flex;
  align-items: start;
  padding: 8px 12px 8px 8px;
  justify-content: start;
  gap: 12px;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const AvatarIcon = styled(ProfileAvatar)`
  height: 44px;
  width: 44px;
  margin-right: 12px;
`;

const ProfileText = styled.span`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const ProfileAddress = styled.div`
  color: #b1dd40;
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const RankRow = styled.div`
  display: flex;
  padding: 0px 16px 16px 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const RankBox = styled.div`
  display: flex;
  width: 120px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: rgba(233, 246, 208, 0.06);
`;
const PointsBox = styled.div`
  display: flex;
  min-width: 120px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: rgba(233, 246, 208, 0.06);
`;

const RankLabel = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.7;
`;
const RankNumber = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

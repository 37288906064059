import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { GlobalStyles } from "./styles/GlobalStyle";
import { ToastContainer } from "react-toastify";
import Home from "./pages/home";
import Dashboards from "./pages/dashboard";
import Chats from "./pages/chats";
import EarlyAccessPage from "./pages/early-access";
import { IsRegistered } from "./protectedRoutes/isRegitered";
import EarnPage from "./pages/earn";
import QuailABI from "./utils/abi/quailAbi.json";
import { IsUserSigned } from "./protectedRoutes/isUserSigned";
import { IsWallet } from "./protectedRoutes/isWallet";
import LeaderboardPage from "./pages/leaderboard";
import CreatePotPage from "./pages/createPot";
import Notification from "./pages/notification";
import BottomMenu from "./components/BottomMenu";
import theme from "./styles/theme";
import { TokenLengthBasedComponent } from "./protectedRoutes/TokenLengthBasedComp";
import "react-toastify/dist/ReactToastify.css";
import { IsNotRegistered } from "./protectedRoutes/isNotRegistered";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import WalletNotConnected from "./components/WalletNotConnected";
import { ethers } from "ethers";
import {
  AppDispatch,
  addSignupFee,
  claimFunds,
  createPot,
  joinPot,
  leavePot,
  syncRotate,
} from "./store";
import { useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { account } = useWeb3React();
  const recoverCreatePotPendingTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingCreatePotTx");
    if (!pendingTxJSON) return;

    const getPotIdFromReceipt = async (receipt: any) => {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const Quail_Finance_Contract_Address =
        process.env.REACT_APP_Quail_Finance_Contract_Address;
      if (Quail_Finance_Contract_Address) {
        const contract = new ethers.Contract(
          Quail_Finance_Contract_Address,
          QuailABI,
          signer
        );
        const potIdPromise = new Promise<number>((resolve, reject) => {
          let eventHandler = (id: any, event: any) => {
            resolve(id);
            contract.off("PotCreated", eventHandler);
          };

          contract.on("PotCreated", eventHandler);
        });
        // console.log("receipt", receipt);
        // const contractInterface = new ethers.Interface([
        //   "event PotCreated(uint256 potId)",
        // ]);
        // console.log("contractInterface", contractInterface);
        // for (let log of receipt.logs) {
        //   try {
        //     const parsedLog = contractInterface.parseLog(log);
        //     console.log("parsedLog", parsedLog);
        //     if (parsedLog && parsedLog.name === "PotCreated") {
        //       return parsedLog.args.potId.toString();
        //     }
        //   } catch (error) {}
        // }
        // throw new Error("PotCreated event not found in the transaction logs");
      }
    };

    const pendingTx = JSON.parse(pendingTxJSON);

    const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
    try {
      const receipt = await ethersProvider.getTransactionReceipt(
        pendingTx.transactionHash
      );

      if (receipt && receipt.status === 1) {
        const potId = await getPotIdFromReceipt(receipt);
        localStorage.removeItem("pendingCreatePotTx");
        let createdPotResponse;
        let attempt = 0;
        do {
          attempt++;
          createdPotResponse = await dispatch(createPot({ potId }));

          // If the request is not fulfilled, wait for a bit before retrying
          if (createdPotResponse.meta.requestStatus !== "fulfilled") {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          }
        } while (
          createdPotResponse.meta.requestStatus !== "fulfilled" &&
          attempt < 5
        );
      } else if (receipt && receipt.status === 0) {
        console.error("Transaction failed and was reverted by the EVM");
        localStorage.removeItem("pendingCreatePotTx");
      } else {
        console.log(
          "Transaction is still pending, consider rechecking later..."
        );
      }
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  const recoverJoinPotPendingTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingJoinPotTx");
    if (!pendingTxJSON) return;
    try {
      const pendingTx = JSON.parse(pendingTxJSON);
      if (pendingTx && pendingTx?.currentRound) {
        if (pendingTx?.currentRound > 1) {
          let attempt = 0;
          let joinPotResponse;

          do {
            attempt++;
            joinPotResponse = await dispatch(
              joinPot({ walletAddress: account, potCode: pendingTx?.potCode })
            );

            if (joinPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 1000));
            }
          } while (
            joinPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 5
          );
        }
      } else {
        let attempt = 0;
        let joinPotResponse;
        do {
          attempt++;
          joinPotResponse = await dispatch(
            joinPot({ walletAddress: account, potCode: pendingTx?.potCode })
          );

          if (joinPotResponse.meta.requestStatus !== "fulfilled") {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        } while (
          joinPotResponse.meta.requestStatus !== "fulfilled" &&
          attempt < 5
        );
      }
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  const recoverClaimFundsPendingTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingClaimFundsTx");
    if (!pendingTxJSON) return;
    try {
      const pendingTx = JSON.parse(pendingTxJSON);
      const claimFundsResponse = await dispatch(
        claimFunds({
          potCode: pendingTx?.potCode,
        })
      );
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  const recoverStartPotPendingTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingStartPotTx");
    if (!pendingTxJSON) return;
    try {
      const pendingTx = JSON.parse(pendingTxJSON);
      let attempt = 0;
      let rotateLiquidityResponse;
      do {
        attempt++;
        rotateLiquidityResponse = await dispatch(
          syncRotate({ potId: pendingTx?.potId })
        );

        if (rotateLiquidityResponse.meta.requestStatus !== "fulfilled") {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } while (
        rotateLiquidityResponse.meta.requestStatus !== "fulfilled" &&
        attempt < 5
      );
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  const recoverTransferEthTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingTransferEth");
    if (!pendingTxJSON) return;
    try {
      const pendingTx = JSON.parse(pendingTxJSON);
      const addFeeResponse = await dispatch(
        addSignupFee({
          transactionHash: pendingTx?.transactionHash,
        })
      );
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  const recoverLeavePotTransactions = async () => {
    const pendingTxJSON = localStorage.getItem("pendingLeavePotTx");
    if (!pendingTxJSON) return;
    try {

      const pendingTx = JSON.parse(pendingTxJSON);
      const leavePotResponse = await dispatch(
        leavePot({
          potCode: pendingTx?.potCode,
        })
      );
    } catch (error) {
      console.error("Failed to check transaction status:", error);
    }
  };

  useEffect(() => {
    (async () => {
      await recoverCreatePotPendingTransactions();
      await recoverJoinPotPendingTransactions();
      await recoverClaimFundsPendingTransactions();
      await recoverStartPotPendingTransactions();
      await recoverTransferEthTransactions();
      await recoverLeavePotTransactions();
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/dashboard"
          element={
            <IsRegistered>
              <Chats />
            </IsRegistered>
          }
        />
        {/* <Route
          path="/dashboard"
          element={
            <IsNotRegistered>
              <WalletNotConnected />
            </IsNotRegistered>
          }
        /> */}
        <Route
          path="/dashboard/:chatId" // New dynamic route for individual chat
          element={<Chats />}
        />
        <Route
          path="/createPot"
          element={
            <IsRegistered>
              <CreatePotPage />
            </IsRegistered>
          }
        />
        {/* <Route
          path="/early-access-verification"
          element={
            <IsRedeemedButNotConnected>
              <EarlyAccessVerification />
            </IsRedeemedButNotConnected>
          }
        /> */}
        <Route
          path="/rewards"
          element={
            <IsRegistered>
              <Dashboards />
            </IsRegistered>
          }
        />
        <Route path="/:token" element={<TokenLengthBasedComponent />} />
        <Route
          path="/early-access"
          element={
            <IsNotRegistered>
              <EarlyAccessPage />
            </IsNotRegistered>
          }
        />
        <Route
          path="/earn"
          element={
            <IsUserSigned>
              <EarnPage />
            </IsUserSigned>
          }
        />
        <Route
          path="/leaderboard"
          element={
            <IsRegistered>
              {/* <IsWallet> */}
              <LeaderboardPage />
              {/* </IsWallet> */}
            </IsRegistered>
          }
        />
        <Route path="/notification" element={<Notification />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
      </Routes>
      {/* <Footer /> */}
      <BottomMenu />
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
};

export default App;

import { localStorageStates } from ".";

function pluralize(count: number, singular: string, plural: string): string {
  return count === 1 ? `${count} ${singular}` : `${count} ${plural}`;
}

export function dateFormat(dateString: string | Date): string {
  // Check if dateString is already formatted
  if (typeof dateString === "string" && dateString.includes("/")) {
    return dateString;
  }

  const date = new Date(dateString);
  const datePart = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(date);

  const timePart = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
    .format(date)
    .toUpperCase();

  const formattedDate = `${datePart} ${timePart}`;

  return formattedDate;
}

export function dateFormatVal(dateInput: Date | number | string): string {
  // Create a Date object from the input
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ""; // or throw an error, depending on your requirements
  }

  // Format the date and time parts
  const datePart = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(date);

  const timePart = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
    .format(date)
    .toUpperCase();

  // Concatenate date and time parts
  const formattedDate = `${datePart} ${timePart}`;

  return formattedDate;
}

export function weiToEther(wei: any) {
  return wei / 1e18;
}

export function describeDuration(seconds: number) {
  const minute = 60;
  const tenMinutes = 600;
  const hour = 3600;
  const sixHours = 21600;
  const twelveHours = 43200;
  const day = 86400;
  const week = 604800;
  const month = 2592000;
  const year = 31557600;

  if (seconds === minute) {
    return "Per Minute";
  } else if (seconds === tenMinutes) {
    return "Every 10 min";
  } 
  else if (seconds === minute) {
    return "Every 1 min";
  }

   else if (seconds === hour) {
    return "Every 1 Hour";
  } else if (seconds === sixHours) {
    return "Every 6 Hours";
  } else if (seconds === twelveHours) {
    return "Every 12 Hours";
  } else if (seconds === day) {
    return "Daily";
  } else if (seconds === week) {
    return "Weekly";
  } else if (seconds === month) {
    return "Monthly";
  } else if (seconds === year) {
    return "Yearly";
  } else {
    return "Custom";
  }
}

export function timeAgo(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return pluralize(diffInSeconds, "second", "seconds") + " ago";
  } else if (diffInSeconds < 3600) {
    return (
      pluralize(Math.floor(diffInSeconds / 60), "minute", "minutes") + " ago"
    );
  } else if (diffInSeconds < 86400) {
    return (
      pluralize(Math.floor(diffInSeconds / 3600), "hour", "hours") + " ago"
    );
  } else {
    return pluralize(Math.floor(diffInSeconds / 86400), "day", "days") + " ago";
  }
}

export function checkUserSigned(): boolean {
  let isSigned = localStorage.getItem(localStorageStates.isSigned);
  return isSigned == "true" ? true : false;
}

export function secondsToDays(seconds: any) {
  const secondsInADay = 24 * 60 * 60;
  return seconds / secondsInADay;
}

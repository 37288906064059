import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  addSignupFee,
  connectingTwitter,
  setIsRegistered,
  getProfileData,
  RootState,
} from "../store";
import { StyledEllipse } from "../components/StyledEllipse";
import { ReactComponent as CheckCircle } from "../assets/bx-check-circle.svg";
import { localStorageStates } from "../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreatedProfile from "../components/Modal/CreatedProfile";
import WalletConnectingModal from "../components/Modal/WalletConnectingModal";
import { useWeb3React } from "@web3-react/core";
import TwitterAccountAlreadyExistModal from "../components/Modal/TwitterAccountAlreadyExistModal";
import TwitterAccountConnectedSuccessModal from "../components/Modal/TwitterAccountConnectedmodal";
import { APPROVE_TERMS_AND_CONDITIONS, TWITTER_ERROR } from "../utils/errors";
import { sendEth } from "../utils/transactions/transferEth";
import { checkUserSigned } from "../utils/helper";
import Footer from "../components/Footer";

const EarlyAccessPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let { token } = useParams();

  const {
    getProfileData: { loading },
  } = useSelector((state: RootState) => state.wallet);
  const [userProfile, setUserProfile] = useState({
    username: "",
    points: 0,
    profilePicture: "",
    twitterInfo: null,
    discordInfo: null,
    generatedInviteCodes: [],
    isBlacklisted: false,
    rank: 0,
  });
  const [isWalletModalOpen, setWalletModalOpen] = useState<boolean>(true);
  const [twitterConnected, setTwitterConnected] = useState<string>();
  const [discordConnected, setDiscordConnected] = useState<string>();
  const [isTransferEthLoading, setIsTransferEthLoading] = useState(false);
  const [isTwitterError, setIsTwitterError] = useState(false);
  const [isTwitterConnected, setIsTwitterConnected] = useState(false);
  const { account, isActive } = useWeb3React();
  // const [discordConnected, setDiscordConnected] = useState<boolean>(
  //   localStorage.getItem(localStorageStates.isConnectedDiscord) === "true"
  // );
  const [isChecked, setIsChecked] = useState(false);

  const [isCreatedProfile, setCreatedProfile] = useState(false);

  const [showError, setShowError] = useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setShowError(false); // Reset error state when checkbox is checked
    }
  };

  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;
  const twitterUsername = userInfoData?.twitterInfo?.username;

  useEffect(() => {
    if (twitterUsername && userInfoData?.hasPaidFee) {
      setTwitterConnected(
        localStorage.getItem(localStorageStates.twitterUsername) || ""
      );
      registeringUser();
    }
  }, [twitterUsername]);
  const claimInviteCode = () => setWalletModalOpen(true);

  const handleCloseTwitterErrorModal = () => setIsTwitterError(false);
  const handleCloseTwitterModal = () => {
    setIsTwitterConnected(false);
  };

  const registeringUser = async () => {
    localStorage.setItem(localStorageStates.isRegistered, "true");
    dispatch(setIsRegistered(true));
    if (account) {
      const gotProfileData = await dispatch(getProfileData(account));
      if (gotProfileData.meta.requestStatus === "fulfilled") {
        if (gotProfileData?.payload?.username) {
          setUserProfile(gotProfileData.payload);
          localStorage.setItem(
            localStorageStates.userInfo,
            JSON.stringify(gotProfileData.payload)
          );
        }
      }
    }
    if (token && token.length === 6) {
      window.location.reload();
    }
    setCreatedProfile(true);
    // try {
    //   let walletAddress = localStorage.getItem(localStorageStates.account);
    //   let code = localStorage.getItem(localStorageStates.code);
    //   let twitterUsername = localStorage.getItem(
    //     localStorageStates.twitterUsername
    //   );
    //   let discordUsername = localStorage.getItem(
    //     localStorageStates.discordUsername
    //   );
    //   const registeredResponse = await dispatch(registerUser({}));
    //   if (registeredResponse.meta.requestStatus === "fulfilled") {
    //     localStorage.setItem(localStorageStates.isRegistered, "true");
    //     setCreatedProfile(true);
    //   } else if (registeredResponse?.payload?.msg) {
    //     toast.error(registeredResponse?.payload?.msg, {
    //       position: "bottom-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // } catch (error) {
    //   toast.error("Error registering user. Please load the page.", {
    //     position: "bottom-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  const connectTwitter = () => {
    const CONNECT_TWITTER_API = process.env.REACT_APP_CONNECT_TWITTER_API;
    if (CONNECT_TWITTER_API) {
      localStorage.setItem(localStorageStates.isConnectingTwitter, "true");
      window.location.href = CONNECT_TWITTER_API;
    }
  };

  const transferEth = async () => {
    setShowError(false);
    if (!isChecked) {
      setShowError(true);
      return;
    }

    setIsTransferEthLoading(true);
    try {
      const TRANSFER_ETH_ADDRESS = process.env.REACT_APP_TRANSFER_ETH_ADDRESS;
      if (TRANSFER_ETH_ADDRESS) {
        sendEth({
          // recipientAddress: TRANSFER_ETH_ADDRESS,
          amount: "0.005",
        }).catch((error) => {
          toast.error(error?.message || "Error transferring ETH", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        if (account) {
          let attempt = 0;
          let getUserProfileResponse;
          let getUserProfileInResponse;
          let isError;
          // let potRound=potDetail

          do {
            // if(potDetail){
            attempt++;
            getUserProfileResponse = await dispatch(getProfileData(account));
            isError = getUserProfileResponse.payload.isError;
            getUserProfileInResponse = getUserProfileResponse.payload;

            if (
              !getUserProfileInResponse?.hasPaidFee ||
              getUserProfileResponse.meta.requestStatus !== "fulfilled"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            (attempt < 60 &&
              !getUserProfileInResponse?.hasPaidFee &&
              !isError) ||
            getUserProfileResponse.meta.requestStatus !== "fulfilled"
          );
          if (!isError) {
            setIsTransferEthLoading(false);
            await registeringUser();
          }
        }
        // const addFeeResponse = await dispatch(
        //   addSignupFee({
        //     transactionHash,
        //   })
        // );
        // console.log("addFeeResponse:", addFeeResponse);

        // if (addFeeResponse.meta.requestStatus === "fulfilled") {

        // }
        // if (addFeeResponse.meta.requestStatus === "rejected") {
        //   toast.error(addFeeResponse?.payload?.message || "Failed to add fee", {
        //     position: "bottom-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }
      }
    } catch (error) {
      console.error("Error transferring ETH:", error);
      setIsTransferEthLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsTwitterError(false);
      setTwitterConnected(
        localStorage.getItem(localStorageStates.twitterUsername) || ""
      );

      setDiscordConnected(
        localStorage.getItem(localStorageStates.discordUsername) || ""
      );
      let redeemed = localStorage.getItem(localStorageStates.redeemed);
      // if (twitterConnected) {
      //   await registeringUser();
      //   return;
      // }
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code) {
        let isConnectingDiscord = localStorage.getItem(
          localStorageStates.isConnectingDiscord
        );
        let isConnectingTwitter = localStorage.getItem(
          localStorageStates.isConnectingTwitter
        );
        // if (isConnectingDiscord == "true") {
        //   try {
        //     const connectedDiscord = await dispatch(
        //       connectingDiscord({ code })
        //     );
        //     if (connectedDiscord.meta.requestStatus === "fulfilled") {
        //       if (connectedDiscord.payload?.user) {
        //         let discordUsername =
        //           connectedDiscord.payload?.user?.discord?.username;
        //         localStorage.setItem(
        //           localStorageStates.discordUsername,
        //           discordUsername
        //         );
        //         setDiscordConnected(discordUsername);
        //         localStorage.setItem(
        //           localStorageStates.isConnectingDiscord,
        //           "false"
        //         );
        //         let twitterUsername = localStorage.getItem(
        //           localStorageStates.twitterUsername
        //         );
        //         if (twitterUsername) {
        //           await registeringUser();
        //         }
        //       }
        //       if (connectedDiscord.payload?.msg) {
        //         toast.error(connectedDiscord.payload?.msg, {
        //           position: "bottom-right",
        //           autoClose: 5000,
        //           hideProgressBar: false,
        //           closeOnClick: true,
        //           pauseOnHover: true,
        //           draggable: true,
        //           progress: undefined,
        //         });
        //       }
        //     } else {
        //       if (connectedDiscord.payload?.msg) {
        //         toast.error(connectedDiscord.payload?.msg, {
        //           position: "bottom-right",
        //           autoClose: 5000,
        //           hideProgressBar: false,
        //           closeOnClick: true,
        //           pauseOnHover: true,
        //           draggable: true,
        //           progress: undefined,
        //         });
        //       }
        //       localStorage.setItem(
        //         localStorageStates.isConnectingDiscord,
        //         "false"
        //       );
        //     }
        //   } catch (err) {
        //     console.log("Error in connecting discord", err);
        //     localStorage.setItem(
        //       localStorageStates.isConnectingDiscord,
        //       "false"
        //     );
        //   }
        // }
        if (isConnectingTwitter == "true") {
          try {
            const connectedTwitter = await dispatch(
              connectingTwitter({ code })
            );
            if (connectedTwitter.meta.requestStatus === "fulfilled") {
              if (connectedTwitter.payload?.user) {
                let twitterUsername =
                  connectedTwitter.payload?.user?.twitter?.username;
                localStorage.setItem(
                  localStorageStates.twitterUsername,
                  twitterUsername
                );
                setTwitterConnected(twitterUsername);
                localStorage.setItem(
                  localStorageStates.isConnectingTwitter,
                  "false"
                );
                let discordUsername = localStorage.getItem(
                  localStorageStates.discordUsername
                );
                // setIsTwitterConnected(true);
                // if (discordUsername) {
                // await registeringUser();
                // }
              }
              if (connectedTwitter.payload?.msg) {
                toast.error(connectedTwitter.payload?.msg, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                if (connectedTwitter.payload?.msg == TWITTER_ERROR) {
                  setIsTwitterError(true);
                }
              }
            } else {
              if (
                connectedTwitter.payload?.msg ||
                connectedTwitter.payload?.message
              ) {
                toast.error(
                  connectedTwitter.payload?.msg ||
                    connectedTwitter.payload?.message,
                  {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                if (
                  connectedTwitter.payload?.msg == TWITTER_ERROR ||
                  connectedTwitter.payload?.message == TWITTER_ERROR
                ) {
                  setIsTwitterError(true);
                }
              }
              localStorage.setItem(
                localStorageStates.isConnectingTwitter,
                "false"
              );
            }
          } catch (err) {
            console.log("Error in connecting twitter");
            localStorage.setItem(
              localStorageStates.isConnectingTwitter,
              "false"
            );
          }
        }
      }
    })();
  }, []);

  const handleFollowQuailFinance = () => {
    const url = "https://x.com/QuailFinance";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  const handleEarlyAccessClick = () => {
    if (!(account && isActive)) {
      if (!checkUserSigned()) {
        toast.error(
          "Please sign the message from wallet or reload the page to continue",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setWalletModalOpen(true);
    }
  };

  return (
    <>
      <Container>
        <StyledEllipse />
        {!isCreatedProfile ? (
          <>
            <MainText>To Create Pot</MainText>
            <SubText>Create Your Quail Profile</SubText>
            <StepBox>
              <Step isCompleted={isActive && !!account}>
                <StepInfo>
                  <StepNumber>1</StepNumber>
                  <StepText>Connect Your Eth Wallet Address</StepText>
                </StepInfo>
                <StepAction>
                  {isActive && !!account ? (
                    <CheckCircle />
                  ) : (
                    //    <Image src={CheckCircle} alt="circle check" />
                    <ActionButton
                      isCompleted={isActive && !!account}
                      onClick={handleEarlyAccessClick}
                    >
                      Connect Wallet
                    </ActionButton>
                  )}
                </StepAction>
              </Step>
              <Step isCompleted={!!twitterConnected}>
                <StepInfo>
                  <StepNumber>2</StepNumber>
                  <StepText>
                    Follow{" "}
                    <HighlightedText onClick={handleFollowQuailFinance}>
                      @QuailFinance
                    </HighlightedText>{" "}
                    on Twitter
                  </StepText>
                </StepInfo>
                <StepAction>
                  {twitterConnected ? (
                    <CheckCircle />
                  ) : (
                    <ActionButton
                      isCompleted={!!twitterConnected}
                      onClick={connectTwitter}
                    >
                      Connect Twitter
                    </ActionButton>
                  )}
                </StepAction>
              </Step>
              {/* <Step isCompleted={!!discordConnected}>
          <StepInfo>
            <StepNumber>3</StepNumber>
            <StepText>Connect Your Discord Account</StepText>
          </StepInfo>
          <StepAction>
            {discordConnected ? (
              <CheckCircle />
            ) : (
              <ActionButton
                isCompleted={!!discordConnected}
                onClick={connectDiscord}
              >
                Connect Discord
              </ActionButton>
            )}
          </StepAction>
        </Step> */}
            </StepBox>
            <CheckboxRow>
              <Checkbox
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <CheckboxLabel>
                I agree to the{" "}
                {/* <span style={{ color: "#B1DD40" }}>Terms of Use</span> */}
                <TermsOfUse to="/termsOfService" target="_blank">
                  Terms of Use
                </TermsOfUse>
                of Quail finance
              </CheckboxLabel>
            </CheckboxRow>
            {showError && (
              <ErrorMessage>{APPROVE_TERMS_AND_CONDITIONS}</ErrorMessage>
            )}
            <CreateButton
              disabled={!(!!twitterConnected && isActive && !!account)}
              // isCompleted={true}
              onClick={transferEth}
            >
              {isTransferEthLoading ? <Loader /> : "Create"}
            </CreateButton>
            {isWalletModalOpen && (
              <WalletConnectingModal
                onClose={closeWalletModal}
                handleAccount={handleAccount}
              />
            )}
            {isTwitterError && (
              <TwitterAccountAlreadyExistModal
                onClose={handleCloseTwitterErrorModal}
              />
            )}
            {isTwitterConnected && (
              <TwitterAccountConnectedSuccessModal
                onClose={handleCloseTwitterModal}
              />
            )}
          </>
        ) : (
          <CreatedProfile />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default EarlyAccessPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 180px;
  max-width: 950px;
  width: 45%;
  margin: 0 auto;

  @media (min-width: 1700px) {
    height: 68vh;
  }
  @media (max-width: 1200px) {
    width: 55%;
  }

  @media (max-width: 950px) {
    width: 68%;
  }

  @media (max-width: 768px) {
    padding-top: 130px;
    padding-bottom: 170px;
    width: 93%;
  }

  @media (max-width: 480px) {
    padding: 16px;
    padding-top: 100px;
    padding-bottom: 130px;
    width: unset;
  }
`;

const MainText = styled.p`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  align-self: baseline;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const SubText = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;
  align-self: baseline;
  margin-top: 0px;
  text-align: left;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 29px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

const Step = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => props.theme.borders.steps};

  @media (max-width: 480px) {
    flex-direction: ${(props) => (props.isCompleted ? "row" : "column")};
    align-items: ${(props) => (props.isCompleted ? "center" : "flex-start")};
  }
`;

const StepNumber = styled.span`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 15px;
    width: 44px;
    height: 44px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    width: 40px;
    height: 40px;
  }
`;

const StepText = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.medium};

  @media (max-width: 768px) {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StepAction = styled.div`
  display: flex;
  flex-direction: row; // default to row
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ActionButton = styled.button<{ isCompleted?: boolean }>`
  display: flex;
  width: 180px;
  height: 44px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;

  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  // line-height: ${(props) => props.theme.lineHeights.large};
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  line-height: 150%; /* 21px */

  @media (max-width: 480px) {
    margin-top: ${(props) => (!props.isCompleted ? "24px" : "unset")};
  }

  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CreateButton = styled(ActionButton)<{ disabled: boolean }>`
  margin-top: 20px;
  background: ${(props) =>
    props.disabled ? "#C7D1B2" : props.theme.colors.primary};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: ${(props) => (props.disabled ? "#C7D1B2" : "#b9ff04")};
    box-shadow: ${(props) =>
      props.disabled ? "none" : props.theme.boxShadow.button};
  }
`;

const StepInfo = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

const HighlightedText = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.medium};
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin: 0px auto;
  margin-top: 20px;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.2);
`;

const CheckboxLabel = styled.label`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  margin: 23px 0;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin: 0 24px 0 24px;
  margin-top: 4px;
`;

const TermsOfUse = styled(Link)`
  color: #b1dd40;
  margin: 0 2px;
`;
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as PotsSvg } from "../assets/pots.svg";
import { ReactComponent as ColorPotsSvg } from "../assets/color_pots.svg";
import { ReactComponent as LeaderboardSvg } from "../assets/leaderboard.svg";
import { ReactComponent as ColorLeaderboardSvg } from "../assets/color_leaderboard.svg";
import { ReactComponent as EarnSvg } from "../assets/earn.svg";
import { ReactComponent as ColorEarnSvg } from "../assets/color_earn.svg";
import { ReactComponent as NotificationBell } from "../assets/notification.svg";
import { ReactComponent as ColorNotificationBell } from "../assets/color_notification.svg";
import { useNavigate, useLocation } from "react-router-dom";

const BottomMenus: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenuTab, setActiveMenuTab] = useState("");
  const [activePath, setActivePath] = useState("");
  const handlePotsMenuTabChange = (
    tab: "dashboard" | "leaderboard" | "earn" | "notification"
  ) => {
    setActiveMenuTab(tab);
    navigate(`/${tab}`);
  };

  useEffect(() => {
    const mainRoute = location.pathname.split("/")[1];
    setActivePath(mainRoute);
    setActiveMenuTab(mainRoute);
    if (mainRoute === activeMenuTab) {
      setActiveMenuTab(mainRoute);
    } 
  }, [location.pathname]);

  return (
    <Container>
      <BottomMenu>
        <BottomMenuTab
          isActive={activeMenuTab === activePath}
          onClick={() => handlePotsMenuTabChange("dashboard")}
        >
          {activeMenuTab !== "dashboard" && <PotsMenuIcon />}
          {activeMenuTab === "dashboard" && <ColorPotsMenuIcon />}
          <MenuText
            isActive={activeMenuTab === "dashboard"}
            onClick={() => handlePotsMenuTabChange("dashboard")}
          >
            Pots
          </MenuText>
        </BottomMenuTab>
        <BottomMenuTab
          isActive={activeMenuTab === activePath}
          onClick={() => handlePotsMenuTabChange("leaderboard")}
        >
          {activeMenuTab !== "leaderboard" && <LeaderboardMenuIcon />}
          {activeMenuTab === "leaderboard" && <ColorLeaderboardMenuIcon />}
          <MenuText
            isActive={activeMenuTab === "leaderboard"}
            onClick={() => handlePotsMenuTabChange("leaderboard")}
          >
            Leaderboard
          </MenuText>
        </BottomMenuTab>
        {/* <BottomMenuTab
                    isActive={activeMenuTab === "earn"}
                    onClick={() => handlePotsMenuTabChange("earn")}
                >
                    {activeMenuTab !== "earn" && <EarnMenuIcon />}
                    {activeMenuTab === "earn" && <ColorEarnMenuIcon />}
                    <MenuText
                        isActive={activeMenuTab === "earn"}
                        onClick={() => handlePotsMenuTabChange("earn")}
                    >
                        Earn
                    </MenuText>
                </BottomMenuTab> */}
        {/* <BottomMenuTab
                    isActive={activeMenuTab === "notification"}
                    onClick={() => handlePotsMenuTabChange("notification")}
                >
                    {activeMenuTab !== "notification" && <NotificationBellIcon />}
                    {activeMenuTab === "notification" && (
                        <ColorNotificationBellMenuIcon />
                    )}

                    <MenuText
                        isActive={activeMenuTab === "notification"}
                        onClick={() => handlePotsMenuTabChange("notification")}
                    >
                        Notification
                    </MenuText>
                </BottomMenuTab> */}
      </BottomMenu>
    </Container>
  );
};

export default BottomMenus;
const Container = styled.div`
  display: none;
  position: sticky;
  bottom: 0px;
  left: 0px;
  z-index: 900;
  background: #13160b;
  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 475px) and (max-height: 450px) {
    bottom: -70px;
  }
  @media (max-width: 475px) and (max-height: 350px) {
    display: none;
  }
`;
const BottomMenu = styled.div`
  display: none;
  width: 94%;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  border-top: 1px solid rgba(233, 246, 208, 0.2);
  @media (max-width: 768px) {
    display: flex;
  }
`;

const BottomMenuTab = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  /* margin-top: -5px; */

  color: rgba(255, 255, 255, 0.7);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
  &:hover,
  &.active {
    /* border-color: #e9f6d0; */
  }
  @media (max-width: 768px) {
    flex: 1;
  }
  /* ${(props) =>
    props.isActive &&
    `
  border-top: 2px solid #b1dd40;
  color: #b1dd40;
  `} */
`;

const PotsMenuIcon = styled(PotsSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const LeaderboardMenuIcon = styled(LeaderboardSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const ColorLeaderboardMenuIcon = styled(ColorLeaderboardSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const EarnMenuIcon = styled(EarnSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const ColorEarnMenuIcon = styled(ColorEarnSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const ColorNotificationBellMenuIcon = styled(ColorNotificationBell)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const NotificationBellIcon = styled(NotificationBell)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const ColorPotsMenuIcon = styled(ColorPotsSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.56;
`;

const MenuText = styled.div<{ isActive: boolean }>`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
  &:hover,
  &.active {
    /* border-color: #e9f6d0; */
  }
  ${(props) =>
    props.isActive &&
    `
  color: #b1dd40;
  `}
`;

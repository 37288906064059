import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import usePageSEO from "../hooks/usePageSEO";

const PrivacyPolicy: React.FC = () => {
  
  usePageSEO({
    title: "Privacy Policy | Quail Finance",
    description:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    keywords: [
      "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
    ],
    ogTitle: "Privacy Policy | Quail Finance",
    ogDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    ogImage: "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    ogUrl: "https://quail.finance/privacyPolicy",
    twitterTitle: "Privacy Policy | Quail Finance",
    twitterDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    twitterImage:
      "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    twitterUrl: "https://quail.finance/privacyPolicy",
  });


  return (
    <>
      <Container>
        <LinearHeader></LinearHeader>
        <HeadingTitle>Privacy Policy</HeadingTitle>
        <HeadingSubtitle>Last Updated: 15/02/2024</HeadingSubtitle>
        <ContentBox>
          <SubTitle>
            Quail.finance and its affiliates (“the platform,” “we,” “our,”
            and/or “us”) value the privacy of individuals who use our website
            and related services (collectively, our “services”). This privacy
            policy (the “Privacy Policy”) explains how we collect, use, and
            share information from or about Quail.finance users (“Users”) or
            their devices. 
          </SubTitle>

          <SubTitle>
            By using our services, you agree to the collection, use, disclosure,
            and procedures this Privacy Policy describes. Beyond the Privacy
            Policy, your use of our services is also subject to our{" "}
            <LinkText to="https://quail.finance/termsOfService" target="_blank">
              Terms of Service
            </LinkText>{" "}
            .
          </SubTitle>
          <HighlightedSubTitle>Information We Collect</HighlightedSubTitle>

          <SubTitle>
            We may collect a variety of information from or about you or your
            devices from various sources, as described below.
          </SubTitle>

          <Title>A. Information You Provide to Us </Title>
          <ListSubTitle>
            <ol>
              <li>
                <HighlightedSubTitle>
                  Registration and Profile Information Collect
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      You may need to register an account with us by connecting
                      a third-party supported crypto wallets of Ethereum Network
                      (e.g.,{" "}
                      <LinkText to="https://metamask.io" target="_blank">
                        metamask.io
                      </LinkText>{" "}
                      ) to access our services. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Content</HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      If you create or join a pot using our services we will
                      receive any content you post including Pot Name, Pot
                      Amount, No of Participants & Rotation Cycle.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Notifications </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      When you agree to sign up for notifications or updates, we
                      ask you for information such as your email address. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Communications </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      If you contact us by email, we may receive additional
                      information about you. For example, when you contact us
                      via email, we will receive your name, email address, the
                      contents of a message or attachments that you may send to
                      us, and other information you choose to provide. If you
                      subscribe to our newsletter, then we will collect certain
                      information from you, such as your email address. When we
                      send you emails, we may track whether you open them to
                      learn how to deliver a better customer experience and
                      improve our services. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Careers </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      If you decide that you wish to apply for a job with us,
                      you may submit your contact information and your resume
                      online. We will collect the information you choose to
                      provide on your resume, such as your education and
                      employment experience. You may also apply through
                      LinkedIn. If you do so, we will collect the information
                      you make available to us on LinkedIn. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
            </ol>
          </ListSubTitle>

          <Title>B. Information We Collect When You Use Our Services </Title>
          <ListSubTitle>
            <ol>
              <li>
                <HighlightedSubTitle>
                  Blockchain Payment Information{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      When you create or join a pot we may receive public
                      blockchain data related to that pot 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Location Information </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      When you use our services, we may infer your general
                      location information (for example, your Internet Protocol
                      (IP) address may indicate your general geographic
                      region). 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Device Information </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We receive information about the device and software you
                      use to access our services, including IP address, web
                      browser type, and operating system version. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle> Usage Information </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      To help us understand how you use our services and to help
                      us improve them, we automatically receive information
                      about your interactions with our services, like the pages
                      or other content you view, and the dates and times of your
                      visits. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>
                   Information from Cookies and Similar Technologies{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We and third-party partners collect information using
                      cookies, pixel tags, or similar technologies. Our
                      third-party partners, such as analytics partners, may use
                      these technologies to collect information about your
                      online activities over time and across different services.
                      Cookies are small text files containing a string of
                      alphanumeric characters. We may use both session cookies
                      and persistent cookies. A session cookie disappears after
                      you close your browser. A persistent cookie remains after
                      you close your browser and may be used by your browser on
                      subsequent visits to our services.  Please review your web
                      browser’s “Help” file to learn the proper way to modify
                      your cookie settings. Please note that if you delete or
                      choose not to accept cookies from the service, you may not
                      be able to utilize the features of the service to their
                      fullest potential. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
            </ol>
          </ListSubTitle>

          <Title>C. Information We Receive from Third Parties</Title>
          <SubTitle>
            Third parties We may receive information about you from third
            parties such as data or marketing partners and combine it with other
            information we have about you. 
          </SubTitle>
          <ListSubTitle>
            <ol>
              <li>
                <HighlightedSubTitle>
                  How We Use the Information We Collect
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      To provide, maintain, improve, and enhance our services;
                    </ListSubTitle>
                  </li>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      To communicate with you, provide you with updates and
                      other information relating to our services, provide
                      information that you request, respond to comments and
                      questions, and otherwise provide customer support;
                    </ListSubTitle>
                  </li>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      For marketing purposes, such as developing and providing
                      promotional and advertising materials that may be useful,
                      relevant, valuable or otherwise of interest to you;
                    </ListSubTitle>
                  </li>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      To find and prevent fraud, and respond to trust and safety
                      issues that may arise;
                    </ListSubTitle>
                  </li>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      For compliance purposes, including enforcing our{" "}
                      <LinkText
                        to="https://quail.finance/termsOfService"
                        target="_blank"
                      >
                        Terms of Service
                      </LinkText>{" "}
                      or other legal rights, or as may be required by applicable
                      laws and regulations or requested by any judicial process
                      or governmental agency; and
                    </ListSubTitle>
                  </li>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      For other purposes for which we provide specific notice at
                      the time the information is collected.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
            </ol>
          </ListSubTitle>

          <Title>D. How We Share the Information We Collect</Title>
          <SubTitle>
            We may share or otherwise disclose information we collect from or
            about you as described below or otherwise disclosed to you at the
            time of the collection.
          </SubTitle>
          <ListSubTitle>
            <ol>
              <li>
                <HighlightedSubTitle>
                  Vendors and Service Providers{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We may share any information we receive with vendors and
                      service providers retained in connection with the
                      provision of our services.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Marketing</HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We do not rent, sell, or share information about you with
                      non-affiliated companies for their direct marketing
                      purposes, unless we have your permission.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Analytics Partners </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We use analytics services such as Google Analytics to
                      collect and process certain analytics data. These services
                      may also collect information about your use of other
                      websites, apps, and online resources. You can learn about
                      Google’s practices by going to Google Privacy Policy, and
                      opt-out of them by downloading the Google Analytics
                      opt-out browser add-on, available at Google Analytics
                      Opt-out Browser Add-on.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>
                   As Required By Law and Similar Disclosures{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We may access, preserve, and disclose your information if
                      we believe doing so is required or appropriate to:
                    </ListSubTitle>
                    <ul>
                      <li style={{ listStyleType: "lower-roman" }}>
                        <ListSubTitle>
                          Comply with law enforcement requests and legal
                          process, such as a court order or subpoena;
                        </ListSubTitle>
                      </li>
                      <li style={{ listStyleType: "lower-roman" }}>
                        <ListSubTitle>
                          Respond to your requests; or{" "}
                        </ListSubTitle>
                      </li>
                      <li style={{ listStyleType: "lower-roman" }}>
                        <ListSubTitle>
                          Protect your, our, or others’ rights, property, or
                          safety.{" "}
                        </ListSubTitle>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <SubTitle>
                For the avoidance of doubt, the disclosure of your information
                may occur if you post any objectionable content on or through
                the Services. 
              </SubTitle>
              <li>
                <HighlightedSubTitle>
                  Merger, Sale, or Other Asset Transfers{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We may disclose and transfer your information to service
                      providers, advisors, potential transactional partners, or
                      other third parties in connection with the consideration,
                      negotiation, or completion of a corporate transaction in
                      which we are acquired by or merged with another company or
                      we sell, liquidate, or transfer all or a portion of our
                      business or assets. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Consent</HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We may also disclose information from or about you or your
                      devices with your permission. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
            </ol>
          </ListSubTitle>

          <Title>E. Your Choices </Title>
          <ListSubTitle>
            <ol>
              <li>
                <HighlightedSubTitle>
                  Marketing Communications{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      You can unsubscribe from our promotional emails via the
                      link provided in the emails. Even if you opt-out of
                      receiving promotional messages from us, you will continue
                      to receive administrative messages from us. {" "}
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Third Parties </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      Our services may contain links to other websites,
                      products, or services that we do not own or operate.  We
                      are not responsible for the privacy practices of these
                      third parties. Please be aware that this document and its
                      content does not apply to your activities on these third
                      party services or any information you disclose to these
                      third parties. We encourage you to read their privacy
                      policies before providing any information to them. 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Security </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We make reasonable efforts to protect your information by
                      using physical and electronic safeguards designed to
                      improve the security of the information we maintain.
                      However, as our services are hosted electronically, we can
                      make no guarantees as to the security or privacy of your
                      information 
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>Children’s Privacy </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We do not knowingly collect, maintain, or use personal
                      information from children under 18 years of age, and no
                      part of our services are directed to children. If you
                      learn that a child has provided us with personal
                      information in violation of this policy document, then you
                      may alert us at{" "}
                      <LinkText to="mailto:legal@quail.finance">
                        legal@quail.finance
                      </LinkText>
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
              <li>
                <HighlightedSubTitle>
                  Changes to this Privacy Policy{" "}
                </HighlightedSubTitle>
                <ul>
                  <li style={{ listStyleType: "lower-alpha" }}>
                    <ListSubTitle>
                      We will post any adjustments to the privacy policy on this
                      page, and the revised version will be effective when it is
                      posted. If we materially change the ways in which we use
                      or share personal information previously collected from
                      you through the services, we will notify you through the
                      services, by email, notifications or other communication.
                    </ListSubTitle>
                  </li>
                </ul>
              </li>
            </ol>
          </ListSubTitle>

          <Title>F. Contact Information </Title>
          <SubTitle>
            If you have any questions, comments, or concerns about our
            processing activities, please email us at{" "}
            <LinkText to="mailto:legal@quail.finance">
              legal@quail.finance
            </LinkText>
          </SubTitle>
        </ContentBox>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 759px;
  width: 90%;
  min-height: 60vh;
  margin: 0 auto;
`;

const LinearHeader = styled.div`
  position: absolute;
  width: 100%;
  height: 252.978px;
  flex-shrink: 0;
  background-image: linear-gradient(to bottom, #b1dd40 0%, #13160b 90%);
  opacity: 0.3;
`;

const HeadingContainer = styled.div``;

const HeadingTitle = styled.div`
  color: #b1dd40;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 54px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-top: 78px;
`;

const HeadingSubtitle = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
  padding-bottom: 77px;
`;

const ContentBox = styled.div`
  gap: 32px;
  /* padding-top: 120px; */
`;

const Title = styled.div`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  opacity: 0.7;
  margin-bottom: 14px;
`;

const HighlightedSubTitle = styled.div`
  color: var(--primary, #b1dd40);
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  opacity: 0.7;
`;

const SubTitle = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
  margin-bottom: 32px;
`;

const ListSubTitle = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
`;
const LinkText = styled(Link)`
  color: var(--light, #e9f6d0);

  /* desktop/body/large */
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import MetamaskWalletOption from "../Wallets/MetamaskWalletOption";
import WalletConnectOption from "../Wallets/WalletConnect2";
import CoinbaseWalletOption from "../Wallets/CoinbaseWallet";
import { Link } from "react-router-dom";

const WalletConnectingModal: React.FC<{
  onClose: () => void;
  handleAccount: (account: string) => void;
}> = ({ onClose, handleAccount }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer  visible={visible}>
        <CloseIconWrapper>
          <CloseIcon onClick={handleClose} />
        </CloseIconWrapper>
        <ModalHeading>Connect Wallet</ModalHeading>
        <MetamaskWalletOption handleAccount={handleAccount} />
        <WalletConnectOption handleAccount={handleAccount} />
        <CoinbaseWalletOption handleAccount={handleAccount} />
        <BottomText>
          By connecting your wallet and using Quail, you agree to our
          <LinkText to="/termsOfService" target="_blank">
            {" "}
            Terms of Service{" "}
          </LinkText>{" "}
          and{" "}
          <LinkText to="/privacyPolicy" target="_blank">
            Privacy Policy
          </LinkText>
          .
        </BottomText>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default WalletConnectingModal;

export const WalletDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  width: 96%;
  max-width: 505px;
  padding: 40px;
  /* padding: 80px 48px 64px 48px; */
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    width: 70%;
    gap: 0px;
  padding: 40px;
  }

  @media (max-width: 480px) {
    width: 100%;
    gap: 0px;
  }
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;

const BottomText = styled.p`
  height: 40px;
  align-self: stretch;
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  opacity: 0.7;
  margin-top: 24px;

  @media (max-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const LinkText = styled(Link)`
  cursor: pointer;
  opacity: 0.7;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ModalHeading = styled.h1`
  align-self: stretch;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 34px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
`;

export const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;

export const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as LogoIcon } from "../../assets/Logo.svg";

const InsufficientFundModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <>
      <MobileModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <MobileModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <ModalTitle>Insufficient Balance</ModalTitle>
          <ModalSubtitle>
            You don't have sufficient USDB in your wallet. Buy/Swap from any of
            the DEXs on Blast
          </ModalSubtitle>
          <HomeButton
            onClick={() =>
              window.open("https://app.thruster.finance", "_blank")
            }
          >
            {"Buy Usdb"}
          </HomeButton>
        </MobileModalContainer>
      </MobileModalBackdrop>

      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <Row>
            <Columns>
              <LogoSvg />
            </Columns>
            <Columns>
              <PopupTitle>Insufficient Balance</PopupTitle>
              <PopupSubTitle>
                You don’t have sufficient USDB in your wallet. Buy/Swap from any
                of the DEXs on Blast.
              </PopupSubTitle>
              <Row>
                <Columns>
                  <PopupTextButton
                    onClick={() =>
                      window.open("https://app.thruster.finance", "_blank")
                    }
                  >
                    BUY USDB
                  </PopupTextButton>
                </Columns>
              </Row>
            </Columns>
          </Row>
        </ModalContainer>
      </ModalBackdrop>
    </>
  );
};

export default InsufficientFundModal;

const MobileModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  height: 120vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align to the bottom in mobile view */
  gap: 32px;
  z-index: 1000;
  top: -48px;
  flex-shrink: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (min-width: 768px) {
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 475px) {
    height: 100vh;
    top: 0;
    display: flex;
  }
`;
const MobileModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
  padding: 40px;
  flex-shrink: 0;
  border: 1px solid rgba(233, 246, 208, 0.16);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    /* Apply styles for mobile view */
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    max-width: none; /* Remove max-width for full width */
    padding: 24px; /* Adjust padding as needed */
    transform: translateY(0); /* Reset translateY for mobile */
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
  }
  @media (max-width: 475px) {
    display: flex;
  }
`;
const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  align-items: flex-start;

  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    display: none;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  width: 100%;
  max-width: 505px;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media (max-width: 475px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 30px;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;
`;
const PopupTitle = styled.div`
  color: var(--secondary-red, #ff645d);
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
`;
const PopupSubTitle = styled.div`
  color: var(--light, #e9f6d0);
  max-width: 390px;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-transform: uppercase;
`;
const PopupTextButton = styled.div`
  color: var(--primary, #b1dd40);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    width: 343px;
    font-size: 16px;
  }
`;

const ModalSubtitle = styled.div`
  margin-top: 16px;
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.7;
`;

const HomeButton = styled.button`
  margin-top: 40px;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  border: none;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 1.5em;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import WalletConnectingModal from "../Modal/WalletConnectingModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { checkUserSigned } from "../../utils/helper";
import { RootState } from "../../store";
import HoverButton from "../Button/HoverButton";

interface BottomSectionProps {
  isRegistered: boolean;
}

const BottomSection: React.FC<BottomSectionProps> = ({ isRegistered }) => {
  const navigate = useNavigate();
  const { account, isActive } = useWeb3React();
  const { isSigningMessage } = useSelector((state: RootState) => state.wallet);
  const [wantsEarlyAccess, setWantsEarlyAccess] = useState(false);
  const [isRegisteredInState, setIsRegisteredInState] = useState(false);

  const [isWalletModalOpen, setWalletModalOpen] = useState(false);

  const handleCreatePot = () => {
    navigate("/createPot");
  };

  useEffect(() => {
    if (account && isActive && wantsEarlyAccess && checkUserSigned()) {
      const path = isRegisteredInState ? "/createPot" : "/early-access";
      navigate(path);
      setWantsEarlyAccess(false);
    }
  }, [
    account,
    isActive,
    wantsEarlyAccess,
    isRegisteredInState,
    navigate,
    isSigningMessage,
  ]);

  useEffect(() => {
    setIsRegisteredInState(isRegistered);
  }, [isRegistered]);

  const handleEarlyAccessClick = async () => {
    if (account && isActive) {
      if (!checkUserSigned()) {
        toast.error(
          "Please sign the message from wallet or reload the page to continue",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setWantsEarlyAccess(true);
        return;
      }
      navigate(isRegisteredInState ? "/createPot" : "/early-access");
    } else {
      setWantsEarlyAccess(true);
      setWalletModalOpen(true);
    }
  };

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  return (
    <Box>
      <MainHeading>
        Enjoy Rotation Of Funds Powered By USDB and Blast's Native Yield
      </MainHeading>
      <ButtonBox>
        <CreateAPotButton
          onClick={handleEarlyAccessClick}
          height="48px"
          padding="0 16px"
        >
          <span>{isRegisteredInState ? "Create POT" : "Create POT"}</span>
        </CreateAPotButton>
        {/* <CreateAPotButton onClick={handleCreatePot}>
          Create POT
        </CreateAPotButton> */}
      </ButtonBox>
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
    </Box>
  );
};

export default BottomSection;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;
  align-items: center;
  position: relative;
  width: 90%;
  margin: 0 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-bottom: 170px;
  }

  @media (max-width: 480px) {
    padding-bottom: 140px;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  //   @media (max-width: 480px) {
  //     flex-direction: column;
  //     align-items: flex-start;
  //   }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 40px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 34px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 45px;
  max-width: 1000px;
  //   width: 62%;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 38px;
  }

  @media (max-width: 480px) {
    font-size: 31px;
  }
`;

export const CreateAPotButton = styled(HoverButton)`
  /* background: ${(props) => props.theme.colors.primary};
  background:  ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  &:hover {
    padding: 0px 17px;
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as XLogo } from "../../assets/dark-x-logo.svg";
import { toast } from "react-toastify";
import HoverButton from "../Button/HoverButton";

const CreatePotSuccessModal: React.FC<{
  onClose: () => void;
  createPotRef?: any;
  inviteLink: any;
}> = ({ onClose, inviteLink, createPotRef }) => {
  const [copyText, setCopyText] = useState("Copy");
  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(inviteLink);
      setCopyText("Copied");
    } catch (error: any) {
      console.error("Failed to copy: ", error);
      if (error) {
        toast.error(error || "Failed to copy:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const shareOnTwitter = () => {
    const inviteCode = inviteLink;
    const tweetText = encodeURIComponent(
      "I just created a pot on #QuailFinance, @QuailFinance." +
        "\n" +
        "\n" +
        "Collateral-Free Liquidity Based on Social Trust is real." +
        "\n" +
        "\n" +
        `Join My Pot - ${inviteCode}`
    );
    const twitterIntentURL = `https://twitter.com/intent/tweet?text=${tweetText}`;

    window.open(twitterIntentURL, "_blank");
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible} ref={createPotRef}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>You have successfully created the pot</ModalTitle>
        <ModalDescription>
          Invite Friends to and start rotating the funds
        </ModalDescription>
        <ModalRow>
          <ModalTextField>
            {/* <Input placeholder="https://qualifi.xyz/hTKzmak" type="string" /> */}
            <Text>{inviteLink}</Text>
            {/* <Text>https://qualifi.xyz/hTKzmak</Text> */}
            <ModalCopy onClick={copyCode} height="38px" padding="0 16px">
              {copyText}
            </ModalCopy>
          </ModalTextField>
        </ModalRow>
        <ModalDescription>Your invite link expires in 7 days</ModalDescription>

        <ButtonRow>
          <CreateAPotButton
            onClick={shareOnTwitter}
            height="48px"
            padding="0 16px"
          >
            <IconContainer>
              <XLogo />
            </IconContainer>
            Share on Twitter
          </CreateAPotButton>
        </ButtonRow>
        <CoinDescription>
          Get Points: <Highlight>+200</Highlight>
        </CoinDescription>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default CreatePotSuccessModal;

// Styled Components

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
    padding: 0;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  padding-bottom: 24px;
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  // max-width: 300px;
  width: 80%;
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
`;

const ModalDescription = styled.p`
  color: #e9f6d0;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  /* margin-top: 16px; */
  margin-bottom: 32px;
  opacity: 0.7;
`;
const Highlight = styled.span`
  color: #b1dd40;
`;

const CoinDescription = styled.p`
  color: white;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  /* margin-top: 16px; */

  @media (max-width: 768px) {
    font-size: 19px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ModalRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
`;

const ModalTextField = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 8px 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
`;

const Text = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk", sans-serif;
  width: 340px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 480px) {
    width: 250px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.3s ease;
  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    background: ${(props) => props.theme.colors.softGray};
    transform: scale(1.2);
    img {
      transform: scale(0.85);
    }
  }

  @media (max-width: 480px) {
    padding: 0px;

    &:first-child {
      margin-right: 12px;
    }
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 52px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;
const ModalCopy = styled(HoverButton)`
  /* cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--primary, #b1dd40);
  &:hover {
    padding: 0px 17px;
    background: var(--primary, #afdc3b);
    box-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  }
  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  } */
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(233, 246, 208, 0.40); */
  background: rgba(233, 246, 208, 0.08);
  /* color: white; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

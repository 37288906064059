export const getCurrentTime = (): string => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
    return formattedTime;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric", // Correct type
        month: "2-digit", // Correct type
        day: "2-digit", // Correct type
        hour: "2-digit", // Correct type
        minute: "2-digit", // Correct type
        hour12: true,
    };

    return date.toLocaleString("en-US", options);
};


export function convertToNepaliTime(utcTimestamp: any) {
    const utcDate = new Date(utcTimestamp);
    // Convert to Nepal Standard Time (NPT), which is UTC+5:45
    const nepaliOffsetMilliseconds = 5 * 60 * 60 * 1000 + 45 * 60 * 1000;
    const nepaliMilliseconds = utcDate.getTime() + nepaliOffsetMilliseconds;
    return new Date(nepaliMilliseconds);
}


export const checkDate = (timestamp: number) => {
    if (isNaN(timestamp) || timestamp <= 0) {
        return '';
    }
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = {month: 'long', day: 'numeric', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};

export const checkCreatedAt = (createdAt: string | undefined): string => {
    if (!createdAt) {
        return '';
    }
    const date = new Date(createdAt);
    if (isNaN(date.getTime())) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = {month: 'long', day: 'numeric', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};


export const getRandomTimestamp = () => {
    const startDate = new Date('2020-01-01').getTime();
    const endDate = new Date().getTime();
    return Math.floor(Math.random() * (endDate - startDate) + startDate);
};


export function formatMessageTime(message: { timestamp?: number; createdAt?: string }): string {
    let messageTime: Date;

    if (message?.timestamp) {
        // If message.timestamp is available, convert it to Date
        messageTime = new Date(message.timestamp);
    } else if (message.createdAt) {
        // If message.timestamp is not available but message.createdAt is, use message.createdAt
        messageTime = new Date(message.createdAt);
    } else {
        // If neither timestamp nor createdAt is available, use the current time
        messageTime = new Date();
    }

    // Format the messageTime to include only the time portion
    const formattedTime = messageTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return formattedTime;
}

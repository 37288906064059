import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface UseRiskPoolTxProps {
  potId: number;
  amount: string;
  signature: string;
  nonce: number;
}

export const useRiskPoolTx = async ({
  potId,
  amount,
  signature,
  nonce,
}: UseRiskPoolTxProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;
  if (Quail_Finance_Contract_Address) {
    const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
    const signer = await ethersProvider.getSigner();
    const contract = new ethers.Contract(
      Quail_Finance_Contract_Address,
      QuailABI,
      signer
    );
    try {
      console.log(
        `Using Risk Pool for Pot ID: ${potId} with Amount: ${amount}`
      );

      const tx = await contract.useRiskPool(
        potId,
        ethers.parseEther(amount),
        signature,
        nonce
      );

      console.log("Transaction hash:", tx.hash);

      const receipt = await tx.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
      } else if (receipt && receipt.status === 0) {
        throw new Error("Transaction failed and was reverted by the EVM");
      }

      return { success: true, txHash: tx.hash };
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import pinIcon from "../../assets/pinIcon.svg";
import seenIcon from "../../assets/seenIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import { PinChatModalProps } from "../../utils/interface";
import { pinMessage } from "../../utils/chatApi/chatApi";

const PinChat: React.FC<PinChatModalProps & { isPinned: boolean }> = ({
  messageId,
  messageContent,
  messages,
  onClose,
  isPinned,
}) => {
  const pinchatRef = useRef<HTMLDivElement>(null);
  const seenByCount = messages?.seenBy?.length || "none";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        onClose &&
        pinchatRef.current &&
        !pinchatRef.current.contains(event.target as Node)
      ) {
        onClose(messageId); // Close pinchat using the messageId
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [onClose, messageId]);

  return (
    <Container ref={pinchatRef}>
      {isPinned ? (
        <div>Message is already pinned</div>
      ) : (
        <PinnedMessageWrap>
          <OptionsContainer>
            <img
              style={{ width: "14px", height: "14px" }}
              src={pinIcon}
              alt={"pinIcon"}
            />
            <OptionButton onClick={() => pinMessage(messageId)}>
              Pin
            </OptionButton>
          </OptionsContainer>
          <OptionsContainer>
            <img
              style={{ width: "14px", height: "14px" }}
              src={deleteIcon}
              alt={"deleteIcon"}
            />
            <OptionButton onClick={() => onDelete(messageId)}>
              Delete
            </OptionButton>
          </OptionsContainer>

          <PinnedChatContent>
            <ChatDetails>
              <OptionsContainer>
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={seenIcon}
                  alt={"seenIcon"}
                />
                <SeenByText>Seen By: {seenByCount}</SeenByText>
              </OptionsContainer>

              <UserAvatarContainer>
                {messages?.seenBy &&
                  messages.seenBy.map((user: any, index: number) => (
                    <UserAvatar
                      key={`${index}-UserAvatar`}
                      src={user.profilePicture}
                      alt={user.username}
                      title={user.username}
                    />
                  ))}
              </UserAvatarContainer>
            </ChatDetails>
          </PinnedChatContent>
        </PinnedMessageWrap>
      )}
    </Container>
  );
};
export default PinChat;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-left: 8px;
`;

const PinnedMessageWrap = styled.div`
  background-color: #2d3123;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  width: max-content;
`;

const PinnedChatContent = styled.div`
  border-radius: 8px;
  padding: 2px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 4px;
`;

const OptionButton = styled.button`
  background-color: transparent;
  border: none;
  color: #e9f6d0;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ChatDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SeenByText = styled.span`
  color: #e9f6d0;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 600;
`;

const UserAvatarContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
`;

const UserAvatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border: 1px solid #e9f6d0;
    transition: border 0.3s ease;
  }
`;

const onDelete = (id: string) => {
  // Implement your delete message logic here
};

import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    primary: "#B1DD40",
    dark: "#13160B",
    text: "#212529",
    light: "#E9F6D0",
    softGray: "rgba(233, 246, 208, 0.10)",
    backdrop: "rgba(0, 0, 0, 0.5)",
    modalBg:
      "linear-gradient(0deg,rgba(233, 246, 208, 0.08) 0%,rgba(233, 246, 208, 0.08) 100%),#13160b",
    dropdownBg:
      "linear-gradient(0deg,rgba(233, 246, 208, 0.08) 0%,rgba(233, 246, 208, 0.08) 100%),#13160b",
    darkBlack: "rgba(233, 246, 208, 0.08)",
    offWhite: "rgba(233, 246, 208, 0.60)",
    lightGray: "rgba(233, 246, 208, 0.1)",
    secondaryRed: "#FF645D",
    // Define other colors here
  },
  textShadow: "0px 0px 14px rgba(214, 232, 81, 0.4)",
  boxShadow: {
    button: "0px 0px 14px 0px rgba(214, 232, 81, 0.40)",
    modal: "0px 0px 50px 0px rgba(233, 246, 208, 0.04)",
  },
  fontWeights: {
    bolder: 900,
    bold: 700,
    normal: 500,
    light: 400,
  },
  lineHeights: {
    small: "105%",
    normal: "120%",
    medium: "140%",
    large: "150%",
  },
  borders: {
    light: "1px solid rgba(233, 246, 208, 0.20)",
    bold: "2px solid rgba(233, 246, 208, 0.24)",
    normal: "1px solid rgba(233, 246, 208, 0.16)",
    input: "1px solid rgba(233, 246, 208, 0.40)",
    steps: "1px solid rgba(233, 246, 208, 0.2)",
    steps2: "1px solid rgba(233, 246, 208, 0.2)",
  },
  fontFamily: {
    primary: "Orbitron, sans-serif",
    secondary: "Space Grotesk, sans-serif",
  },
  // Define other design tokens here
};

export default theme;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const PotChatContainer: React.FC<{
  index: number;
  hoveredRow?: any;
  text?: any;
}> = ({ index, hoveredRow, text }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  return (
    <Container tabIndex={index}>
    <TooltipContainer isOpen={hoveredRow === index}>
      <TooltipContent>{ text }</TooltipContent>
    </TooltipContainer>
  </Container>  );
};

export default PotChatContainer;
const Container = styled.div<{tabIndex: number}>`
  position: ${(props) => (props.tabIndex === 0  ? "fixed" : "absolute")};
  z-index: 1000;
`;

const TooltipContainer = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  bottom: 20px;
  background-color: #b1dd40;
  width: 130px;
  height: 15px;
  border-radius: 8px;
  padding: 12px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &::after {
    content: "";
    position: absolute;
    left: 15%;
    bottom: -38%; /* Position the pointer at the top of the tooltip */
    transform: translateX(-50%) rotate(180deg); /* Rotate the pointer arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #b1dd40 transparent;
  }
`;
const TooltipContent = styled.div`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-bottom: 12px; /* Add margin bottom to separate paragraphs */
`;

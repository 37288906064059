import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { ReactComponent as LogoIcon } from "../../assets/Logo.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as WinnerTrophy } from "../../assets/WinnerTrophy.svg";
import Skeleton from "../Skeleton/Skeleton";
import HoverButton from "../Button/HoverButton";

const SelectWinner: React.FC<{
  onClose: () => void;
  currentRoundWinnerData?: any;
}> = ({ onClose, currentRoundWinnerData }) => {
  const [desiredChainId, setDesiredChainId] = useState<number>();
  const { connector, chainId } = useWeb3React();
  const [error, setError] = useState();

  useEffect(() => {
    if (chainId && (!desiredChainId || desiredChainId === -1)) {
      setDesiredChainId(chainId);
    }
  }, [chainId]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <>
      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <Row>
            <Columns>
              <LogoSvg />
            </Columns>
            <Columns>
              <PopupTitle>
                Congratulations "
                {currentRoundWinnerData?.joinedParticipant?.user?.username}"
              </PopupTitle>

              <PopupSubTitle>
                {" "}
                Round {currentRoundWinnerData?.joinedParticipant?.round} Winner
                has been Selected
              </PopupSubTitle>
            </Columns>
          </Row>
        </ModalContainer>
      </ModalBackdrop>

      <MobileModalBackdrop visible={visible} onClick={handleBackdropClick}>
        {!currentRoundWinnerData ? (
          <ModalContainer visible={visible}>
            <SkeletonWrapper>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </SkeletonWrapper>
          </ModalContainer>
        ) : (
          <MobileModalContainer visible={visible}>
            <CloseIcon onClick={handleClose} />

            <WinnerTrophy />

            <ModalTitle>
              Congratulations "
              {currentRoundWinnerData?.joinedParticipant?.user?.username}"
            </ModalTitle>

            <ModalDescription>
              {" "}
              Round {currentRoundWinnerData?.joinedParticipant?.round} Winner
              has been Selected
            </ModalDescription>

            <ButtonRow>
              <AddPotButton
                onClick={handleClose}
                height="40px"
                padding="0 16px"
              >
                CLOSE
              </AddPotButton>
            </ButtonRow>
          </MobileModalContainer>
        )}
      </MobileModalBackdrop>
    </>
  );
};

export default SelectWinner;

// Styled Components
const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  align-items: flex-start;

  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    display: none;
  }
`;
const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  width: 100%;
  max-width: 505px;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media (max-width: 475px) {
    display: none;
  }
`;

const MobileModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop};
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
    display: flex;
  }
`;

const MobileModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: none;
  width: 100%;
  max-width: 505px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    width: 100%;
    gap: 0px;
  }

  @media (max-width: 475px) {
    width: 100%;
    gap: 0px;
    border-radius: 20px 20px 0 0;
    display: flex;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 30px;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;
`;
const PopupTitle = styled.div`
  color: #b1dd40;
  max-width: 440px;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
`;
const PopupSubTitle = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 22px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  margin-top: 1.5em;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
`;

const ModalDescription = styled.p`
  color: #e9f6d0;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  /* margin-top: 16px; */
  margin-bottom: 24px;
  margin-top: 24px;
  opacity: 0.7;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 24px;
    width: 67%;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

const AddPotButton = styled(HoverButton)`
  /* height: 40px;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 6px;
  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: 0px 17px;
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }

  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ThreeDots } from "../../assets/three_dot.svg";
import { ChatData, PotChatContainerProps } from "../../utils/interface";
import { ReactComponent as defaultImage } from "../../assets/default_image.svg";
// import {ReactComponent as NoChat} from "../../assets/NoChat.svg";
import NoChat from "../../assets/NoChat.svg";
import { ReactComponent as Alert } from "../../assets/Alert.svg";
import DropdownMenus from "../../components/chatbox/DropdownMenu";
// import { fetchGroupChats } from "../../utils/chatApi/chatApi";
import Skeleton from "../Skeleton/Skeleton";
import Pagination from "../../components/chatbox/Pagination";
import { toast } from "react-toastify";
import {
  AppDispatch,
  RootState,
  getChatsData,
  resetChats,
  resetLeavePot,
  setIsRestarted,
  setIsLeavePotModalOpen,
  setIsCreatedPot,
  emptyChatData,
  setIsDeposited,
  getJoinedParticipants,
  getPotDetail,
  setIsSelectedWinner,
  setIsClaimedFunds,
  setIsLeavePot,
  setJoinedParticipants,
  removeChat,
  setIsCreatePotLoading,
  setIsDepositedLoading,
  setIsSelectWinnerLoading,
  setIsClaimLoading,
  setIsLeavePotLoading,
  checkSyncDetails,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import DepositTooltip from "../../components/chatbox/DepositTooltip";
import { timeAgo } from "../../utils/helper";

const PotChatContainer: React.FC<PotChatContainerProps> = ({
  openNotificationModal,
  renderThreeDots,
  openChatInFullScreen,
  onRowClick,
  onDepositClick,
  selectedRow,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { chatId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownOpenMap, setDropdownOpenMap] = useState<
    Record<string, boolean>
  >({});
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [isSelectedRow, setSelectedRow] = useState<string | null>(null);
  const [isDropdownActive, SetIsDropdownActive] = useState(false);
  const navigate = useNavigate();
  const {
    chats: { data: chatData, loading: chatLoading, chatCount, isArchived },
    restartPot: { isRestarted },
    leavePot: { isLeavePot },
    createPot: { isCreated, isSyncing, errorWhileSyncing: createPotError },
    deposit: {
      isJoined,
      errorWhileSyncing: depositError,
      isDeposited,
      isSyncingJoined,
    },
    selectWinner: { isSelectedWinner, error: selectWinnerError },
    potDetail: { data: potDetail },
    joinedParticpants: { data: joinedParticpant },
    claimFund: { isClaimed },
  } = useSelector((state: RootState) => state.wallet);
  const isSyncingRef = useRef(isSyncing);
  const isSyncingJoinedRef = useRef(isSyncingJoined);
  const sentinelRef = useRef(null);
  const ParamsChatId = chatId;

  useEffect(() => {
    if (chatData?.length > 0) {
      setSelectedRow(chatData[0]?.pot?.groupChat);
    }
    if (ParamsChatId) {
      setSelectedRow(ParamsChatId);
    }
  }, [chatData, ParamsChatId]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedRow(selectedRow);
    }
  }, [selectedRow]);

  const toggleDropdown = (index: number, isOpen: boolean) => {
    if (renderThreeDots) {
      setDropdownOpenMap((prev) => ({
        ...prev,
        [index]: isOpen,
      }));
    }
  };

  const handleDropdownClick = (index: number) => {
    if (index === chatData.length - 1) {
      SetIsDropdownActive(true);
    }
    toggleDropdown(index, !dropdownOpenMap[index]);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenMap({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let debounceTimer: string | number | NodeJS.Timeout | undefined;
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (
          entry.isIntersecting &&
          !chatLoading &&
          !isPageLoading &&
          chatData.length < chatCount
        ) {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            setCurrentPage((prevPage) => prevPage + 1);
          }, 200); // Adjust debounce delay as needed
        }
      },
      {
        root: null,
        rootMargin: "100px 0px",
        threshold: 0.5,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      observer.disconnect();
      clearTimeout(debounceTimer);
    };
  }, [chatLoading, isPageLoading, chatData.length, chatCount]);

  useEffect(() => {
    (async () => {
      if (!chatLoading && !isPageLoading) {
        if (isSyncing || createPotError) {
          // await getChatsData({ pageNo: currentPage, isArchived });
          dispatch(resetChats());
          let firstResponse = await dispatch(
            getChatsData({
              pageNo: currentPage,
              isArchived,
              hasSynced: false,
            })
          );

          let firstChatPotId =
            firstResponse.payload.data.response[0]?.pot?.groupChat;
          let attempt = 0;
          let getChatDataResponse;
          let chatDataInResponse;
          let isError;
          do {
            attempt++;
            dispatch(resetChats());
            getChatDataResponse = await dispatch(
              getChatsData({
                pageNo: currentPage,
                isArchived,
                hasSynced: true,
              })
            );
            isError = getChatDataResponse.payload.isError;
            chatDataInResponse = getChatDataResponse.payload.data.response;
            if (chatDataInResponse[0].pot.groupChat === firstChatPotId) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            attempt < 60 &&
            (isSyncingRef.current || isSyncingJoined) &&
            chatDataInResponse[0].pot.groupChat === firstChatPotId &&
            !isError
          );
          if (chatDataInResponse[0].pot.groupChat !== firstChatPotId) {
            if (isSyncingRef.current) {
              dispatch(
                setIsCreatedPot({
                  isCreated: true,
                  isSyncing: false,
                  data: [],
                })
              );
              dispatch(setIsCreatePotLoading(false));
              navigate(`/dashboard/${chatDataInResponse[0].pot.groupChat}`, {
                state: {
                  potCreated: true,
                  potCode: chatDataInResponse[0].pot.potCode,
                },
              });
              setSelectedRow(chatDataInResponse[0].pot.groupChat);
            }
            // else if (isSyncingJoined) {
            //   let joinPotResponse;
            //   let attemptJoin = 0;
            //   do {
            //     attemptJoin++;
            //     joinPotResponse = await dispatch(
            //       checkSyncDetails({
            //         potId: chatDataInResponse[0]?.pot?.potId,
            //         eventType: "participantUpdated",
            //       })
            //     );

            //     if (joinPotResponse.meta.requestStatus !== "fulfilled") {
            //       await new Promise((resolve) => setTimeout(resolve, 2000));
            //     }
            //   } while (
            //     joinPotResponse.meta.requestStatus !== "fulfilled" &&
            //     attemptJoin < 60
            //   );
            //   if (joinPotResponse.meta.requestStatus === "fulfilled") {
            //     dispatch(setIsDepositedLoading(false));
            //     dispatch(
            //       setIsDeposited({
            //         // isDeposited: true,
            //         isJoined: true,
            //         data: null,
            //         isSyncingJoined: false,
            //       })
            //     );
            //     navigate(`/dashboard/${chatDataInResponse[0].pot.groupChat}`, {
            //       state: {
            //         potCreated: true,
            //         potCode: chatDataInResponse[0].pot.potCode,
            //       },
            //     });

            //     setSelectedRow(chatDataInResponse[0].pot.groupChat);
            //   }
            // }
          }
          if (isError) {
            dispatch(
              setIsCreatedPot({
                isCreated: false,
                isSyncing: false,
              })
            );
          }
        } else if (currentPage === 1 && chatData.length === 0) {
          dispatch(resetChats());
          setIsPageLoading(true);
          await dispatch(getChatsData({ pageNo: currentPage, isArchived }));
          setIsPageLoading(false);
        } else if (currentPage > 1) {
          setIsPageLoading(true);
          await dispatch(getChatsData({ pageNo: currentPage, isArchived }));
          setIsPageLoading(false);
        } else {
          setCurrentPage(1);
          dispatch(resetChats());
          await dispatch(getChatsData({ pageNo: 1, isArchived }));
          setIsFirstRender(false);
        }
      }
    })();
  }, [currentPage, dispatch, isArchived, isSyncing, createPotError]);

  useEffect(() => {
    if (isSyncingJoined) setCurrentPage(1);
  }, [isSyncingJoined]);

  const handleMouseEnter = () => {
    setTooltipActive(true);
  };
  const handleMouseLeave = () => {
    setTooltipActive(false);
  };

  const leftBarRef = useRef<HTMLDivElement>(null);
  const handleScrollToBottom = () => {
    if (leftBarRef.current) {
      leftBarRef.current.scrollBy({
        top: 70,
        behavior: "smooth",
      });
    }
  };
  const onCloseLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(false));
  };

  const openLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(true));
  };

  return (
    <Container ref={leftBarRef} isdropdownactive={isDropdownActive}>
      {chatLoading && chatData.length === 0 ? (
        <Skeleton />
      ) : chatData && chatData.length > 0 ? (
        chatData?.map((chat: any, index: number) => (
          <div
            key={`${chat._id}-${index}-potChatBox`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <PotChatRow
              key={`${chat._id}-${index}-potChatRow`}
              isActive={isSelectedRow === chat?.pot?.groupChat}
              onClick={() => {
                openNotificationModal(index, chat._id);
                if (onRowClick) {
                  onRowClick(index, chat._id);
                  setSelectedRow(chat._id);
                }
              }}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {chat?.amountDeposited === 0 ? <AlertIcon /> : <></>}
              {chat.participants.length === 0 ? (
                <BoxOne>
                  <AvatarOne />{" "}
                </BoxOne>
              ) : (
                <DualAvatar>
                  <BoxOne>
                    {chat.participants.length >= 1 &&
                    chat?.participants[0]?.profilePicture ? (
                      <img
                        src={chat?.participants[0]?.profilePicture}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "100px",
                          border: "solid 1px white",
                        }}
                        alt="Avatar"
                      />
                    ) : (
                      <AvatarOne />
                    )}
                  </BoxOne>
                  <BoxTwo>
                    {chat.participants.length >= 2 &&
                    chat?.participants[1]?.profilePicture ? (
                      <img
                        src={chat?.participants[1]?.profilePicture}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "100px",
                          border: "solid 1px white",
                        }}
                        alt="Avatar"
                      />
                    ) : (
                      <AvatarTwo />
                    )}
                    {tooltipActive && chat?.amountDeposited === 0 && (
                      <DepositTooltip
                        hoveredRow={hoveredRow}
                        index={index}
                        text={"Please make a deposit"}
                      />
                    )}
                  </BoxTwo>
                </DualAvatar>
              )}
              <PotChatBox>
                <PotChatText>{chat.name}</PotChatText>
                <TimePeriod>
                  {chat.admin ? chat.admin.username : null}:{" "}
                  {chat.messages && chat.messages.length > 0
                    ? chat.messages[0].msg
                    : ""}
                  {chat.newMsg === true && <ActiveDot></ActiveDot>}
                </TimePeriod>
                <TimePeriod>{timeAgo(chat?.pot?.createdAt)}</TimePeriod>

                <OptionsBox
                  isOpen={hoveredRow === index}
                  onClick={(e) => {
                    handleDropdownClick(index);
                    handleScrollToBottom();
                    e.stopPropagation();
                  }}
                >
                  <ThreeDotsIcon />
                </OptionsBox>
                <MobileOptionsBox
                  onClick={(e) => {
                    handleDropdownClick(index);
                    handleScrollToBottom();
                    e.stopPropagation();
                  }}
                >
                  <ThreeDotsIcon />
                </MobileOptionsBox>
              </PotChatBox>
              {dropdownOpenMap[index] && (
                <DropdownMenus
                  chatDataProps={chat}
                  isDropDownItem={true}
                  dropdownRef={dropdownRef}
                  dropdownOpenMap={dropdownOpenMap}
                  toggleDropdown={toggleDropdown}
                  onDepositClick={onDepositClick}
                />
              )}
            </PotChatRow>
          </div>
        ))
      ) : (
        <NoChatBox>
          <img
            src={NoChat}
            alt="nochat"
            style={{ width: "100px", height: "87.412px" }}
          />
          <NoChatText>
            Welcome to Quail. As a newcomer, your journey begins anew with us.
            Start afresh – no pots or chat history. Let's sculpt your financial
            future together.
          </NoChatText>
        </NoChatBox>
      )}
      {chatLoading && chatData.length > 0 && <Loader />}
      <Sentinel ref={sentinelRef} />
    </Container>
  );
};

export default PotChatContainer;

const Sentinel = styled.div`
  height: 10px;
`;

const Container = styled.div<{ isdropdownactive: boolean }>`
  width: 100%;
  /* margin-top: 1em !important; */
  /* padding-top: 10px; */
  height: 48vh;
  overflow-y: auto;
  padding-bottom: ${(props) => (props.isdropdownactive ? "70px" : "")};

  @media (max-width: 475px) {
    height: 100vh;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    height: 55vh;
  }
`;

const PotChatRow = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  gap: 12px;
  padding: 8px;
  /* margin-bottom: 12px; */
  /* border-radius: 8px; */
  /* margin-top: 1em; */
  border-bottom: 1px solid rgba(233, 246, 208, 0.2);

  &:hover,
  &.active {
    background: rgba(233, 246, 208, 0.04);
  }

  ${(props) =>
    props.isActive &&
    `
    background: rgba(233, 246, 208, 0.08);
  `}
`;

const NoChatBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 45vh;
  @media (max-width: 475px) {
    height: 60vh;
  }
`;

const NoChatText = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.28px;
  width: 270px;
  opacity: 0.7;
  margin-top: 14px;
`;

const Avatar = styled.div`
  width: 44px;
  height: 44px;
`;
const DualAvatar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AlertIcon = styled(Alert)`
  display: flex;
  width: 10px;
  height: 10px;
`;

const AvatarOne = styled(defaultImage)`
  width: 30px;
  height: 30px;
  border-radius: 77.143px;
`;

const AvatarTwo = styled(defaultImage)`
  width: 30px;
  height: 30px;
  border-radius: 77.143px;
`;

const BoxOne = styled.div`
  display: flex;
  padding-left: 14px;
  align-items: flex-start;
  gap: 8px;
`;
const BoxTwo = styled.div`
  display: flex;
  margin-top: -16px;
  padding-right: 14px;
  align-items: flex-start;
`;

const PotChatBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const PotChatText = styled.span`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const TimePeriod = styled.span`
  display: flex;
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActiveDot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #b1dd40;
  border-radius: 100%;
  top: 45%;
  right: 5%;
`;

const OptionsBox = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 1%;
  z-index: 2;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  @media (max-width: 475px) {
    display: none;
  }

  &:hover {
    border-radius: 100px;
    background: rgba(233, 246, 208, 0.12);
  }
`;

const MobileOptionsBox = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 1%;
  z-index: 2;
  display: none;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  @media (max-width: 475px) {
    display: flex;
  }

  /* &:hover { */
  border-radius: 100px;
  background: rgba(233, 246, 208, 0.12);
  /* } */
`;

const ThreeDotsIcon = styled(ThreeDots)``;

const Loader = styled.div`
  border: ${(props) => `4px solid ${props.theme.colors.primary}`};
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 10px;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

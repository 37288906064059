import styled from "styled-components";

export const StyledEllipse = styled.div<{ isopen?: boolean }>`
  width: 870px;
  height: 870px;
  flex-shrink: 0;
  border-radius: 870px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(177, 221, 64, 0.8) 0%,
    rgba(177, 221, 64, 0) 100%
  );
  filter: blur(100px);
  position: absolute;
  top: -650px;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);

  @media (max-width: 1200px) {
    width: 700px;
    height: 700px;
    border-radius: 700px;
    top: -550px;
  }

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    border-radius: 500px;
    top: -350px;
  }

  @media (max-width: 480px) {
    display: ${(props) => (props.isopen ? "none" : "none")};
    width: 300px;
    height: 300px;
    border-radius: 300px;
    top: -200px;
  }
`;

export const StyledEllipseMobile = styled.div`
  width: 870px;
  height: 870px;
  flex-shrink: 0;
  border-radius: 870px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(177, 221, 64, 0.8) 0%,
    rgba(177, 221, 64, 0) 100%
  );
  filter: blur(108px);
  position: absolute;
  top: -150px;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);

  @media (max-width: 1200px) {
    width: 700px;
    height: 700px;
    border-radius: 700px;
    top: -550px;
  }

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    border-radius: 500px;
    top: -350px;
  }

  @media (max-width: 480px) {
    // display: none;
    width: 300px;
    height: 300px;
    border-radius: 300px;
    top: -178px;
  }
`;

export const StyledEllipseRotate = styled.div`
  width: 870px;
  height: 870px;
  flex-shrink: 0;
  border-radius: 50%; /* This makes it a perfect circle */
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(177, 221, 64, 0.8) 0%,
    rgba(177, 221, 64, 0) 100%
  );
  filter: blur(100px);
  position: absolute; /* Fixed relative to the viewport */
  bottom: -650px; /* Half outside the viewport */
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  z-index: -1; /* Ensure it's behind the text/button */

  @media (max-width: 1200px) {
    width: 700px;
    height: 700px;
    bottom: -545px; /* Half outside the viewport */
  }

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    bottom: -343px; /* Half outside the viewport */
  }

  @media (max-width: 480px) {
    width: 300px;
    height: 300px;
    bottom: -257px; /* Half outside the viewport */
  }
`;

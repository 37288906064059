import USDBABI from "../abi/usdbAbi.json";
import { ethers } from "ethers";
import { Insufficient_Fund_Error } from "../errors";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface ApproveUsdbTxProps {
  amount: string;
}

export const approveUsdbTx = async ({ amount }: ApproveUsdbTxProps) => {
  if (!window.ethereum) {
    throw new Error("Ethereum object not found in window");
  }
  const USDB_Contract_Address = process.env.REACT_APP_USDB_Contract_Address;
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;
  if (USDB_Contract_Address && Quail_Finance_Contract_Address) {
    try {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const signerAddress = await signer.getAddress();
      const USDBContract = new ethers.Contract(
        USDB_Contract_Address,
        USDBABI,
        signer
      );
      const balance = await USDBContract.balanceOf(signerAddress);
      const formattedBalance = ethers.formatUnits(balance, "ether");
      if (formattedBalance < BigInt(amount).toString()) {
        throw new Error(Insufficient_Fund_Error);
      }

      // Get the current allowance
      const currentAllowance = await USDBContract.allowance(
        signerAddress,
        Quail_Finance_Contract_Address
      );
      const currentAllowanceBigInt = BigInt(currentAllowance);
      // const formattedCurrentAllowance = ethers.formatUnits(
      //   currentAllowance,
      //   "ether"
      // );
      // console.log(`Current allowance is: ${formattedCurrentAllowance} USDB`);

      const desiredApprovalAmountBigInt = ethers.parseEther(amount);

      if (currentAllowanceBigInt < desiredApprovalAmountBigInt) {
        const tx = await USDBContract.approve(
          Quail_Finance_Contract_Address,
          ethers.parseEther("10000000000000000")
        );
        const receipt = await tx.wait();
        if (receipt && receipt.status === 1) {
          console.log("Transaction successfully mined and confirmed");
        } else if (receipt && receipt.status === 0) {
          throw new Error("Transaction failed and was reverted by the EVM");
        }
      } else {
        console.log("Current allowance is sufficient for the transaction.");
      }
    } catch (error: any) {
      if (error.message === Insufficient_Fund_Error) {
        // Handling the specific Insufficient Fund error
        console.log("You do not have enough funds to complete this operation.");
        throw new Error(error.message);
      } else {
        const parsedEthersError = getParsedEthersError(error);
        console.log("parsedEthersError", parsedEthersError);
        if (parsedEthersError.errorCode == "REJECTED_TRANSACTION") {
          throw new Error("You rejected the transaction");
        }
        throw new Error(parsedEthersError.context);
      }
    }
  }
};

import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { EmailModalProps } from "../../utils/interface";
import CloseIcon from "../../assets/close_icon.svg";
import HoverButton from "../Button/HoverButton";

const EmailNotificationModal: React.FC<EmailModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const [inputEmail, setInputEmail] = useState("you@youremail.com");

  const handleConfirm = () => {
    onConfirm();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setInputEmail(event.target.value);
  };
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseButton onClick={handleClose} src={CloseIcon} alt="Close" />
        <MainHeading>EMAIL SETTING</MainHeading>
        <ModalTitle>
          Turn on email notifications for this update content
        </ModalTitle>
        <Row>
          <EmailText>
            YOUR EMAIL <span style={{ color: "#FF645D" }}>*</span>
          </EmailText>
        </Row>

        <ModalInput
          type="email"
          value={inputEmail}
          onChange={handleInputChange}
          placeholder="Enter your email"
        />
        <div style={{ width: "100%", marginTop: "32px" }}>
          <EmailStyledButton
            onClick={() => handleConfirm()}
            height="44px"
            padding="0"
          >
            Send confirmation{" "}
          </EmailStyledButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default EmailNotificationModal;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  width: 100vw;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 614px) {
    width: 90vw;
    justify-content: flex-end;
  }

  @media (max-width: 297px) {
    width: 100vw;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  width: 480px;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);

  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 614px) {
    width: 100%;
    padding: 18px 36px 130px 22px;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  @media (max-width: 614px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalTitle = styled.h2`
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #aeb89a;
  display: flex;
  justify-content: center;
`;

const EmailText = styled.h2`
  color: #aeb89a;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
`;

const ModalInput = styled.input`
  width: 92%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  height: 48px;
  border-radius: 8px;
  padding: 0 16px;
  gap: 10px;
  background: #343829;
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 26px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 0;
  max-width: 100%;
`;

const EmailStyledButton = styled(HoverButton)`
  width: 98%;
  height: 48px;
  border-radius: 8px;
  padding: 0px 16px;
  background-color: #ff645d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 24px;
`;

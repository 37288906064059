import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface JoinAPotTxProps {
  potId: number;
  signature: string;
  nonce: number;
  shouldSync: boolean;
  currentRound?: any;
  potCode?: any;
}

export const joinPotTx = async ({
  potId,
  signature,
  nonce,
  shouldSync,
  currentRound,
  potCode,
}: JoinAPotTxProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;
  if (Quail_Finance_Contract_Address) {
    try {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(
        Quail_Finance_Contract_Address,
        QuailABI,
        signer
      );

      const tx = await contract.joinPot(potId, signature, nonce);
      console.log("Transaction hash:", tx.hash);
      if (shouldSync)
        localStorage.setItem(
          "pendingJoinPotTx",
          JSON.stringify({
            transactionHash: tx.hash,
            potCode,
            currentRound,
          })
        );
      const receipt = await tx.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
        localStorage.removeItem("pendingJoinPotTx");
      } else if (receipt && receipt.status === 0) {
        localStorage.removeItem("pendingJoinPotTx");
        throw new Error("Transaction failed and was reverted by the EVM");
      }
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};

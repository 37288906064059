import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as TwitterLogo } from "../../assets/Twitter-logo.svg";
import { ReactComponent as CopyLogo } from "../../assets/copy-logo.svg";
import { ReactComponent as XLogo } from "../../assets/dark-x-logo.svg";
import { toast } from "react-toastify";
import HoverButton from "../Button/HoverButton";
import { useDispatch } from "react-redux";
import { AppDispatch, shareOnTwitterApi } from "../../store";

const InviteFriendPot: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  chatDataProps: any;
  isOpen?: boolean;
  inviteFriendRef?: any;
  joinedParticipants?: any;
}> = ({
  onClose,
  onConfirm,
  isOpen,
  chatDataProps,
  inviteFriendRef,
  joinedParticipants,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  if (!isOpen) {
    return null;
  }
  const [copyText, setCopyText] = useState("Copy");
  const domain = window.location.hostname;

  const shareOnTwitter = async () => {
    try {
      const sharedTwitterResponse = await dispatch(
        shareOnTwitterApi({
          potCode: chatDataProps.pot.potCode,
        })
      );
      if (sharedTwitterResponse.meta.requestStatus === "fulfilled") {
        const inviteCode = `${domain}/${chatDataProps.pot.potCode}`;
        const tweetText = encodeURIComponent(
          "I just created a pot on #QuailFinance, @QuailFinance." +
            "\n" +
            "\n" +
            "Collateral-Free Liquidity Based on Social Trust is real." +
            "\n" +
            "\n" +
            `Join My Pot - ${inviteCode}`
        );
        const twitterIntentURL = `https://twitter.com/intent/tweet?text=${tweetText}`;

        window.open(twitterIntentURL, "_blank");
      }
      if (sharedTwitterResponse.meta.requestStatus === "rejected") {
        toast.error(
          sharedTwitterResponse?.payload?.message ||
            "Failed to share, please try again",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err: any) {
      console.error("error in sharing on twitter");
    }
  };

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(
        `${domain}/${chatDataProps.pot.potCode}`
      );
      setCopyText("Copied");
    } catch (error: any) {
      console.error("Failed to copy: ", error);
      if (error) {
        toast.error(error || "Failed to copy:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>INVITE FRIENDS TO {chatDataProps?.pot?.name}</ModalTitle>
        <ModalText>Send the pot invitation link to invite a friends</ModalText>
        <ModalRow>
          <ModalTextField>
            {/* <Input placeholder="https://qualifi.xyz/hTKzmak" type="string" /> */}
            <Text>{`${domain}/${chatDataProps?.pot?.potCode}`}</Text>
            {/* <Text>https://testnet.quail.finace/hTKzmak</Text> */}
            <ModalCopy onClick={copyCode} height="32px" padding="0 16px">
              {copyText}
            </ModalCopy>
          </ModalTextField>
        </ModalRow>
        <ModalDescription>Your invite link expires in 7 days</ModalDescription>
        {!joinedParticipants?.istwitterShared && (
          <ButtonRow>
            <CreateAPotButton
              onClick={shareOnTwitter}
              height="48px"
              padding="0 16px"
            >
              <IconContainer>
                <XLogo />
              </IconContainer>
              Share on Twitter
            </CreateAPotButton>
          </ButtonRow>
        )}

        {/* <ButtonsWrap>
          <ShareButtonContainer onClick={shareOnTwitter}>
            <TwitterLogo style={{ marginLeft: 5 }} />
          </ShareButtonContainer>

          <ShareButtonContainer2 onClick={copyCode}>
            <CopyLogo style={{ marginLeft: 5 }} />
          </ShareButtonContainer2>
        </ButtonsWrap>

        <ModalText>Your invite link expires in 7 days</ModalText> */}
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default InviteFriendPot;

// Styled Componentsyr

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 90px;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 505px;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;

  /* modal glow */
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 614px) {
    width: 100%;
    border-radius: 2em 2em 0 0;
    padding: 18px 36px 64px 22px;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 90px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 614px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  /* font-family: ${(props) => props.theme.fontFamily.secondary}; */
  font-family: Orbitron;
  max-width: 300px;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 614px) {
    font-size: 18px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ShareButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  height: 40px;
  background-color: #b1dd40;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
const ShareButtonContainer2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  height: 40px;
  background-color: #3c4131;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    padding: 0px 17px;
    background: var(--primary, #afdc3b);
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  width: 30%;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const ModalRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
`;

const ModalTextField = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 8px 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  @media (max-width: 400px) {
    padding: 0px 8px 0px 8px;
  }
`;

const ModalDescription = styled.p`
  color: #e9f6d0;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  /* margin-top: 16px; */
  margin-bottom: 32px;
  opacity: 0.7;
`;

const Text = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk", sans-serif;
  width: 340px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 480px) {
    width: 250px;
    font-size: 11px;
  }
  @media (max-width: 400px) {
    width: 200px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.3s ease;
  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    background: ${(props) => props.theme.colors.softGray};
    transform: scale(1.2);
    img {
      transform: scale(0.85);
    }
  }

  @media (max-width: 480px) {
    padding: 0px;

    &:first-child {
      margin-right: 12px;
    }
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 35px;
    padding: 5px 24px;
    font-size: 14px;
  }
`;
const ModalCopy = styled(HoverButton)`
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.primary};
  &:hover {
    background: #b9ff04;
    box-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(233, 246, 208, 0.40); */
  background: rgba(233, 246, 208, 0.08);
  /* color: white; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 475px) {
    font-size: 14px;
  }
`;

export const CreateAPotButton = styled(HoverButton)`
  display: flex;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

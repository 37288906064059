import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {MetaMask} from "@web3-react/metamask";
import {WalletConnect as WalletConnectV2} from "@web3-react/walletconnect-v2";
import {WalletConnect} from "@web3-react/walletconnect";
import {CoinbaseWallet} from "@web3-react/coinbase-wallet";
import {hooks as metaMaskHooks, metaMask} from "./utils/connectors/metamask";
import {hooks as walletConnectHooks, walletConnect,} from "./utils/connectors/walletConnect";
import {hooks as walletConnectV2Hooks, walletConnectV2,} from "./utils/connectors/walletConnectV2";
import {coinbaseWallet, hooks as coinbaseWalletHooks,} from "./utils/connectors/coinbaseWallet";
import {Web3ReactHooks, Web3ReactProvider,} from "@web3-react/core";
import {Provider} from "react-redux";
import {store} from "./store";
import {Web3Provider} from "@ethersproject/providers";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

const queryClient = new QueryClient();

const connectors: [
        MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet,
    Web3ReactHooks
][] = [
    [metaMask, metaMaskHooks],
    [walletConnect, walletConnectHooks],
    [walletConnectV2, walletConnectV2Hooks],
    [coinbaseWallet, coinbaseWalletHooks],
];

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Web3ReactProvider connectors={connectors}>
                    <App/>
                </Web3ReactProvider>
            </Provider>
        </QueryClientProvider>
    </BrowserRouter>
    // </React.StrictMode>
);

reportWebVitals();

import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { toast } from "react-toastify";
import { syncRotation } from "../chatApi/chatApi";

interface RotateALiquidityTxProps {
  potId: number;
  userCommitment: string;
  userRandom: string;
  providerRandom: string;
}

export const rotateLiquidityTx = async ({
  potId,
  userCommitment,
  userRandom,
  providerRandom,
}: RotateALiquidityTxProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;

  // await axios.get(
  //   `https://fortuna-staging.dourolabs.app/v1/chains/blast-testnet/revelations/${sequenceNumber}`
  // );
  if (Quail_Finance_Contract_Address) {
    try {
      const authToken = localStorage.getItem("token");

      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(
        Quail_Finance_Contract_Address,
        QuailABI,
        signer
      );

      const entropyFee = await contract.getEntropyFee();

      const tx = await contract.rotateLiquidity(
        potId,
        userCommitment,
        userRandom,
        "0x" + providerRandom,
        {
          value: entropyFee,
        }
      );
      localStorage.setItem(
        "pendingStartPotTx",
        JSON.stringify({
          transactionHash: tx.hash,
          potId,
        })
      );
      console.log("Transaction hash:", tx.hash);

      const receipt = await tx.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
        localStorage.removeItem("pendingStartPotTx");
      } else if (receipt && receipt.status === 0) {
        localStorage.removeItem("pendingStartPotTx");
        throw new Error("Transaction failed and was reverted by the EVM");
      }

      // TODO Add The sync-rotation api here...................................................................

      // syncRotation(authToken, potId)
      //   .then((data: any) => {
      //     console.log("Liquidity rotated successfully:", data);
      //   })
      //   .catch((error: any) => {
      //     console.error("Error rotating liquidity:", error);
      //     toast.error(error?.message, {
      //       position: "bottom-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //   });
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};

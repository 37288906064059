import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface CreateAPotTxProps {
  rotationCycleInSeconds: number;
  interestDenominator: number;
  interestNumerator: number;
  numParticipants: number;
  amount: string;
  potName: string;
  userCommitment: string;
}

export const createPotTx = async ({
  amount,
  interestDenominator,
  interestNumerator,
  numParticipants,
  rotationCycleInSeconds,
  potName,
  userCommitment,
}: CreateAPotTxProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_Contract_Address;
  if (Quail_Finance_Contract_Address) {
    try {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(
        Quail_Finance_Contract_Address,
        QuailABI,
        signer
      );

      const entropyFee = await contract.getEntropyFee();

      const potIdPromise = new Promise<number>((resolve, reject) => {
        let eventHandler = (id: any, event: any) => {
          resolve(id);
          contract.off("PotCreated", eventHandler);
        };

        contract.on("PotCreated", eventHandler);
      });

      const transaction = await contract.createPot(
        potName,
        userCommitment,
        rotationCycleInSeconds,
        interestDenominator,
        interestNumerator,
        numParticipants,
        ethers.parseEther(amount),
        {
          value: entropyFee,
        }
      );
      localStorage.setItem(
        "pendingCreatePotTx",
        JSON.stringify({
          transactionHash: transaction.hash,
          potName,
          rotationCycleInSeconds,
          interestDenominator,
          interestNumerator,
          numParticipants,
          amount,
          userCommitment,
        })
      );
      console.log("Transaction hash:", transaction.hash);

      const potId = await potIdPromise;
      const receipt = await transaction.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
        localStorage.removeItem("pendingCreatePotTx");
      } else if (receipt && receipt.status === 0) {
        localStorage.removeItem("pendingCreatePotTx");
        throw new Error("Transaction failed and was reverted by the EVM");
      }
      contract.removeAllListeners("PotCreated");
      return { potId };
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};

import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as BuiltOnBlast } from "../../assets/built-on-blast.svg";
import { ReactComponent as CantinaWordmarkBrandBlack } from "../../assets/Cantina-Wordmark-Brand-Black.svg";
import { ReactComponent as PythNetworkSeeklogo } from "../../assets/pyth-network-seeklogo.svg";

const slide = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Ticker: React.FC<{}> = () => {
  const builtOnBlast = "https://blast.io"
  const cantinaWordmarkBrandBlack = "https://cantina.xyz/welcome";
  const pythNetwork = "https://pyth.network";
  return (
    <TickerWrapper>
      <TickerContent>
        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>
        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>
        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>

        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>
        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>
        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>

        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>

        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>

        <TickerItem onClick={() => window.open(builtOnBlast, "_blank")}>
          <BuiltOnBlast />
        </TickerItem>
        <TickerItem
          onClick={() => window.open(cantinaWordmarkBrandBlack, "_blank")}
        >
          <CantinaWordmarkBrandBlack />
        </TickerItem>
        <TickerItem onClick={() => window.open(pythNetwork, "_blank")}>
          <PythNetworkSeeklogo />
        </TickerItem>
      </TickerContent>
    </TickerWrapper>
  );
};

export default Ticker;

const TickerWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  /* width: fit-content; */
  margin-top: 102px;
  animation: ${slide} 20s linear infinite;
  animation-play-state: running; /* By default, let it run */

  &:hover {
    animation-play-state: paused; /* Pause animation on hover */
  }
`;

const TickerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 150px;
`;

const TickerItem = styled.div`
  color: red;
  padding: 0 15px;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
`;

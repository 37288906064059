import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getHomePageStats, RootState, AppDispatch } from "../store";
import { localStorageStates } from "../utils";
import MainSection from "../components/Home/MainSection";
import QualifiSection from "../components/Home/QualifiSection";
import RotationSection from "../components/Home/RotationSection";
import BottomSection from "../components/Home/BottomSection";
import { ReactComponent as ExpandMoreIcon } from "../assets/expand_more.svg";
import { toast } from "react-toastify";
import Footer from "../components/Footer";
import SliderHome from "../components/Home/sliderHome";
import usePageSEO from "../hooks/usePageSEO";

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isRegistered: isRegisteredInStore } = useSelector(
    (state: RootState) => state.wallet
  );

  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [stats, setStats] = useState<{
    totalPots?: string;
    totalUsers?: string;
    cumulativeVolume?: string;
    yieldDistribution?: string;
  }>({});

  const qualifiSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const registered = localStorage.getItem(localStorageStates.isRegistered);
    setIsRegistered(registered === "true" ? true : false);
  }, [isRegisteredInStore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsResponse = await dispatch(getHomePageStats({}));
        if (statsResponse.meta.requestStatus === "fulfilled") {
          const { totalUsers } = statsResponse.payload;
          setStats(statsResponse.payload);
          localStorage.setItem(
            localStorageStates.totalUsers,
            totalUsers || "0"
          );
        }
        if (statsResponse.meta.requestStatus === "rejected") {
          toast.error(
            statsResponse?.payload?.message || "Failed to get stats",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
        if (error) {
          toast.error(error || "Error fetching data:", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    };
    fetchData();
  }, [dispatch]);

  const handleScrollToQualifi = () => {
    qualifiSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  usePageSEO({
     title: "Home | Quail Finance",
     description:
       "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
     keywords: [
       "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
     ],
     ogTitle: "Home | Quail Finance",
     ogDescription:
       "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
     ogImage:
       "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
     ogUrl: "https://quail.finance",
     twitterTitle: "Home | Quail Finance",
     twitterDescription:
       "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
     twitterImage:
       "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
     twitterUrl: "https://quail.finance",
   });


  return (
    <>
      <Container>
        <MainSection
          onExpandMoreClick={handleScrollToQualifi}
          isRegistered={isRegistered}
        />
        <BlinkingArrow onClick={handleScrollToQualifi} />
        <QualifiSection
          ref={qualifiSectionRef}
          data={{
            totalPots: stats.totalPots || "0",
            totalUsers: stats.totalUsers || "0",
            commulativeVolume: stats.cumulativeVolume || "0",
            yieldDistribution: stats.yieldDistribution || "0",
          }}
        />
        <SliderHome />
        <RotationSection isRegistered={isRegistered} />
        <BottomSection isRegistered={isRegistered} />
        {/* <StyledEllipseRotate /> */}
      </Container>
      <Footer />
    </>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14vh;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-top: 13px;
  }

  @media (max-width: 480px) {
    padding-top: 12px;
    margin-bottom: 0px;
  }
`;

const BlinkingArrow = styled(ExpandMoreIcon)`
  cursor: pointer;
  display: block;
  margin: 16px auto;
  animation: blinkAndFakeMove 1s infinite ease-in-out;

  @keyframes blinkAndFakeMove {
    0%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 0;
      transform: translateY(5px); // Slight move down
    }
  }
`;

import { useEffect, useState } from "react";
import { checkUserSigned } from "../utils/helper";
import { Navigate } from "react-router-dom";

interface RouteProps {
    children: React.ReactNode;
}

export const IsUserSigned: React.FC<RouteProps> = ({ children }) => {
    if (!checkUserSigned()) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

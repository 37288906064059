import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ChatData } from "../utils/interface";
import LeftBar from "../components/dashboard/leftbar";
import RightBar from "../components/dashboard/rightbar";
import ChatDisplayUI from "../components/chatbox/ChatDisplayUI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import WalletConnectingModal from "../components/Modal/WalletConnectingModal";
import CreatePotSuccessModal from "../components/Modal/CreatePotSuccessModal";
import { toast } from "react-toastify";
import DepositSuccessModal from "../components/Modal/DepositSuccessModal";
import DepositModal from "../components/Modal/DepositModal";
import {
  AppDispatch,
  joinPot,
  joinPotSignature,
  setWalletConnect,
  setIsDeposited,
  setDepositError,
  setIsDepositedLoading,
  RootState,
  setPotDetail,
  getJoinedParticipants,
  checkSyncDetails,
} from "../store";
import { setOnParticipantJoined } from "../utils/chatApi/chatApi";
import { joinPotTx } from "../utils/transactions/joinAPot";
import { useDispatch, useSelector } from "react-redux";
import { approveUsdbTx } from "../utils/transactions/approveUsdb";
import { Insufficient_Fund_Error } from "../utils/errors";
import InsufficientFundModal from "../components/Modal/InsufficientFundModal";
import usePageSEO from "../hooks/usePageSEO";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const createPotRef = useRef<HTMLButtonElement>(null);
  const domain = window.location.hostname;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const {
    chats: { data: chatLists },
    joinedParticpants: { data: joinedParticipants },
  } = useSelector((state: RootState) => state.wallet);
  // const [chatLists, setChatLists] = useState<ChatData[]>([]);
  const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
  const [isMinTaskBar, setMinTaskBar] = useState(true);
  const [potCode, setPotCode] = useState("");
  const [isCreatedPot, setIsCreatedPot] = useState(false);
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null);
  const [isWalletModalOpen, setWalletModalOpen] = useState(true);
  const [defaultChatId, setDefaultChatId] = useState("");
  const [loading, setLoading] = useState(true);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [isLeftBarContainerHidden, setIsLeftBarContainerHidden] =
    useState(false);
  const {
    potDetail: { data: potDetails },
  } = useSelector((state: RootState) => state.wallet);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [isDepositSuccessModal, setIsDepositSuccessModal] = useState(false);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);

  const { provider, account, isActive } = useWeb3React();
  const { chatId } = useParams<{ chatId: string }>();
  const { token } = useParams();

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };
  const currentPotCode = selectedChat?.pot?.potCode;
  const {
    createPot: {
      loading: createPotLoading,
      data: createPotData,
      error: creatPotError,
    },
  } = useSelector((state: RootState) => state.wallet);
  // useEffect(() => {
  // if (window.history.state && window.history.state.state) {
  //   window.history.replaceState({}, document.title);
  // }

  // Your existing logic
  //   if (location.state?.potCreated && location.state?.potCode) {
  //     setIsCreatedPot(true);
  //     setPotCode(location.state?.potCode);
  //     history.replaceState({}, "");
  //   }
  // }, [location]);

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  useEffect(() => {
    if (chatLists?.length > 0) {
      const defaultChat = chatLists.find((chat: any) => chat._id === chatId);
      setSelectedChat(defaultChat || chatLists[0]);
      if (chatId) {
        navigate(`/dashboard/${chatId}`);
      } else {
        if (token && token.length === 5) {
          console.log("token if:", token);
        } else if (token && token.length === 6) {
          console.log("token elsee if:", token);
        } else if (!createPotLoading) {
          console.log("token elsee:", token);
          navigate(`/dashboard/${chatLists[0]?.pot?.groupChat}`);
        }
      }
    }
  }, [chatId, chatLists]);

  const onClickMinTaskBar = () => {
    setMinTaskBar(!isMinTaskBar);
  };

  const onCloseCreatePotSuccessModal = () => {
    setIsCreatedPot(false);
  };

  const handleChatSelection = (chat: ChatData) => {
    setSelectedChat(chat);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 475);
      setIsTab(window.innerWidth < 1024);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openChatInFullScreen = (index: number) => {
    setClickedRowIndex(index);
  };

  const goBackToChatGroup = () => {
    setClickedRowIndex(1);
  };

  const handleRowClick = (index: any, chatId: any) => {
    setIsRowClicked(true);
    setIsLeftBarContainerHidden(true); // Hide LeftBarContainer on row click
  };

  const handleDepositClick = () => {
    setIsDepositModal(true);
  };
  const onCloseDepositModal = () => {
    setIsDepositModal(false);
  };
  const onCloseDepositSuccessModal = () => {
    // getJoinedParticipants(currentPotCode);
    setIsDepositSuccessModal(false);
  };

  const fetchJoinedParticipants = async () => {
    try {
      const response = await dispatch(
        getJoinedParticipants({ potCode: currentPotCode })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onDeposit = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsDepositModal(false);

      return;
    }

    setIsDepositLoading(true);
    dispatch(setIsDepositedLoading(true));
    setIsDepositModal(false);

    try {
      const potId = selectedChat?.pot?.potId;
      const potCode = selectedChat?.pot?.potCode;
      if (potId && potCode) {
        try {
          await approveUsdbTx({
            amount: selectedChat?.pot?.amount?.toString(),
          });
        } catch (error: any) {
          console.log("Error during USDB approval:", error?.message);
          if (error?.message.includes(Insufficient_Fund_Error)) {
            onCloseDepositModal();
            setIsInsufficientFundModal(true);
            setIsDepositModal(false);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        setIsDepositModal(false);
        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          await joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: true,
            potCode,
          });

          let joinPotResponse;
          let attempt = 0;
          do {
            attempt++;
            joinPotResponse = await dispatch(
              checkSyncDetails({ potId, eventType: "participantUpdated" })
            );

            if (joinPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            joinPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );
          if (joinPotResponse.meta.requestStatus === "fulfilled") {
            dispatch(
              setIsDeposited({
                isDeposited: true,
                data: null,
              })
            );
            await fetchJoinedParticipants();

            setIsDepositSuccessModal(true);
            // setIsJoinPotConfirmModal(true);
            // createdPot(code);
          } else {
            dispatch(
              setDepositError(
                joinPotResponse?.payload?.message || "Failed to join a pot"
              )
            );
            toast.error(
              joinPotResponse?.payload?.message || "Failed to join a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          // if (potDetails && potDetails?.currentRound) {
          //   if (potDetails?.currentRound > 1) {
          //     let attempt = 0;
          //     let joinPotResponse;
          //     do {
          //       attempt++;
          //       joinPotResponse = await dispatch(
          //         joinPot({ walletAddress: account, potCode })
          //       );

          //       // If the request is not fulfilled, wait for a bit before retrying
          //       if (joinPotResponse.meta.requestStatus !== "fulfilled") {
          //         await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          //       }
          //     } while (
          //       joinPotResponse.meta.requestStatus !== "fulfilled" &&
          //       attempt < 5
          //     ); // Retry up to 5 times
          //     // Check if it succeeded after retries
          //     if (joinPotResponse.meta.requestStatus === "fulfilled") {
          //       dispatch(
          //         setIsDeposited({
          //           isDeposited: true,
          //           data: null,
          //         })
          //       );
          //       await fetchJoinedParticipants();

          //       setIsDepositSuccessModal(true);
          //     }
          //     if (joinPotResponse.meta.requestStatus === "rejected") {
          //       dispatch(
          //         setDepositError(
          //           getJoinPotSignatureResponse?.payload?.message ||
          //             "Failed to join a pot"
          //         )
          //       );
          //       toast.error(
          //         getJoinPotSignatureResponse?.payload?.message ||
          //           "Failed to join a pot",
          //         {
          //           position: "bottom-right",
          //           autoClose: 5000,
          //           hideProgressBar: false,
          //           closeOnClick: true,
          //           pauseOnHover: true,
          //           draggable: true,
          //           progress: undefined,
          //         }
          //       );
          //     }
          //   } else {
          //     dispatch(
          //       setIsDeposited({
          //         isDeposited: true,
          //         data: null,
          //       })
          //     );
          //     await fetchJoinedParticipants();

          //     setIsDepositSuccessModal(true);
          //   }
          // }
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          dispatch(
            setDepositError(
              getJoinPotSignatureResponse?.payload?.message ||
                "Failed to join a pot"
            )
          );
          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      dispatch(setDepositError(error?.message || "Failed to deposit"));
      toast.error(error?.message || "Failed to deposit", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to deposit:", error);
    } finally {
      dispatch(setIsDepositedLoading(false));
      setIsDepositLoading(false);
    }
  };

  useEffect(() => {
    if (isDepositSuccessModal) {
      setTimeout(() => setIsDepositSuccessModal(false), 5000);
    }
  }, [isDepositSuccessModal]);
  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        createPotRef.current &&
        !createPotRef.current.contains(event.target as Node)
      ) {
        setIsCreatedPot(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  usePageSEO({
    title: "Dashboard | Quail Finance",
    description:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    keywords: [
      "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
    ],
    ogTitle: "Dashboard | Quail Finance",
    ogDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    ogImage: "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    ogUrl: "https://quail.finance/dashboard",
    twitterTitle: "Dashboard | Quail Finance",
    twitterDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    twitterImage:
      "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    twitterUrl: "https://quail.finance/dashboard",
  });


  return (
    <Container>
      {!isMobile || !isLeftBarContainerHidden ? (
        <LeftBarContainer>
          <LeftBar
            openChatInFullScreen={openChatInFullScreen}
            onClick={handleChatSelection}
            handleRowClick={handleRowClick}
            onDepositClick={handleDepositClick}
          />
        </LeftBarContainer>
      ) : null}

      {(isMobile && isLeftBarContainerHidden) || !isMobile ? (
        <ChatDisplayUI
          chatDataProps={selectedChat}
          onClickMinTaskBar={onClickMinTaskBar}
          goBackToChatGroup={() => {
            setIsLeftBarContainerHidden(false); // Hide LeftBarContainer on row click
          }}
          defaultChatId={defaultChatId}
          chatLoading={loading}
        />
      ) : null}

      {!isMobile
        ? isMinTaskBar && <RightBar chatDataProps={selectedChat} />
        : null}

      {isCreatedPot && (
        <CreatePotSuccessModal
          inviteLink={`${domain}/${potCode}`}
          onClose={onCloseCreatePotSuccessModal}
          createPotRef={createPotRef}
        />
      )}
      {isDepositModal && (
        <DepositModal
          onClose={onCloseDepositModal}
          onConfirm={onDeposit}
          isLoading={isDepositLoading}
          potDetail={selectedChat?.pot}
        />
      )}

      {isDepositSuccessModal && (
        <DepositSuccessModal
          statusData={selectedChat}
          onClose={onCloseDepositSuccessModal}
        />
      )}
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
      {isInsufficientFundModal && (
        <InsufficientFundModal onClose={onCloseInsifficientModal} />
      )}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  height: auto;
  /* padding-bottom: 2em; */
  @media (max-width: 475px) {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
`;

const LeftBarContainer = styled.div`
  display: block;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../assets/Logo.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as RightArrowIconSvg } from "../../assets/arrow-right.svg";
import HoverButton from "../Button/HoverButton";
import { useNavigate, useParams } from "react-router-dom";

const JoinPotSucessModal: React.FC<{
  onClose: () => void;
  onConfirm: (chatId: any) => void;
  potDetail: any;
}> = ({ onClose, onConfirm, potDetail }) => {
  const navigate = useNavigate();
  const handleGroupChatId = () => {
    // console.log("handleGroupChatId");
    // onConfirm(potDetail ? potDetail?.groupChat : "");
    // handleClose();
    ViewPotHandle();
  };
  const [visible, setVisible] = useState(false);

  const ViewPotHandle = () => {
    const chatRoute = potDetail?.groupChat
      ? `/dashboard/${potDetail?.groupChat}`
      : "/dashboard";
    navigate(chatRoute);
  };

  useEffect(() => {
    setVisible(true);
    setTimeout(ViewPotHandle, 100);
    setTimeout(handleClose, 5000);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <>
      <MobileModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <MobileModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <ModalTitle>
            You have successfully Joined {potDetail?.name}
          </ModalTitle>
          <ModalText>
            Great Job. Deposit Regularly And Wait For Your Turn To Claim The
            Pot.
          </ModalText>
          <div style={{ width: "130px", marginTop: "20px" }}>
            <ViewPotButton
              onClick={handleGroupChatId}
              height="44px"
              padding="0"
            >
              View Pot
              <RightArrowIconSvg />
            </ViewPotButton>
          </div>
        </MobileModalContainer>
      </MobileModalBackdrop>

      <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
        <ModalContainer visible={visible}>
          <CloseIcon onClick={handleClose} />
          <Row>
            <Columns>
              <LogoSvg />
            </Columns>
            <Columns>
              <PopupTitle>
                You have successfully Joined "{potDetail?.name}"
              </PopupTitle>
              <PopupSubTitle>
                Great Job. Deposit Regularly And Wait For Your Turn To Claim The
                Pot.
              </PopupSubTitle>

              <Row>
                <Columns>
                  <PopupTextButton onClick={handleGroupChatId}>
                    VIEW POT
                  </PopupTextButton>
                </Columns>
              </Row>
            </Columns>
          </Row>
        </ModalContainer>
      </ModalBackdrop>
    </>
  );
};

export default JoinPotSucessModal;

// Styled Componentsyr

const MobileModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  padding: 48px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 768px) {
    justify-content: flex-end;
    top: -40px;
    left: -24px;
  }
  @media (max-width: 475px) {
    display: none;
  }
`;
const MobileModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 640px;
  padding: 40px;
  /* padding: 48px 48px 40px 48px; */
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    padding: 10px 48px 24px 48px;
    border-radius: 4em;
  }
  @media (max-width: 475px) {
    display: none;
  }
`;
const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  align-items: flex-start;

  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    display: none;
  }
`;
const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  width: 100%;
  max-width: 645px;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media (max-width: 475px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 30px;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;
`;
const PopupTitle = styled.div`
  color: #b1dd40;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
`;
const PopupSubTitle = styled.div`
  color: var(--light, #e9f6d0);
  max-width: 540px;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const PopupTextButton = styled.div`
  color: var(--primary, #b1dd40);
  text-align: center;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  cursor: pointer;
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
  opacity: 0.7;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
    width: 80%;
    margin-bottom: 0;
  }
`;

const ViewPotButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

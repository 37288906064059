import React from "react";
import styled from "styled-components";
import { ReactComponent as ElilipseSvg } from "../../assets/Elilipse.svg";
import { ReactComponent as Participant1 } from "../../assets/participant1.svg";
import { ReactComponent as Participant2 } from "../../assets/participant2.svg";
import { ReactComponent as Participant3 } from "../../assets/participant3.svg";

const ParticipantsComponents: React.FC<{
  chatDataProps?: any;
}> = ({ chatDataProps }) => {
  return (
    <>
      {chatDataProps?.participants.length >= 1 &&
      chatDataProps?.participants[0]?.profilePicture ? (
        <img
          src={chatDataProps?.participants[0]?.profilePicture}
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "-10px",
            marginTop: "3px",
            borderRadius: "100px",
            border: "solid 1px white",
          }}
          alt="Avatar"
        />
      ) : (
        <ParticipantIcon1 />
      )}
      {chatDataProps.participants.length >= 2 &&
      chatDataProps?.participants[1]?.profilePicture ? (
        <img
          src={chatDataProps?.participants[1]?.profilePicture}
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "-10px",
            marginTop: "3px",
            borderRadius: "100px",
            border: "solid 1px white",
          }}
          alt="Avatar"
        />
      ) : (
        <ParticipantIcon2 />
      )}
      {chatDataProps.participants.length >= 3 &&
      chatDataProps?.participants[2]?.profilePicture ? (
        <img
          src={chatDataProps?.participants[2]?.profilePicture}
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "-10px",
            marginTop: "3px",
            borderRadius: "100px",
            border: "solid 1px white",
          }}
          alt="Avatar"
        />
      ) : (
        <ParticipantIcon3 />
      )}
      {chatDataProps.participants.length >= 4 &&
      chatDataProps?.participants[3]?.profilePicture ? (
        <img
          src={chatDataProps?.participants[3]?.profilePicture}
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "-10px",
            marginTop: "3px",
            borderRadius: "100px",
            border: "solid 1px white",
          }}
          alt="Avatar"
        />
      ) : (
        <ParticipantIcon1 />
      )}
      {chatDataProps.participants.length > 4 && <ParticipantIcon4 />}
    </>
  );
};

export default ParticipantsComponents;

const ParticipantIcon1 = styled(Participant1)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;
const ParticipantIcon2 = styled(Participant2)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;
const ParticipantIcon3 = styled(Participant3)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;
const ParticipantIcon4 = styled(ElilipseSvg)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;

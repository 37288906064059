import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as notificationSvg } from "../../assets/notification.svg";
import { ReactComponent as PersonAddDropdownIcon } from "../../assets/person_add_dropdown.svg";
import { ReactComponent as ElilipseSvg } from "../../assets/Elilipse.svg";
import { ReactComponent as DefaultImage } from "../../assets/default_image.svg";
import defaultImage from "../../assets/default_image.svg";
import NewCoinImg from "../../assets/newCoinImg.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../assets/alertIcon.svg";
import DistributedRiskPoolSuccessModal from "../Modal/DistributedRiskPoolSuccessModal";
import { ChatRightBarUIProps, CounterBtnProps } from "../../utils/interface";
import InviteFriendPot from "../Modal/InviteFriendPotModal";
import StartPotModal from "../Modal/StartPotModal";
import ParticipationModal from "../Modal/ParticipantsModal";
import { describeDuration, secondsToDays } from "../../utils/helper";
import {
  AppDispatch,
  getRandomness,
  joinPot,
  joinPotSignature,
  leavePot,
  setWalletConnect,
  syncRotate,
  claimFunds,
  setIsClaimLoading,
  setClaimFundError,
  RootState,
  setIsClaimedFunds,
  archiveApi,
  setIsSelectWinnerLoading,
  setIsSelectedWinner,
  setSelectWinnerError,
  setIsDeposited,
  setIsDepositedLoading,
  setDepositError,
  setIsLeavePotModalOpen,
  setIsLeavePotLoading,
  setIsLeavePot,
  setLeavePotError,
  setIsPotDetailLoading,
  setPotDetail,
  getPotDetail,
  getJoinedParticipants,
  setJoinedParticipants,
  setIsArchived,
  restartPotApi,
  getCommitment,
  setIsRestarted,
  removeChat,
  checkSyncDetails,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  getJoinedParticipantListData,
  setOnParticipantJoined,
  setOnRestartPot,
  setonLeavePot,
  setonWinnerSelected,
} from "../../utils/chatApi/chatApi";
import { PotDataDetails } from "../../types";
import Skeleton from "../Skeleton/Skeleton";
import { rotateLiquidityTx } from "../../utils/transactions/rotateLiquidity";
import { toast } from "react-toastify";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { approveUsdbTx } from "../../utils/transactions/approveUsdb";
import { Insufficient_Fund_Error } from "../../utils/errors";
import { useWeb3React } from "@web3-react/core";
import { joinPotTx } from "../../utils/transactions/joinAPot";
import { claimRewardsTx } from "../../utils/transactions/claimFunds";

import ClaimSuccessModal from "../Modal/ClaimSuccessModal";
import DepositSuccessModal from "../Modal/DepositSuccessModal";
import DepositModal from "../Modal/DepositModal";
import SelectWinner from "../Modal/SelectWinnerModal";
import { io } from "socket.io-client";
import { withdrawFromPotTx } from "../../utils/transactions/leavePot";
import LeaveModal from "../Modal/LeavePotModal";
import RotationCycleModal from "../Modal/RotationCycleModal";
import InsufficientFundModal from "../Modal/InsufficientFundModal";
import OutlinedButton from "../Button/OutlinedButton";
import { createPotTx } from "../../utils/transactions/createAPot";

const Dashboard: React.FC<ChatRightBarUIProps> = ({
  chatDataProps,
  width,
  borderTop,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    claimFund: { loading: isClaimLoading },
    isLeavePotModalOpen,
    potDetail: { data: potDetails, loading },
    joinedParticpants: { data: joinedParticipants },
    chats: { data: chatData },
  } = useSelector((state: RootState) => state.wallet);
  const isSocketInitialized = useRef(false);
  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;
  const startWinnerRef = useRef<HTMLButtonElement>(null);
  const inviteFriendRef = useRef<HTMLButtonElement>(null);
  const leavePotRef = useRef<HTMLButtonElement>(null);
  const selectWinnerRef = useRef<HTMLButtonElement>(null);
  const { account, provider } = useWeb3React();
  const [chatListData, setChatListData] = useState<any>(
    chatDataProps || {
      name: "Pot Name Unavailable",
      participants: [],
      _id: "",
      sender: "",
      profilePicture: "",
      username: "",
      pot: {
        formattedDate: null,
        noOfParticipants: 0,
        createdAt: "2024-04-02T16:43:47.754Z",
        rotationCycle: null,
        potId: null,
        potCode: "",
        currentRound: "",
      },
    }
  );
  const navigate = useNavigate();
  const [socket, setSocket] = useState<any>(null);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isDepositSuccessModal, setIsDepositSuccessModal] = useState(false);
  const [isClaimedSuccessModal, setIsClaimedSuccessModal] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);
  const [isRotationCycleCompletedModal, setIsRotationCycleCompletedModal] =
    useState(false);
  const [isCounterTime, setCounterTime] = useState(true);
  const [isLeaveLoading, setIsLeaveLoading] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isParticipationModalOpen, setIsParticipationModalOpen] =
    useState(false);
  const [isShareRiskPoolSuccessModal, setIsShareRiskPoolSuccessModal] =
    useState(false);
  const [isStartPotModal, setIsStartPotModal] = useState(false);
  const [isLeavePotModal, setIsLeavePotModal] = useState(false);
  const [isSelectWinner, setIsSelectWinner] = useState(false);
  const [isStartPotLoading, setIsStartPotLoading] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [potDetails, setPotDetails] = useState<PotDataDetails | null>(null);
  const [potShareData, setPotShareData] = useState<PotDataDetails | null>(null);
  // const [joinedParticipants, setJoinedParticipants] = useState<any>(null);
  const [potDetailsData, setPotDetailsData] = useState<PotDataDetails | null>(
    null
  );

  const [winnerData, setWinnerData] = useState<any>(null);

  const [completed, setCompleted] = useState(false);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);

  const [isBorderTop, setIsBorderTop] = useState(false);
  // const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isRestartLoading, setIsRestartLoading] = useState(false);
  const [isWithdrawSuccessModal, setIsWithdrawSuccessModal] = useState(false);
  useEffect(() => {
    if (borderTop === true) {
      setIsBorderTop(true);
    }
  }, [chatListData]);

  useEffect(() => {
    setChatListData(chatDataProps);
    dispatch(setIsPotDetailLoading(true));
    if (chatDataProps) {
      dispatch(setIsPotDetailLoading(false));
    }
  }, [chatDataProps]);

  // useEffect(() => {
  //   if (!isSocketInitialized.current) {
  //     const newSocket = io(SOCKET_URI, {
  //       auth: { token: authToken },
  //     });
  //     setSocket(newSocket);
  //     isSocketInitialized.current = true;

  //     newSocket.on("connect", function () {
  //       // newSocket.emit(
  //       //   "joinChat",
  //       //   { chatId: chatDataProps?._id },
  //       //   (response: any) => {
  //       //     console.log("Join chat emit response:", response);
  //       //   }
  //       // );

  //       // participantJoinedFunc(chatDataProps?._id);

  //       // newSocket.on("message", (data: Message) => {
  //       //   console.log("message===>", data);
  //       //   setMessages((prevMessages) => [...prevMessages, data]);
  //       // });

  //       // newSocket.on("newUserJoined", (data: any) => {
  //       //   console.log("newUserJoined===>", data);
  //       // });

  //       // Listen for the "participantJoined" event
  //       // newSocket.on("participantJoined", (data: any) => {
  //       //   console.log("Participant joinedddddddddddddd:", data);
  //       //   setParticipantDetail(data);
  //       //   console.log(participantDetail, "is participants detail");
  //       // });
  //       newSocket.on("winnerSelected", (data: any) => {
  //         // setParticipantDetail(data);
  //         // console.log(participantDetail, "is participants detail");
  //       });
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //       isSocketInitialized.current = false;
  //     }
  //   };
  // }, [socket]);

  // const currentPotCode = chatDataProps?.pot?.potCode;

  useEffect(() => {
    setOnParticipantJoined((data: any) => {
      dispatch(
        setPotDetail({
          ...data,
        })
      );

      (async () => {
        const currentPotCode = chatListData?.pot?.potCode;
        const responseList = await getJoinedParticipantListData(currentPotCode);
        const dataList = await responseList;
        setPotShareData(dataList);
      })();
      setChatListData((prev: any) => {
        return {
          ...prev,
          participants: [...prev?.participants],
        };
      });
    });
    setonWinnerSelected((data: any) => {
      let winnerSelectedData = {
        ...data,
        amountDeposited: 0,
      };

      if (
        winnerSelectedData?.isCompleted &&
        winnerSelectedData?.creator == userInfoData?.userId
      ) {
        setIsRotationCycleCompletedModal(true);
      }
      setWinnerData(winnerSelectedData);
      // setJoinedParticipants(winnerSelectedData);

      setIsSelectWinner(true);
      (async () => {
        await fetchPotDetails();
      })();
      // setPinnedMessage((prevPinnedMessages) => [...prevPinnedMessages, data]);
      // handleClosePinChat(data?._id);
    });
    setonLeavePot((data: any) => {
      if (data?.participants.includes(userInfoData?.userId)) {
        (async () => {
          await fetchJoinedParticipants();
          await fetchPotDetails();
        })();
      }
    });
    setOnRestartPot((data: any) => {
      (async () => {
        await fetchJoinedParticipants();
        dispatch(setIsRestarted(true));
      })();
    });
  }, [potDetails]);

  const fetchPotDetails = async () => {
    try {
      const currentPotCode = chatListData?.pot?.potCode;
      if (currentPotCode) {
        await dispatch(getPotDetail({ pot: currentPotCode }));
      }
    } catch (error: any) {
      console.error("Error fetching pot details:", error);
      if (error) {
        toast.error(error || "Error fetching pot details:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const fetchJoinedParticipants = async () => {
    try {
      const currentPotCode = chatListData?.pot?.potCode;
      if (currentPotCode) {
        const response = await dispatch(
          getJoinedParticipants({ potCode: currentPotCode })
        );
        const responseList = await getJoinedParticipantListData(currentPotCode);
        const dataList = await responseList;
        setPotDetailsData(response.payload);
        // setJoinedParticipants(data);
        setPotShareData(dataList);
        dispatch(setIsPotDetailLoading(false));
      }
    } catch (error) {
      dispatch(setIsPotDetailLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setIsPotDetailLoading(true));
    // if (isSelectWinner) {
    // }
    (async () => {
      await fetchJoinedParticipants();
      // await fetchPotDetails();
    })();
    setTimeout(() => setIsSelectWinner(false), 5000);

    // Clean up function if needed
    return () => {
      // Any cleanup code here
    };
  }, [chatListData, isSelectWinner]);

  useEffect(() => {
    if (isClaimedSuccessModal) {
      setTimeout(() => setIsClaimedSuccessModal(false), 5000);
    }
  }, [isClaimedSuccessModal]);

  const openViewParticipants = () => {
    setIsParticipationModalOpen(true);
  };

  const onCloseStartPotModal = () => {
    setIsStartPotModal(false);
  };

  const onCloseLeavePotModal = () => {
    dispatch(setIsLeavePotModalOpen(false));
  };

  const onCloseWinnerModal = () => {
    // getJoinedParticipants(currentPotCode);
    setIsSelectWinner(false);
  };

  const onCloseShareRiskPoolSuccessModal = () => {
    setIsShareRiskPoolSuccessModal(false);
  };

  const toggleTooltip = () => {
    setIsStartPotModal(true);
  };

  const openLeavePotModal = () => {
    // setIsLeavePotModal(true);
    dispatch(setIsLeavePotModalOpen(true));
  };

  const onCloseClaimedSuccessModal = () => {
    setIsClaimedSuccessModal(false);
  };

  const onCloseDepositModal = () => {
    setIsDepositModal(false);
  };

  const onCloseDepositSuccessModal = () => {
    // getJoinedParticipants(currentPotCode);
    setIsDepositSuccessModal(false);
  };

  const targetDate = new Date(potDetails?.lastWinnerSelectedDate);
  // console.log('lastWinnerSelectedDate:', potDetails?.lastWinnerSelectedDate);
  // console.log('Initial target date:', targetDate);

  const secondsToMilliseconds = (seconds: number) => {
    return seconds * 1000;
  };

  const rotationCycleMilliseconds = secondsToMilliseconds(
    potDetails?.rotationCycle
  );
  // console.log('Rotation cycle in milliseconds:', rotationCycleMilliseconds);

  targetDate.setTime(targetDate.getTime() + rotationCycleMilliseconds);
  // console.log('Next winner selection date:', targetDate);
  // console.log('current:', new Date());

  // Parse the date strings into Date objects
  const date1 = new Date(targetDate);
  const date2 = new Date();

  const isTimeCompleted = date1 < date2;

  let totalJoinedParticipants = potDetails?.currentRoundParticipants.length;
  let totalParticipants =
    potDetails != null ? potDetails?.noOfParticipants : "0";

  let joinedPotSize =
    potDetails?.currentRoundParticipants?.length * potDetails?.amount;
  let totalPotSize = potDetails?.noOfParticipants * potDetails?.amount;

  let participantsCount = potDetails?.currentRoundParticipants?.length;
  // if (potDetails?.currentRound > 1) {
  //   totalParticipants = totalJoinedParticipants;
  //   totalPotSize = joinedPotSize;
  //   // setCompleted(true);
  // }

  const onDeposit = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsDepositModal(false);

      return;
    }

    dispatch(setIsDepositedLoading(true));
    setIsDepositLoading(true);
    setIsDepositModal(false);
    try {
      const potId = chatListData?.pot?.potId;
      const potCode = chatListData?.pot?.potCode;
      dispatch(
        setIsDeposited({
          isDeposited: true,
          data: null,
          isSyncingDeposit: true,
          hasSynced: false,
        })
      );

      if (potId && potCode) {
        try {
          await approveUsdbTx({
            amount: chatListData?.pot?.amount?.toString(),
          });
        } catch (error: any) {
          dispatch(setDepositError(error?.message || "Failed to join a pot"));
          console.log("Error during USDB approval:", error?.message);
          if (error?.message.includes(Insufficient_Fund_Error)) {
            onCloseDepositModal();
            setIsInsufficientFundModal(true);
            setIsDepositModal(false);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }

        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: true,
            currentRound: potDetails?.currentRound,
            potCode: potCode,
          }).catch((error) => {
            dispatch(setDepositError(error?.message || "Failed to deposit"));
            toast.error(error?.message || "Failed to deposit", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
          let attempt = 0;
          let getPotDetailResponse;
          let getPotDetailInResponse;
          let isError;
          // let potRound=potDetail

          do {
            // if(potDetail){

            attempt++;
            getPotDetailResponse = await dispatch(
              getJoinedParticipants({
                potCode,
              })
            );
            isError = getPotDetailResponse.payload.isError;
            getPotDetailInResponse = getPotDetailResponse.payload;
            if (
              getPotDetailInResponse?.round == joinedParticipants.round ||
              getPotDetailResponse.meta.requestStatus !== "fulfilled"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            (attempt < 60 &&
              getPotDetailInResponse?.round == joinedParticipants.round &&
              !isError) ||
            getPotDetailResponse.meta.requestStatus !== "fulfilled"
          );
          if (!isError) {
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            let joinPotResponse;
            let attemptDeposit = 0;
            do {
              attemptDeposit++;
              joinPotResponse = await dispatch(
                checkSyncDetails({ potId, eventType: "participantUpdated" })
              );

              if (joinPotResponse.meta.requestStatus !== "fulfilled") {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            } while (
              joinPotResponse.meta.requestStatus !== "fulfilled" &&
              attemptDeposit < 60
            );
            if (joinPotResponse.meta.requestStatus === "fulfilled") {
              dispatch(setIsDepositedLoading(false));
              dispatch(
                setIsDeposited({
                  isDeposited: false,
                  data: null,
                  isSyncingDeposit: false,
                  hasSynced:
                    isError &&
                    getPotDetailInResponse?.round == joinedParticipants.round
                      ? false
                      : true,
                })
              );
            }
          }
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          dispatch(
            setDepositError(
              getJoinPotSignatureResponse?.payload?.message ||
                "Failed to join a pot"
            )
          );
          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      dispatch(setDepositError(error?.message || "Failed to deposit"));
      toast.error(error?.message || "Failed to deposit", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to deposit:", error);
    } finally {
      dispatch(setIsDepositedLoading(false));
      setIsDepositLoading(false);
    }
  };

  const onLeave = async () => {
    try {
      if (
        !completed &&
        joinedParticipants?.hasWon &&
        joinedParticipants?.totalClaimed === 0
      ) {
        toast.warn("Please claim the funds first", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      dispatch(setIsLeavePotLoading(true));
      setIsLeaveLoading(true);
      dispatch(setIsLeavePotModalOpen(false));
      const potCode = chatListData?.pot?.potCode;
      if (chatListData?.pot?.potId && account) {
        if (joinedParticipants?.amountDeposited > 0) {
          await withdrawFromPotTx({
            potId: chatListData?.pot?.potId,
            participantAddress: account,
            isWinner: joinedParticipants?.hasWon,
            potCode,
          });
        }

        const leavePotResponse = await dispatch(
          leavePot({
            potCode,
          })
        );
        if (leavePotResponse.meta.requestStatus === "fulfilled") {
          // dispatch(
          //   setJoinedParticipants({
          //     amountWithdrawn: 1,
          //   })
          // );
          // dispatch(
          //   setIsLeavePot({
          //     isLeavePot: true,
          //   })
          // );
          // // navigate("/dashboard");
          // dispatch(removeChat(chatListData?._id));
          // await fetchJoinedParticipants();
          // // navigate(`/dashboard/${chatData[1]._id}`);
          // navigate(`/dashboard`);
          // dispatch(setIsArchived(true));
        }
        if (leavePotResponse.meta.requestStatus === "rejected") {
          dispatch(
            setLeavePotError(
              leavePotResponse?.payload?.message || "Failed to Leave Pot"
            )
          );
          toast.error(
            leavePotResponse?.payload?.message || "Failed to Leave Pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      dispatch(setLeavePotError(error?.message || "Failed to Leave Pot"));
      toast.error(error?.message || "Failed to Leave Pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      dispatch(setIsLeavePotLoading(false));
      setIsLeaveLoading(false);
    }
  };
  const handleOnConfirmStartPot = async () => {
    try {
      setIsStartPotLoading(true);
      dispatch(setIsSelectWinnerLoading(true));
      setIsStartPotModal(false);
      const getRandomnessResponse = await dispatch(
        getRandomness({ potCode: chatListData?.pot?.potCode })
      );
      if (getRandomnessResponse.meta.requestStatus === "fulfilled") {
        let isSelectedWinner = true;
        dispatch(
          setIsSelectedWinner({
            isSelectedWinner: true,
            data: null,
            hasSynced: false,
          })
        );
        let firstCurrentRound = potDetails.currentRound;
        let attempt = 0;
        let getPotDetailResponse;
        let getPotDetailInResponse;
        let isError;
        const { userRandom, commitment, providerRandom } =
          getRandomnessResponse.payload;
        rotateLiquidityTx({
          potId: chatListData?.pot?.potId,
          userCommitment: commitment,
          userRandom,
          providerRandom,
        }).catch((error) => {
          dispatch(
            setSelectWinnerError(error?.message || "Failed to rotate liquidity")
          );
          toast.error(error?.message || "Failed to rotate liquidity", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        do {
          attempt++;
          if (firstCurrentRound) {
            getPotDetailResponse = await dispatch(
              getPotDetail({
                pot: potDetails?.potCode,
              })
            );
            isError = getPotDetailResponse.payload.isError;
            getPotDetailInResponse = getPotDetailResponse.payload;
            if (
              getPotDetailInResponse.currentRound <= firstCurrentRound ||
              getPotDetailResponse.meta.requestStatus !== "fulfilled"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          }
        } while (
          (attempt < 60 &&
            getPotDetailInResponse.currentRound <= firstCurrentRound &&
            !isError) ||
          getPotDetailResponse?.meta?.requestStatus !== "fulfilled"
        );
        if (getPotDetailInResponse && !isError) {
          // const rotationCycleMilliseconds =
          //   getPotDetailInResponse?.rotationCycle * 1000;

          // targetDate.setTime(
          //   targetDate.getTime() + rotationCycleMilliseconds
          // );
          // const date1 = new Date(targetDate);
          // const date2 = new Date();
          // const isTimeCompleted = date1 < date2;
          let rotateLiquidityResponse;
          let conditionMet = false;
          let attempt = 0;
          do {
            attempt++;
            rotateLiquidityResponse = await dispatch(
              checkSyncDetails({
                lastWinnerSelectedDate: true,
                potId: getPotDetailInResponse?.potId,
                eventType: "winnerSelected",
              })
            );

            if (rotateLiquidityResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 1000));
            } else {
              if (isTimeCompleted) {
                conditionMet = true;
              } else {
                conditionMet = false;
                await new Promise((resolve) => setTimeout(resolve, 1000));
              }
            }
          } while (
            !conditionMet &&
            rotateLiquidityResponse.meta.requestStatus != "fulfilled" &&
            attempt < 60
          );
          if (rotateLiquidityResponse.meta.requestStatus === "fulfilled") {
            getPotDetailResponse = await dispatch(
              getJoinedParticipants({ potCode: potDetails.potCode })
            );
            setIsStartPotModal(false);
            dispatch(setIsSelectWinnerLoading(false));
            dispatch(
              setIsSelectedWinner({
                isSelectedWinner: false,
                data: null,
                hasSynced:
                  isError &&
                  getPotDetailInResponse.currentRound <= firstCurrentRound
                    ? false
                    : true,
              })
            );
          }
        }
      }
      if (getRandomnessResponse.meta.requestStatus === "rejected") {
        dispatch(
          setSelectWinnerError(
            getRandomnessResponse?.payload?.message || "Failed to select winner"
          )
        );
        toast.error(
          getRandomnessResponse?.payload?.message || "Failed to select winner",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      dispatch(
        setSelectWinnerError(error?.message || "Failed to rotate liquidity")
      );
      toast.error(error?.message || "Failed to rotate liquidity", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to rotate liquidity:", error);
    } finally {
      setIsStartPotLoading(false);
      dispatch(setIsSelectWinnerLoading(false));
    }
    // setIsStartPotModal(false);
  };

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    const timerText = `${days}D : ${hours}H : ${minutes}M : ${seconds}Sec`;
    setCounterTime(completed);

    return <CounterBtn completed={completed}>{timerText}</CounterBtn>;
  };

  // const targetDate: Date = new Date(potDetails?.lastWinnerSelectedDate);
  // targetDate.setDate(
  //   targetDate.getDate() + secondsToDays(potDetails?.rotationCycle)
  // );

  // console.log('current:', new Date());
  // console.log('lastWinnerSelectedDate:', potDetails);

  const handleMouseEnter = () => {
    setTooltipActive(true);
  };

  const handleMouseLeave = () => {
    setTooltipActive(false);
  };

  useEffect(() => {
    dispatch(setIsPotDetailLoading(true));
    if (
      // potDetails?.currentRoundParticipants.length * potDetails?.amount ==
      //   potDetails?.noOfParticipants * potDetails?.amount &&
      isTimeCompleted &&
      joinedParticipants?.round !== potDetails?.currentRound
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
      dispatch(setIsPotDetailLoading(false));
    }
  }, [chatListData, potDetails, joinedParticipants]);

  useEffect(() => {
    if (
      // potDetails?.currentRoundParticipants.length * potDetails?.amount ==
      //   potDetails?.noOfParticipants * potDetails?.amount &&
      isTimeCompleted &&
      joinedParticipants?.amountDeposited !== 0
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    // if (
    //   isTimeCompleted
    //   // potDetails?.currentRound > 1
    //   // joinedParticipants?.amountDeposited > 0
    // ) {
    //   setCompleted(true);
    // }
  }, [date1, date2]);

  useEffect(() => {
    onCloseRotationCycleCompletedModal();
    const handleClickOutside = (event: MouseEvent) => {
      if (
        startWinnerRef.current &&
        !startWinnerRef.current.contains(event.target as Node)
      ) {
        setIsStartPotModal(false);
      }
    };
    const handleInviteClickOutside = (event: MouseEvent) => {
      if (
        inviteFriendRef.current &&
        !inviteFriendRef.current.contains(event.target as Node)
      ) {
        setInviteModalOpen(false);
      }
    };
    const handleClickOutsideLeavePotModal = (event: MouseEvent) => {
      if (
        leavePotRef.current &&
        !leavePotRef.current.contains(event.target as Node)
      ) {
        dispatch(setIsLeavePotModalOpen(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleInviteClickOutside);
    document.addEventListener("mousedown", handleClickOutsideLeavePotModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleInviteClickOutside);
      document.removeEventListener(
        "mousedown",
        handleClickOutsideLeavePotModal
      );
    };
  }, []);

  const onCloseWithdrawSuccessModal = () => {
    setIsWithdrawSuccessModal(false);
  };

  const onClaim = async () => {
    try {
      dispatch(setIsClaimLoading(true));
      const potCode = chatListData?.pot?.potCode;
      // setIsClaimLoading(true);
      dispatch(
        setIsClaimedFunds({
          isClaimed: true,
          data: null,
          hasSynced: false,
        })
      );
      if (chatListData?.pot?.potId) {
        claimRewardsTx({
          potId: chatListData?.pot?.potId,
          potCode,
        }).catch((error) => {
          dispatch(
            setClaimFundError(error?.message || "Failed to claim funds")
          );
          toast.error(error?.message || "Failed to claim funds", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        let firstResponse = await dispatch(
          getJoinedParticipants({ potCode: potDetails?.potCode })
        );
        let attempt = 0;
        let getPotDetailResponse;
        let getPotDetailInResponse;
        let isError;
        // let potRound=potDetail

        do {
          // if(potDetail){

          attempt++;
          getPotDetailResponse = await dispatch(
            getJoinedParticipants({
              potCode: potDetails?.potCode,
            })
          );
          isError = getPotDetailResponse.payload.isError;
          getPotDetailInResponse = getPotDetailResponse.payload;

          if (
            (getPotDetailInResponse?.hasWon &&
              getPotDetailInResponse?.totalClaimed == 0) ||
            getPotDetailResponse.meta.requestStatus !== "fulfilled"
          ) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        } while (
          (attempt < 60 &&
            getPotDetailInResponse?.hasWon &&
            getPotDetailInResponse?.totalClaimed == 0 &&
            !isError) ||
          getPotDetailResponse.meta.requestStatus !== "fulfilled"
        );
        if (!isError) {
          let claimFundsResponse;
          let claimAttempt = 0;
          do {
            claimAttempt++;
            claimFundsResponse = await dispatch(
              checkSyncDetails({
                potId: chatListData?.pot?.potId,
                eventType: "claim",
              })
            );

            if (claimFundsResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            claimFundsResponse.meta.requestStatus !== "fulfilled" &&
            claimAttempt < 60
          );
          if (claimFundsResponse.meta.requestStatus === "fulfilled") {
            dispatch(
              setJoinedParticipants({
                totalClaimed: claimFundsResponse.payload.claimedAmount,
              })
            );
            dispatch(setIsClaimLoading(false));
            dispatch(
              setIsClaimedFunds({
                isClaimed: false,
                data: null,
                hasSynced:
                  isError &&
                  getPotDetailInResponse?.hasWon &&
                  getPotDetailInResponse?.totalClaimed == 0
                    ? false
                    : true,
              })
            );
            setIsClaimedSuccessModal(true);
            // await fetchJoinedParticipants();
          }
        }
      }
    } catch (error: any) {
      dispatch(setClaimFundError(error?.message || "Failed to claim funds"));
      toast.error(error?.message || "Failed to claim funds", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      dispatch(setIsClaimLoading(false));
      // setIsClaimLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const onWithdraw = async () => {
    try {
      setIsWithdrawLoading(true);
      const potCode = chatListData?.pot?.potCode;
      if (chatListData?.pot?.potId && account) {
        const response = await withdrawFromPotTx({
          potId: chatListData?.pot?.potId,
          participantAddress: account,
          isWinner: joinedParticipants?.hasWon,
          potCode,
        });

        const leavePotResponse = await dispatch(
          leavePot({
            potCode,
          })
        );
        if (leavePotResponse.meta.requestStatus === "fulfilled") {
          dispatch(
            setJoinedParticipants({
              amountWithdrawn: 1,
            })
          );
          // setJoinedParticipants((prevData: any) => {
          //   return {
          //     ...prevData,
          //     amountWithdrawn: 1,
          //   };
          // });
          await fetchJoinedParticipants();
          setIsWithdrawSuccessModal(true);
        }
        if (leavePotResponse.meta.requestStatus === "rejected") {
          toast.error(
            leavePotResponse?.payload?.message || "Failed to winthdraw funds",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to withdraw funds", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsWithdrawLoading(false);
    }
    // setIsStartPotModal(false);
  };

  const onCloseRotationCycleCompletedModal = () => {
    setIsRotationCycleCompletedModal(false);
  };
  const onArchive = async () => {
    try {
      setIsArchiveLoading(true);
      const archiveResponse = await dispatch(
        archiveApi({
          potId: chatListData?.pot.potId,
          groupChatId: chatListData?._id,
        })
      );
      if (archiveResponse.meta.requestStatus === "fulfilled") {
        setIsRotationCycleCompletedModal(false);
        toast.success(
          archiveResponse?.payload?.message || "Successfully Archived",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(setIsArchived(true));
      }
      if (archiveResponse.meta.requestStatus === "rejected") {
        toast.error(
          archiveResponse?.payload?.message || "Failed to archive the pot",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to archive the pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsArchiveLoading(false);
    }
  };
  const onRestart = async () => {
    try {
      setIsRestartLoading(true);
      if (
        chatListData?.pot?.amount &&
        chatListData?.pot?.noOfParticipants &&
        chatListData?.pot?.name &&
        chatListData.pot.rotationCycle
      ) {
        const noOfParticipants = chatListData.pot.noOfParticipants;
        const potAmount = chatListData.pot.amount;
        const potName = chatListData?.pot?.name;
        const rotationCycle = chatListData.pot.rotationCycle;
        const currentPotId = chatListData?.pot?.potId;
        const getCommitmentResponse = await dispatch(
          getCommitment({ potId: currentPotId })
        );
        if (getCommitmentResponse.meta.requestStatus === "fulfilled") {
          try {
            await approveUsdbTx({ amount: potAmount.toString() });
          } catch (error: any) {
            if (error?.message.includes(Insufficient_Fund_Error)) {
              setIsInsufficientFundModal(true);
              throw new Error(error?.message);
            }
            throw new Error(error?.message || "Failed to approve USDB");
          }
          const { commitment, randomNumber } = getCommitmentResponse.payload;
          const createPotTxResponse = await createPotTx({
            rotationCycleInSeconds: +rotationCycle,
            interestDenominator: 1000,
            interestNumerator: 0,
            numParticipants: +noOfParticipants,
            amount: potAmount.toString(),
            potName: potName,
            userCommitment: commitment,
          });

          await new Promise((resolve) => setTimeout(resolve, 2000));

          let attempt = 0;
          let restartPotResponse;

          do {
            attempt++;
            restartPotResponse = await dispatch(
              checkSyncDetails({
                potId: createPotTxResponse?.potId,
                eventType: "potRestarted",
              })
            );

            if (restartPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            restartPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );

          if (restartPotResponse.meta.requestStatus === "fulfilled") {
            onCloseRotationCycleCompletedModal();
            toast.success(
              restartPotResponse?.payload?.message || "Successfully Restarted",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
          if (restartPotResponse.meta.requestStatus === "rejected") {
            toast.error(
              restartPotResponse?.payload?.message || "Failed to restart a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to restart the pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsRestartLoading(false);
    }
  };

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  const scrollToButton = () => {
    selectWinnerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  scrollToButton();

  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);
  useEffect(() => {
    if (isDepositSuccessModal) {
      setTimeout(() => setIsDepositSuccessModal(false), 5000);
    }
  }, [isDepositSuccessModal]);
  return (
    <Container>
      <>
        {/* <MobileHeader>
          <MobileTitle>
            <ArrowBack style={{ marginRight: 10 }} />
            Pot details
          </MobileTitle>
          <VerticalThreeDotsIcon />
        </MobileHeader> */}
        <TopSection>
          <PotHeader>
            <DualAvatar>
              <BoxOne>
                {potDetails?.currentRoundParticipants.length >= 1 &&
                potDetails?.currentRoundParticipants[0]?.user
                  ?.profilePicture ? (
                  <img
                    src={
                      potDetails?.currentRoundParticipants[0]?.user
                        ?.profilePicture
                    }
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100px",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar />
                )}
              </BoxOne>
              <BoxTwo>
                {potDetails?.currentRoundParticipants.length >= 2 &&
                potDetails?.currentRoundParticipants[1]?.user
                  ?.profilePicture ? (
                  <img
                    src={
                      potDetails?.currentRoundParticipants[1]?.user
                        ?.profilePicture
                    }
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100px",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar />
                )}
              </BoxTwo>
            </DualAvatar>
            <MobileDualAvatar>
              <BoxOne>
                {potDetails?.currentRoundParticipants.length >= 1 &&
                potDetails?.currentRoundParticipants[0]?.user
                  ?.profilePicture ? (
                  <img
                    src={
                      potDetails?.currentRoundParticipants[0]?.user
                        ?.profilePicture
                    }
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "100px",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar />
                )}
              </BoxOne>
              <BoxTwo>
                {potDetails?.currentRoundParticipants?.length >= 2 &&
                potDetails?.currentRoundParticipants[1]?.user
                  ?.profilePicture ? (
                  <img
                    src={
                      potDetails?.currentRoundParticipants[1]?.user
                        ?.profilePicture
                    }
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = defaultImage;
                    }}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "100px",
                    }}
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar />
                )}
              </BoxTwo>
            </MobileDualAvatar>
            <BoawaahPot>{potDetails?.name || ""}</BoawaahPot>
          </PotHeader>
          <Section>
            {/* <Background>
                            <NotificationRow>
                                <IconTextSpan>
                                    <NotificationIcon/>
                                    <PrimaryText>Notification</PrimaryText>
                                </IconTextSpan>
                                <ToggleButton onToggle={handleToggle}/>
                            </NotificationRow>
                        </Background> */}
            <BackgroundContent>
              {
                // loading && potDetails === null ? (
                //   <SkeletonWrapper>
                //     <Skeleton />
                //     <Skeleton />
                //     <Skeleton />
                //   </SkeletonWrapper>
                // ) :
                potDetails != null ? (
                  <BackgroundContent>
                    {!(
                      !completed &&
                      joinedParticipants?.hasWon &&
                      joinedParticipants?.totalClaimed === 0 &&
                      !(chatListData && chatListData?.pot?.archivedAt)
                    ) &&
                      !completed &&
                      joinedParticipants?.amountDeposited === 0 &&
                      !(chatListData && chatListData?.pot?.archivedAt) &&
                      !completed && (
                        <DepositAlertBox>
                          <AlertIcon />
                          <AlertTitle>
                            Please make a Deposit for Round{" "}
                            {potDetails != null
                              ? potDetails?.currentRound
                              : " Unknown"}
                          </AlertTitle>
                        </DepositAlertBox>
                      )}
                    <BackgroundInvite>
                      <NotificationRow>
                        <IconTextSpan>
                          <PersonAddDropdown />
                          <PrimaryText>Invite Member</PrimaryText>
                        </IconTextSpan>
                        {/* <InviteButton onClick={() => setInviteModalOpen(true)}>
                        Invite
                      </InviteButton> */}
                        <InviteButton
                          onClick={() => setInviteModalOpen(true)}
                          outlinedText={"Invite"}
                        />
                      </NotificationRow>
                    </BackgroundInvite>
                    <Background>
                      <Cols>
                        <TwoLineRow>
                          <SecondaryText>participants</SecondaryText>
                          <ParticipantPrimaryNumber>
                            <PrimaryNumber>
                              {totalJoinedParticipants}/
                              {totalJoinedParticipants}
                              {/* {potDetails?.currentRoundParticipants.length}
                            /
                            {potDetails != null
                              ? potDetails?.noOfParticipants
                              : "0"} */}
                            </PrimaryNumber>
                            {/* <PrimaryNumber>15/25</PrimaryNumber> */}
                            <RightText onClick={openViewParticipants}>
                              {participantsCount >= 1 &&
                              potDetails?.currentRoundParticipants[0]?.user
                                ?.profilePicture ? (
                                <img
                                  src={
                                    potDetails?.currentRoundParticipants[0]
                                      ?.user?.profilePicture
                                  }
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultImage;
                                  }}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "-10px",
                                    marginTop: "3px",
                                    borderRadius: "100px",
                                    border: "solid 1px white",
                                  }}
                                  alt="Avatar"
                                />
                              ) : (
                                participantsCount >= 1 && <ParticipantIcon1 />
                              )}
                              {participantsCount >= 2 &&
                              potDetails?.currentRoundParticipants[1]?.user
                                ?.profilePicture ? (
                                <img
                                  src={
                                    potDetails?.currentRoundParticipants[1]
                                      ?.user?.profilePicture
                                  }
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultImage;
                                  }}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "-10px",
                                    marginTop: "3px",
                                    borderRadius: "100px",
                                    border: "solid 1px white",
                                  }}
                                  alt="Avatar"
                                />
                              ) : (
                                participantsCount >= 2 && <ParticipantIcon2 />
                              )}
                              {participantsCount >= 3 &&
                              potDetails?.currentRoundParticipants[2]?.user
                                ?.profilePicture ? (
                                <img
                                  src={
                                    potDetails?.currentRoundParticipants[2]
                                      ?.user?.profilePicture
                                  }
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultImage;
                                  }}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "-10px",
                                    marginTop: "3px",
                                    borderRadius: "100px",
                                    border: "solid 1px white",
                                  }}
                                  alt="Avatar"
                                />
                              ) : (
                                participantsCount >= 3 && <ParticipantIcon3 />
                              )}
                              {participantsCount >= 4 &&
                              potDetails?.currentRoundParticipants[3]?.user
                                ?.profilePicture ? (
                                <img
                                  src={
                                    potDetails?.currentRoundParticipants[3]
                                      ?.user?.profilePicture
                                  }
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultImage;
                                  }}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "-10px",
                                    marginTop: "3px",
                                    borderRadius: "100px",
                                    border: "solid 1px white",
                                  }}
                                  alt="Avatar"
                                />
                              ) : (
                                participantsCount >= 4 && <ParticipantIcon1 />
                              )}
                              {participantsCount > 4 && <ParticipantIcon4 />}
                            </RightText>
                          </ParticipantPrimaryNumber>
                        </TwoLineRow>
                        <TwoLineRow>
                          <SecondaryText>Pot Size</SecondaryText>
                          <DollarPrimaryNumber>
                            {/* {potDetails?.currentRoundParticipants?.length *
                            potDetails?.amount}
                          /{potDetails?.noOfParticipants * potDetails?.amount} */}
                            {joinedPotSize}/{totalPotSize}
                            <img
                              src={NewCoinImg}
                              alt={"NewCoinImg"}
                              style={{ width: "1.5em", marginLeft: "0.5em" }}
                            />
                          </DollarPrimaryNumber>
                        </TwoLineRow>
                        {/*<TwoLineRow>*/}
                        {/*  <SecondaryText>Risk Rate</SecondaryText>*/}
                        {/*  <PrimaryNumber>*/}
                        {/*    {potDetails?.riskRate || "Unknown"}*/}
                        {/*  </PrimaryNumber>*/}
                        {/*</TwoLineRow>*/}
                        {/*{chatListData?.pot?.amount && (*/}
                        {/*<TwoLineRow>*/}
                        {/*    <SecondaryText>Risk Pool Amount</SecondaryText>*/}
                        {/*    <DollarPrimaryNumber>*/}
                        {/*        {weiToEther(potDetails?.amount)}*/}
                        {/*        <img*/}
                        {/*            src={NewCoinImg}*/}
                        {/*            alt={"NewCoinImg"}*/}
                        {/*            style={{width: "1.5em", marginLeft: "0.5em"}}*/}
                        {/*        />*/}
                        {/*    </DollarPrimaryNumber>*/}
                        {/*</TwoLineRow>*/}
                        {/*)}*/}

                        {potDetails?.rotationCycle && (
                          <TwoLineRow>
                            <SecondaryText>Rotation Cycle</SecondaryText>
                            <PrimaryNumber>
                              {describeDuration(potDetails?.rotationCycle)}
                            </PrimaryNumber>
                            {/* <PrimaryNumber>Bi-weekly</PrimaryNumber> */}
                          </TwoLineRow>
                        )}

                        {/*<TwoLineRow>*/}
                        {/*  <SecondaryText>Created at</SecondaryText>*/}
                        {/*  <PrimaryNumber>*/}
                        {/*    {potDetails != null*/}
                        {/*      ? dateFormatVal(potDetails?.createdAt)*/}
                        {/*      : "Unknown"}*/}
                        {/*  </PrimaryNumber>*/}
                        {/*</TwoLineRow>*/}
                        <TwoLineRow>
                          <SecondaryText>Currently Running</SecondaryText>
                          <PrimaryNumber>
                            Round{" "}
                            {potDetails != null
                              ? potDetails?.currentRound
                              : " Unknown"}
                          </PrimaryNumber>
                        </TwoLineRow>

                        <TwoLineRow>
                          <SecondaryText>Currently Running Cycle</SecondaryText>
                          <PrimaryNumber>
                            Cycle{" "}
                            {potDetailsData != null
                              ? potDetailsData?.pot?.currentCycle
                              : " Unknown"}
                          </PrimaryNumber>
                        </TwoLineRow>

                        <TwoLineRow>
                          {/* {joinedParticipants?.amountDeposited === 0 && ( */}
                          <NextDepositText completed={completed}>
                            Next deposit Until
                          </NextDepositText>
                          {/* )} */}

                          <Countdown date={targetDate} renderer={renderer} />
                        </TwoLineRow>
                        {/* {joinedParticipants?.amountDeposited !== 0 &&
                      joinedParticipants?.amountWithdrawn === 0 ? (
                        <ButtonRow>
                          <LeaveAPotButton onClick={openLeavePotModal}>
                            <span>LEAVE</span>
                          </LeaveAPotButton>
                        </ButtonRow>
                      ) : (
                        <></>
                      )} */}
                      </Cols>

                      {/* <NotificationRow>
                                            <DepositPotButton onClick={() => setIsDepositModal(true)}
                                                              completed={!completed}>
                                                Deposit
                                            </DepositPotButton>
                                        </NotificationRow> */}
                    </Background>
                  </BackgroundContent>
                ) : (
                  <div style={{ color: "white", textAlign: "center" }}>
                    No chat data available
                  </div>
                )
              }
            </BackgroundContent>
          </Section>
        </TopSection>

        {/* {completed && ( */}
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!(chatListData && chatListData?.pot?.archivedAt) && (
            <ButtomSection isbordertop={isBorderTop}>
              {/* {completed && ( */}

              {completed ? (
                <StartPotButton
                  ref={selectWinnerRef}
                  onClick={toggleTooltip}
                  completed={completed}
                >
                  Select Winner
                </StartPotButton>
              ) : !completed &&
                joinedParticipants?.hasWon &&
                joinedParticipants?.totalClaimed === 0 ? (
                <ClaimButton onClick={onClaim}>
                  {isClaimLoading ? <Loader /> : "Claim"}
                </ClaimButton>
              ) : !completed && joinedParticipants?.amountDeposited === 0 ? (
                <DepositPotTextButton onClick={() => setIsDepositModal(true)}>
                  Make Deposit
                </DepositPotTextButton>
              ) : (
                <></>
              )}

              {/* {!completed &&
              joinedParticipants?.hasWon &&
              joinedParticipants?.totalClaimed !== 0 &&
              joinedParticipants?.amountDeposited === 0 && (
                <DepositPotTextButton onClick={() => setIsDepositModal(true)}>
                  <BlinkingTextWrapper>Make Deposit</BlinkingTextWrapper>
                </DepositPotTextButton>
              )} */}
            </ButtomSection>
          )}
        </div>
        {/* )} */}
        {/* {completed && (
          <TooltipContainer>
            <TooltipContent>
              {potDetails?.creatorData?.isAdmin
                ? `Only POT Creater can select the winner`
                : `Please wait for everyone to deposit in order to select winner.`}
            </TooltipContent>
          </TooltipContainer>
        )} */}

        {isInviteModalOpen && (
          <InviteFriendPot
            onClose={() => setInviteModalOpen(false)}
            onConfirm={() => {
              /* handle confirmation */
            }}
            chatDataProps={chatListData}
            isOpen={isInviteModalOpen}
            inviteFriendRef={inviteFriendRef}
            joinedParticipants={joinedParticipants}
          />
        )}
        {isStartPotModal && (
          <StartPotModal
            isLoading={isStartPotLoading}
            onClose={onCloseStartPotModal}
            onConfirm={() => handleOnConfirmStartPot()}
            chatDataProps={chatListData}
            startWinnerRef={startWinnerRef}
          />
        )}
        {/* {isLeavePotModalOpen && (
          <LeaveModal
            isLoading={isLeaveLoading}
            onClose={onCloseLeavePotModal}
            onConfirm={() => onLeave()}
            chatDataProps={chatListData}
            leavePotRef={leavePotRef}
          />
        )} */}
        {/* {isRotationCycleCompletedModal && (
          <RotationCycleModal
            onClose={onCloseRotationCycleCompletedModal}
            onRestart={onRestart}
            onArchive={onArchive}
            isArchiveLoading={isArchiveLoading}
            isRestartLoading={isRestartLoading}
          />
        )} */}

        {isSelectWinner && (
          <SelectWinner
            onClose={onCloseWinnerModal}
            currentRoundWinnerData={winnerData}
          />
        )}

        {isShareRiskPoolSuccessModal && (
          <DistributedRiskPoolSuccessModal
            onClose={onCloseShareRiskPoolSuccessModal}
          />
        )}

        {isParticipationModalOpen && (
          <ParticipationModal
            onClose={() => setIsParticipationModalOpen(false)}
            chatListData={chatListData}
            potDetails={potDetails}
            potDetailsData={potShareData}
          />
        )}

        {isClaimedSuccessModal && (
          <ClaimSuccessModal
            onClose={onCloseClaimedSuccessModal}
            statusData={joinedParticipants}
          />
        )}

        {isDepositModal && (
          <DepositModal
            onClose={onCloseDepositModal}
            onConfirm={onDeposit}
            isLoading={isDepositLoading}
            potDetail={potDetails}
          />
        )}

        {isDepositSuccessModal && (
          <DepositSuccessModal
            statusData={chatListData}
            onClose={onCloseDepositSuccessModal}
          />
        )}
        {isInsufficientFundModal && (
          <InsufficientFundModal onClose={onCloseInsifficientModal} />
        )}
      </>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  /* background: #13160b; */
  border: 1px solid #373636;
  /* Custom scrollbar */

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  @media (min-width: 475px) {
    height: 88vh;
  }
  @media (max-width: 475px) {
    height: auto;
    width: 100%;
  }
  @media (max-width: 475px) and (max-height: 635px) {
    height: 500px;
  }
`;

const MobileHeader = styled.div`
  display: none;
  min-width: 305px;
  overflow-x: hidden;
  height: 64px;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  background: #24281b;
  /* sm */
  @media (max-width: 640px) {
    display: flex;
  }
  /* xs */
  @media (max-width: 475px) {
    display: flex;
    min-width: 96%;
  }
`;

const DepositAlertBox = styled.div`
  display: flex;
  width: 94%;
  padding: 14px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #ef665b;
  background: #ef665b;
`;

const AlertTitle = styled.div`
  color: #610700;
  font-family: "Space Grotesk";
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  flex: 1 0 0;
`;

const MobileTitle = styled.div`
  display: flex;
  align-items: start;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

const PotHeader = styled.div`
  display: flex;
  width: 320px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  @media (max-width: 475px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 0 0 16px;
  }
`;

const Section = styled.div`
  display: flex;
  padding: 2px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  //flex: 1 0 0;
  align-self: stretch;
`;

const DualAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 475px) {
    display: none;
  }
`;

const MobileDualAvatar = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 475px) {
    display: flex;
    margin: 8px 0 2px 0;
  }
`;
const BoxOne = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: flex-start;
  gap: 8px;
`;
const BoxTwo = styled.div`
  display: flex;
  margin-top: -20px;
  padding-right: 14px;
  align-items: flex-start;
  /* @media (max-width: 475px) {
    display: none;
  } */
`;

const Background = styled.div`
  /* display: flex; */
  padding: 16px;
  /* justify-content: space-between; */
  /* align-items: center; */
  align-self: stretch;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.08);
  margin: 8px 0;
`;

const BackgroundInvite = styled.div`
  padding: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.08);
  margin: 8px 0;
  @media (max-width: 475px) {
    padding: 12px 16px;
  }
`;

const NotificationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PersonAddDropdown = styled(PersonAddDropdownIcon)`
  width: 20px;
  height: 20px;
  opacity: 0.7;
  margin-right: 12px;
`;

const NotificationIcon = styled(notificationSvg)`
  width: 24px;
  height: 24px;
  opacity: 0.7;
  margin-right: 12px;
`;
const PrimaryText = styled.div`
  color: var(--light, #e9f6d0);
  font-family: "Space Grotesk";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const SecondaryText = styled.div`
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const NextDepositText = styled.div<CounterBtnProps>`
  display: flex;
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;
const PrimaryNumber = styled.div`
  color: var(--Neutral-White, #fff);
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const DollarPrimaryNumber = styled.div`
  color: var(--Neutral-White, #fff);
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
`;

const ParticipantPrimaryNumber = styled.div`
  display: flex;
  justify-content: space-between; // Add this line
  align-items: center;
  align-self: stretch;
  color: var(--Neutral-White, #fff);
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const Cols = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const TwoLineRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RightText = styled.div`
  cursor: pointer;
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
`;

const ParticipantIcon1 = styled(DefaultImage)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;

const DefaultAvatar = styled(DefaultImage)`
  height: 40px;
  width: 40px;
  @media (max-width: 475px) {
    height: 25px;
    width: 25px;
  }
`;
const ParticipantIcon2 = styled(DefaultImage)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;
const ParticipantIcon3 = styled(DefaultImage)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;
const ParticipantIcon4 = styled(ElilipseSvg)`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  margin-top: 3px;
`;

const InviteButton = styled(OutlinedButton)`
  cursor: pointer;
  display: flex;
  height: 30px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  color: var(--primary, #b1dd40);
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background-color: transparent;
  margin-left: 3em;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: 0px 12px;
    background: #afdc3b;
    box-shadow: 0px 0px 13px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 12px;
    color: #13160b;
    box-shadow: 0px 0px 13px rgba(214, 232, 81, 0.4);
  }
`;

const ButtomSection = styled.div<{ isbordertop: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* width: 300px; */
  width: 100%;
  padding: 16px 12px;
  gap: 10px;
  align-self: stretch;
  border-top: ${(props) =>
    props.isbordertop === true ? "1px solid rgba(233, 246, 208, 0.12)" : ""};
  @media (max-width: 475px) {
    width: 96%;
    padding: 8px 12px;
  }
`;
const StartPotButton = styled.button<CounterBtnProps>`
  cursor: ${(props) => (!props.completed ? "not-allowed" : "pointer")};
  display: flex;
  height: 40px;
  padding: 0px 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: none;
  background: ${(props) =>
    !props.completed ? "#2f3228" : "var(--primary, #b1dd40)"};
  /* glow */
  color: ${(props) => (!props.completed ? "#e8d2d2" : "var(--dark, #13160b)")};
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: ${(props) => (props.completed ? "0 17px" : "")};
    background: ${(props) => (props.completed ? "#b9ff04" : "")};
    box-shadow: ${(props) =>
      props.completed ? "0px 0px 18px rgba(214, 232, 81, 0.4)" : ""};
  }

  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  /* Disable pointer events when completed */
  pointer-events: ${(props) => (!props.completed ? "none" : "auto")};
`;

const ClaimPotButton = styled.button`
  margin-top: 16px;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.08);
  background: rgba(233, 246, 208, 0.08);
  color: #afdc3b;
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: 0 17px;
    background: #b9ff04;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }
`;
export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 24px;
    /* width: 67%; */
    padding: 10px 24px;
    font-size: 14px;
  }
`;

const DepositPotTextButton = styled(BaseButton)`
  color: white;
  margin-top: 20px;
  background: #ff645d;
  &:hover {
    background: #ef3c34;
    color: white;
  }
  @media (max-width: 475px) {
    margin-top: 0px;
  }
`;

const blinkAnimation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
`;

// Styled component for the blinking text
const BlinkingTextWrapper = styled.span`
  animation: ${blinkAnimation} 2s infinite;
`;

const DepositPotButton = styled.button<CounterBtnProps>`
  margin-top: 16px;
  cursor: pointer;
  display: ${(props) => (props.completed ? "flex" : "none")};
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.08);
  background: rgba(233, 246, 208, 0.08);
  color: #afdc3b;
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    padding: 0 17px;
    background: #b9ff04;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }
`;

const CounterBtn = styled.button<CounterBtnProps>`
  margin-top: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 6px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${(props) => (props.completed ? "#FF645D" : "#b1dd40")};
  /* glow */
  border: ${(props) =>
    props.completed ? "1px solid #FF645D" : "1px solid #b1dd40"};
  box-shadow: ${(props) =>
    props.completed
      ? "0px 0px 14px 0px rgba(255, 100, 93, 0.4)"
      : "0px 0px 14px 0px rgba(214, 232, 81, 0.4)"};

  color: var(--dark, #13160b);
  text-align: center;

  /* desktop/misc/button-md */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  //text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.completed ? "" : "afdc3b")};
    box-shadow: ${(props) =>
      props.completed ? "" : "0px 0px 18px rgba(214, 232, 81, 0.4)"};
  }

  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  pointer-events: none;
`;

const BoawaahPot = styled.span`
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  border-radius: 10px;
  color: white;
  margin-right: 10px;
  font-family: Space Grotesk, sans-serif;
  line-height: 22.4px;
  letter-spacing: 1%;
`;

const SwitchContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 6em;
  width: 32px;
  height: 20px;
  background-color: #b1dd40;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const SwitchDot = styled.span`
  display: block;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%) translateX(0);
  transition: all 0.3s ease;

  &:checked {
    transform: translateY(-50%) translateX(12px);
  }
`;

const SwitchInput = styled.input`
  display: none;

  &:checked + ${SwitchDot} {
    transform: translateY(-45%) translateX(12px);
    background: #13160b;
  }
`;

const IconTextSpan = styled.div`
  display: flex;
`;
const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;
const BackgroundContent = styled.div`
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 475px) {
    align-items: start;
  }
`;

const TooltipContainer = styled.div`
  display: block;
  position: absolute;
  bottom: 56px;
  background-color: #b1dd40;
  width: 270px;
  height: 24px;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -38%; /* Position the pointer at the top of the tooltip */
    transform: translateX(-50%) rotate(180deg); /* Rotate the pointer arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #b1dd40 transparent;
  }
`;
const TooltipContent = styled.div`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-bottom: 12px; /* Add margin bottom to separate paragraphs */
`;

export const LeaveAPotButton = styled(BaseButton)`
  border: solid 1px #ff645d;
  color: #ff645d;
  margin-top: 20px;
  &:hover {
    background: #ff645d;
    box-shadow: ${(props) => props.theme.boxShadow.button};
    color: white;
  }
  @media (max-width: 475px) {
    margin-top: 0px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b;
  width: 13px;
  height: 13px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ClaimButton = styled.button`
  display: flex;
  height: 40px;
  cursor: pointer;
  padding: 0px 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: none;

  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  letter-spacing: 0.28px;
  text-transform: uppercase;
  margin-right: 1em;
  justify-content: center;
  align-items: center;

  &:hover {
    padding: 0 17px;
    background: #afdc3b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
    color: #13160b;
  }

  &:active {
    padding: 0px 16px;
    color: #13160b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }

  @media (max-width: 475px) {
    margin-right: 0;
    display: flex;
  }
`;

const WinthdrawButton = styled(ClaimButton)``;

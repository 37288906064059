import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import BlastPic from "../../assets/blast_logo.svg";
import { ReactComponent as BigBang } from "../../assets/bigBang.svg";
import { ReactComponent as BackgroundImage } from "../../assets/blast_bg.svg";
import { ReactComponent as ExpandMore } from "../../assets/expand_more.svg";
import { ReactComponent as WorkspacePremium } from "../../assets/workspace_premium.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { checkUserSigned } from "../../utils/helper";
import { RootState } from "../../store";
import WalletConnectingModal from "../Modal/WalletConnectingModal";
import HoverButton from "../Button/HoverButton";

interface MainSectionProps {
  onExpandMoreClick: () => void;
  isRegistered: boolean;
}

const MainSection: React.FC<MainSectionProps> = ({
  onExpandMoreClick,
  isRegistered,
}) => {
  const navigate = useNavigate();
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const { isSigningMessage } = useSelector((state: RootState) => state.wallet);
  const [wantsEarlyAccess, setWantsEarlyAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRegisteredInState, setIsRegisteredInState] = useState(false);
  const { account, isActive } = useWeb3React();

  useEffect(() => {
    if (account && isActive && wantsEarlyAccess && checkUserSigned()) {
      const path = isRegisteredInState ? "/createPot" : "/early-access";
      navigate(path);
      setWantsEarlyAccess(false);
    }
  }, [
    account,
    isActive,
    wantsEarlyAccess,
    isRegisteredInState,
    navigate,
    isSigningMessage,
  ]);

  useEffect(() => {
    setIsRegisteredInState(isRegistered);
  }, [isRegistered]);

  const handleEarlyAccessClick = async () => {
    try {
      setLoading(true);
      const isSigned = checkUserSigned();
      if (account && isActive) {
        if (!checkUserSigned()) {
          toast.error(
            "Please sign the message from wallet or reload the page to continue",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setWantsEarlyAccess(true);
          return;
        }
        navigate(isRegisteredInState ? "/createPot" : "/early-access");
      } else {
        setWantsEarlyAccess(true);
        setWalletModalOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  return (
    <>
      <Box>
        <TopHeadingBox>
          <TopHeading>BUILT on</TopHeading>
          <ImageWrapper loading={loading}>
            {loading && <LoadingSpinner />}{" "}
            {/* Display a loading spinner over the image */}
            <img src={BlastPic} alt={"BlastPic"} />
          </ImageWrapper>
        </TopHeadingBox>
        <MainHeading>Rotation Of Credit Based on Social Trust</MainHeading>
        <SubHeading>
          Quail Finance is a SocialFi dApp that facilitates rotation of credit
          among group members based on social trust.
        </SubHeading>
        <ButtonBox>
          <CreateAPotButton
            onClick={handleEarlyAccessClick}
            disabled={loading}
            height="60px"
            padding="10px 32px"
          >
            <span>
              {loading
                ? "Loading..."
                : isRegisteredInState
                ? "Create POT"
                : "Create POT"}
            </span>
          </CreateAPotButton>
        </ButtonBox>
        <BoxContainer>
          <FirstColumn>
            <img
              src={BlastPic}
              alt={"BlastPic"}
              style={{ width: "135.471px", height: "28px" }}
            />
            <BigBangSVG />
            <NormalText>Competition Runner ups</NormalText>
            <NormalHighlitedText>
              Distribution for Early Contributers
            </NormalHighlitedText>
          </FirstColumn>
          <SecondColumn>
            <BoxCard>
              <BoxCardRow>
                <img
                  src={BlastPic}
                  alt={"BlastPic"}
                  style={{ width: "77.412px", height: "16px" }}
                />
                <SmallText>Points</SmallText>
              </BoxCardRow>
              <NumberText>100%</NumberText>
              <CaptionText>Blast Points Earned</CaptionText>
            </BoxCard>
            <BoxCard>
              <BoxCardRow>
                <img
                  src={BlastPic}
                  alt={"BlastPic"}
                  style={{ width: "77.412px", height: "16px" }}
                />
                <SmallText>Gold</SmallText>
              </BoxCardRow>
              <NumberText>100%</NumberText>
              <CaptionText>Blast Gold Earned</CaptionText>
            </BoxCard>
          </SecondColumn>
        </BoxContainer>
        {/* <ExpandMoreImage onClick={onExpandMoreClick} /> */}
      </Box>
      <Row>
        <WorkspacePremium />
        <SubHeadingTwo>
          100% of Blast Points and Gold earned will be distributed to users on
          Quail Finance
        </SubHeadingTwo>
      </Row>
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
    </>
  );
};
export default MainSection;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-height: 60vh;
  margin: 0 auto;
  /* max-height: 900px; */

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
    display: flex;
    justify-content: center;
    min-height: 80vh;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 60px;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 52px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const ExpandMoreImage = styled(ExpandMore)`
  margin-top: 184px;
  cursor: pointer;
`;

export const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 77px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 0;
  max-width: 1400px;

  @media (max-width: 768px) {
    font-size: 52px;
  }

  @media (max-width: 480px) {
    font-size: 35px;
    text-align: left;
  }
`;

export const SubHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0;
  margin-bottom: 48px;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
  }
`;

export const TopHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  margin-right: 8px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
  }
`;

export const TopHeadingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin-right: auto;
    margin-top: 60px;
  }
`;

export const CreateAPotButton = styled(HoverButton)<{ disabled: boolean }>`
  background: ${(props) =>
    props.disabled ? "#ccc" : props.theme.colors.primary};
  color: ${(props) => (props.disabled ? "#666" : props.theme.colors.dark)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  /* box-shadow: ${(props) =>
    !props.disabled ? props.theme.boxShadow.button : "none"}; */

  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

const ImageWrapper = styled.div<{ loading: boolean }>`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${(props) => (props.loading ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BoxContainer = styled.div`
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1090px;
  align-items: flex-start;
  gap: 16px;
`;

const FirstColumn = styled.div`
  display: flex;
  padding: 80px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.2);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  @media (max-width: 560px) {
    max-width: 500px;
  }
  @media (max-width: 475px) {
    gap: 32px;
  }
`;

const BlastBackgroundImage = styled(BackgroundImage)`
  /* position: absolute; */
  /* background: url(backgroundImage) lightgray 50% / cover no-repeat; */
  /* mix-blend-mode: screen; */
`;

const BigBangSVG = styled(BigBang)`
  margin-top: -20px;
  @media (max-width: 678px) {
    max-width: 400px;
  }
  @media (max-width: 575px) {
    max-width: 300px;
  }
`;

const NormalText = styled.div`
  margin-top: -40px;
  color: #fcfec6;
  text-align: center;
  font-family: Orbitron;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 19.2px */
  letter-spacing: 2.56px;
  text-transform: uppercase;
`;

const NormalHighlitedText = styled.div`
  color: var(--primary, #b1dd40);
  text-align: center;

  /* glow */
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);

  /* desktop/misc/tag */
  font-family: Orbitron;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%; /* 17.6px */
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;
const SecondColumn = styled.div`
  display: flex;
  margin: 0 auto;
  width: 426px;
  height: 400px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  flex-shrink: 0;
  @media (max-width: 530px) {
    width: 88%;
  }
  @media (max-width: 1065px) {
    width: 80% !important;
    display: flex;
    /* padding: 80px 18px; */
  }
  @media (max-width: 475px) {
    width: 100% !important;
    padding: 0;
    margin: 0;
  }
`;

const BoxCard = styled.div`
  display: flex;
  padding: 32px 0px 24px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.2);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  @media (max-width: 530px) {
    padding: 32px 0px 24px 0px;
  }
`;
const BoxCardRow = styled.div`
  display: flex;
  gap: 4px;
`;

export const NumberText = styled.span`
  margin-top: -30px;
  color: ${(props) => props.theme.colors.primary};
  font-family: "Orbitron", sans-serif;
  text-align: center;
  font-weight: 900;
  font-size: 45px;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.9px; // 2% of 45px
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 38px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

const SmallText = styled.div`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  font-family: "Orbitron", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;

const CaptionText = styled.div`
  color: var(--light, #e9f6d0);
  text-align: center;

  /* desktop/body/small */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  align-self: stretch;
`;

const Row = styled.div`
  margin-top: 32px;
  display: flex;
  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`;

const SubHeadingTwo = styled.div`
  color: #e9f6d0;
  text-align: center;

  /* desktop/body/large */
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  @media (max-width: 480px) {
    max-width: 313px;
    text-align: center;
  }
`;

import {localStorageStates} from "../utils";
import {Navigate} from "react-router-dom";
import {checkUserSigned} from "../utils/helper";

interface RouteProps {
    children: React.ReactNode;
}

export const IsNotRegistered: React.FC<RouteProps> = ({children}) => {
    const redeemed = localStorage.getItem(localStorageStates.redeemed);
    const isRegistered = localStorage.getItem(
        localStorageStates.isRegistered
    );
    const discordUsername = localStorage.getItem(
        localStorageStates.discordUsername
    );

    if ((isRegistered == "true" && checkUserSigned()) || !checkUserSigned()) {
        return <Navigate to="/" replace/>;
    }

    return <>{children}</>;
};

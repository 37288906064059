import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as dollorSvg } from "../../assets/dollor.svg";
import { ReactComponent as ElilipseSvg } from "../../assets/Elilipse.svg";
import { ReactComponent as Participant1 } from "../../assets/participant1.svg";
import { ReactComponent as Participant2 } from "../../assets/participant2.svg";
import { ReactComponent as Participant3 } from "../../assets/participant3.svg";
import { dateFormat, describeDuration, weiToEther } from "../../utils/helper";
import NewCoinImg from "../../assets/newCoinImg.svg";
import { APPROVE_TERMS_AND_CONDITIONS } from "../../utils/errors";
import { Link } from "react-router-dom";
import { joinPot } from "../../store";
import HoverButton from "../Button/HoverButton";

const JoinPotConfirmModal: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  potDetail: any;
  chatDataProps?: any;
  joinPotSuccessModalRef?: any;
}> = ({
  onClose,
  onConfirm,
  isLoading,
  potDetail,
  chatDataProps,
  joinPotSuccessModalRef,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setShowError(false); // Reset error state when checkbox is checked
    }
  };

  const handleConfirmClick = () => {
    if (!isChecked) {
      setShowError(true);
    } else {
      onConfirm();
    }
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <RowLine>
          <ModalTitle>Join this pot</ModalTitle>
          <CloseIcon onClick={handleClose} />
        </RowLine>

        <BorderBottom />

        <ModalText>#{potDetail?.potId}</ModalText>
        <ModalSubHeading>{potDetail?.name}</ModalSubHeading>
        <ModalTextSmall>
          by <Span> @{potDetail?.creatorData?.username}</Span>
        </ModalTextSmall>

        <Row>
          <LeftText>Pot Amount</LeftText>
          <RightText>
            {potDetail?.amount}
            <img
              src={NewCoinImg}
              alt={"NewCoinImg"}
              style={{ width: "1.5em", marginLeft: "0.5em" }}
            />
          </RightText>
        </Row>

        {/* <Row>
          <LeftText>Interest</LeftText>
          <RightText>{potDetail?.riskRate}</RightText>
        </Row> */}

        <Row>
          <LeftText>Rotation Cycle</LeftText>
          <RightText>{describeDuration(potDetail?.rotationCycle)}</RightText>
        </Row>

        <Row>
          <LeftText>participants</LeftText>
          <RightText>
            <ParticipantText>{potDetail?.noOfParticipants}</ParticipantText>
            {/* <Participants chatDataProps={chatDataProps} /> */}
            {potDetail?.joinedParticipants?.map((_: any, index: number) => {
              if (
                index < 3 &&
                potDetail?.joinedParticipants[index]?.profilePicture
              )
                return (
                  <img
                    src={potDetail?.joinedParticipants[index]?.profilePicture}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "-10px",
                      marginTop: "3px",
                      borderRadius: "100px",
                      border: "solid 1px white",
                    }}
                    alt="Avatar"
                  />
                );
              else if (index < 3) dummyParticipant[index];
            })}
            {potDetail?.joinedParticipants?.length >= 3 ?? <ParticipantIcon4 />}
          </RightText>
        </Row>

        {/* <Row>
          <LeftText>Created at</LeftText>
          <RightText>{dateFormat(potDetail?.createdAt)}</RightText>
        </Row> */}

        {/*<Row>*/}
        {/*    <LeftText>Telegram group</LeftText>*/}
        {/*    <RightText>*/}
        {/*        <Span>telegram.com/abdei</Span>*/}
        {/*    </RightText>*/}
        {/*</Row>*/}

        <BorderBottom />

        <Label>DEPOSIT AND JOIN</Label>
        <InputBox>
          <img
            src={NewCoinImg}
            alt={"NewCoinImg"}
            style={{ width: "1.5em", marginLeft: "0.5em" }}
          />
          <InputText> {potDetail?.amount} USD</InputText>
        </InputBox>
        <CheckboxRow>
          <Checkbox
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel>
            I agree to the{" "}
            <TermsOfUse to="/termsOfService" target="_blank">
              Terms of Use
            </TermsOfUse>{" "}
            &{" "}
            <TermsOfUse to="/privacyPolicy" target="_blank">
              Privacy Policy
            </TermsOfUse>{" "}
            of Quail finance
          </CheckboxLabel>
        </CheckboxRow>
        {showError && (
          <ErrorMessage>{APPROVE_TERMS_AND_CONDITIONS}</ErrorMessage>
        )}
        <div style={{ width: "100%", margin: "20px 0" }}>
          <DepositButton onClick={handleConfirmClick} height="44px" padding="0">
            {" "}
            {isLoading ? <Loader /> : "Deposit and Join"}
          </DepositButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default JoinPotConfirmModal;

// Styled Components

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin: 0;
  margin-top: 4px;
`;

export const RowLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 0 0;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 50px;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 480px;
  padding: 40px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  border: 1px solid rgba(233, 246, 208, 0.16);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  padding: 20px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const DollorIcon = styled(dollorSvg)`
  width: 24px;
  height: 24px;
`;

const SmallDollorIcon = styled(dollorSvg)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-top: 3px;
`;

const ParticipantIcon1 = styled(Participant1)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 3px;
`;

const ParticipantIcon2 = styled(Participant2)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 3px;
`;

const ParticipantIcon3 = styled(Participant3)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 3px;
`;

const ParticipantIcon4 = styled(ElilipseSvg)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 3px;
`;

const ParticipantText = styled.div`
  margin-right: 15px;
`;

const ModalTitle = styled.h2`
  color: #e9f6d0;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  margin: 0px;
  opacity: 0.7;
  align-self: stretch;
  text-align: left;
  /* width: 100%; */
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: Orbitron; */
  font-size: 16px;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  opacity: 0.7;
  text-align: center;
  margin: 0 24px 0 0;
`;

const ModalSubHeading = styled.h3`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: Orbitron; */
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  margin: 20px 0;
  /* margin-bottom: 6px; */
  text-transform: uppercase;
`;

const ModalTextSmall = styled.div`
  display: flex;
  color: #909296;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin: 0 24px 16px 0;
`;

const Span = styled.div`
  color: #b1dd40;
  margin: 0 4px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 16px 24px 0 0;
  /* width: 430px; */
`;

const LeftText = styled.div`
  display: flex;
  color: rgba(233, 246, 208, 0.8);
  /* font-family: ${(props) => props.theme.fontFamily.secondary}; */
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;

const RightText = styled.div`
  display: flex;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Mono", monospace; */
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
`;

const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  margin: 20px 0;
`;

const Label = styled.div`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0 24px 0 0;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(233, 246, 208, 0.08);
  padding: 10px;
  width: 95%;
  margin: 20px 0;
`;

const InputText = styled.div`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Mono", monospace; */
  font-size: 16px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 24px 0 0;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.2);
`;

const CheckboxLabel = styled.label`
  display: inline;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  @media (max-width: 475px) {
  font-size: 12px;
  }
`;

const DepositButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  margin: 20px 0;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const dummyParticipant = [
  <ParticipantIcon1 />,
  <ParticipantIcon2 />,
  <ParticipantIcon3 />,
];

const TermsOfUse = styled(Link)`
  color: #b1dd40;
  margin: 0 6px;
`;

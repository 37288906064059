import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowForwardLightIcon } from "../../assets/arrow_forward_light.svg";
import { ReactComponent as BlastIcon } from "../../assets/blast_logo1.svg";
import { ReactComponent as HelpIcon } from "../../assets/help.svg";
import { WalletConnect } from "@web3-react/walletconnect";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { GnosisSafe } from "@web3-react/gnosis-safe";
import { useWeb3React } from "@web3-react/core";
import { Network } from "ethers";
import { getAddChainParameters } from "../../utils/chains";
import { metaMask } from "../../utils/connectors/metamask";
import HoverButton from "../Button/HoverButton";

const UnSupportedChainModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [desiredChainId, setDesiredChainId] = useState<number>();
  const { connector, chainId } = useWeb3React();
  const [error, setError] = useState();

  useEffect(() => {
    if (chainId && (!desiredChainId || desiredChainId === -1)) {
      setDesiredChainId(chainId);
    }
  }, [chainId]);

  const switchChain = useCallback(
    async (desiredChainId: number) => {
      setDesiredChainId(desiredChainId);

      try {
        if (
          // If we're already connected to the desired chain, return
          desiredChainId === chainId ||
          // If they want to connect to the default chain and we're already connected, return
          (desiredChainId === -1 && chainId !== undefined)
        ) {
          setError(undefined);
          return;
        }

        if (desiredChainId === -1 || connector instanceof GnosisSafe) {
          await connector.activate();
        } else if (
          connector instanceof WalletConnectV2 ||
          connector instanceof WalletConnect ||
          connector instanceof Network
        ) {
          await connector.activate(desiredChainId);
        } else {
          await connector.activate(getAddChainParameters(desiredChainId));
        }

        setError(undefined);
      } catch (error: any) {
        setError(error);
      }
    },
    [metaMask, chainId, setError]
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <IconRow>
          <HelpIcon />
          <ArrowForwardLightIcon />
          <BlastIcon />
        </IconRow>
        <ModalTitle>This chain is unsupported</ModalTitle>
        <ModalDescription>
          Your connected chain is currently not supported. Please switch
          networks to continue
        </ModalDescription>

        <ButtonRow>
          <CreateAPotButton
            onClick={() => {
              if (process.env.REACT_APP_CHAIN_ID)
                switchChain(+process.env.REACT_APP_CHAIN_ID);
            }}
            height="48px"
            padding="0 16px"
          >
            <span>Switch Network</span>
          </CreateAPotButton>
        </ButtonRow>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default UnSupportedChainModal;

// Styled Components

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
`;

export const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  width: 96%;
  max-width: 505px;
  padding: 40px;
  /* padding: 80px 48px 64px 48px; */
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    width: 70%;
    gap: 0px;
    padding: 60px 24px 48px 24px;
  }

  @media (max-width: 480px) {
    width: 82%;
    gap: 0px;
    padding: 40px 16px 32px 16px;
  }
`;
// const ModalContainer = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   max-width: 540px;
//   padding: 48px;
//   border-radius: 8px;
//   z-index: 1003;
//   border: 1px solid rgba(233, 246, 208, 0.16);
//   background: linear-gradient(
//       0deg,
//       rgba(233, 246, 208, 0.08) 0%,
//       rgba(233, 246, 208, 0.08) 100%
//     ),
//     #13160b;
//   box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
// `;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
`;

const ModalDescription = styled.p`
  color: #e9f6d0;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  /* margin-top: 16px; */
  margin-bottom: 32px;
  margin-top: 32px;
  opacity: 0.7;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 52px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(233, 246, 208, 0.40); */
  background: rgba(233, 246, 208, 0.08);
  /* color: white; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

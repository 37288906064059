import {Participant} from "../interface";

export const getSuggestions = (value: string, chatDataProps: any) => {
    if (value.endsWith('@') || value.endsWith('@ ')) {
        if (chatDataProps && chatDataProps.participants) {
            return chatDataProps.participants.map((participant: Participant) =>
                participant.username
            );
        }
    }
    return [];
};

export const handleSuggestionClick = (suggestion: string, inputValue: string, setInputValue: Function) => {
    const atIndex = inputValue.lastIndexOf('@');
    if (atIndex !== -1) {
        // Replace the text after '@' with the selected suggestion
        const newValue = inputValue.substring(0, atIndex + 1) + suggestion;
        setInputValue(newValue);
    } else {
        setInputValue(null);
    }
};
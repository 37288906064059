import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DiscordLogo } from "../assets/discord-logo.svg";
import { ReactComponent as XLogo } from "../assets/x-logo.svg";
import { localStorageStates } from "../utils";
import { Link } from "react-router-dom";

const DISCORD_URL = process.env.REACT_APP_DISCORD_URL;
const TWITTER_URL = process.env.REACT_APP_TWITTER_URL;

const Footer: React.FC = () => {
  const [totalUsers, setTotalUsers] = useState<string>("");

  useEffect(() => {
    setTotalUsers(localStorage.getItem(localStorageStates.totalUsers) || "");
  }, []);

  return (
    <StyledFooter>
      {/* <ThirdColumn>
        <PolicyContainer>
                    <StyledLink
                        to="https://docs.google.com/document/d/1_EEWrOKfgJvRuZeB7nSAbBH2M_pdLm5vxCMz3SqVfG4/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text>TERMS OF SERVICE</Text>
                    </StyledLink>
                </PolicyContainer>

                <PolicyContainer>
                    <StyledLink
                        to="https://docs.google.com/document/d/1VrLDtDnL1Xfdfa2hZalInqSHaA7u7SCUrAJTxk1wG9w/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text>PRIVACY POLICY</Text>
                    </StyledLink>

                </PolicyContainer>
        <PolicyContainer>
          <StyledLink to="/termsOfService">
            <Text>TERMS OF SERVICE</Text>
          </StyledLink>
        </PolicyContainer>

        <PolicyContainer>
          <StyledLink to="/privacyPolicy">
            <Text>PRIVACY POLICY</Text>
          </StyledLink>
        </PolicyContainer>
      </ThirdColumn> */}
      <ThirdColumn>
        <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
          <IconContainer>
            <DiscordLogo />
          </IconContainer>
        </a>
        <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
          <IconContainer>
            <XLogo />
          </IconContainer>
        </a>
        {/* <Text>© 2024 — QUAIL FINANCE</Text> */}
      </ThirdColumn>
      <ThirdColumn>
        {/* <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
          <IconContainer>
            <DiscordLogo />
          </IconContainer>
        </a>
        <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
          <IconContainer>
            <XLogo />
          </IconContainer>
        </a> */}
        <Text>© 2024 — QUAIL FINANCE</Text>
      </ThirdColumn>
    </StyledFooter>
  );
};

export default Footer;

export const StyledFooter = styled.footer`
  display: flex;
  height: 80px;
  position: relative;
  bottom: 0px;
  z-index: 1;
  padding: 0px 64px;
  justify-content: space-between;
  align-items: center;
  border-top: ${(props) => props.theme.borders.light};
  background-color: ${(props) => props.theme.colors.dark};
  @media (max-width: 768px) {
    padding: 18px 38px;
    gap: 20px;
  }

  @media (max-width: 480px) {
    padding: 22px 16px;
    flex-direction: column;
    align-items: center;
    height: unset;
  }
`;

export const Text = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  opacity: 0.56;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    text-align: center;
    order: 3;
    margin-bottom: 6px;
  }
`;

export const Highlight = styled(Text)`
  letter-spacing: 1.4px;
  opacity: 1;
`;

export const SecondColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 480px) {
    order: 1; // Move the Total Users to the first position
    width: 100%;
    margin-bottom: 0px;
  }
`;

const ThirdColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    gap: 4px;
  }

  @media (max-width: 480px) {
    order: 2;
    margin-bottom: 0px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.3s ease;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    background: ${(props) => props.theme.colors.softGray};
    transform: scale(1.2);

    img {
      transform: scale(0.85);
    }
  }

  @media (max-width: 480px) {
    padding: 0px;

    &:first-child {
      margin-right: 12px;
    }
  }
`;
const PolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.3s ease;

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

const StyledLink = styled(Link)<{ isActive?: boolean }>`
  opacity: 0.8;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => (props.isActive ? props.theme.colors.primary : "#fff")};
  text-decoration: none;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) =>
      props.isActive ? props.theme.colors.primary : "transparent"};
    transition: background-color 0.3s;
  }

  &:hover {
    opacity: 1;
  }
`;

import { io, Socket } from "socket.io-client";

const SERVER_URL = process.env.REACT_APP_SOCKET_URI;
const authToken = localStorage.getItem("token");
let socket: Socket;
if (SERVER_URL) {
  socket = io(SERVER_URL, {
    auth: {
      token: authToken,
    },
  });
  socket.on("message", (data: any) => {
    if (onMessageReceivedCallback) {
      onMessageReceivedCallback(data);
    }
  });
  socket.on("messagePinned", (data: any) => {
    if (onPinnedMsgReceivedCallback) {
      onPinnedMsgReceivedCallback(data);
    }
  });
  socket.on("messageSeen", (data) => {
    if (onMessageSeenCallback) {
      onMessageSeenCallback(data.messageId);
    }
  });
  socket.on("participantUpdated", (data: any) => {
    if (onParticipantJoinedCallback) {
      onParticipantJoinedCallback(data);
    }
  });
  socket.on("winnerSelected", (data: any) => {
    if (onWinnerSelectedCallback) {
      onWinnerSelectedCallback(data);
    }
  });
  socket.on("potLeaved", (data: any) => {
    if (onLeavePotCallback) {
      onLeavePotCallback(data);
    }
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from the server");
  });

  socket.on("connect_error", (error) => {
    console.error("WebSocket connection error:", error);
  });

  socket.on("potRestarted", (data: any) => {
    if (onRestartPotCallback) {
      onRestartPotCallback(data);
    }
  });
}

let chatId: string | null = null;

export const dif = (id: string | null) => {
  if (id && id !== chatId) {
    chatId = id;

    joinChat(chatId);
  }
};

function joinChat(chatId: any) {
  socket.emit("joinChat", { chatId }, (response: any) => {
    console.log("response join chat", response);
  });
}

export const sendMsg = (chatId: any, content: any) => {
  socket.emit("sendMessage", {
    chatId,
    content,
  });
};

let onMessageReceivedCallback: ((data: any) => void) | null = null;
export const setOnMessageReceived = (callback: (data: any) => void) => {
  onMessageReceivedCallback = callback;
};

let onRestartPotCallback: ((data: any) => void) | null = null;
export const setOnRestartPot = (callback: (data: any) => void) => {
  onRestartPotCallback = callback;
};

let onPinnedMsgReceivedCallback: ((data: any) => void) | null = null;
export const setOnPinnedMsgReceived = (callback: (data: any) => void) => {
  onPinnedMsgReceivedCallback = callback;
};

let onMessageSeenCallback: ((messageId: string) => void) | null = null;
export const setOnMessageSeen = (callback: (messageId: string) => void) => {
  onMessageSeenCallback = callback;
};

let onParticipantJoinedCallback: ((data: any) => void) | null = null;

export const setOnParticipantJoined = (callback: (data: any) => void) => {
  onParticipantJoinedCallback = callback;
};

let onWinnerSelectedCallback: ((data: any) => void) | null = null;

export const setonWinnerSelected = (callback: (data: any) => void) => {
  onWinnerSelectedCallback = callback;
};

let onLeavePotCallback: ((data: any) => void) | null = null;

export const setonLeavePot = (callback: (data: any) => void) => {
  onLeavePotCallback = callback;
};

export async function fetchGroupChats(
  token: any,
  statusValue: string | undefined | null = "active",
  limit: number = 30
) {
  try {
    const url = `${SERVER_URL}/api/group-chats?limit=${limit}`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch group chats");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching group chats:", error);
    throw error;
  }
}

const MAX_RETRIES = 5;

export async function listMessages(
  token: any,
  chatId: any,
  limit = 50,
  page = 1,
  retries: number = MAX_RETRIES
) {
  const url = `${SERVER_URL}/api/messages/${chatId}?limit=${limit}&page=${page}`;
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch messages");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (retries > 0) {
      console.log(`Retrying... (${retries} attempts left)`);
      return listMessages(token, chatId, limit, page, retries - 1);
    } else {
      throw new Error("Maximum retries exceeded");
    }
  }
}

export async function fetchNotifications(limit = 50, page = 1) {
  try {
    const response = await fetch(
      `${SERVER_URL}/api/notifications?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    } else {
      console.error("Failed to fetch notifications. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return null;
  }
}

export async function markNotificationAsRead(notificationId: any) {
  try {
    const response = await fetch(
      `${SERVER_URL}/api/notifications/${notificationId}/mark-as-read/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    if (response.status === 200) {
      return true; // Success
    } else {
      console.error(
        "Failed to mark notification as read. Status:",
        response.status
      );
      return false;
    }
  } catch (error) {
    console.error("Error marking notification as read:", error);
    return false;
  }
}

export const pinMessage = (messageId: any) => {
  socket.emit("pinMessage", { messageId }, (response: any) => {});
};

export const markMsgSeen = (messageId: any) => {
  socket.emit("messageSeen", { messageId });
};
export const participantJoinedFunc = (chatId: any) => {
  socket.emit("participantUpdated", { chatId });
};

export async function getPotDetails(potCode: any) {
  try {
    const response = await fetch(`${SERVER_URL}/api/pot/${potCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch pot details. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching pot details:", error);
    return null;
  }
}

export async function getJoinedParticipants(potCode: any) {
  try {
    const response = await fetch(
      `${SERVER_URL}/api/joined-participants/${potCode}/detail`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(
        "Failed to fetch joined participants. Status:",
        response.status
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching joined participants:", error);
    return null;
  }
}
export async function getJoinedParticipantListData(potCode: any) {
  try {
    const response = await fetch(
      `${SERVER_URL}/api/joined-participants/${potCode}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(
        "Failed to fetch joined participants list. Status:",
        response.status
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching joined participants list:", error);
    return null;
  }
}

export async function claimUserPoints(token: any) {
  try {
    const response = await fetch(`${SERVER_URL}/api/user/claim-points`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // Add body data if needed
      body: JSON.stringify({
        // Add any parameters you need to send in the request body
        // For example:
        // userId: 'your_user_id'
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to claim user points. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error claiming user points:", error);
    return null;
  }
}

export async function claimUserGolds(token: any) {
  try {
    const response = await fetch(`${SERVER_URL}/api/user/claim-gold`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // Add body data if needed
      body: JSON.stringify({
        // Add any parameters you need to send in the request body
        // For example:
        // userId: 'your_user_id'
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to claim user points. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error claiming user points:", error);
    return null;
  }
}

export async function syncRotation(token: any, potId: any): Promise<any> {
  try {
    const url = `${SERVER_URL}/api/sync-rotation/${potId}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({roundNumber}),
    });

    if (!response.ok) {
      throw new Error("Failed to rotate liquidity");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error rotating liquidity:", error);
    throw error;
  }
}

export async function getQuailPoints() {
  try {
    const response = await fetch(`${SERVER_URL}/api/quailStats`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch quail stats. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching quail stats:", error);
    return null;
  }
}

import React from 'react';
import styled from 'styled-components';
import {TickIconProps} from "../../utils/interface";
import MarkSeenIcon from '../../assets/MarkSeenIcon.svg'

const TickIconWrapper = styled.div<TickIconProps>`
    svg {
        fill: ${props => props.isChecked ? '#b1dd40' : '#fff'};
        width: 14px;
        height: 14px;
    }
`;

const MarkMsgAsRead: React.FC<TickIconProps> = ({isChecked}) => {
    return (
        <TickIconWrapper isChecked={isChecked}>
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M745.472 352.256c-8.192-4.096-20.48-4.096-28.672 0L462.848 614.4 344.064 491.52c-8.192-8.192-20.48-8.192-28.672 0s-8.192 20.48-4.096 28.672L446.464 655.36h4.096s4.096 0 4.096 4.096h16.384s4.096 0 4.096-4.096h4.096l270.335-274.432c0-8.192 0-20.48-4.096-28.672z"/>
                <path
                    d="M512 1024C229.376 1024 0 794.624 0 512S229.376 0 512 0s512 229.376 512 512-229.376 512-512 512zm0-983.04C253.952 40.96 40.96 253.952 40.96 512S253.952 983.04 512 983.04 983.04 770.048 983.04 512 770.048 40.96 512 40.96z"/>
            </svg>
        </TickIconWrapper>
    );
};

export default MarkMsgAsRead;

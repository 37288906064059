import { useEffect, useState } from "react";
import { localStorageStates } from "../utils";
import { checkUserSigned } from "../utils/helper";
import { Navigate } from "react-router-dom";
import WalletNotConnected from "../components/WalletNotConnected";
import EarlyAccessPage from "../pages/early-access";

interface RouteProps {
  children: React.ReactNode;
}

export const IsRegistered: React.FC<RouteProps> = ({ children }) => {
  const redeemed = localStorage.getItem(localStorageStates.redeemed);
  const isRegistered = localStorage.getItem(localStorageStates.isRegistered);
  const twitterUsername = localStorage.getItem(
    localStorageStates.twitterUsername
  );
  const discordUsername = localStorage.getItem(
    localStorageStates.discordUsername
  );
  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;

  if (
    (isRegistered != "true" || !checkUserSigned()) &&
    userInfoData?.hasPaidFee
  ) {
    return <WalletNotConnected />;
    // return <Navigate to="/" replace />;
  }
  if (!userInfoData?.hasPaidFee) {
    return <EarlyAccessPage />;
  }

  return <>{children}</>;
};

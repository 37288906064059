import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as AvatarForActivities } from "../assets/Avatar_activites.svg";

const activitiesDummyData = [
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
  { name: "Toomlet", invitedBy: "@casual", timeAgo: "1 min ago" },
];

const LeaderboardPage = () => {
  const [activitiesData, setActivitiesData] =
    useState<any>(activitiesDummyData);

  return (
    <ActivityContainerMobile>
      <ActivitiesTitle>Activities</ActivitiesTitle>
      {activitiesData.map((activity: any, index: any) => (
        <ActivityRowMobile key={`${index}-ActivityRowMobile`}>
          <Avatar>
            <AvatarForActivities />
          </Avatar>
          <ActivityBox>
            <ActivityText>
              {activity.name} invited by {activity.invitedBy}
            </ActivityText>
            <TimePeriod>{activity.timeAgo}</TimePeriod>
          </ActivityBox>
        </ActivityRowMobile>
      ))}
    </ActivityContainerMobile>
  );
};

export default LeaderboardPage;

const ActivityRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ActivityContainerMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
`;

const ActivitiesTitle = styled.h2`
  color: ${(props) => props.theme.colors.light};
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const ActivityRowMobile = styled(ActivityRow)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Avatar = styled.div`
  // Define your avatar styles here
`;

const ActivityBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityText = styled.span`
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const TimePeriod = styled.span`
  color: #b1dd40;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.56;
`;

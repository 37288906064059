import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import MetaMaskWalletIconSvg from "../../assets/metamask-wallet-icon.svg";
import { ReactComponent as RightArrowIconSvg } from "../../assets/arrow-right.svg";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { WalletConnect } from "@web3-react/walletconnect";
import { hooks, metaMask } from "../../utils/connectors/metamask";
import { MetaMask } from "@web3-react/metamask";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { GnosisSafe } from "@web3-react/gnosis-safe";
import { getAddChainParameters } from "../../utils/chains";

const MetamaskWalletOption: React.FC<{
  handleAccount: (account: string) => void;
}> = ({ handleAccount }) => {
  const {
    useChainId,
    useAccounts,
    useIsActivating,
    useAccount,
    useIsActive,
    useProvider,
    useENSNames,
  } = hooks;
  const isActive = useIsActive();
  const chainId = useChainId();
  const account = useAccount();

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  // const provider = useProvider();

  const [error, setError] = useState();

  const switchChain = useCallback(
    async (
      desiredChainId: number | undefined,
      connector: MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet
    ) => {
      try {
        const isMetaMaskInAppBrowser =
          window.ethereum && window.ethereum.isMetaMask;

        if (isMobile() && !isMetaMaskInAppBrowser) {
          // This URL should be the URL of your DApp
          const SITE_URL = process.env.REACT_APP_SITE_URL;
          const dappUrl = SITE_URL;
          const metamaskDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
          window.location.href = metamaskDeepLink;
        } else {
          if (desiredChainId === -1 || connector instanceof GnosisSafe) {
            await connector.activate();
          } else if (
            connector instanceof WalletConnectV2 ||
            connector instanceof WalletConnect
          ) {
            await connector.activate(desiredChainId);
          } else {
            if (desiredChainId) {
              await connector.activate(getAddChainParameters(desiredChainId));
            } else {
              await connector.activate();
            }
          }
        }

        setError(undefined);
      } catch (error: any) {
        setError(error);
      }
    },
    [metaMask, chainId, setError]
  );

  useEffect(() => {
    if (isActive && account) {
      handleAccount(account);
    }
  }, [isActive, account]);

  // const handleWalletOptionClick = (walletType: string) => {
  //   switch (walletType) {
  //     case walletStates.metamask:
  //       // setActiveChainId(chainId);
  //       switchChain(chainId, metaMask);
  //       break;
  //   }
  // };

  return (
    <WalletOptionButton onClick={() => switchChain(chainId, metaMask)}>
      <WalletDescriptionBox>
        <img src={MetaMaskWalletIconSvg} alt="Metamask" />
        <WalletText>Metamask</WalletText>
      </WalletDescriptionBox>
      <RightArrowIconSvg />
    </WalletOptionButton>
  );
};

export default MetamaskWalletOption;

const WalletOptionButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0px 16px;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => props.theme.fontWeights.light};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    height: 55px;
  }

  @media (max-width: 480px) {
    height: 45px;
  }
`;

const WalletText = styled.span`
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  text-transform: uppercase;
`;

export const WalletDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  width: 96%;
  max-width: 505px;
  padding: 80px 48px 64px 48px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};

  @media (max-width: 768px) {
    width: 70%;
    gap: 0px;
    padding: 60px 24px 48px 24px;
  }

  @media (max-width: 480px) {
    width: 82%;
    gap: 0px;
    padding: 40px 16px 32px 16px;
  }
`;

export const ModalHeading = styled.h1`
  align-self: stretch;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 34px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { StyledEllipse } from "../components/StyledEllipse";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  attachInviteCode,
  checkTokenStatus,
  RootState,
} from "../store";
import { toast } from "react-toastify";
import { localStorageStates } from "../utils";
import { useWeb3React } from "@web3-react/core";
import { useNavigate, useParams } from "react-router-dom";
import WalletConnectingModal from "../components/Modal/WalletConnectingModal";
import Footer from "../components/Footer";

const EarlyAccessVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { token } = useParams();
  const { tokenStatus } = useSelector((state: RootState) => state.wallet);
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const { isActive, connector, account, chainId, provider } = useWeb3React();
  const [code, setCode] = useState<string[]>(Array(5).fill(""));
  const [error, setError] = useState<string>("");

  const openWalletModal = () => setWalletModalOpen(true);

  useEffect(() => {
    (async () => {
      if (token && token.length === 5 && typeof token === "string") {
        setCode(token.split(""));
        if (isActive && account) {
          const tokenResult = await dispatch(checkTokenStatus(token));

          if (tokenResult.meta.requestStatus === "fulfilled") {
            //   localStorage.setItem(localStorageStates.redeemed, "true");
            localStorage.setItem(localStorageStates.code, token);
            setWalletModalOpen(false);
            //   router.replace("/early-access-verification");
          } else {
            toast.error(tokenResult.payload?.message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setError(
              tokenResult.payload?.message ||
                "Failed to check token status. Please try again."
            );
          }
        }
      } else if (token) {
        navigate("/");
        // setCode(Array(5).fill("")); // Set code to initial state instead of navigating
      }
    })();
  }, [token]);

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      // setAccount(accountProp);
      closeWalletModal();
    }
  }, []);

  const handlePaste = async (event: React.ClipboardEvent) => {
    event.preventDefault();

    try {
      const pasteData = await navigator.clipboard.readText();
      const trimmedData = pasteData.trim().substring(0, 5);

      if (trimmedData.length > 0) {
        const characters = trimmedData.split("");
        const filledCharacters =
          characters.length < 5
            ? [...characters, ...Array(5 - characters.length).fill("")]
            : characters;
        setCode(filledCharacters);
      } else {
        setError("Please paste a valid code.");
      }
    } catch (error: any) {
      console.error("Error reading clipboard data:", error);
      if (error) {
        toast.error(error || "Error reading clipboard data:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setError("Error reading clipboard data. Please try again.");
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 4) {
      const nextInput = document.getElementById(
        `input${index + 1}`
      ) as HTMLInputElement;
      nextInput?.focus();
    }
  };

  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  const handleBackspace = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !code[index]) {
      const prevInput = document.getElementById(
        `input${index - 1}`
      ) as HTMLInputElement;
      prevInput?.focus();
    }
  };

  const isButtonDisabled = code.some((c) => c === "");

  const handleRedeemInviteCodeClick = async () => {
    if (!isActive && !account) {
      openWalletModal();
      return;
    }
    setError("");
    const fullCode = code.join("");
    const tokenResult = await dispatch(attachInviteCode(fullCode));

    if (tokenResult.meta.requestStatus === "fulfilled") {
      localStorage.setItem(localStorageStates.redeemed, "true");
      localStorage.setItem(localStorageStates.code, fullCode);
      setWalletModalOpen(false);
      navigate("/early-access");
    } else {
      toast.error(tokenResult.payload?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(
        tokenResult.payload?.message ||
          "Failed to check token status. Please try again."
      );
    }
  };

  return (
    <>
      <Container>
        <StyledEllipse />
        <MainHeading>Create A Pot</MainHeading>
        <SubHeading>Enter your invite code</SubHeading>
        <InputBoxes onPaste={handlePaste}>
          {code.map((c, i) => (
            <InputBox
              key={i}
              id={`input${i}`}
              maxLength={1}
              value={c}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(i, e.target.value)
              }
              onKeyDown={(e) => handleBackspace(i, e)}
            />
          ))}
        </InputBoxes>
        <RedeemButton
          disabled={isButtonDisabled || tokenStatus.loading}
          onClick={handleRedeemInviteCodeClick}
        >
          {tokenStatus?.loading ? <Spinner /> : "Redeem Invite Code"}
        </RedeemButton>
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        <FooterText>
          Already registered?{" "}
          <LoginLink onClick={openWalletModal}>Login with wallet</LoginLink>
        </FooterText>
        {isWalletModalOpen && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <WalletConnectingModal
              onClose={closeWalletModal}
              handleAccount={handleAccount}
            />
          </React.Suspense>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default EarlyAccessVerification;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 180px;
  max-width: 950px;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 29px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.h2`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  opacity: 0.7;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const InputBoxes = styled.div`
  display: flex;
  gap: 10px;
  margin: 32px auto;
`;

const InputBox = styled.input`
  display: flex;
  width: 48px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.input};
  background: ${(props) => props.theme.colors.darkBlack};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  // text-transform: uppercase;
  color: ${(props) => props.theme.colors.light};

  @media (max-width: 768px) {
    width: 46px;
    height: 50px;
  }

  @media (max-width: 480px) {
    width: 44px;
    height: 48px;
  }
`;

const RedeemButton = styled.button`
  display: flex;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 195px;
  border-radius: 8px;
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.darkBlack : theme.colors.primary};
  box-shadow: ${({ disabled, theme }) =>
    disabled ? "none" : theme.boxShadow.button};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.offWhite : theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  // line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.28px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const FooterText = styled.p`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 32px;
`;

const LoginLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  cursor: pointer;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as infoSvg } from "../assets/info.svg";
import { ReactComponent as ExpandMoreIcon } from "../assets/expand_more.svg";
import { ReactComponent as TelegramLogo } from "../assets/telegram-logo.svg";
import { approveUsdbTx } from "../utils/transactions/approveUsdb";
import { createPotTx } from "../utils/transactions/createAPot";
import { useWeb3React } from "@web3-react/core";
import {
  AppDispatch,
  RootState,
  checkSyncDetails,
  createPot,
  getChatsData,
  getCommitment,
  joinPotSignature,
  setCreatePotError,
  setIsArchived,
  setIsCreatePotLoading,
  setIsCreatedPot,
  setWalletConnect,
} from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JoinPotConfirmModal from "../components/Modal/JoinPotConfirmModal";
import { joinPotTx } from "../utils/transactions/joinAPot";
import { Insufficient_Fund_Error } from "../utils/errors";
import CreatePotConfirmModal from "../components/Modal/CreatePotConfirmModal";
import { toast } from "react-toastify";
import InsufficientFundModal from "../components/Modal/InsufficientFundModal";
import HoverButton from "../components/Button/HoverButton";
import Footer from "../components/Footer";

type Errors = {
  [key: string]: string;
};

const CreatePotPage = () => {
  const {
    createPot: { loading: createPotLoading },
  } = useSelector((state: RootState) => state.wallet);
  const {
    chats: { data: chatData, loading: chatLoading, chatCount, isArchived },
    restartPot: { isRestarted },
    leavePot: {  },
    createPot: { isCreated },
    deposit: { isJoined },
  } = useSelector((state: RootState) => state.wallet);
  const domain = window.location.hostname;
  const [isJoinPotConfirmModal, setIsJoinPotConfirmModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { account, provider } = useWeb3React();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [potName, setPotName] = useState("");
  const [potAmount, setPotAmount] = useState("");
  const [riskRate, setRiskRate] = useState("");
  // const [isLoadingCreatePot, setIsLoadingCreatePot] = useState(false);
  const [isInsufficientFundModal, setIsInsufficientFundModal] = useState(false);
  const [potCode, setPotCode] = useState("");
  const [potId, setPotId] = useState<number | null>(null);
  const [numberOfParticipants, setNumberOfParticipants] = useState("");
  // const [rotationCycleDisplay, setRotationCycleDisplay] = useState("Daily");
  const [rotationCycle, setRotationCycle] = useState("86400"); // Default to daily
  const selectRef = useRef<HTMLSelectElement>(null);
  // const [telegramGroupLink, setTelegramGroupLink] = useState("");
  // const [isCreatedPot, setIsCreatedPot] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [potDetail, setPotDetail] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Daily");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const joinPotSuccessModalRef = useRef<HTMLDivElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const options = [
    { label: "Every 1 min", value: "60" },
    { label: "Every 10 min", value: "600" },
    { label: "Every 1 Hour", value: "3600" },
    { label: "Every 6 Hour", value: "21600" },
    { label: "Every 12 Hour", value: "43200" },
    { label: "Daily", value: "86400" },
    { label: "Weekly", value: "604800" },
    { label: "Monthly", value: "2592000" },
  ];

  function getRotationCycle(label: string) {
    const option = options.find((option) => option.label === label);
    return option ? option.value : null;
  }

  const handleSelectOption = (option: { label: string; value: string }) => {
    setSelectedOption(option.label);
    const tempRotationCycle = getRotationCycle(option.label);
    if (tempRotationCycle) setRotationCycle(tempRotationCycle);
    setIsDropdownOpen(false);
  };

  const validateFields = () => {
    setErrors({});
    let tempErrors: Errors = {};
    if (!potName) tempErrors.potName = "Pot name is required.";
    if (!potAmount || +potAmount <= 0)
      tempErrors.potAmount = "Pot amount is required.";
    // if (!riskRate || +riskRate <= 0)
    //   tempErrors.riskRate = "Risk rate is required.";
    if (!numberOfParticipants || +numberOfParticipants <= 0)
      tempErrors.numberOfParticipants = "Number of participants is required.";
    if (!numberOfParticipants || +numberOfParticipants == 1)
      tempErrors.numberOfParticipants =
        "Number of participants must be greater than 1.";
    if (!rotationCycle || +rotationCycle <= 0)
      tempErrors.rotationCycle = "Rotation cycle is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    if (isInsufficientFundModal) {
      setTimeout(() => setIsInsufficientFundModal(false), 5000);
    }
  }, [isInsufficientFundModal]);

  useEffect(() => {
    resetFields();
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sanitizeInputValue = (value: string) => {
    let sanitizedValue = value.replace(/\D/g, "");
    let intValue = parseInt(sanitizedValue, 10);
    intValue = isNaN(intValue) ? 0 : Math.max(0, intValue);
    return intValue.toString();
  };

  const handleCreatePot = useCallback(async () => {
    if (!provider || !account) {
      // setIsCreatedPot(false);
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!validateFields()) return;
    setPotCode("");
    dispatch(setIsCreatePotLoading(true));
    dispatch(
      setIsCreatedPot({
        isCreated: false,
        isSyncing: false,
        data: {
          name: potName,
        },
      })
    );
    try {
      const getCommitmentResponse = await dispatch(getCommitment({}));
      if (getCommitmentResponse.meta.requestStatus === "fulfilled") {
        try {
          await approveUsdbTx({ amount: potAmount?.toString() });
        } catch (error: any) {
          dispatch(
            setCreatePotError({
              error: error?.message || "Failed to create a pot",
            })
          );
          if (error?.message.includes(Insufficient_Fund_Error)) {
            setIsInsufficientFundModal(true);
            throw new Error(error?.message);
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        navigate(`/dashboard`, { state: { potCreated: true } });
        const { commitment, randomNumber } = getCommitmentResponse.payload;
        dispatch(
          setIsCreatedPot({
            isCreated: false,
            isSyncing: true,
            data: [],
          })
        );
        dispatch(setIsArchived(false));
        const createPotTxResponse = await createPotTx({
          rotationCycleInSeconds: +rotationCycle,
          interestDenominator: 1000,
          interestNumerator: 0,
          numParticipants: +numberOfParticipants,
          amount: potAmount,
          potName,
          userCommitment: commitment,
        });
        setPotId(potId);

        // let createdPotResponse;
        // let attempt = 0;
        // do {
        //   attempt++;
        //   createdPotResponse = await dispatch(
        //     getChatsData({ pageNo: 1, isArchived: false })
        //   );
        // createdPotResponse = await dispatch(
        //   checkSyncDetails({ potId: createPotTxResponse?.potId })
        // );

        //   if (createdPotResponse.meta.requestStatus !== "fulfilled") {
        //     await new Promise((resolve) => setTimeout(resolve, 2000));
        //   }
        // } while (
        //   createdPotResponse.meta.requestStatus !== "fulfilled" &&
        //   attempt < 60
        // );
        // if (createdPotResponse.meta.requestStatus === "fulfilled") {
        //   let code = createdPotResponse.payload?.pot?.potCode;
        //   let chatId = createdPotResponse.payload?.pot?.groupChat;
        //   setPotDetail(createdPotResponse.payload?.pot);
        //   setPotCode(code);
        //   setIsJoinPotConfirmModal(false);
        //   dispatch(
        //     setIsCreatedPot({
        //       isCreated: true,
        //       data: createdPotResponse.payload?.pot,
        //     })
        //   );
        //   navigate(`/dashboard/${chatId}`, {
        //     state: {
        //       potCreated: true,
        //       potCode: code,
        //     },
        //   });
        //   dispatch(setIsArchived(false));
        //   resetFields();
        // setIsJoinPotConfirmModal(true);
        // createdPot(code);
        // } else {
        //   dispatch(
        //     setCreatePotError({
        //       error:
        //         createdPotResponse?.payload?.message ||
        //         "Failed to create a pot",
        //     })
        //   );
        //   toast.error(
        //     createdPotResponse?.payload?.message || "Failed to create a pot",
        //     {
        //       position: "bottom-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     }
        //   );
        // }
      }
      if (getCommitmentResponse.meta.requestStatus === "rejected") {
        dispatch(
          setCreatePotError({
            error:
              getCommitmentResponse?.payload?.message ||
              "Failed to create a pot",
          })
        );

        toast.error(
          getCommitmentResponse?.payload?.message || "Failed to create a pot",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error: any) {
      console.log("error in create a pot", error, error?.message, error?.data);
      dispatch(
        setCreatePotError({
          error: error?.message || "Failed to create a pot",
        })
      );
      toast.error(error?.message || "Failed to create a pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Failed to create pot:", error);
    } finally {
      dispatch(setIsCreatePotLoading(false));
    }
  }, [
    account,
    provider,
    potAmount,
    rotationCycle,
    numberOfParticipants,
    // riskRate,
  ]);

  useEffect(() => {
    setPotCode("");
    // setIsCreatedPot(false);
    dispatch(setIsCreatePotLoading(false));
  }, []);

  const resetFields = () => {
    setPotName("");
    setPotAmount("");
    // setRiskRate("");
    setNumberOfParticipants("");
    setRotationCycle("86400");
    // setTelegramGroupLink("");
    setErrors({});
  };

  const JoinPotHandle = async () => {
    // Ensure MetaMask and account are connected
    if (!provider || !account) {
      dispatch(setWalletConnect(true));
      console.error("MetaMask is not connected or account is not available");
      toast.error("MetaMask is not connected or account is not available", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsConfirmLoading(true);

    try {
      if (potId && potCode) {
        try {
          await approveUsdbTx({ amount: potAmount?.toString() });
        } catch (error: any) {
          console.log("Error during USDB approval:", error?.message);
          if (error?.message.includes(Insufficient_Fund_Error)) {
            setIsJoinPotConfirmModal(false);
            setIsInsufficientFundModal(true);
            return;
          }
          throw new Error(error?.message || "Failed to approve USDB");
        }
        const getJoinPotSignatureResponse = await dispatch(
          joinPotSignature({ potCode })
        );

        if (getJoinPotSignatureResponse.meta.requestStatus === "fulfilled") {
          const { signature, nonce } = getJoinPotSignatureResponse.payload;
          await joinPotTx({
            potId,
            signature,
            nonce: nonce,
            shouldSync: false,
          });
          let joinPotResponse;
          let attempt = 0;
          do {
            attempt++;
            joinPotResponse = await dispatch(
              checkSyncDetails({ potId, eventType: "participantUpdated" })
            );

            if (joinPotResponse.meta.requestStatus !== "fulfilled") {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          } while (
            joinPotResponse.meta.requestStatus !== "fulfilled" &&
            attempt < 60
          );
          if (joinPotResponse.meta.requestStatus === "fulfilled") {
            setIsJoinPotConfirmModal(false);
            navigate("/dashboard", {
              state: {
                potCreated: true,
                potCode,
              },
            });
            resetFields();
          } else {
            toast.error(
              joinPotResponse?.payload?.message || "Failed to join a pot",
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          // Retry mechanism
          // let attempt = 0;
          // let joinPotResponse;
          // do {
          //   attempt++;
          //   joinPotResponse = await dispatch(
          //     joinPot({ walletAddress: account, potCode })
          //   );

          //   // If the request is not fulfilled, wait for a bit before retrying
          //   if (joinPotResponse.meta.requestStatus !== "fulfilled") {
          //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
          //   }
          // } while (
          //   joinPotResponse.meta.requestStatus !== "fulfilled" &&
          //   attempt < 6
          // ); // Retry up to 6 times

          // Check if it succeeded after retries
        }
        if (getJoinPotSignatureResponse.meta.requestStatus === "rejected") {
          toast.error(
            getJoinPotSignatureResponse?.payload?.message ||
              "Failed to join a pot",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to join a pot", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // console.error("Failed to create pot:", error);
    } finally {
      setIsConfirmLoading(false);
    }
  };

  const createdPot = (code: string) => {};

  const JoinPotConfirmCloseHandle = () => {
    setIsJoinPotConfirmModal(false);
  };

  const onCloseInsifficientModal = () => {
    setIsInsufficientFundModal(false);
  };

  return (
    <>
      <Box>
        <ButtonBox>
          <MainHeading>Create New Pot</MainHeading>
          <Label>Pot Name</Label>
          <Input
            placeholder="My amazing pot"
            type="text"
            value={potName}
            onChange={(e) => setPotName(e.target.value)}
          />
          {errors.potName && <ErrorMessage>{errors.potName}</ErrorMessage>}
          {/* <Row>
          <Column> */}
          <Label>Pot Amount</Label>
          {/* <Input
              placeholder="100"
              type="number"
              value={potAmount}
              onChange={(e) => setPotAmount(e.target.value)}
            /> */}
          <PotAmountInputContainer>
            <PotAmountInput
              placeholder="100"
              type="number"
              min="0"
              value={potAmount}
              onChange={(e) => setPotAmount(sanitizeInputValue(e.target.value))}
              onBlur={(e) => {
                if (parseFloat(e.target.value) < 0) {
                  setPotAmount("1");
                }
              }}
            />
            <PotAmountCointype>USDB</PotAmountCointype>
          </PotAmountInputContainer>
          {errors.potAmount && <ErrorMessage>{errors.potAmount}</ErrorMessage>}
          {/* </Column>
          <Column>
            {window.innerWidth >= 768 ? (
              <Label>Risk Rate</Label>
            ) : (
              <Label>Risk Rate</Label>
            )}
            <PotRiskRateContainer>
              <PotRiskRateInput
                placeholder="1"
                type="number"
                min="0"
                value={riskRate}
                onChange={(e) =>
                  setRiskRate(sanitizeInputValue(e.target.value))
                }
                onBlur={(e) => {
                  if (parseFloat(e.target.value) < 0) {
                    setRiskRate("1");
                  }
                }}
              />
              <PotRiskRate>%</PotRiskRate>
            </PotRiskRateContainer>
            {errors.riskRate && <ErrorMessage>{errors.riskRate}</ErrorMessage>}
          </Column> */}
          {/* </Row> */}
          <Row>
            <Column>
              <Label>No. of participants</Label>
              <Input
                placeholder="2"
                type="number"
                min="0"
                value={numberOfParticipants}
                onChange={(e) =>
                  setNumberOfParticipants(sanitizeInputValue(e.target.value))
                }
                onBlur={(e) => {
                  if (parseFloat(e.target.value) < 0) {
                    setNumberOfParticipants("1");
                  }
                }}
              />
              {errors.numberOfParticipants && (
                <ErrorMessage>{errors.numberOfParticipants}</ErrorMessage>
              )}
            </Column>
            <Column>
              <Label>Rotation Cycle</Label>
              <CustomDropdown
                ref={dropdownRef}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedOption}
                {isDropdownOpen && (
                  <DropdownOptions>
                    {options.map((option) => (
                      <DropdownOption
                        key={option.value}
                        onClick={() => handleSelectOption(option)}
                      >
                        {option.label}
                      </DropdownOption>
                    ))}
                  </DropdownOptions>
                )}
                <ExpandIcon />
              </CustomDropdown>
              {errors.rotationCycle && (
                <ErrorMessage>{errors.rotationCycle}</ErrorMessage>
              )}
            </Column>
          </Row>

          {/* <TelegramGroup>
          <Label>Telegram Group link</Label>

          <TelegramInputContainer>
            <TelegramImage />
            <TelegramInput
              placeholder="telegram.com/quailFinance"
              type="text"
              value={telegramGroupLink}
              onChange={(e) => setTelegramGroupLink(e.target.value)}
            />
          </TelegramInputContainer>
        </TelegramGroup> */}

          <SecondaryLabel>
            <InfoIcon />
            <span>
              Please note that participation is “Invite Only” so share links
              with people that you really trust.
            </span>
          </SecondaryLabel>

          <div style={{ margin: "20px 0" }}>
            <CreateAPotButton
              disabled={createPotLoading}
              onClick={!createPotLoading ? handleCreatePot : undefined}
              height="48px"
              padding="0 16px"
            >
              {createPotLoading ? <Loader /> : "Create a Pot"}
            </CreateAPotButton>
          </div>
        </ButtonBox>
        {isJoinPotConfirmModal && (
          <CreatePotConfirmModal
            onClose={JoinPotConfirmCloseHandle}
            onConfirm={JoinPotHandle}
            isLoading={isConfirmLoading} // Update this according to your logic
            potDetail={potDetail}
          />
        )}

        {isInsufficientFundModal && (
          <InsufficientFundModal onClose={onCloseInsifficientModal} />
        )}
      </Box>
      <Footer />
    </>
  );
};

export default CreatePotPage;

const PotAmountInputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0px auto 44px auto;
  @media (max-width: 475px) {
    margin: 0px auto 60px auto;
  }
`;
const PotAmountCointype = styled.p`
  position: absolute;
  right: 10px;
  transform: translateY(-10%);
  width: 45px;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
`;
const PotAmountInput = styled.input<{ $inputColor?: string }>`
  position: absolute;
  padding: 10px 55px 10px 16px;
  box-sizing: border-box;
  outline: none;

  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  width: 100%;
`;

const PotRiskRateContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0px auto 44px auto;
`;
const PotRiskRate = styled.p`
  position: absolute;
  right: 10px;
  transform: translateY(-10%);
  width: 15px;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
`;
const PotRiskRateInput = styled.input<{ $inputColor?: string }>`
  position: absolute;
  padding: 10px 25px 10px 16px;
  box-sizing: border-box;
  outline: none;

  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  width: 100%;
`;

const ExpandIcon = styled(ExpandMoreIcon)`
  position: absolute;
  right: 10px;
  transform: translateY(-10%);
  width: 45px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
`;

const TelegramInputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0px auto 30px auto;
`;

const TelegramGroup = styled.div`
  align-self: stretch;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TelegramImage = styled(TelegramLogo)`
  position: absolute;
  left: 10px;
  transform: translateY(50%);
  width: 24px;
  height: 24px;
`;
const TelegramInput = styled.input<{ $inputColor?: string }>`
  padding: 10px 15px 10px 45px;
  box-sizing: border-box;
  outline: none;
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch !important;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  width: 100%;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  @media (max-width: 475px) {
    margin-bottom: 12px;
  }
`;

export const Box = styled.div`
  margin: 70px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (min-width: 1700px) {
    height: 74vh;
  }
  @media (min-width: 475px) {
    height: 80vh;
  }
  @media (max-width: 475px) {
    margin: 0 auto;
    overflow-y: scroll;
  }
`;

export const MainHeading = styled.h1`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #b1dd40;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: Orbitron; */
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;
  @media (max-width: 475px) {
    font-size: 24px;
    line-height: 80%;
  }
`;

const Label = styled.div`
  && {
    color: #fff;
    font-family: ${(props) => props.theme.fontFamily.secondary};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    align-items: left;
    margin-top: 24px;
    margin-bottom: 12px;
    @media (max-width: 475px) {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }
`;

const InfoIcon = styled(infoSvg)`
  top: 20px;
  right: 20px;
  opacity: 0.7;
  padding: 1px 8px 0 0;
`;

const SecondaryLabel = styled.div`
  color: #e9f6d0;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  /* font-family: "Space Grotesk", sans-serif; */
  opacity: 0.7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  display: flex;
  margin-top: 1.5em;
  @media (max-width: 768px) {
    margin-top: 1em;
  }
`;

export const ButtonBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 500px;
  padding: 1em;
`;

export const BaseButton = styled.button`
  cursor: pointer;
  width: fit-content;
  display: flex;
  height: 48px;
  width: 167px;
  padding: 0px 16px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-top: 32px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;
  border: none;

  @media (max-width: 768px) {
    height: 40px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 34px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

// @ts-ignore
export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
  &:active {
    color: grey;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 2em;
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CustomDropdown = styled.div`
  position: relative;
  width: 92%;
  @media (max-width: 507px) {
    width: 95%;
  }
  height: 28px;
  background: rgba(233, 246, 208, 0.08);
  border: 1px solid rgba(233, 246, 208, 0.4);
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const DropdownOptions = styled.div`
  position: absolute;
  width: 100%;
  background: #13160b;
  border: 1px solid rgba(233, 246, 208, 0.4);
  border-radius: 8px;
  color: white;
  padding: 0;
  margin-top: 2px;
  z-index: 100;
`;

const DropdownOption = styled.div`
  padding: 10px;

  &:hover {
    background-color: rgba(233, 246, 208, 0.2);
  }
`;

import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import { ReactComponent as LogoIcon } from "../assets/Logo.svg";
import { ReactComponent as CloseIcon } from "../assets/close_icon.svg";
import { ReactComponent as CancelIcon } from "../assets/cancel_big.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
// import { ethers } from "ethers";
import { ReactComponent as ExpandMoreIcon } from "../assets/expand_more.svg";
import { ReactComponent as BlastIcon } from "../assets/BlastIcon.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { ReactComponent as CheckCircle } from "../assets/bx-check-circle.svg";
// import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import notificationSvg from "../assets/notifications_bell.svg";
import { StyledEllipse, StyledEllipseMobile } from "./StyledEllipse";
import WalletConnectingModal from "./Modal/WalletConnectingModal";
import BlastPointsModal from "./Modal/BlastPointsModal";

import { walletConnectV2 } from "../utils/connectors/walletConnectV2";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { coinbaseWallet } from "../utils/connectors/coinbaseWallet";
import { useWeb3React } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  getRewards,
  logout,
  registeringUser,
  resetClaimFund,
  resetCreatePot,
  resetDeposit,
  resetLeavePot,
  resetSelectWinner,
  RootState,
  setIsRegistered,
  setIsSigningMessage,
  setWalletConnect,
  verifyUser,
  getProfileData,
  setResetSyncedSelectWinner,
  resetIsJoined,
} from "../store";

import { localStorageStates } from "../utils";
import UnSupportedChainModal from "./Modal/UnSupportedChainModal";
import ErrorModal from "./Modal/ErrorModal";
import { toast } from "react-toastify";
import { checkUserSigned } from "../utils/helper";
import { metaMask } from "../utils/connectors/metamask";
import NotificationModal from "./Modal/notificationModal";

// interface CustomWindow extends Window {
//   ethereum?: {
//     on: (event: string, callback: (error: any) => void) => void;
//     request: (request: { method: string; params?: any[] }) => Promise<any>;
//   };
// }

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    createPot: {
      loading: createPotLoading,
      isCreated: isCreatedPot,
      data: createPotData,
      error: creatPotError,
    },
    claimFund: {
      loading: claimFundLoading,
      hasSynced: isClaimedFund,
      data: claimFundData,
      error: claimFundError,
    },
    selectWinner: {
      loading: selectWinnerLoading,
      hasSynced: isSelectedWinner,
      data: selectWinnerData,
      error: selectWinnerError,
    },
    deposit: {
      loading: depositLoading,
      hasSynced:isDeposited,
      isJoined,
      data: depositData,
      error: depositError,
      
    },
    leavePot: {
      loading: leavePotLoading,
      hasSynced:isLeavePot,
      data: leavePotData,
      error: leavePotError,
      
    },
  } = useSelector((state: RootState) => state.wallet);
  const { isActive, connector, account, chainId, provider } = useWeb3React();
  const [isNotification, setIsNotification] = useState(false);
  const chatRef = useRef<HTMLButtonElement>(null);
  const BlastRef = useRef<HTMLButtonElement>(null);
  const notificationRef = useRef<HTMLButtonElement>(null);
  const [potCode, setPotCode] = useState("");
  let [searchParams] = useSearchParams();
  const path = location.pathname;
  const hasDashboard = path.includes("/dashboard");

  const [error, setError] = useState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isBlastPointsModal, setIsBlastPointsModal] = useState(false);
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const [twitterUserName, setTwitterUserName] = useState<string>("");
  const [isUnSupportedChainModalOpen, setIsUnSupportedChainModalOpen] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isChats, setIsChats] = useState(false);
  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  // const [isClaimPoints, setIsClaimPoints] = useState(false);

  const { isConnecting } = useSelector((state: RootState) => state.wallet);

  const authToken = localStorage.getItem("token");

  if (searchParams.get("code") && (searchParams.get("code") || "").length > 0) {
    localStorage.setItem(
      localStorageStates.code,
      searchParams.get("code") || ""
    );
  }

  const {
    leaderboardRewards: {
      data: leaderboardRewardsData,
      loading: leaderboardRewardsLoading,
    },
  } = useSelector((state: RootState) => state.wallet);

  const domain = window.location.hostname;
  const [userProfile, setUserProfile] = useState({
    username: "",
    points: 0,
    profilePicture: "",
    twitterInfo: null,
    discordInfo: null,
    generatedInviteCodes: [],
    isBlacklisted: false,
    rank: 0,
    allocatedGold: 0,
    claimedGold: 0,
    depositedAmount: 0,
  });

  const toggleMobileMenu = () => {
    // const newIsMobileMenuOpen = !isMobileMenuOpen;
    setIsMobileMenuOpen(!isMobileMenuOpen);

    document.body.style.overflow = isMobileMenuOpen ? "" : "hidden";
  };

  useEffect(() => {
    if (isErrorModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isErrorModalOpen]);

  const signMessage = async (message: string) => {
    if (!provider) {
      return;
    }

    try {
      const signer = provider.getSigner();
      const fullMessage = `Sign in to the Quail mainnet beta.\n\nNonce: ${message}`;
      const signature = await signer.signMessage(fullMessage);
      return signature;
    } catch (error: any) {
      console.error("Error signing message:", error);
      if (error) {
        toast.error(error?.message || "Error signing message:", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleClickNotification = () => {
    setIsNotification(!isNotification);
  };

  const handleChatsClick = () => {
    setIsChats(!isChats);
  };

  const handleBlastPointsModal = () => {
    setIsBlastPointsModal(!isBlastPointsModal);
  };

  const onBlastModalClose = () => {
    setIsBlastPointsModal(false);
  };

  // const openClaimDetailsModal = () => {
  //     setIsClaimPoints(!isClaimPoints);
  // };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      // setAccount(accountProp);
      closeWalletModal();
    }
  }, []);

  // const openWalletModal = () => {
  //   setWalletModalOpen(true);
  // };

  const closeWalletModal = () => {
    dispatch(setWalletConnect(false));
    setWalletModalOpen(false);
  };

  const closeUnSupportedChainModal = () => {
    setIsUnSupportedChainModalOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (account) {
        const gotProfileData = await dispatch(getProfileData(account));
        if (gotProfileData.meta.requestStatus === "fulfilled") {
          if (gotProfileData?.payload?.username) {
            setUserProfile(gotProfileData.payload);
            localStorage.setItem(
              localStorageStates.userInfo,
              JSON.stringify(gotProfileData.payload)
            );
          }
        }
        if (userInfoData?.allocatedGold > 0) {
          setIsBlastPointsModal(true);
        }
      }
    })();
  }, [chainId, account, dispatch]);

  useEffect(() => {
    if (chainId != process.env.REACT_APP_CHAIN_ID && chainId) {
      // handleDisconnect();
      dispatch(logout());
      setIsUnSupportedChainModalOpen(true);
    } else {
      closeUnSupportedChainModal();
    }
  }, [chainId]);

  useEffect(() => {
    closeErrorModal();
    setTwitterUserName("");
    setTwitterUserName(
      localStorage.getItem(localStorageStates.twitterUsername) || ""
    );
    if (chainId == process.env.REACT_APP_CHAIN_ID && chainId) {
      closeUnSupportedChainModal();
      (async () => {
        const registeredAddress = localStorage.getItem(
          localStorageStates.registeredAddress
        );
        if (isActive && account && registeredAddress != account) {
          localStorage.setItem(localStorageStates.twitterUsername, "");
          setTwitterUserName("");
          try {
            // navigate("/");
            dispatch(logout());
            const registeredUser = await dispatch(
              registeringUser({ walletAddress: account })
            );
            if (registeredUser.meta.requestStatus === "fulfilled") {
              if (registeredUser.payload?.nonce) {
                const nonce = registeredUser.payload.nonce;
                const isExist = registeredUser?.payload?.exists;
                if (isExist) {
                  // localStorage.setItem(localStorageStates.isRegistered, "true");
                  // dispatch(setIsRegistered(true));
                }
                const signature = await signMessage(nonce);
                if (signature) {
                  const verifiedUser = await dispatch(
                    verifyUser({
                      walletAddress: account,
                      nonce: nonce,
                      signature: signature,
                    })
                  );
                  if (verifiedUser.meta.requestStatus === "fulfilled") {
                    localStorage.setItem(localStorageStates.isSigned, "true");
                    const token = verifiedUser.payload?.token;
                    const redeemed = verifiedUser.payload?.userInfo?.invitedBy;
                    const hasPaidFee =
                      verifiedUser.payload?.userInfo?.hasPaidFee;
                    const twitterUsername =
                      verifiedUser.payload?.userInfo?.twitterInfo?.username;
                    const discordUsername =
                      verifiedUser.payload?.userInfo?.discordInfo?.username;
                    localStorage.setItem(localStorageStates.token, token);
                    localStorage.setItem(
                      localStorageStates.registeredAddress,
                      account
                    );

                    localStorage.setItem(
                      localStorageStates.redeemed,
                      redeemed ? "true" : "false"
                    );
                    localStorage.setItem(
                      localStorageStates.twitterUsername,
                      twitterUsername || ""
                    );
                    setTwitterUserName(twitterUsername || "");
                    localStorage.setItem(
                      localStorageStates.discordUsername,
                      discordUsername || ""
                    );
                    if (hasPaidFee) {
                      localStorage.setItem(
                        localStorageStates.isRegistered,
                        "true"
                      );
                      dispatch(setIsRegistered(true));
                    } else {
                      localStorage.setItem(
                        localStorageStates.isRegistered,
                        "false"
                      );
                      dispatch(setIsRegistered(false));
                    }
                    if (verifiedUser.payload?.mssg) {
                      localStorage.setItem(
                        localStorageStates.isSigned,
                        "false"
                      );
                      // localStorage.setItem(
                      //   localStorageStates.isRegistered,
                      //   "false"
                      // );
                      // dispatch(setIsRegistered(false));
                    }
                    localStorage.setItem(
                      localStorageStates.userInfo,
                      JSON.stringify(verifiedUser.payload?.userInfo)
                    );
                    dispatch(setIsSigningMessage(false));
                    const gotProfileData = await dispatch(
                      getProfileData(account)
                    );
                    if (gotProfileData.meta.requestStatus === "fulfilled") {
                      if (gotProfileData?.payload?.username) {
                        setUserProfile(gotProfileData.payload);
                        localStorage.setItem(
                          localStorageStates.userInfo,
                          JSON.stringify(gotProfileData.payload)
                        );
                      }
                    }
                    if (userInfoData?.allocatedGold > 0) {
                      setIsBlastPointsModal(true);
                    }
                  }
                  if (verifiedUser.meta.requestStatus === "rejected") {
                    toast.error(
                      verifiedUser?.payload?.message || "Failed to verified",
                      {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                    localStorage.setItem(localStorageStates.isSigned, "false");
                    // localStorage.setItem(
                    //   localStorageStates.isRegistered,
                    //   "false"
                    // );
                    // dispatch(setIsRegistered(false));
                    setIsErrorModalOpen(true);
                    return;
                  }
                  // else {
                  //   localStorage.setItem(localStorageStates.isSigned, "false");
                  //   localStorage.setItem(
                  //     localStorageStates.isRegistered,
                  //     "false"
                  //   );
                  //   dispatch(setIsRegistered(false));
                  //   setIsErrorModalOpen(true);
                  //   return;
                  // }
                }
              }
            }
            if (registeredUser.meta.requestStatus === "rejected") {
              toast.error(
                registeredUser?.payload?.message || "Failed to register",
                {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
            //  else {
            //   setIsErrorModalOpen(true);
            // }
          } catch (err) {
            setIsErrorModalOpen(true);
            localStorage.setItem(
              localStorageStates.isConnectingTwitter,
              "false"
            );
          }
        } else {
          localStorage.setItem(localStorageStates.isSigned, "true");
          // dispatch(setIsSigningMessage(false));
        }
      })();
    }
  }, [account, chainId]);

  // useEffect(() => {

  // }, [chainId, account, dispatch]);

  useEffect(() => {
    if (isConnecting) {
      setWalletModalOpen(true);
    }
    (async () => {
      if (connector instanceof MetaMask) {
        await metaMask.connectEagerly().catch((error) => {
          console.debug("Failed to connect eagerly to metamasl", error);
        });
      }
      if (connector instanceof WalletConnect) {
        await walletConnectV2.connectEagerly().catch((error) => {
          console.debug("Failed to connect eagerly to wallet connect", error);
        });
      }
      if (connector instanceof CoinbaseWallet) {
        await coinbaseWallet.connectEagerly().catch((error) => {
          console.debug("Failed to connect eagerly to coinbase", error);
        });
      }
    })();

    return () => {
      document.body.style.overflow = "";
    };
  }, [isConnecting]);

  const [dropdownvisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onRefresh = () => {
    closeErrorModal();
    window.location.reload();
  };

  const toggleDropdown = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setDropdownVisible(!dropdownvisible);
  };
  useEffect(() => {
    (async () => {
      await dispatch(getRewards({}));
    })();
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownvisible]);

  useEffect(() => {
    setIsErrorModalOpen(false);
    const handleClickOutside = (event: MouseEvent) => {
      if (chatRef.current && !chatRef.current.contains(event.target as Node)) {
        setIsChats(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        BlastRef.current &&
        !BlastRef.current.contains(event.target as Node)
      ) {
        setIsBlastPointsModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setIsChats(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (window.history.state && window.history.state.state) {
      window.history.replaceState({}, document.title);
    }

    // Your existing logic
    if (location.state?.potCreated && location.state?.potCode) {
      setPotCode(location.state?.potCode);
    }
  }, [location]);

  const handleDisconnect = async () => {
    if (connector) {
      if (connector?.deactivate) {
        await connector.deactivate();
      } else {
        await connector?.resetState();
      }

      dispatch(logout());
      // navigate("/");
    }
  };

  const openNotificationModal = (index: number, chatId: any) => {
    // setClickedRowIndex(index);
    // setIsNotificationModalOpen(true);
    navigate(`/dashboard/${chatId}`);
  };

  // const handleEarlyAccessClick = () => {
  //   if (account && isActive) {
  //     if (!checkUserSigned()) {
  //       toast.error(
  //         "Please sign the message from wallet or reload the page to continue",
  //         {
  //           position: "bottom-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         }
  //       );
  //       return;
  //     }
  //     const redeemed = localStorage.getItem(localStorageStates.redeemed);
  //     const twitterUsername = localStorage.getItem(
  //       localStorageStates.twitterUsername
  //     );
  //     const discordUsername = localStorage.getItem(
  //       localStorageStates.discordUsername
  //     );
  //     if (redeemed == "true" && twitterUsername && discordUsername) {
  //       navigate("/createPot");
  //     } else {
  //       navigate("/early-access-verification");
  //     }
  //   } else {
  //     setWalletModalOpen(true);
  //   }
  // };
  const handleEarlyAccessClick = () => {
    if (account && isActive) {
      if (!checkUserSigned()) {
        toast.error(
          "Please sign the message from wallet or reload the page to continue",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      const twitterUsername = localStorage.getItem(
        localStorageStates.twitterUsername
      );

      if (twitterUsername) {
        navigate("/createPot");
      } else {
        navigate("/early-access");
      }
    } else {
      setWalletModalOpen(true);
    }
  };
  // const handleEarlyAccessClick = () => {
  //   if (account && isActive) {
  //     if (!checkUserSigned()) {
  //       toast.error(
  //         "Please sign the message from wallet or reload the page to continue",
  //         {
  //           position: "bottom-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         }
  //       );
  //       return;
  //     }
  //     const redeemed = localStorage.getItem(localStorageStates.redeemed);
  //     const twitterUsername = localStorage.getItem(
  //       localStorageStates.twitterUsername
  //     );
  //     const discordUsername = localStorage.getItem(
  //       localStorageStates.discordUsername
  //     );
  //     if (redeemed == "true" && twitterUsername && discordUsername) {
  //       navigate("/createPot");
  //     } else {
  //       navigate("/early-access-verification");
  //     }
  //   } else {
  //     setWalletModalOpen(true);
  //   }
  // };

  const userInfoDataString = localStorage.getItem("userInfo");
  const userInfoData = userInfoDataString
    ? JSON.parse(userInfoDataString)
    : null;

  // Use optional chaining and nullish coalescing to handle potential null values
  const liquidityPoints = userInfoData?.liquidityPoints ?? 0;
  const unClaimedLiquidityPoints = userInfoData?.unClaimedLiquidityPoints ?? 0;
  const totalLiquidityPoints = liquidityPoints + unClaimedLiquidityPoints;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCreatedPot) {
      timeout = setTimeout(() => {
        dispatch(resetCreatePot());
      }, 10000);
    }
    if (creatPotError) {
      timeout = setTimeout(() => {
        dispatch(resetCreatePot());
      }, 5000);
    }
    if (isClaimedFund) {
      timeout = setTimeout(() => {
        dispatch(resetClaimFund());
      }, 5000);
    }
    if (claimFundError) {
      timeout = setTimeout(() => {
        dispatch(resetClaimFund());
      }, 5000);
    }
    if (isSelectedWinner) {
      timeout = setTimeout(() => {
        dispatch(setResetSyncedSelectWinner());
      }, 5000);
    }
    if (selectWinnerError) {
      timeout = setTimeout(() => {
        dispatch(resetSelectWinner());
      }, 5000);
    }
    if(isJoined){
      timeout = setTimeout(() => {
        dispatch(resetIsJoined());
      }, 5000);
    }
    if (isDeposited || isJoined) {
      timeout = setTimeout(() => {
        dispatch(resetDeposit());
      }, 5000);
    }
    if (depositError) {
      timeout = setTimeout(() => {
        dispatch(resetDeposit());
      }, 5000);
    }
    if (isLeavePot) {
      timeout = setTimeout(() => {
        dispatch(resetLeavePot());
      }, 5000);
    }
    if (leavePotError) {
      timeout = setTimeout(() => {
        dispatch(resetLeavePot());
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    isCreatedPot,
    dispatch,
    creatPotError,
    isClaimedFund,
    claimFundError,
    isSelectedWinner,
    selectWinnerError,
    isDeposited,
    isJoined,
    depositError,
    isLeavePot,
    leavePotError,
  ]);

  return (
    <StyledHeader>
      <StyledEllipse isopen={isMobileMenuOpen ? true : false} />
      <LogoLink to="/">
        <Logo onClick={() => console.log("Navigate to home")}>
          <LogoSvgWrapper>
            <LogoSvg />
          </LogoSvgWrapper>
          QUAIL FINANCE
        </Logo>
      </LogoLink>
      <DesktopMenu>
        <StyledLink to="/" isactive={location.pathname === "/" ? true : false}>
          Home
        </StyledLink>
        <StyledLink
          to="/dashboard"
          isactive={
            hasDashboard === true && location.pathname === location.pathname
              ? true
              : false
          }
        >
          Dashboard
        </StyledLink>
        <StyledLink
          to="/leaderboard"
          isactive={location.pathname === "/leaderboard" ? true : false}
        >
          Leaderboard
        </StyledLink>
        {/*<StyledLink*/}
        {/*    to="/earn"*/}
        {/*    isActive={location.pathname === "/earn"}*/}
        {/*>*/}
        {/*    Earn*/}
        {/*</StyledLink>*/}
        <StyledLink
          to="https://docs.quail.finance/"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </StyledLink>
      </DesktopMenu>
      <ActionItems>
        {createPotLoading ? (
          <>
            <div style={{ position: "relative" }}>
              <Loader />
              <TooltipContainer>
                <TooltipContent>
                  Please wait while we create your{" "}
                  <div
                    style={{
                      fontWeight: "bold",
                      marginLeft: "4px",
                      marginRight: "4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    `{createPotData?.name}`
                  </div>{" "}
                  pot
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : claimFundLoading ? (
          <>
            <div style={{ position: "relative" }}>
              <Loader />
              <TooltipContainer>
                <TooltipContent>
                  Please wait while claiming your funds{" "}
                  {/* <div style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {" "}
                  POT
                </div> */}
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : selectWinnerLoading ? (
          <>
            <div style={{ position: "relative" }}>
              <Loader />
              <TooltipContainer>
                <TooltipContent>
                  Please wait while selecting the winner{" "}
                  {/* <div style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {" "}
                  POT
                </div> */}
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : leavePotLoading ? (
          <>
            <div style={{ position: "relative" }}>
              <Loader />
              <TooltipContainer>
                <TooltipContent>
                  Please wait while leaving the pot{" "}
                  {/* <div style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {" "}
                  POT
                </div> */}
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : depositLoading ? (
          <>
            <div style={{ position: "relative" }}>
              <Loader />
              <TooltipContainer>
                <TooltipContent>
                  Please wait while depositing{" "}
                  {/* <div style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {" "}
                  POT
                </div> */}
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : isCreatedPot ? (
          <>
            <div style={{ position: "relative" }}>
              <CheckCircle />
              <TooltipContainer>
                <TooltipContent>
                  Your `{createPotData?.name}` pot has been created. Thank you.
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : isClaimedFund ? (
          <>
            <div style={{ position: "relative" }}>
              <CheckCircle />
              <TooltipContainer>
                <TooltipContent>
                  Your fund has been claimed. Thank you.
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : isSelectedWinner ? (
          <>
            <div style={{ position: "relative" }}>
              <CheckCircle />
              <TooltipContainer>
                <TooltipContent>
                  Winner has been selected. Thank you.
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : isDeposited || isJoined? (
          <>
            <div style={{ position: "relative" }}>
              <CheckCircle />
              <TooltipContainer>
                <TooltipContent>
                  Amount has been deposited. Thank you.
                </TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : isLeavePot ? (
          <>
            <div style={{ position: "relative" }}>
              <CheckCircle />
              <TooltipContainer>
                <TooltipContent>You left the Pot. Thank you.</TooltipContent>
              </TooltipContainer>
            </div>
          </>
        ) : creatPotError ? (
          <>
            <div style={{ position: "relative" }}>
              <CancelIcon />
              <ErrorTooltipContainer>
                <TooltipContent>
                  Your pot has not been created. Please try again.
                </TooltipContent>
              </ErrorTooltipContainer>
            </div>
          </>
        ) : claimFundError ? (
          <>
            <div style={{ position: "relative" }}>
              <CancelIcon />
              <ErrorTooltipContainer>
                <TooltipContent>
                  Your fund has not been claimed. Please try again.
                </TooltipContent>
              </ErrorTooltipContainer>
            </div>
          </>
        ) : selectWinnerError ? (
          <>
            <div style={{ position: "relative" }}>
              <CancelIcon />
              <ErrorTooltipContainer>
                <TooltipContent>
                  Winner has not been selected. Please try again.
                </TooltipContent>
              </ErrorTooltipContainer>
            </div>
          </>
        ) : leavePotError ? (
          <>
            <div style={{ position: "relative" }}>
              <CancelIcon />
              <ErrorTooltipContainer>
                <TooltipContent>
                  You did not leave the pot. Please try again.
                </TooltipContent>
              </ErrorTooltipContainer>
            </div>
          </>
        ) : depositError ? (
          <>
            <div style={{ position: "relative" }}>
              <CancelIcon />
              <ErrorTooltipContainer>
                <TooltipContent>
                  Amount has not been deposited. Please try again.
                </TooltipContent>
              </ErrorTooltipContainer>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* <>
          <CancelIcon />
          <ErrorTooltipContainer>
            <TooltipContent>
              Your pot has not been created. Please try again.
            </TooltipContent>
          </ErrorTooltipContainer>
        </> */}
        {/*<BlastPointsBox onClick={handleBlastPointsModal}>*/}
        {isActive && account && twitterUserName && (
          <BlastPointsBox onClick={() => handleBlastPointsModal()}>
            {/* <BlastPointsText onClick={() => setIsClaimPoints(!isClaimPoints)}> */}
            <BlastPointsText>
              {(
                leaderboardRewardsData?.quailRewards +
                leaderboardRewardsData?.blastRewards
              )?.toLocaleString()}{" "}
              PTS
            </BlastPointsText>
            <BlastIcon />
          </BlastPointsBox>
        )}
        {/*TODO:for now chat and notification icons and modals in header commented */}

        {/*<NotificationSvgWrapper onClick={handleChatsClick}>*/}
        {/*    <ChatSvg/>*/}
        {/*</NotificationSvgWrapper>*/}
        {/*{isChats && (*/}
        {/*    <ChatsModal*/}
        {/*        chatRef={chatRef}*/}
        {/*        openNotificationModal={openNotificationModal}*/}
        {/*    ></ChatsModal>*/}
        {/*)}*/}
        {isActive && account && twitterUserName && (
          <NotificationSvgWrapper onClick={handleClickNotification}>
            <img src={notificationSvg} alt={"logo"} />
          </NotificationSvgWrapper>
        )}

        {isActive && account ? (
          <WalletButtonWrapper>
            <ConnectedWalletButton
              ref={buttonRef}
              onClick={toggleDropdown}
              dropdownvisible={dropdownvisible ? true : false}
            >
              {twitterUserName
                ? twitterUserName
                : `${account.substring(0, 6)}...${account.substring(
                    account.length - 4
                  )}`}
              <ExpandMoreIcon />
            </ConnectedWalletButton>
            <WalletDropdown
              ref={dropdownRef}
              show={dropdownvisible ? true : false}
            >
              <WalletAddressRow>
                <LinkedWalletText>LINKED WALLET</LinkedWalletText>
                <WalletAddress>
                  {twitterUserName
                    ? twitterUserName
                    : `${account.substring(0, 6)}...${account.substring(
                        account.length - 4
                      )}`}
                </WalletAddress>
              </WalletAddressRow>
              <DisconnectButton onClick={handleDisconnect}>
                <StyledLogoutSvg />
                Logout
              </DisconnectButton>
            </WalletDropdown>
          </WalletButtonWrapper>
        ) : (
          <ConnectWalletButton onClick={handleEarlyAccessClick}>
            Connect Wallet
          </ConnectWalletButton>
        )}
        <MobileMenuIcon onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </MobileMenuIcon>
      </ActionItems>

      {isMobileMenuOpen && (
        <MobileMenu isopen={isMobileMenuOpen ? true : false}>
          <StyledEllipseMobile />
          <LinkBox
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            <StyledLink to="/">Home</StyledLink>
          </LinkBox>
          <LinkBox
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            <StyledLink to="/dashboard">Dashboard</StyledLink>
          </LinkBox>
          <LinkBox
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            <StyledLink to="/leaderboard">Leaderboard</StyledLink>
          </LinkBox>
          {/*<LinkBox>*/}
          {/*      <StyledLink to="/earn">Earn</StyledLink>*/}
          {/*    </LinkBox>*/}
          <LinkBox
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            <StyledLink
              to="https://docs.quail.finance/"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </StyledLink>
          </LinkBox>
          {isActive && account && twitterUserName && (
            <LinkBox onClick={() => handleBlastPointsModal()}>
              <MobileBlastPointsBox>
                {/* <BlastPointsText onClick={() => setIsClaimPoints(!isClaimPoints)}> */}
                {/* <BlastPointsText>{totalLiquidityPoints} PTS</BlastPointsText> */}
                <BlastPointsText>
                  {(
                    leaderboardRewardsData?.quailRewards +
                    leaderboardRewardsData?.blastRewards
                  )?.toLocaleString()}{" "}
                  PTS
                </BlastPointsText>
                <StarIcon />
              </MobileBlastPointsBox>
            </LinkBox>
          )}
          {isActive && account ? (
            <MobileConnectWalletButton onClick={handleDisconnect}>
              Logout
            </MobileConnectWalletButton>
          ) : (
            <MobileConnectWalletButton onClick={handleEarlyAccessClick}>
              Connect Wallet
            </MobileConnectWalletButton>
          )}
        </MobileMenu>
      )}
      {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )}
      {isNotification && (
        <NotificationModal
          onClose={handleClickNotification}
          notificationRef={notificationRef}
        ></NotificationModal>
      )}

      {isErrorModalOpen && isActive && account && (
        <ErrorModal onRefresh={onRefresh} />
      )}
      {isBlastPointsModal && (
        <BlastPointsModal
          authToken={authToken}
          userInfoData={userInfoData}
          onClose={onBlastModalClose}
          BlastRef={BlastRef}
          inviteLink={`${domain}/${potCode}`}
        />
      )}
      {isUnSupportedChainModalOpen && isActive && account && (
        <UnSupportedChainModal onClose={closeUnSupportedChainModal} />
      )}
      {/*{isClaimPoints && <ClaimPointsDetail/>}*/}
    </StyledHeader>
  );
};

export default Header;

const LinkBox = styled.div`
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid rgba(233, 246, 208, 0.2);
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 88;
  border-bottom: 1px solid rgba(233, 246, 208, 0.2);

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const DesktopMenu = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 968px) {
    gap: 18px;
  }
  @media (max-width: 850x) {
    gap: 14px;
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoSvg = styled(LogoIcon)`
  width: 45px;
  height: 45px;

  @media (max-width: 969px) {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const NotificationSvg = styled(notificationSvg)`
  width: 44px;
  height: 44px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 480px) {
    width: 29px;
    height: 29px;
  }
`;

const NotificationSvgWrapper = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    border-radius: 100px;
    background: rgba(233, 246, 208, 0.12);
  }
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 475px) {
    width: 29px;
    height: 29px;
  }
`;

const StyledLink = styled(Link)<{ isactive?: boolean }>`
  opacity: 0.8;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => (props.isactive ? props.theme.colors.primary : "#fff")};
  text-decoration: none;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) =>
      props.isactive ? props.theme.colors.primary : "transparent"};
    transition: background-color 0.3s;
  }

  &:hover {
    opacity: 1;
  }

  @media (min-width: 769px) and (max-width: 835px) {
    font-size: 11px;
  }
`;

const BlastPointsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    justify-content: start;
    display: none;
  }
`;

const MobileBlastPointsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
`;

const BlastPointsText = styled.div`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #e9f6d0;
  margin-right: 5px;
  @media (min-width: 769px) and (max-width: 835px) {
    font-size: 11px;
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const ActionItems = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

const ConnectWalletButton = styled.button`
  display: flex;
  height: 48px;
  cursor: pointer;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.bold};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  // line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.28px;
  text-transform: uppercase;

  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
  @media (max-width: 768px) {
    // font-size: 13px;
    // padding: 8px 20px;
    // height: 45px;
    display: none;
  }
`;

const MobileConnectWalletButton = styled(ConnectWalletButton)`
  @media (max-width: 768px) {
    margin-bottom: 80px;
    height: 52px;
    width: 100%;
    display: flex; // Always show this button in the mobile menu
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  position: relative;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }

  @media (max-width: 475px) {
    margin-left: 0.5em;
  }
`;

const MobileMenu = styled.div<{ isopen: boolean }>`
  display: none; // Keep this to initially hide the menu
  flex-direction: column;
  justify-content: space-between; // Adjust this if needed
  align-items: center;
  gap: 16px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  background: var(--dark, #13160b);
  /* background-color: rgba(0, 0, 0, 0.9); */
  z-index: 1000;
  padding: 20px 24px; // Add padding to top and bottom for spacing
  box-sizing: border-box; // Ensure padding is included in height calculation
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between; // This helps in pushing the button to the bottom
    align-items: center;
    gap: 16px;
    // Additional styles for padding, etc.
    ${MobileConnectWalletButton} {
      margin-top: auto; // Push the button to the bottom of the mobile menu
      width: 100% !important;
      /* width: calc(
        100% - 40px
      );  */
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 24px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  @media (max-width: 968px) {
    font-size: 20px;
  }
  @media (max-width: 868px) {
    font-size: 17px;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const LogoSvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: auto;

  svg {
    height: 100%;
    width: auto;
  }

  @media (max-width: 968px) {
    height: 40px;
  }
  @media (max-width: 868px) {
    height: 35px;
  }

  @media (max-width: 768px) {
    height: 42px;
  }

  @media (max-width: 480px) {
    height: 39px;
  }
`;

const WalletButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ConnectedWalletButton = styled(ConnectWalletButton)<{
  dropdownvisible: boolean;
}>`
  background: ${(props) =>
    props.dropdownvisible ? props.theme.colors.softGray : "transparent"};
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  border-radius: 6px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: 20px; /* 125% */
  padding: 10px 5px 10px 5px;
  border: none;

  &:hover {
    background: ${(props) => props.theme.colors.softGray};
    color: ${(props) => props.theme.colors.light};
  }
`;

const WalletDropdown = styled.div<{ show: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: ${(props) => props.theme.colors.modalBg};
  border-radius: 6px;
  width: 240px;
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: 1000;
`;

const WalletAddressRow = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const LinkedWalletText = styled.div`
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: 20px;
  text-transform: uppercase;
  opacity: 0.7;
`;

const WalletAddress = styled.div`
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const DisconnectButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.light};
  padding: 14px 16px;
  align-items: center;
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: 20px;
  border-top: ${(props) => props.theme.borders.light};
`;

const StyledLogoutSvg = styled(LogoutIcon)`
  margin-right: 8px;
`;

const Loader = styled.div`
  border: 2px solid #b1dd40;
  border-radius: 50%;
  border-top: 2px solid #13160b; // Adjust the color to match your button or theme
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TooltipContainer = styled.div`
  display: block;
  position: absolute;
  /* bottom: 56px; */
  background-color: #b1dd40;
  width: 270px;
  /* min-width: 270px;
  max-width: 370px; */
  height: 24px;
  border-radius: 8px;
  padding: 12px;
  top: 40px;
  right: -135px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap; /* Prevents the text from wrapping to a new line */

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: -38%; /* Position the pointer at the top of the tooltip */
    transform: translateX(-50%) rotate(0deg); /* Rotate the pointer arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #b1dd40 transparent;
    @media (max-width: 768px) {
      left: 90%;
    }
  }
  @media (max-width: 768px) {
    right: -15px;
  }
  @media (max-width: 370px) {
    width: 250px;
  }
  @media (max-width: 350px) {
    width: 230px;
  }
  @media (max-width: 328px) {
    width: 210px;
  }
`;

const ErrorTooltipContainer = styled(TooltipContainer)`
  background: #ff645d;
  color: black;

  &::after {
    border-color: transparent transparent #ff645d transparent;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-bottom: 12px; /* Add margin bottom to separate paragraphs */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis at the end of the text */
  white-space: nowrap;
  overflow-x: auto;
`;

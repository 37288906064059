import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import WalletConnectIconSvg from "../../assets/wallet-connect-icon.svg";
import { ReactComponent as RightArrowIconSvg } from "../../assets/arrow-right.svg";
import { GnosisSafe } from "@web3-react/gnosis-safe";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { hooks, walletConnectV2 } from "../../utils/connectors/walletConnectV2";
import { getAddChainParameters } from "../../utils/chains";
// import useWallet from "../hooks/wallet";

const WalletConnectOption: React.FC<{
  handleAccount: (account: string) => void;
}> = ({ handleAccount }) => {
  const {
    useChainId,
    useAccounts,
    useIsActivating,
    useAccount,
    useIsActive,
    useProvider,
    useENSNames,
  } = hooks;
  const isActive = useIsActive();
  const chainId = useChainId();
  // const accounts = useAccounts();
  const account = useAccount();
  // const isActivating = useIsActivating();

  const provider = useProvider();
  // const ENSNames = useENSNames(provider);

  const [error, setError] = useState(undefined);

  const switchChain = useCallback(
    async (
      desiredChainId: number | undefined,
      connector: MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet
    ) => {
      try {
        if (desiredChainId === -1 || connector instanceof GnosisSafe) {
          await connector.activate();
        } else if (
          connector instanceof WalletConnectV2 ||
          connector instanceof WalletConnect
        ) {
          // await connector.deactivate();
          await connector.activate(desiredChainId);
        } else {
          if (desiredChainId) {
            await connector.activate(getAddChainParameters(desiredChainId));
          } else {
            await connector.activate();
          }
        }

        setError(undefined);
      } catch (error: any) {
        setError(error);
      }
    },
    [walletConnectV2, chainId, setError]
  );

  useEffect(() => {
    if (isActive && account) {
      handleAccount(account);
    }
  }, [isActive, account]);

  return (
    <WalletOptionButton onClick={() => switchChain(chainId, walletConnectV2)}>
      <WalletDescriptionBox>
        <img src={WalletConnectIconSvg} alt="Wallet Connect" />
        <WalletText>Wallet Connect</WalletText>
      </WalletDescriptionBox>
      <RightArrowIconSvg />
    </WalletOptionButton>
  );
};

export default WalletConnectOption;

const WalletOptionButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0px 16px;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => props.theme.fontWeights.light};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    height: 55px;
  }

  @media (max-width: 480px) {
    height: 45px;
  }
`;

const WalletText = styled.span`
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  text-transform: uppercase;
`;

export const WalletDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  width: 96%;
  max-width: 505px;
  padding: 80px 48px 64px 48px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};

  @media (max-width: 768px) {
    width: 70%;
    gap: 0px;
    padding: 60px 24px 48px 24px;
  }

  @media (max-width: 480px) {
    width: 82%;
    gap: 0px;
    padding: 40px 16px 32px 16px;
  }
`;

export const ModalHeading = styled.h1`
  align-self: stretch;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 34px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

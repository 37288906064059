import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
* {
  user-select: text; /* or auto */
}
  body {
    background-color: ${(props) => props.theme.colors.dark};
    color: ${(props) => props.theme.colors.text};
    overflow-x: hidden; /* Hide horizontal scrollbar */ 
    margin: 0;
    padding: 0;
  }
  iframe {
    display: none;
  }
`;

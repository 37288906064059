import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
// import { getPotDetails } from "../../utils/chatApi/chatApi";
// import { PotDataDetails } from "../../types";
import { dateFormatVal, weiToEther } from "../../utils/helper";
import NewCoinImg from "../../assets/newCoinImg.svg";
import Skeleton from "../Skeleton/Skeleton";
import { APPROVE_TERMS_AND_CONDITIONS } from "../../utils/errors";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import HoverButton from "../Button/HoverButton";

const DepositModal: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  potDetail?: any;
}> = ({ onClose, onConfirm, isLoading, potDetail }) => {
  // const [potDetailData, setPotDetails] = useState<PotDataDetails | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const potCode = potDetail?.potCode;
  const {
    potDetail: { data: potDetailData, loading },
  } = useSelector((state: RootState) => state.wallet);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setShowError(false);
    }
  };

  const handleConfirmClick = () => {
    if (!isChecked) {
      setShowError(true);
    } else {
      onConfirm();
    }
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  // useEffect(() => {
  //   setLoading(true);

  //   const fetchPotDetails = async () => {
  //     try {
  //       const response = await getPotDetails(potCode);
  //       const data = await response;
  //       setPotDetails(data);
  //       console.log(data, "is our data");
  //       setLoading(false);
  //     } catch (error: any) {
  //       console.error("Error fetching pot details:", error);
  //       if (error) {
  //         toast.error(error || "Error fetching pot details:", {
  //           position: "bottom-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //       setLoading(false);
  //     }
  //   };

  //   fetchPotDetails();

  //   // Clean up function if needed
  //   return () => {
  //     // Any cleanup code here
  //   };
  // }, [potCode]);

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        {isLoading ? (
          <SkeletonWrapper>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </SkeletonWrapper>
        ) : (
          <>
            <HeaderTop>
              <ModalTitle>Deposit</ModalTitle>
              <CloseIcon onClick={handleClose} />
            </HeaderTop>
            <BorderBottom />
            <ModalText>#{potDetail?.potId}</ModalText>
            <ModalSubHeading>{`${potDetail?.name}  chat`}</ModalSubHeading>
            <ModalTextSmall>
              by{" "}
              <span style={{ color: "#B1DD40" }}>
                @
                {potDetail?.creatorData?.username ||
                  potDetailData?.creatorData?.username}
              </span>{" "}
            </ModalTextSmall>
            <Row>
              <LeftText>Deposit date</LeftText>

              <RightText>
                {" "}
                {potDetail != null
                  ? dateFormatVal(potDetail?.updatedAt)
                  : "Unknown"}
              </RightText>
            </Row>
            <BorderBottom />
            <Label>Deposit Amount</Label>
            <InputBox>
              <DollarPrimaryNumber>
                <img
                  src={NewCoinImg}
                  alt={"NewCoinImg"}
                  style={{ width: "1.5em", marginRight: "0.5em" }}
                />{" "}
                {potDetail?.amount} USDB
              </DollarPrimaryNumber>
            </InputBox>
            <CheckboxRow>
              <Checkbox
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <CheckboxLabel>
                I agree to the{" "}
                <TermsOfUse to="/termsOfService" target="_blank">
                  Terms of Use
                </TermsOfUse>{" "}
                &{" "}
                <TermsOfUse to="/privacyPolicy" target="_blank">
                  Privacy Policy
                </TermsOfUse>{" "}
                of Quail finance
              </CheckboxLabel>
            </CheckboxRow>
            {showError && (
              <ErrorMessage>{APPROVE_TERMS_AND_CONDITIONS}</ErrorMessage>
            )}
            <div style={{ width: "100%", marginTop: "20px" }}>
              <DepositButton
                onClick={handleConfirmClick}
                height="44px"
                padding="0"
              >
                {" "}
                {isLoading ? <Loader /> : "Make Deposit"}
              </DepositButton>
            </div>
          </>
        )}
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default DepositModal;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin: 0 24px 0 24px;
  margin-top: 4px;
`;
// Styled Components
const ModalContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 96%;
  max-width: 470px;
  padding: 40px;
  /* padding: 48px 48px; */
  background: #13160b;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    /* Apply styles for mobile view */
    width: 100%;
    max-width: 88%; /* Remove max-width for full width */
    padding: 24px; /* Adjust padding as needed */
    border-radius: 12px; /* Rounded corners */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
  }
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
    top: 62%;
  }
`;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: center;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;

const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  color: #e9f6d0;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  margin: 0px;
  opacity: 0.7;
  align-self: stretch;
  text-align: left;
  width: 100%;
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 16px;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  opacity: 0.7;
  text-align: center;
`;

const ModalSubHeading = styled.h3`
  color: #fff;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  margin: 20px 0;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

const ModalTextSmall = styled.div`
  color: #909296;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 6px;
  align-items: center;
`;

const LeftText = styled.div`
  color: rgba(233, 246, 208, 0.8);
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
`;

const RightText = styled.div`
  color: #fff;
  font-family: "Space Mono", monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
`;

const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  margin: 20px 0;
`;

const Label = styled.div`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(233, 246, 208, 0.08);
  padding: 10px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
`;

const IconPlaceholder = styled.div`
  // Placeholder for your icon
`;

const InputText = styled.div`
  color: #fff;
  font-family: "Space Mono", monospace;
  font-size: 16px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: rgba(233, 246, 208, 0.2);
`;

const CheckboxLabel = styled.label`
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
`;

const DepositButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* desktop/misc/button-md */
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 1.5em;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DollarPrimaryNumber = styled.div`
  color: var(--Neutral-White, #fff);
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 96%;
  max-width: 470px;
`;

const TermsOfUse = styled(Link)`
  color: #b1dd40;
  margin: 0 2px;
`;

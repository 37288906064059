export const localStorageStates = {
  account: "account",
  redeemed: "redeemed",
  isRegistered: "isRegistered",
  code: "code",
  walletType: "walletType",
  isWalletConnected: "isWalletConnected",
  twitterUsername: "twitterUsername",
  discordUsername: "discordUsername",
  isConnectingDiscord: "isConnectingDiscord",
  isConnectingTwitter: "isConnectingTwitter",
  token: "token",
  registeredAddress: "registeredAddress",
  totalUsers: "totalUsers",
  userInfo: "userInfo",
  isSigned: "isSigned",
};

export const walletStates = {
  metamask: "metamask",
  coinbase: "coinbase",
  okx: "okx",
  walletconnect: "walletconnect",
};

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import usePageSEO from "../hooks/usePageSEO";

const PrivacyPolicy: React.FC = () => {
  
  usePageSEO({
    title: "Terms of Service | Quail Finance",
    description:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    keywords: [
      "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
    ],
    ogTitle: "Terms of Service | Quail Finance",
    ogDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    ogImage: "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    ogUrl: "https://quail.finance/termsOfService",
    twitterTitle: "Terms of Service | Quail Finance",
    twitterDescription:
      "Discover how Quail Finance enables collateral-free liquidity with our SocialFi and Web3 products on Blast.",
    twitterImage:
      "https://storage.googleapis.com/twitter-finance/quailFinance.jpeg",
    twitterUrl: "https://quail.finance/termsOfService",
  });

  return (
    <>
      <Container>
        <LinearHeader></LinearHeader>
        <HeadingTitle>Terms of Service </HeadingTitle>
        <HeadingSubtitle>Last Updated: 15/02/2024</HeadingSubtitle>
        <ContentBox>
          <SubTitle>
            Quail.finance is a SocialFi dApp that facilitates collateral-free
            loans to group members based on social trust. It is inspired by the
            group-based rotating saving and credit scheme. It is deployed on the
            Blast Blockchain. Quail.finance hereinafter is referred as{" "}
            <span style={{ fontWeight: "bold" }}>
              “Platform” or “Service” or “we” or “us”
            </span>
            .
          </SubTitle>

          <SubTitle>
            THE "PLATFORM" IS AVAILABLE ON AN “AS IS” BASIS WITHOUT WARRANTIES
            OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
            PURPOSE AND NON-INFRINGEMENT.
          </SubTitle>

          <SubTitle>
            YOU ASSUME ALL RISKS ASSOCIATED WITH USING THE "PLATFORM", AND
            DIGITAL ASSETS AND DECENTRALIZED SYSTEMS GENERALLY, INCLUDING BUT
            NOT LIMITED TO, THAT: (A) DIGITAL ASSETS ARE HIGHLY VOLATILE; (B)
            STAKING OR DELEGATING DIGITAL ASSETS IS INHERENTLY RISKY DUE TO BOTH
            FEATURES OF SUCH ASSETS AND SYSTEMS AND THE POTENTIAL UNAUTHORIZED
            ACTS OF THIRD PARTIES; (C) YOU MAY NOT HAVE READY ACCESS TO ASSETS;
            AND (D) YOU MAY LOSE SOME OR ALL OF YOUR TOKENS OR OTHER ASSETS. YOU
            AGREE THAT YOU WILL HAVE NO RECOURSE AGAINST ANYONE ELSE FOR ANY
            LOSSES DUE TO THE USE OF THE "PLATFORM". FOR EXAMPLE, THESE LOSSES
            MAY ARISE FROM OR RELATE TO: (I) LOST FUNDS; (II) SERVER FAILURE OR
            DATA LOSS; (III) CORRUPTED CRYPTOCURRENCY WALLET FILES; (IV)
            UNAUTHORIZED ACCESS; (V) ERRORS, MISTAKES, OR INACCURACIES; OR (VI)
            THIRD-PARTY ACTIVITIES.
          </SubTitle>

          <SubTitle>
            <span style={{ fontWeight: "bold" }}>ARBITRATION NOTICE</span> YOU
            AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL BE RESOLVED BY
            BINDING, INDIVIDUAL ARBITRATION, AND BY ACCEPTING TO USE OUR
            SERVICES, YOU AND THE "PLATFORM" ARE EACH WAIVING THE RIGHT TO A
            TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
            REPRESENTATIVE PROCEEDING.
          </SubTitle>

          <Title>A. Agreement to Terms</Title>
          <SubTitle>
            By accessing or using the Platform (even just browsing{" "}
            <LinkText to="https://quail.finance/" target="_blank">
              https://quail.finance/
            </LinkText>{" "}
            ), you agree that you have read and understood, and, as a condition
            to your use of the “platform”, you agree to be bound by the
            following terms and conditions, including our privacy policy. If you
            don’t agree with these terms, you may not use the “platform”
            including browsing our website. Certain features of the platform,
            including transactions and authentication, are facilitated by
            Third-Party wallet extensions. By using the platform, you agree that
            you are bound by the terms of service and privacy policy for the
            applicable extensions when using the applicable extension.
          </SubTitle>

          <Title>B. Use of Platform</Title>
          <SubTitle>
            You agree to use Quail.finance only for lawful purposes and in
            accordance with the given Terms of Service. The “Platform” includes
            a website that facilitates collateral-free loans to group members
            based on social trust. Through the “Platform”, users can create pot
            ,,, invite participants,,, rotate liquidity
          </SubTitle>
          <SubTitle>
            While Quail.finance offers access to collateral free loans, “the
            platform” will have no insight into or control over active pots, its
            liquidity, payments or transactions. Accordingly, Quail.finance will
            not be responsible or liable to you for any losses incurred as a
            result of your use of “the platform”.
          </SubTitle>

          <Title>C. Eligibility</Title>
          <SubTitle>
            The service is intended for users who are at least 18 years old. You
            agree that by using the site and the platform you are at least 18
            years of age, or accessing the service under the supervision of a
            parent or guardian, and you are legally able to enter into a
            contract. If you are a parent or legal guardian of a user under the
            age of 18 (or the age of legal majority), you agree to be fully
            responsible for the acts or omissions of such user in relation to
            the Platform. If you use the platform on behalf of another person or
            entity, (a) all references to “you” throughout the terms of service
            will include that person or entity, (b) you represent that you are
            authorized to accept these terms of service on that person’s or
            entity’s behalf, and (c) in the event you or the person or entity
            violates these terms, the person or entity agrees to be responsible
            to us. If you are an entity, organization, or company, the
            individual accepting these terms of service on your behalf
            represents and warrants that they have authority to bind you to
            these terms and you agree to be bound by these terms.
          </SubTitle>

          <Title>D. Electronic Wallet Terms </Title>
          <SubTitle>
            To access certain features of the platform, including, you will need
            to register an account with us and connect a third party supported
            crypto wallet (e.g., metamask.io{" "}
            <LinkText to="https://metamask.io" target="_blank">
              metamask.io
            </LinkText>{" "}
            ) (“Wallet”) (“Your Account”). Please review our{" "}
            <LinkText to="https://quail.finance/privacyPolicy" target="_blank">
              Privacy Policy
            </LinkText>{" "}
            for the terms which govern your access to and control of Your
            Account. You are responsible for creating and maintaining the
            confidentiality of your account and you are also responsible for any
            activity concerning your account. By using your crypto wallet in
            connection with the platform, you agree that you are using that
            wallet under the terms and conditions of the provider of the wallet.
            Wallets are not operated by, maintained by, or affiliated with
            Quail.finance and we do not have custody or control over the
            contents of your wallet and have no ability to retrieve or transfer
            its contents. We accept no responsibility for, or liability to you,
            in connection with your use of a wallet and make no representations
            or warranties regarding how the Platform will operate with any
            specific wallet. You are solely responsible for keeping your wallet
            secure and you should never share your wallet credentials with
            anyone. If you discover an issue related to your wallet, please
            contact your wallet provider.
          </SubTitle>
          <SubTitle>
            Likewise, you are solely responsible for your account and any
            associated wallet and we are not liable for any acts or omissions by
            you in connection with your account or as a result of your account
            or wallet being compromised. You agree to immediately notify us if
            you discover or otherwise suspect any security issues related to the
            platform or your account. When you use your wallet with the
            platform, you represent to us that your account and wallet are owned
            or controlled exclusively and directly by you and no other person.
          </SubTitle>

          <Title>E. License </Title>
          <SubTitle>
            You shall not, directly or indirectly: (a) reverse engineer,
            decompile, disassemble, or otherwise attempt to discover the object
            code, source code, or underlying ideas or algorithms of the
            Platform; (b) modify, translate, adapt, or create derivative works
            of the Platform in any way; (c) rent, lease, distribute, sell,
            resell, assign, or otherwise transfer the service or any copy
            thereof; (d) Use the service for any purpose other than its intended
            purpose; (e) attempt to disable or circumvent any security or other
            technological measure designed to protect the service or any content
            available through the platform; or (f) use the service for any
            illicit or prohibited purposes or as a means to infringe any third
            party rights. If you breach these license restrictions, or otherwise
            exceed the scope of the licenses granted in these terms, then you
            may be subject to prosecution and damages, as well as liability for
            infringement of intellectual property rights, and denial of access
            to the service.
          </SubTitle>
          <SubTitle>
            Without limiting any other provision of these terms, we reserve the
            right to, in our sole discretion and without notice or liability,
            deny access to and use of the service (including blocking certain ip
            addresses), to any person for any reason or for no reason, including
            without limitation for breach of any representation, warranty, or
            covenant contained in these terms or of any applicable law or
            regulation. We may terminate your use or participation in the site
            at any time, without warning, at our sole discretion.
          </SubTitle>
          <SubTitle>
            If we terminate or suspend your access to the platform or service
            for any reason, you are prohibited from attempting to access the
            website or service under your name, a fake or borrowed name, or the
            name of any third party, even if you may be acting on behalf of the
            third party. In addition to terminating or suspending your access,
            we reserve the right to take appropriate legal action, including
            without limitation pursuing civil, criminal, and injunctive redress.
          </SubTitle>
          <Title>F. Ownership; Proprietary Rights; Marks</Title>
          <HighlightedSubTitle>
            Ownership; Proprietary Rights{" "}
          </HighlightedSubTitle>
          <SubTitle>
            The visual interfaces, graphics, design, compilation, information,
            data, computer code (including source code or object code),
            products, software, services, and all other elements of the Platform
            (collectively, “Materials”) are protected by intellectual property
            and other laws. It’s important to note that all materials included
            on the Platform are our property or the property of other
            third-party licensors of the service. Except as expressly authorized
            by us, you may not make use of the materials. We reserve all rights
            to Materials not granted expressly in these terms.
          </SubTitle>
          <HighlightedSubTitle>Marks </HighlightedSubTitle>
          <SubTitle>
            Our trademarks, platform or service marks, and logos (collectively,
            the “Quail.finance Trademarks”) used and displayed on the Platform
            are our registered and/or unregistered trademarks or platform or
            service marks. Any other product and service names located on the
            Platform may be trademarks or service marks owned by third parties
            (collectively with the Quail.finance Trademarks, the “Trademarks”).
            Except as otherwise permitted by law, you may not use the Trademarks
            to disparage us or the applicable third party, our or a third
            party’s products or services, or in any manner (using commercially
            reasonable judgment) that may damage any goodwill in the trademarks.
          </SubTitle>
          <Title>G. Cost and Fees </Title>
          <SubTitle>
            Our fees for the platform defined as “Account Creation Fees” is
            0.005 eth charged to users during Quail.finance account creation
            whereas “Rotation Fees” is 1% of total pot size charged during every
            rotation cycle.
          </SubTitle>

          <Title>H. Third-Party Terms</Title>
          <HighlightedSubTitle>External Sites</HighlightedSubTitle>
          <SubTitle>
            The Platform may contain links to or embed content in other
            websites, apps or other online properties that are not owned or
            controlled by the platform (collectively, “External Sites”). The
            content of external sites is not developed or provided by us. We are
            not responsible for the content of any external sites and do not
            make any representations regarding the content or accuracy of any
            materials on external sites. You should contact the site
            administrator or webmaster for external sites if you have any
            concerns regarding content located on those external sites. You
            should take precautions when downloading files from all websites to
            protect your devices from viruses and other destructive programs. If
            you decide to access any external sites, then you do so at your own
            risk. Further, you will be solely responsible for compliance with
            any terms of service or similar terms imposed by any external site
            in connection with your use of external sites.
          </SubTitle>
          <HighlightedSubTitle>Third-Party Software </HighlightedSubTitle>
          <SubTitle>
            The Platform may include or incorporate third-party software
            components that are generally available free of charge under
            licenses granting recipients broad rights to copy, modify, and
            distribute those components (“Third-Party Components”). Although the
            platform is provided to you subject to these terms, nothing in these
            terms prevents, restricts, or is intended to prevent or restrict you
            from obtaining third-party components under the applicable
            third-party licenses or to limit your use of third-party components
            under those third-party licenses.
          </SubTitle>

          <Title>
            I. Prohibited Conduct. BY USING THE PLATFORM, YOU AGREE NOT TO{" "}
          </Title>
          <SubTitle>
            <ol>
              <li>
                Use the Platform for any illegal purpose or in violation of any
                local, state, national, or international law;
              </li>
              <li>
                Harass, threaten, demean, embarrass, bully or otherwise harm any
                other user of the platform;
              </li>
              <li>
                Violate, or encourage others to violate, or provide instructions
                on how to violate any right of a third party, including by
                infringing or misappropriating any third-party intellectual
                property right;
              </li>
              <li>
                Interfere with security-related features of the platform,
                including disabling or circumventing features that prevent or
                limit use or copying of any content.
              </li>
              <li>
                Make unauthorized recordings or screen captures of any content,
                including user content, transmitted on or through the Platform;
              </li>
              <li>
                Interfere with the operation of the platform or any user’s
                enjoyment of the the Platform, including by: (a) uploading or
                otherwise disseminating any virus, adware, spyware, worm, or
                other malicious code; (b) making any unsolicited offer or
                advertisement to anothersUser of the service; (c) collecting
                personal information about another user or third party without
                consent; or (d) interfering with or disrupting any network,
                equipment, or server connected to or used to provide the
                service;
              </li>
              <li>
                Attempt to access or search the the Platform or content
                available on the the Platform or download content from the the
                platform through the use of any engine, software, tool, agent,
                device or mechanism (including spiders, robots, crawlers, data
                mining, gathering, or extraction tools) other than the software
                and/or search agents provided by us or other generally available
                third-party web browsers;
              </li>
              <li>
                Perform any fraudulent activity including impersonating any
                person or entity, claiming a false affiliation, or accessing any
                other account on the platform without permission, or provide any
                false, inaccurate, or misleading information to us
              </li>
              <li>
                Post user content that is abusive, bigoted, dangerous,
                defamatory, false, harassing, harmful, infringing, misleading,
                obscene, offensive, pornographic, racist, threatening, unlawful,
                violent, vulgar, or otherwise inappropriate;
              </li>
            </ol>
          </SubTitle>
          <Title>J. Disclaimer of Warranty </Title>
          <SubTitle>
            The Platform is provided on an "as is" and "as available" basis
            without any representation or warranty of any kind, whether express,
            implied or statutory. The Platform does not guarantee the accuracy,
            timeliness, completeness, reliability, suitability, or availability
            of the service. You use the platform at your own risk.
          </SubTitle>
          <Title>K. Limitation of Liability</Title>
          <SubTitle>
            To the maximum extent permitted by law, the platform and its
            affiliates, officers, employees, agents and partners shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, including without limitation, loss of profits,
            data, use, goodwill, or other intangible losses, resulting from (i)
            your access to or use of or inability to access or use the service,
            (ii) any conduct or content of any third party on the service, or
            (iii) unauthorized access, use or alteration of your transmissions
            or content.
          </SubTitle>
          <Title>L. Indemnification </Title>
          <SubTitle>
            To the fullest extent permitted by law, you are responsible for your
            use of the platform, and you will defend and indemnify us, our
            affiliates and their respective shareholders, directors, managers,
            members, officers, employees, consultants, and agents (together, the
            “Quail.finance Entities”) from and against every claim brought by a
            third party, and any related liability, damage, loss, and expense,
            including reasonable attorneys’ fees and costs, arising out of or
            connected with: (i) your unauthorized use of, or misuse of, the
            platform; (ii) your violation of any portion of these terms, any
            representation, warranty, or agreement referenced in these terms, or
            any applicable law or regulation; (iii) your violation of any
            third-party right, including any intellectual property right or
            publicity, confidentiality, other property, or privacy right; or
            (iv) any dispute or issue between you and any third party. We
            reserve the right, at our own expense, to assume the exclusive
            defense and control of any matter otherwise subject to
            indemnification by you (without limiting your indemnification
            obligations with respect to that matter), and in that case, you
            agree to cooperate with our defense of those claims.
          </SubTitle>
          <Title>M. Modification of Terms </Title>
          <SubTitle>
            The Platform reserves the right to modify these terms at any time.
            You should periodically review these terms for any changes. Your
            continued use of the Platform after any modifications to these terms
            shall constitute your acceptance of such modifications.
          </SubTitle>
          <Title>N. Dispute Resolution </Title>
          <SubTitle>
            Any dispute arising out of or relating to these terms of service or
            the use of Quail.finance shall be resolved through binding
            arbitration. By using Quail.finance, you acknowledge that you have
            read, understood, and agree to be bound by these terms of service.
          </SubTitle>

          <Title>O. Miscellaneous</Title>
          <HighlightedSubTitle>General Terms</HighlightedSubTitle>
          <SubTitle>
            These Terms, together with the{" "}
            <LinkText to="https://quail.finance/privacyPolicy" target="_blank">
              Privacy Policy
            </LinkText>{" "}
            and any other agreements expressly incorporated by reference into
            these Terms, are the entire and exclusive understanding and
            agreement between you and Quail.finance regarding your use of the
            platform. You may not assign or transfer these terms or your rights
            under these terms, in whole or in part, by operation of law or
            otherwise, without our prior written consent. We may assign these
            terms at any time without notice or consent. The failure to require
            performance of any provision will not affect our right to require
            performance at any other time after that, nor will a waiver by us of
            any breach or default of these terms, or any provision of these
            terms, be a waiver of any subsequent breach or default or a waiver
            of the provision itself. Use of section headers in these terms is
            for convenience only and will not have any impact on the
            interpretation of any provision. Throughout these terms, the use of
            the word “including” means “including but not limited to.” If any
            part of these terms is held to be invalid or unenforceable, the
            unenforceable part will be given effect to the greatest extent
            possible, and the remaining parts will remain in full force and
            effect.
          </SubTitle>
          <HighlightedSubTitle>Privacy Policy </HighlightedSubTitle>
          <SubTitle>
            Please read the{" "}
            <LinkText to="https://quail.finance/privacyPolicy" target="_blank">
              Privacy Policy
            </LinkText>{" "}
            carefully for information relating to our collection, use, storage,
            and disclosure of your personal information.
          </SubTitle>
          <HighlightedSubTitle>Additional Terms</HighlightedSubTitle>
          <SubTitle>
            Your use of the Platform is subject to all additional terms,
            policies, rules, or guidelines applicable to the Platform or certain
            features of the platform that we may post on or link to from the
            platform (the “Additional Terms”). All Additional Terms are
            incorporated by this reference into, and made a part of, these
            Terms.
          </SubTitle>

          <HighlightedSubTitle>Contact Information </HighlightedSubTitle>
          <SubTitle>
            If you have any questions about these terms of service, please
            contact us at{" "}
            <LinkText to="mailto:legal@quail.finance">
              legal@quail.finance
            </LinkText>
          </SubTitle>
          <HighlightedSubTitle>No Support </HighlightedSubTitle>
          <SubTitle>
            We are under no obligation to provide support for the platform. In
            instances where we may offer support, the support will be subject to
            published policies.
          </SubTitle>
          <HighlightedSubTitle>International Use </HighlightedSubTitle>
          <SubTitle>
            We make no representation that the Platform is appropriate or
            available for use in your jurisdiction. Access to the platform from
            countries or territories or by individuals where such access is
            illegal is prohibited.
          </SubTitle>
        </ContentBox>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 759px;
  width: 90%;
  min-height: 60vh;
  margin: 0 auto;
`;

const LinearHeader = styled.div`
  position: absolute;
  width: 100%;
  height: 252.978px;
  flex-shrink: 0;
  background-image: linear-gradient(to bottom, #b1dd40 0%, #13160b 90%);
  opacity: 0.3;
`;

const HeadingContainer = styled.div``;

const HeadingTitle = styled.div`
  color: #b1dd40;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 54px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-top: 78px;
`;

const HeadingSubtitle = styled.div`
  color: #e9f6d0;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
  padding-bottom: 77px;
`;

const ContentBox = styled.div`
  gap: 32px;
  /* padding-top: 120px; */
`;

const Title = styled.div`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  opacity: 0.7;
  margin-bottom: 14px;
`;

const HighlightedSubTitle = styled.div`
  color: var(--primary, #b1dd40);
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  opacity: 0.7;
`;

const SubTitle = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
  margin-bottom: 32px;
`;

const LinkText = styled(Link)`
  color: var(--light, #e9f6d0);

  /* desktop/body/large */
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  opacity: 0.7;
`;

import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as RightArrowIconSvg } from "../../assets/arrow-right.svg";
import HoverButton from "../Button/HoverButton";

const InvitePotModal: React.FC<{
  onClose: () => void;
  onConfirm: (text: string) => void;
  isLoading: boolean;
  link: string;
  joinPotModalRef?: any,
}> = ({ onClose, onConfirm, isLoading, link, joinPotModalRef }) => {
  const domain = window.location.hostname;

  const [text, setText] = useState("");

  const handleInputChange = (value: string) => {
    setText(value);
  };

  useEffect(() => {
    if (link) setText(link);
    else setText("");
  }, [link]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>Join a pot</ModalTitle>
        <ModalText>
          Enter an invite link below to join an existing pot
        </ModalText>
        <Row>
          <LeftText>
            Invite link <Span>*</Span>
          </LeftText>
        </Row>
        <Input
          value={text}
          placeholder={`${domain}/abcdef`}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value)
          }
          type="string"
        />
           <div style={{width: '100%', marginTop: '32px'}}>
        <NextButton onClick={() => onConfirm(text)} height="44px" padding="0">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              Next <RightArrowIconSvg />
            </>
          )}
        </NextButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default InvitePotModal;

// Styled Componentsyr

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 505px;
  padding: 40px;
  /* padding: 48px 24px; */
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;

  /* modal glow */
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 614px) {
    width: 100%;
    border-radius: 2em;
    padding: 18px 31px 36px 31px;
  }
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 614px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: #b1dd40;
  /* font-family: Orbitron; */
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  @media (max-width: 614px) {
    font-size: 18px;
  }
`;

const ModalText = styled.div`
  color: #e9f6d0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  opacity: 0.7;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 6px;
  @media (max-width: 614px) {
    margin-top: 10px;
  }
`;

const LeftText = styled.div`
  display: flex;
  color: #fff;
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-top: 16px;
`;

const Span = styled.div`
  color: #ff645d;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-left: 8px;
  margin-top: -4px;
`;

const NextButton = styled(HoverButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 32px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;
export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 8px 0px 16px;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

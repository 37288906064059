import React, { useEffect, useCallback, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as LeftDepositeSvg } from "../../assets/left_deposit.svg";
import { ParticipationModalProps } from "../../utils/interface";
import { ReactComponent as ParticipantsDefaultImage } from "../../assets/ParticipantsDefaultImage.svg";
import defaultImage from "../../assets/default_image.svg";

const ParticipationModal: React.FC<ParticipationModalProps> = ({
  onClose,
  chatListData,
  potDetails,
  potDetailsData,
}) => {
  // const [isClosing, setIsClosing] = useState(false);

  // const handleClose = useCallback(() => {
  //   setIsClosing(true);
  //   setTimeout(() => {
  //     onClose();
  //     setIsClosing(false);
  //   }, 300);
  // }, [onClose]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalContainer visible={visible} onClick={handleBackdropClick}>
      <ModalContent visible={visible}>
        <TopHeader>
          <ModalHeaderTitle>
            Participants for {potDetails?.name}
            <Highlight>({potDetailsData?.length})</Highlight>
          </ModalHeaderTitle>
          <CloseIconContainer onClick={handleClose}>
            <CloseSvg />
          </CloseIconContainer>
        </TopHeader>
        <ModalSubHeader>
          Total Rounds {potDetails?.currentRound - 1}{" "}
        </ModalSubHeader>

        <MiddleSection>
          {Array.isArray(potDetailsData) &&
          potDetailsData?.some(
            (participant: any) =>
              participant?.hasWon &&
              participant?.wonRound === participant?.round
          ) ? (
            <MiddleSectionBox>
              <CurrentLabel>Current</CurrentLabel>
              <CurrentParticipantsGrid>
                {potDetailsData?.map((participant: any, index: number) => {
                  if (
                    participant?.hasWon &&
                    participant?.wonRound === participant?.round
                  ) {
                    return (
                      <ParticipantRow key={`${index}-ParticipantRow`}>
                        {participant?.user?.profilePicture ? (
                          <ParticipantsImageContaine>
                            <img
                              style={{
                                width: "48px",
                                height: "48px",
                                borderRadius: "50%",
                              }}
                              src={participant?.user?.profilePicture}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = defaultImage;
                              }}
                              alt={participant?.user?.username}
                            />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        ) : (
                          <ParticipantsImageContaine>
                            <ParticipantsDefaultImage />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        )}
                        <ParticipantName>
                          {participant?.user?.username}
                        </ParticipantName>
                      </ParticipantRow>
                    );
                  } else {
                    return null; // Skip participants who haven't won or haven't won in the current round
                  }
                })}
              </CurrentParticipantsGrid>
            </MiddleSectionBox>
          ) : null}
        </MiddleSection>

        <PastWinnerSection>
          {Array.isArray(potDetailsData) &&
          potDetailsData?.some(
            (participant: any) =>
              participant?.hasWon &&
              participant?.wonRound !== participant?.round
          ) ? (
            <PastWinnerSectionBox>
              <CurrentLabel>Past Winner</CurrentLabel>
              <PastParticipantsGrid>
                {potDetailsData?.map((participant: any, index: number) => {
                  if (
                    participant?.hasWon &&
                    participant?.wonRound !== participant?.round
                  ) {
                    return (
                      <ParticipantRow key={`${index}-ParticipantRow1`}>
                        {participant?.user?.profilePicture ? (
                          <ParticipantsImageContaine>
                            <img
                              style={{
                                width: "48px",
                                height: "48px",
                                borderRadius: "50%",
                              }}
                              src={participant?.user?.profilePicture}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = defaultImage;
                              }}
                              alt={participant?.user?.username}
                            />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        ) : (
                          <ParticipantsImageContaine>
                            <ParticipantsDefaultImage />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        )}
                        <PastWinnerBox>
                          <ParticipantName>
                            {participant?.user?.username}
                          </ParticipantName>
                          <RoundText>Round {participant?.wonRound}</RoundText>
                        </PastWinnerBox>
                      </ParticipantRow>
                    );
                  } else {
                    return null; // Skip participants who haven't won or haven't won in the previous round
                  }
                })}
              </PastParticipantsGrid>
            </PastWinnerSectionBox>
          ) : null}
        </PastWinnerSection>
        <>
          {Array.isArray(potDetailsData) &&
          potDetailsData?.some(
            (participant: any) => !participant?.hasLeave && !participant?.hasWon
            // && participant?.wonRound !== participant?.round
          ) ? (
            <>
              <ButtomSection>
                <CurrentLabel>Next In Line </CurrentLabel>
                <HorizentalLine></HorizentalLine>
                <HorizentalDot></HorizentalDot>
              </ButtomSection>
              <ButtomParticipantsGrid>
                {potDetailsData?.map((participant: any, index: number) => {
                  if (!participant?.hasLeave && !participant?.hasWon) {
                    return (
                      <ParticipantRow key={`${index}-ParticipantRow2`}>
                        {participant?.user?.profilePicture ? (
                          <ParticipantsImageContaine>
                            <img
                              style={{
                                width: "48px",
                                height: "48px",
                                borderRadius: "50%",
                              }}
                              src={participant?.user?.profilePicture}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = defaultImage;
                              }}
                              alt={participant?.user?.username}
                            />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        ) : (
                          <ParticipantsImageContaine>
                            <ParticipantsDefaultImage />
                            {participant?.round !==
                              potDetails?.currentRound && <LeftDepositIcon />}
                          </ParticipantsImageContaine>
                        )}
                        <ParticipantName>
                          {participant?.user?.username}
                        </ParticipantName>
                      </ParticipantRow>
                    );
                  } else {
                    return null; // Skip participants who have won the last round or have already won
                  }
                })}
              </ButtomParticipantsGrid>
            </>
          ) : null}
        </>
      </ModalContent>
    </ModalContainer>
  );
};

export default ParticipationModal;

const Highlight = styled.span`
  color: #7c88ba;
  font-family: "Space Mono", monospace;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  opacity: 0.7;
`;

const RoundText = styled.div`
  display: flex;
  height: 24px;
  padding: 0 12px 0 12px;
  max-width: 60px;
  align-items: center;
  border-radius: 100px;
  background: #b1dd40;
  color: #13160b;
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const PastWinnerBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContainer = styled.div<{
  visible: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 100;
  border-radius: 8px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  border: 1px solid rgba(233, 246, 208, 0.16);
  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const ModalContent = styled.div<{ visible: boolean }>`
  max-width: 750px;
  width: 100%;
  /* height: 100%; */
  max-height: 709px;
  overflow-y: auto;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  padding: 40px;
  box-sizing: border-box;
  margin: auto 0;
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }

  // Ensure scrolling still works without the scrollbar
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  /* @media (max-width: 475px) {
    padding: 40px 16px 16px 16px;
  } */
`;
const ModalHeaderTitle = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 14px;
  /* @media (max-width: 475px) {
    font-size: 18px;
  } */
`;
const ModalSubHeader = styled.div`
  color: var(--neutral-white, var(--color-white, #fff));
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  /* @media (max-width: 475px) {
    margin-bottom: 48px;
  } */
`;
const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MiddleSection = styled.div`
  display: flex;
`;

const PastWinnerSection = styled.div`
  display: flex;
`;

const ParticipantsImageContaine = styled.div`
  position: relative;
`;
const LeftDepositIcon = styled(LeftDepositeSvg)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 30px;
  top: 33px;
`;
const PastWinnerSectionBox = styled.div``;
const MiddleSectionBox = styled.div``;
const DividerLine = styled.div`
  border: 1px solid #b1dd40;
  height: 96px;
  border-radius: 15px;
  margin: 0 20px;
`;
const HorizentalLine = styled.div`
  display: none;
  border: 1px solid #b1dd40;
  border-radius: 15px;
  width: 459.133px;
  height: 0px;
  margin-left: 25px;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const HorizentalDot = styled.div`
  display: none;
  border: 1px solid #b1dd40;
  border-radius: 15px;
  width: 9.309px;
  height: 10px;
  margin-left: 5px;
  background: #b1dd40;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const ButtomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* justify-content: space-between; */
  /* @media (max-width: 475px) {
    justify-content: flex-start;
  } */
`;
const CurrentLabel = styled.div`
  color: var(--light, #e9f6d0);
  font-family: Orbitron;
  font-size: 20px;
  font-weight: 900;
  line-height: 140%;
  text-transform: uppercase;
`;

const ParticipantsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); // Adjust based on content size
  gap: 32px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // Fewer columns on smaller screens
  }

  @media (max-width: 480px) {
    /* grid-template-columns: repeat(2, 1fr); // Fewer columns on smaller screens */
    grid-template-columns: 1fr; // Single column on very small screens
    /* margin-top: 16px; */
  }
`;

const ButtomParticipantsGrid = styled.div`
  display: grid;
  max-height: 240px;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // Fewer columns on smaller screens
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr; // Single column on very smal screens
  }
`;

const CurrentParticipantsGrid = styled(ParticipantsGrid)`
  margin-top: 20px;
  margin-bottom: 40px;
`;
const PastParticipantsGrid = styled(ParticipantsGrid)`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
`;

const CloseIconContainer = styled.div`
  top: 16px;
  right: 16px;
  cursor: pointer;
  /* @media (max-width: 475px) {
    margin-top: -30px;
  } */
`;

const ParticipantRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
  /* @media (max-width: 475px) {
    margin: 8px 0;
  } */
`;

const ParticipantName = styled.span`
  color: var(--neutral-white, #fff);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

// Keyframes for the slide-up animation
const slideUp = keyframes`
    0% {
        transform: translateY(70%); /* Start from below the screen */
    }
    100% {
        transform: translateY(0%); /* End at 0% translateY */
    }
`;

const slideDown = keyframes`
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(70%);
    }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as newCoinImg } from "../assets/newCoinImg.svg";
import { ReactComponent as logoIcon } from "../assets/small_logo.svg";
import ComingSoonModal from "../components/Modal/ComingSoon";
import { getQuailPoints } from "../utils/chatApi/chatApi";
import Skeleton from "../components/Skeleton/Skeleton";

const metrics = [
  { title: "TVL", value: "2,510,890" },
  { title: "Daily Rewards", value: "15,000" },
  { title: "APY", value: "12.5%" },
  // Add other metrics as needed
];

// Dummy data for the graph
const data = [
  { name: "Jan 1", value: 4000 },
  { name: "Jan 2", value: 3000 },
  { name: "Jan 3", value: 2000 },
  { name: "Jan 4", value: 2780 },
  { name: "Jan 5", value: 1890 },
  { name: "Jan 6", value: 2390 },
  { name: "Jan 7", value: 3490 },
];

const EarnPage = () => {
  const [activeTab, setActiveTab] = useState("QALP Price");
  const [isComingSoon, setComingSoon] = useState(false);
  const [quailStats, setQuailStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const switchTab = (tab: any) => {
    setActiveTab(tab);
  };

  const [activeSecondTab, setSecondActiveTab] = useState("Deposite");

  useEffect(() => {
    if (isComingSoon) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isComingSoon]);

  useEffect(() => {
    const fetchQuailPoints = async () => {
      setIsLoading(true);
      try {
        const data = await getQuailPoints();
        setQuailStats(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching quail stats:", error);
        setIsLoading(false);
      }
    };

    fetchQuailPoints();
  }, []);

  const switchSecondTab = (tab: any) => {
    setSecondActiveTab(tab);
  };

  return isLoading ? (
    <SkeletonWrapper>
      <Skeleton />
      <Skeleton />
    </SkeletonWrapper>
  ) : quailStats ? (
    <PageContainer noScroll={isComingSoon}>
      <TopHeaderRow>
        <Heading>Qalp </Heading>
        <RewardQuail>QAL Reward</RewardQuail>
      </TopHeaderRow>
      <InfoText>Stake your USDB, earn 50% of the trading fees.</InfoText>
      {/* <DataBox>
                <Column>
                    {" "}
                    <MetricsContainer>
                        <MetricBox>
                            <MetricTitle>TVL</MetricTitle>
                            <MetricValue>
                                <NewCoinImg />
                                2,510,890
                            </MetricValue>
                        </MetricBox>
                        <MetricBox>
                            <MetricTitle>
                                APR <DaySpan>30D</DaySpan>
                            </MetricTitle>
                            <MetricValueHiglighted>45.85%</MetricValueHiglighted>
                        </MetricBox>
                        <MetricBox>
                            <MetricTitle>QALP PRICE</MetricTitle>
                            <MetricValue>
                                <NewCoinImg />
                                1.1722
                            </MetricValue>
                        </MetricBox>
                    </MetricsContainer>
                </Column>
                <BorderRight />
                <GraphColumn>
                    <TabRow>
                        <Tab
                            isActive={activeTab === "QALP Price"}
                            onClick={() => switchTab("QALP Price")}
                        >
                            QALP Price
                        </Tab>
                        <Tab
                            isActive={activeTab === "TVL"}
                            onClick={() => switchTab("TVL")}
                        >
                            TVL
                        </Tab>
                    </TabRow>

                    <GraphContainer>
                        <LineChart width={1000} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#B1DD40"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </GraphContainer>
                </GraphColumn>
            </DataBox> */}

      <SecondDataBox>
        <Card>
          <MetricsRow>
            <MetricBox>
              <MetricTitle>Total Value</MetricTitle>
              <MetricValue>
                <NewCoinImg />
                {parseFloat(quailStats?.data?.totalValue.toFixed(2))}
              </MetricValue>
            </MetricBox>
            <MetricBox>
              <MetricTitle>My Qalp</MetricTitle>
              <MetricValue>
                {parseFloat(quailStats?.data?.myQalp.toFixed(2))}
              </MetricValue>
            </MetricBox>
          </MetricsRow>
          <MetricsRow>
            <MetricBox>
              <MetricTitle>Est. Daily Qal Reward</MetricTitle>
              <MetricValue>
                <LogoIcon />
                {parseFloat(quailStats?.data?.estimatedDailyReward.toFixed(2))}
              </MetricValue>
            </MetricBox>

            <MetricBox>
              <MetricTitle>
                APR
                {/*<TimePeriod> 30D</TimePeriod>*/}
              </MetricTitle>
              <HeighlightMetricValue>
                {quailStats?.data?.APR}%
              </HeighlightMetricValue>
            </MetricBox>
          </MetricsRow>
          {/* </MetricsContainer> */}
          {/* </Column> */}
        </Card>
        <SecondCard>
          <Column>
            <TabRow>
              <Tab
                isActive={activeSecondTab === "Deposite"}
                onClick={() => switchSecondTab("Deposite")}
              >
                Deposite
              </Tab>
              <Tab
                isActive={activeSecondTab === "Withdraw"}
                onClick={() => switchSecondTab("Withdraw")}
              >
                Withdraw
              </Tab>
            </TabRow>

            {activeSecondTab === "Deposite" && (
              <EarnContainer>
                <SecondRow>
                  <SecondRightCol>
                    <EarnLeftText>Amount</EarnLeftText>
                  </SecondRightCol>
                  <SecondLeftCol>
                    <EarnRightText>Balance: 317.117067</EarnRightText>
                  </SecondLeftCol>
                </SecondRow>
                <Input placeholder="0.0" type="number" />
                <Row>
                  <RightCol>
                    <EarnTitle>You will receive: -</EarnTitle>
                    <EarnSubTitle>Est. daily QAL rewards: -</EarnSubTitle>
                  </RightCol>
                  <LeftCol>
                    <DepositButton>Deposit</DepositButton>
                  </LeftCol>
                </Row>
                {/* <BorderBottom /> */}
                {/* <BulletList>
                  As a counterparty in trades, you will receive 50% of trading
                  fees, which are automatically compounded into your MKLP
                  holdings.
                  <ul>
                    <BulletPoints>
                      {" "}
                      MKLP is NOT principal protected. The value of MKLP may
                      decrease from traders' PnL.
                    </BulletPoints>
                    <BulletPoints>
                      % fee is incurred when withdrawing from Merkle LP. The fee
                      is credited to the remaining LPs to reward long-term
                      depositors.
                    </BulletPoints>
                    <BulletPoints>
                      You can withdraw up to 20% of your initial deposit amount
                      daily over a span of 5 days, to help stabilize the
                      protocol in volatile market conditions.
                    </BulletPoints>
                  </ul>
                </BulletList> */}
              </EarnContainer>
            )}
            {activeSecondTab === "Withdraw" && (
              <EarnContainer>
                <ThirdRow>
                  <LeftColInput>
                    <EarnLeftText>Weekly Withdrawable</EarnLeftText>
                    <TodayInput placeholder="1723.065489" type="number" />
                  </LeftColInput>
                  <RightColInput>
                    <EarnLeftText>Withdrawn Today</EarnLeftText>
                    <TodayInput placeholder="0.0" type="number" />
                  </RightColInput>
                </ThirdRow>
                <SecondRow>
                  <SecondRightCol>
                    <EarnLeftText>Amount</EarnLeftText>
                  </SecondRightCol>
                  <SecondLeftCol>
                    <EarnRightText>Withdrawable: 317.117067</EarnRightText>
                  </SecondLeftCol>
                </SecondRow>
                <Input placeholder="0.0" type="number" />
                <Row>
                  <RightCol>
                    <EarnTitle>Withdraw Fee 0.1%: -</EarnTitle>
                    <EarnTitle>You will receive: -</EarnTitle>
                  </RightCol>
                  <LeftCol>
                    <DepositButton>With Draw</DepositButton>
                  </LeftCol>
                </Row>
                {/* <BorderBottom /> */}
                {/* <BulletList>
                  As a counterparty in trades, you will receive 50% of trading
                  fees, which are automatically compounded into your MKLP
                  holdings.
                  <ul>
                    <BulletPoints>
                      {" "}
                      MKLP is NOT principal protected. The value of MKLP may
                      decrease from traders' PnL.
                    </BulletPoints>
                    <BulletPoints>
                      % fee is incurred when withdrawing from Merkle LP. The fee
                      is credited to the remaining LPs to reward long-term
                      depositors.
                    </BulletPoints>
                    <BulletPoints>
                      You can withdraw up to 20% of your initial deposit amount
                      daily over a span of 5 days, to help stabilize the
                      protocol in volatile market conditions.
                    </BulletPoints>
                  </ul>
                </BulletList> */}
              </EarnContainer>
            )}
          </Column>
        </SecondCard>
      </SecondDataBox>
      {isComingSoon && <ComingSoonModal />}
    </PageContainer>
  ) : (
    <PageContainer noScroll={isComingSoon}>
      <Title> No Data</Title>
    </PageContainer>
  );
};

export default EarnPage;
export const LeftColInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RightColInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
`;

export const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const LeftCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SecondRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: -30px;
`;

export const ThirdRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SecondRightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SecondLeftCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PageContainer = styled.div<{ noScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  width: 90%;
  max-width: 1200px;
  padding: 50px;
  margin: 0 auto;
  overflow: ${(props) => (props.noScroll ? "block" : "auto")};
  /* height: ${(props) => (props.noScroll ? "100vh" : "auto")}; */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
  @media (min-height: 780px) {
    height: 80vh;
  }
  /* @media (min-width: 1050px) {
      min-width: 500px;
    } */
  /* @media (min-width: 1150px) {
      min-width: 600px;
    } */
  /* @media (min-width: 1300px) {
      min-width: 700px;
    }  */
`;

const Heading = styled.h1`
  color: var(--primary, #b1dd40);
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  font-family: Orbitron;
  font-size: 34px;
  font-weight: 900;
  text-transform: uppercase;
`;

const NewCoinImg = styled(newCoinImg)`
  width: 22px;
  height: 22px;
  border-radius: 1000px;
  margin-top: 4px;
  margin-right: 4px;
`;

const LogoIcon = styled(logoIcon)`
  width: 30px;
  height: 30px;
  border-radius: 1000px;
  margin-top: 4px;
  margin-right: 4px;
`;

const DaySpan = styled.div`
  display: flex;
  height: 20px;
  width: 40px;
  padding: 0px 8px;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-left: 10px;
  border-radius: 100px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.24) 0%,
      rgba(233, 246, 208, 0.24) 100%
    ),
    #13160b;
`;

const BorderRight = styled.div`
  border-right: 1px solid rgba(233, 246, 208, 0.2);
  margin: 0 20px;
`;

const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  margin: 32px 0;
`;

const RewardQuail = styled.div`
  display: flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--primary, #b1dd40);
  margin-left: 8px;

  color: var(--dark, #13160b);
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const TopHeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.p`
  color: var(--light, #e9f6d0);
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.7;
  margin-top: -20px;
`;

const DataBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px;

  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.06) 0%,
      rgba(233, 246, 208, 0.06) 100%
    ),
    #13160b;
  overflow-x: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const SecondDataBox = styled.div`
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  gap: 10px;
  margin-top: 48px;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Card = styled.div`
  display: flex;
  max-width: 347px;
  /* width: 440px; */
  /* height: 394px; */
  padding: 32px;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  gap: 52px;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.06) 0%,
      rgba(233, 246, 208, 0.06) 100%
    ),
    #13160b;

  /* display: flex;
    max-width: 347px;
    padding: 40px 32px;
    flex-direction: column;
    align-items: flex-start; */
  /* border-radius: 8px;
    border: 1px solid rgba(233, 246, 208, 0.16);
    background: linear-gradient(
        0deg,
        rgba(233, 246, 208, 0.06) 0%,
        rgba(233, 246, 208, 0.06) 100%
      ),
      #13160b; */
  /* @media (min-width: 477px) {
      width: 347px;
    } */
`;

const SecondCard = styled.div`
  /* min-width: 626px; */
  max-width: 850px;
  padding: 32px 32px 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.06) 0%,
      rgba(233, 246, 208, 0.06) 100%
    ),
    #13160b;
  @media (min-width: 960px) {
    min-width: 400px;
  }
  @media (min-width: 1050px) {
    min-width: 500px;
  }
  @media (min-width: 1150px) {
    min-width: 600px;
  }
  @media (min-width: 1300px) {
    min-width: 700px;
  }
`;

const TabRow = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const GraphColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1393px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: ${(props) =>
    props.isActive ? "rgba(233, 246, 208, 0.12)" : "none"};

  color: #fff;
  font-family: "Space Grotesk";
  font-size: 14px;
  min-width: 108px;
  line-height: 140%;
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
  border-radius: ${(props) => (props.isActive ? "100px" : "")};
  border: ${(props) => (props.isActive ? "2px solid #E9F6D0" : "")};
`;

const GraphContainer = styled.div`
  margin-top: 20px;
  /* overflow: auto; */
`;

const EarnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 759px;
  padding: 32px 0 0 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const EarnLeftText = styled.div`
  color: var(--neutral-white, #fff);
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  opacity: 0.7;
`;

const EarnRightText = styled.div`
  color: var(--neutral-white, #fff);

  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
`;

const EarnTitle = styled.div`
  color: var(--neutral-white, #fff);

  /* desktop/body/medium */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
`;

const EarnSubTitle = styled.div`
  color: var(--primary, #b1dd40);
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
`;

const BulletPoints = styled.li`
  margin-top: 10px;
`;
const DepositButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 44px;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;

  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MetricsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const MetricBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-radius: 8px;
  color: #e9f6d0;
  margin-left: 10px;
`;

const MetricTitle = styled.div`
  display: flex;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.28px;
  color: rgba(233, 246, 208, 0.7); // Adjust for less emphasis
`;

const MetricSubTitle = styled.div`
  color: var(--light, #e9f6d0);

  /* desktop/body/small */
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.7;
`;

const MetricValue = styled.div`
  display: flex;
  font-family: "Space Mono";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--light, #e9f6d0); // Use CSS variable for easy theming
  margin-top: 10px; // Adjust as needed
`;

const HeighlightMetricValue = styled.div`
  color: var(--primary, #b1dd40);

  /* desktop/heading/five--number */
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-top: 10px; // Adjust as needed
`;

const TimePeriod = styled.div`
  display: flex;
  height: 20px;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.24) 0%,
      rgba(233, 246, 208, 0.24) 100%
    ),
    #13160b;
  color: var(--light, #e9f6d0);

  /* desktop/misc/popup-tag */
  font-family: "Space Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin-left: 8px;
`;

const MetricValueHiglighted = styled.div`
  display: flex;
  font-family: "Space Mono";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--light, #b1dd40); // Use CSS variable for easy theming
  margin-top: 10px; // Adjust as needed
`;

const HeadingText = styled.h2`
  color: var(--light, #e9f6d0);
  font-family: Orbitron;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
  margin: 80px 0 32px 0;
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  width: 95%;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const TodayInput = styled.input<{ $inputColor?: string }>`
  display: flex;
  width: 90%;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.4);
  background: rgba(233, 246, 208, 0.08);
  color: white;
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

const BulletList = styled.div`
  color: var(--neutral-white, #fff);

  /* desktop/body/medium */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  align-self: stretch;
  opacity: 0.7;
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;
const Title = styled.h1`
  text-align: center;
  color: white;
`;

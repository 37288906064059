import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { AppDispatch, logout } from "../../store";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as InfoIcon } from "../../assets/Check-box-round.svg";
import HoverButton from "../Button/HoverButton";

const TwitterAccountConnectedModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [desiredChainId, setDesiredChainId] = useState<number>();
  const { connector, chainId } = useWeb3React();
  const [error, setError] = useState();

  useEffect(() => {
    if (chainId && (!desiredChainId || desiredChainId === -1)) {
      setDesiredChainId(chainId);
    }
  }, [chainId]);

  // const handleDisconnect = async () => {
  //   if (connector) {
  //     if (connector?.deactivate) {
  //       await connector.deactivate();
  //     } else {
  //       await connector?.resetState();
  //     }

  //     dispatch(logout());
  //     navigate("/");
  //   }
  // };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        {/* <IconRow>
          <HelpIcon />
          <ArrowForwardLightIcon />
          <BlastIcon />
        </IconRow> */}
        <InfoIcon />

        <ModalTitle>Twitter Account Connected</ModalTitle>
        <ModalDescription>
          Yay! you have connected your twitter account.
        </ModalDescription>

        <ButtonRow>
          <CreateAPotButton onClick={() => onClose()}  height="48px" padding="0 16px">
            <span style={{ color: "#13160B" }}>CLOSE</span>
          </CreateAPotButton>
        </ButtonRow>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default TwitterAccountConnectedModal;

// Styled Components

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.backdrop} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;
const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  width: 96%;
  max-width: 505px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: ${(props) => props.theme.borders.normal};
  background: ${(props) => props.theme.colors.modalBg};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media (max-width: 768px) {
    width: 70%;
    gap: 0px;
  }

  @media (max-width: 480px) {
    width: 100%;
    gap: 0px;
  }

  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;
// const ModalContainer = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   max-width: 540px;
  // padding: 40px;
  //   padding: 48px;
//   border-radius: 8px;
//   z-index: 1003;
//   border: 1px solid rgba(233, 246, 208, 0.16);
//   background: linear-gradient(
//       0deg,
//       rgba(233, 246, 208, 0.08) 0%,
//       rgba(233, 246, 208, 0.08) 100%
//     ),
//     #13160b;
//   box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
// `;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ModalTitle = styled.div`
  color: var(--primary, #b1dd40);
  text-align: center;

  /* glow */
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);

  /* desktop/heading/four */
  font-family: Orbitron;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.52px;
  text-transform: uppercase;
  margin-top: 32px;
  @media (max-width: 475px) {
  font-size: 20px;
  margin-top: 16px;
  }
`;

const ModalDescription = styled.div`
  color: var(--light, #e9f6d0);
  text-align: center;

  /* desktop/body/medium */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 16px;
  margin-bottom: 32px;
  /* margin-top: 24px; */
  opacity: 0.7;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 24px;
    width: 67%;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const Input = styled.input<{ $inputColor?: string }>`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(233, 246, 208, 0.40); */
  background: rgba(233, 246, 208, 0.08);
  /* color: white; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const CreateAPotButton = styled(HoverButton)`
  background: #B1DD40;
  color: ${(props) => props.theme.colors.dark};
/* 
  &:hover {
    background: #afdc3b;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

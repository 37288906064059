import {
  configureStore,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { localStorageStates } from "./utils";
import { toast } from "react-toastify";

interface Activities {
  data: ActivityItem[];
}

interface ActivityItem {
  _id: string;
  content: string;
  mentions: Mention[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Mention {
  twitter?: TwitterInfo;
  discord?: DiscordInfo;
  _id: string;
  address: string;
  points: number;
  generatedInviteCodes: string[];
  isBlacklisted: boolean;
  isAdmin: boolean;
  nonce: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  invitedBy: string;
  usedInviteCode: string;
  profilePicture?: string;
  username: string;
}

interface TwitterInfo {
  displayName: string;
  id: string;
  username: string;
}

interface DiscordInfo {
  discriminator: string;
  displayName: string;
  id: string;
  username: string;
}

interface InvitedBy {
  _id: string;
  address: string;
  generatedInviteCodes: any[]; // Replace any with a more specific type if possible
  isBlacklisted: boolean;
  nonce: string;
  __v: number;
  points: number;
  updatedAt: string;
  username?: any;
}

export interface User {
  _id: string;
  createdAt?: string;
  updatedAt: string;
  __v: number;
  profilePicture?: string;
  points: number;
  invitedBy?: InvitedBy;
  username?: string;
  liquidityPoints?: any;
}

export interface UserData {
  data: User[];
  next?: {
    page: number;
    limit: number;
  } | null;
  previous?: {
    page: number;
    limit: number;
  } | null;
}

interface LeaderboardRewards {
  blastRewards: number;
  yieldRewards: number;
  quailRewards: number;
}

interface WalletState {
  isConnecting: boolean;
  isRegistered: boolean;
  isSigningMessage: boolean;
  tokenStatus: {
    used: boolean | null;
    loading: boolean;
  };
  chats: {
    data: any;
    loading: boolean;
    error: any;
    chatCount: any;
    isArchived: boolean;
  };
  potDetail: {
    data: any;
    loading: boolean;
    error: any;
  };
  joinedParticpants: {
    data: any;
    loading: boolean;
    error: any;
  };
  registringToken: {
    loading: boolean;
  };
  getProfileData: {
    loading: boolean;
  };
  leaderboardData: {
    data: UserData;
    loading: boolean;
    // error: null;
  };
  restartPot: {
    isRestarted: boolean;
    // error: null;
  };

  leaderboardRewards: {
    data: LeaderboardRewards;
    loading: boolean;
    // error: null;
  };
  activitiesData: {
    data: Activities[];
    loading: boolean;
    activitiyCount: number;
    pageCount: number;
    // error: null;
  };
  rewards: number;
  potCode: null | string;
  createPot: {
    loading: boolean;
    isCreated: boolean;
    isSyncing: boolean;
    hasPotId: boolean;
    data: any;
    error: any;
    errorWhileSyncing?: any;
  };
  claimFund: {
    loading: boolean;
    isClaimed: boolean;
    hasSynced: boolean;
    data: any;
    error: any;
  };
  selectWinner: {
    loading: boolean;
    isSelectedWinner: boolean;
    data: any;
    hasSynced: boolean;
    error: any;
  };
  leavePot: {
    loading: boolean;
    isLeavePot: boolean;
    hasSynced: boolean;
    data: any;
    error: any;
  };
  deposit: {
    loading: boolean;
    isDeposited: boolean;
    isSyncingDeposit: boolean;
    isSyncingJoined: boolean;
    isJoined: boolean;
    data: any;
    error: any;
    errorWhileSyncing?: any;
    hasSynced: boolean;
  };
  isLeavePotModalOpen: boolean;
}

const initialState: WalletState = {
  isConnecting: false,
  isRegistered: false,
  isSigningMessage: true,
  tokenStatus: {
    used: null,
    loading: false,
  },
  registringToken: {
    loading: false,
  },
  getProfileData: {
    loading: false,
  },
  chats: {
    data: [],
    loading: false,
    error: null,
    chatCount: null,
    isArchived: false,
  },
  leaderboardData: {
    data: {
      data: [],
      next: null,
      previous: null,
    },
    loading: false,
    // error: null,
  },
  restartPot: {
    isRestarted: false,
  },
  createPot: {
    loading: false,
    isCreated: false,
    isSyncing: false,
    hasPotId: true,
    data: null,
    errorWhileSyncing: null,
    error: null,
  },
  claimFund: {
    loading: false,
    isClaimed: false,
    hasSynced: false,
    data: null,
    error: null,
  },
  selectWinner: {
    loading: false,
    isSelectedWinner: false,
    data: null,
    error: null,
    hasSynced: false,
  },
  potDetail: {
    data: null,
    loading: false,
    error: null,
  },
  joinedParticpants: {
    data: null,
    loading: false,
    error: null,
  },
  leavePot: {
    loading: false,
    isLeavePot: false,
    data: null,
    error: null,
    hasSynced: false,
  },
  deposit: {
    loading: false,
    isDeposited: false,
    isJoined: false,
    data: null,
    error: null,
    errorWhileSyncing: null,
    isSyncingDeposit: false,
    isSyncingJoined: false,
    hasSynced: false,
  },
  leaderboardRewards: {
    data: {
      blastRewards: 0,
      quailRewards: 0,
      yieldRewards: 0,
    },
    loading: false,
    // error: null,
  },
  activitiesData: {
    data: [],
    loading: false,
    activitiyCount: 0,
    pageCount: 0,
    // error: null,
  },
  isLeavePotModalOpen: false,
  potCode: null,
  rewards: 0,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletConnect: (state, action: PayloadAction<boolean>) => {
      state.isConnecting = action.payload;
    },
    setIsSigningMessage: (state, action: PayloadAction<boolean>) => {
      state.isSigningMessage = action.payload;
    },
    setPotCode: (state, action: PayloadAction<string | null>) => {
      state.potCode = action.payload;
    },
    setIsRegistered: (state, action: PayloadAction<boolean>) => {
      state.isRegistered = action.payload;
    },
    setRewardPoints: (state, action: any) => {
      state.rewards = action.payload;
    },
    logout: (state, action: PayloadAction<undefined>) => {
      localStorage.removeItem(localStorageStates.token);
      localStorage.removeItem(localStorageStates.userInfo);
      localStorage.removeItem(localStorageStates.redeemed);
      localStorage.removeItem(localStorageStates.twitterUsername);
      localStorage.removeItem(localStorageStates.discordUsername);
      localStorage.removeItem(localStorageStates.isRegistered);
      localStorage.removeItem(localStorageStates.registeredAddress);
      localStorage.removeItem(localStorageStates.isSigned);
      state.isRegistered = false;
      state.isSigningMessage = true;
    },
    setIsCreatePotLoading: (state, action: PayloadAction<boolean>) => {
      state.createPot = {
        ...state.createPot,
        loading: action.payload,
        error: null,
      };
    },
    setIsCreatedPot: (state, action: PayloadAction<any>) => {
      state.createPot = {
        ...state.createPot,
        isCreated: action.payload?.isCreated,
        isSyncing: action.payload?.isSyncing,
        hasPotId: action.payload?.hasPotId,
        data: {
          ...state.createPot.data,
          ...action.payload?.data,
        },
        error: null,
        errorWhileSyncing: null,
      };
    },
    setCreatePotError: (state, action: PayloadAction<any>) => {
      state.createPot = {
        loading: false,
        isCreated: false,
        isSyncing: false,
        hasPotId: true,
        data: state.createPot.data,
        error: action.payload.error,
        errorWhileSyncing: action.payload.error,
      };
    },
    resetCreatePot: (state, action: PayloadAction<undefined>) => {
      state.createPot = {
        loading: false,
        isCreated: false,
        isSyncing: state.createPot.isSyncing,
        hasPotId: true,
        data: null,
        error: null,
      };
    },
    setIsClaimLoading: (state, action: PayloadAction<boolean>) => {
      state.claimFund = {
        ...state.claimFund,
        loading: action.payload,
        error: null,
      };
    },
    setIsClaimedFunds: (state, action: PayloadAction<any>) => {
      state.claimFund = {
        ...state.claimFund,
        isClaimed: action.payload?.isClaimed,
        hasSynced: action.payload?.hasSynced,
        data: {
          ...state.claimFund.data,
          ...action.payload?.data,
        },
      };
    },
    setClaimFundError: (state, action: PayloadAction<any>) => {
      state.claimFund = {
        loading: false,
        isClaimed: false,
        data: null,
        error: action.payload,
        hasSynced: false,
      };
    },
    resetClaimFund: (state, action: PayloadAction<undefined>) => {
      state.claimFund = {
        ...state.claimFund,
        // loading: false,
        isClaimed: false,
        data: null,
        error: null,
        hasSynced: false,
      };
    },
    setIsSelectWinnerLoading: (state, action: PayloadAction<boolean>) => {
      state.selectWinner = {
        ...state.selectWinner,
        loading: action.payload,
        error: null,
      };
    },
    setIsSelectedWinner: (state, action: PayloadAction<any>) => {
      state.selectWinner = {
        ...state.selectWinner,
        isSelectedWinner: action.payload?.isSelectedWinner,
        hasSynced: action.payload?.hasSynced,
        data: {
          ...state.selectWinner.data,
          ...action.payload?.data,
        },
      };
    },
    setSelectWinnerError: (state, action: PayloadAction<any>) => {
      state.selectWinner = {
        loading: false,
        isSelectedWinner: false,
        data: null,
        error: action.payload,
        hasSynced: false,
      };
    },
    resetSelectWinner: (state, action: PayloadAction<undefined>) => {
      state.selectWinner = {
        ...state.selectWinner,
        // loading: false,
        isSelectedWinner: false,
        data: null,
        error: null,
        // hasSynced: false
      };
    },
    setResetSyncedSelectWinner: (state, action: PayloadAction<undefined>) => {
      state.selectWinner = {
        ...state.selectWinner,

        hasSynced: false,

        // hasSynced: false
      };
    },
    setIsLeavePotLoading: (state, action: PayloadAction<boolean>) => {
      state.leavePot = {
        ...state.leavePot,
        loading: action.payload,
        error: null,
      };
    },
    setIsLeavePot: (state, action: PayloadAction<any>) => {
      state.leavePot = {
        ...state.leavePot,
        isLeavePot: action.payload?.isLeavePot,
        hasSynced: action.payload?.hasSynced,
        data: {
          ...state.leavePot.data,
          ...action.payload?.data,
        },
      };
    },
    setLeavePotError: (state, action: PayloadAction<any>) => {
      state.leavePot = {
        loading: false,
        isLeavePot: false,
        data: null,
        error: action.payload,
        hasSynced: false,
      };
    },

    resetLeavePot: (state, action: PayloadAction<undefined>) => {
      state.leavePot = {
        ...state.leavePot,
        // loading: false,
        isLeavePot: false,
        data: null,
        error: null,
        hasSynced: false,
      };
    },
    setIsDepositedLoading: (state, action: PayloadAction<boolean>) => {
      state.deposit = {
        ...state.deposit,
        loading: action.payload,
        errorWhileSyncing: null,
        error: null,
      };
    },
    setIsDeposited: (state, action: PayloadAction<any>) => {
      state.deposit = {
        ...state.deposit,
        isDeposited: action.payload?.isDeposited,
        isJoined: action.payload?.isJoined,
        isSyncingDeposit: action.payload?.isSyncingDeposit,
        isSyncingJoined: action.payload?.isSyncingJoined,
        hasSynced: action.payload?.hasSynced,
        data: {
          ...state.deposit.data,
          ...action.payload?.data,
        },
      };
    },
    setDepositError: (state, action: PayloadAction<any>) => {
      state.deposit = {
        loading: false,
        isDeposited: false,
        isJoined: false,
        data: null,
        error: action.payload,
        isSyncingDeposit: false,
        isSyncingJoined: false,
        hasSynced: false,
        // errorWhileSyncing: action.payload,
      };
    },

    resetDeposit: (state, action: PayloadAction<undefined>) => {
      state.deposit = {
        ...state.deposit,
        // isDeposited: false,
        // isJoined: false,
        // isDeposited: false,
        data: null,
        error: null,
        isSyncingDeposit: false,
        hasSynced: false,
      };
    },
    resetIsJoined: (state, action: PayloadAction<undefined>) => {
      state.deposit = {
        ...state.deposit,
        isJoined: false,
      };
    },
    setPotDetail: (state, action: PayloadAction<any>) => {
      state.potDetail = {
        ...state.potDetail,
        data: { ...state.potDetail.data, ...action.payload },
      };
    },
    setIsPotDetailLoading: (state, action: PayloadAction<boolean>) => {
      state.potDetail = {
        ...state.potDetail,
        loading: action.payload,
      };
    },
    setJoinedParticipants: (state, action: PayloadAction<any>) => {
      state.joinedParticpants = {
        ...state.joinedParticpants,
        data: { ...state.joinedParticpants.data, ...action.payload },
      };
    },
    setIsJoinedParticpantLoading: (state, action: PayloadAction<boolean>) => {
      state.joinedParticpants = {
        ...state.joinedParticpants,
        loading: action.payload,
      };
    },
    setIsLeavePotModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLeavePotModalOpen = action.payload;
    },
    setIsArchived: (state, action: PayloadAction<boolean>) => {
      state.chats.isArchived = action.payload;
    },
    removeChat: (state, action: PayloadAction<string>) => {
      state.chats = {
        ...state.chats,
        data: state.chats.data.filter(
          (chat: any) => chat._id !== action.payload
        ),
      };
    },
    setIsRestarted: (state, action: PayloadAction<boolean>) => {
      state.restartPot.isRestarted = action.payload;
    },

    resetChats: (state, action: PayloadAction<undefined>) => {
      state.chats.data =
        state.createPot.isSyncing || state.deposit.isSyncingJoined
          ? state.chats.data
          : [];
      state.chats.loading = false;
      state.chats.error = null;
      state.chats.chatCount = null;
    },
    emptyChatData: (state, action: PayloadAction<undefined>) => {
      state.chats.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attachInviteCode.fulfilled, (state, action) => {
      // state.tokenStatus.used = action.payload.data.used;
      state.tokenStatus.loading = false;
    });

    builder.addCase(attachInviteCode.pending, (state) => {
      state.tokenStatus.loading = true;
    });

    builder.addCase(attachInviteCode.rejected, (state) => {
      state.tokenStatus.loading = false;
      // state.tokenStatus.used = null;
    });
    builder.addCase(getChatsData.fulfilled, (state, action) => {
      // state.tokenStatus.used = action.payload.data.used;
      state.chats.data = action.payload.hasSynced
        ? [...action.payload.data?.response]
        : [...state.chats.data, ...action.payload.data?.response];
      state.chats.loading = false;
      state.chats.chatCount = action.payload.data.chatCount;
    });

    builder.addCase(getChatsData.pending, (state) => {
      state.chats.loading = true;
      state.chats.data = [...state.chats.data];
    });

    builder.addCase(getChatsData.rejected, (state) => {
      state.chats.loading = false;
      state.chats.data = [...state.chats.data];
      // state.tokenStatus.used = null;
    });
    builder.addCase(getPotDetail.fulfilled, (state, action) => {
      state.potDetail.data = action.payload;
      state.potDetail.loading = false;
    });

    builder.addCase(getPotDetail.pending, (state) => {
      state.potDetail.data =
        state.createPot.isSyncing ||
        state.deposit.isSyncingJoined ||
        state.selectWinner.loading ||
        state.claimFund.loading ||
        state.leavePot.loading ||
        state.deposit.loading
          ? state.potDetail.data
          : null;
      state.potDetail.loading = true;
    });

    builder.addCase(getPotDetail.rejected, (state) => {
      state.potDetail.loading = false;
    });
    builder.addCase(getJoinedParticipants.fulfilled, (state, action) => {
      state.joinedParticpants.data = action.payload;
      state.joinedParticpants.loading = false;
    });

    builder.addCase(getJoinedParticipants.pending, (state) => {
      state.joinedParticpants.data = null;
      state.joinedParticpants.loading = true;
    });

    builder.addCase(getJoinedParticipants.rejected, (state) => {
      state.joinedParticpants.loading = false;
    });

    builder.addCase(registerUser.pending, (state, action) => {
      state.registringToken.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registringToken.loading = false;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.registringToken.loading = false;
    });

    builder.addCase(getProfileData.pending, (state, action) => {
      state.getProfileData.loading = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.getProfileData.loading = false;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.getProfileData.loading = false;
    });
    builder
      .addCase(fetchLeaderboardData.pending, (state) => {
        state.leaderboardData.loading = true;
      })
      .addCase(fetchLeaderboardData.fulfilled, (state, action) => {
        state.leaderboardData = action.payload;
        state.leaderboardData.loading = false;
      })
      .addCase(fetchLeaderboardData.rejected, (state, action) => {
        state.leaderboardData.loading = false;
      });

    builder
      .addCase(getRewards.pending, (state) => {
        state.leaderboardRewards.loading = true;
      })
      .addCase(getRewards.fulfilled, (state, action) => {
        state.leaderboardRewards.data = action.payload;
        state.leaderboardRewards.loading = false;
      })
      .addCase(getRewards.rejected, (state, action) => {
        state.leaderboardRewards.loading = false;
      });
    builder
      .addCase(getActivities.pending, (state) => {
        state.activitiesData.loading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        // state.activitiesData.data = action.payload;
        state.activitiesData.data = [
          ...state.activitiesData.data,
          ...action.payload.data,
        ];
        state.activitiesData.loading = false;
        state.activitiesData.activitiyCount = action.payload.activitiyCount;
        state.activitiesData.pageCount = action.payload.next.page;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.activitiesData.loading = false;
      });
  },
});

export const getProfileData = createAsyncThunk<
  any,
  string,
  {
    rejectValue: any;
  }
>(
  "wallet/getProfileData",
  async (walletAddress: string, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/user-profile`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkTokenStatus = createAsyncThunk<
  {
    success: boolean;
    msg: string;
    data: {
      used: boolean;
    };
  },
  string,
  {
    rejectValue: any;
  }
>("wallet/checkTokenStatus", async (token: string, { rejectWithValue }) => {
  try {
    const formData = new URLSearchParams();
    formData.append("code", token);
    // formData.append("wallet_address", walletAddress);
    // formData.append("twitter", twitter);
    // formData.append("discord", discord);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/verify-invite-code",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const attachInviteCode = createAsyncThunk<
  {
    success: boolean;
    msg: string;
    data: {
      used: boolean;
    };
  },
  string,
  {
    rejectValue: any;
  }
>(
  "wallet/attachInviteCode",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("code", token);
      // Retrieve the authToken and ensure it's in the correct format with "Bearer "
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/attach-invite-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken, // Updated to include "Bearer " prefix
          },
          body: formData,
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const claimFunds = createAsyncThunk<any, any>(
  "wallet/claimFunds",
  async ({ potCode }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      // Retrieve the authToken and ensure it's in the correct format with "Bearer "
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/joined-participants/${potCode}/claim`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken, // Updated to include "Bearer " prefix
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const leavePot = createAsyncThunk<any, any>(
  "wallet/leavePot",
  async ({ potCode }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      // Retrieve the authToken and ensure it's in the correct format with "Bearer "
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/joined-participants/${potCode}/leave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken, // Updated to include "Bearer " prefix
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk<any, any>(
  "wallet/registerUser",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user-profile",
        {
          method: "GET",
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRewards = createAsyncThunk<any, any>(
  "wallet/getRewards",
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user/rewards",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const riskPoolSignature = createAsyncThunk<any, any>(
  "wallet/riskPoolSignature",
  async ({ amount, potCode }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const formData = new URLSearchParams();
      formData.append("amount", amount);
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/use-risk-pool-signature/${potCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSignupFee = createAsyncThunk<any, any>(
  "wallet/addSignupFee",
  async ({ transactionHash }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const formData = new URLSearchParams();
      formData.append("transactionHash", transactionHash);
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/user/add-sign-up-fee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommitment = createAsyncThunk<any, any>(
  "wallet/getCommitment",
  async ({ potId }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      let route;
      if (potId) {
        route = `/get-commitment?potId=${potId}`;
      } else {
        route = `/get-commitment`;
      }
      const response = await fetch(process.env.REACT_APP_API_URL + route, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: authToken,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRandomness = createAsyncThunk<any, any>(
  "wallet/getRandomness",
  async ({ potCode }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/get-randomness/${potCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPot = createAsyncThunk<any, any>(
  "wallet/createPot",
  async ({ potId }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/sync-pot/${potId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const syncRotate = createAsyncThunk<any, any>(
  "wallet/syncRotate",
  async ({ potId }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/sync-rotation/${potId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const joinPotSignature = createAsyncThunk<any, any>(
  "wallet/joinPotSignature",
  async ({ potCode }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/join-pot-signature/${potCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const joinPot = createAsyncThunk<any, any>(
  "wallet/joinPot",
  async ({ walletAddress, potCode }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("potCode", potCode);
      formData.append("joinedParticipantId", walletAddress);
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/join-pot/${potCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHomePageStats = createAsyncThunk<any, any>(
  "wallet/getHomePageStats",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/stats", {
        method: "GET",
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkSyncDetails = createAsyncThunk<any, any>(
  "wallet/checkSyncDetails",
  async ({ potId, eventType }, { rejectWithValue, dispatch }) => {
    try {
      let route;
      if (eventType) {
        route = `/check-details/${potId}?eventType=${eventType}`;
      } else {
        route = `/check-details/${potId}`;
      }
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(process.env.REACT_APP_API_URL + route, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: authToken,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getChatsData = createAsyncThunk<any, any, { state: RootState }>(
  "wallet/getChatsData",
  async (
    { limit = 10, pageNo, isArchived = false, hasSynced = false },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/group-chats?limit=${limit}&page=${pageNo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data);
      }
      return {
        ...data,
        hasSynced,
        isError:
          state.wallet.createPot.errorWhileSyncing ||
          state.wallet.deposit.error,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getActivities = createAsyncThunk<any, any>(
  "wallet/getActivities",
  async ({ limit = 50, pageNo }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/activities?limit=${limit}&page=${pageNo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPotDetail = createAsyncThunk<any, any>(
  "wallet/getPotDetail",
  async ({ pot }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/pot/" + pot,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return { ...data, isError: state.wallet.selectWinner.error };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getJoinedParticipants = createAsyncThunk<any, any>(
  "wallet/getJoinedParticipants",
  async ({ potCode }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/joined-participants/${potCode}/detail`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }
      return {
        ...data,
        isError:
          state.wallet.deposit.error ||
          state.wallet.claimFund.error ||
          state.wallet.leavePot.error,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectingDiscord = createAsyncThunk<any, any>(
  "wallet/connectingDiscord",
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("code", code);
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/verify-discord`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      console.log("error in discord", error);
      return rejectWithValue(error);
    }
  }
);

export const shareOnTwitterApi = createAsyncThunk<any, any>(
  "wallet/shareOnTwitterApi",
  async ({ potCode }, { rejectWithValue, dispatch }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/twitter-share/${potCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      console.log("error in discord", error);
      return rejectWithValue(error);
    }
  }
);

export const connectingTwitter = createAsyncThunk<any, any>(
  "wallet/connectingTwitter",
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("code", code);
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/verify-twitter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (response.status === 400) {
        toast.error(data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(logout());
          window.location.href = "/";
          return rejectWithValue("Unauthorized: Token expired");
        } else {
          return rejectWithValue(data);
        }
      }

      return data;
    } catch (error) {
      console.log("error in twitter", error);
      return rejectWithValue(error);
    }
  }
);

export const registeringUser = createAsyncThunk<any, any>(
  "wallet/registeringUser",
  async ({ walletAddress }, { rejectWithValue }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("address", walletAddress);
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/check-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      console.log("error in registering", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchLeaderboardData = createAsyncThunk<any, any>(
  "wallet/registeringUser",
  async ({ isBlacklisted, page, limit }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/users/?isBlacklisted=${isBlacklisted}&page=${page}&limit=${limit}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const verifyUser = createAsyncThunk<any, any>(
  "wallet/verifyUser",
  async ({ nonce, signature, walletAddress }, { rejectWithValue }) => {
    const formData = new URLSearchParams();
    formData.append("address", walletAddress);
    formData.append(
      "nonce",
      `Sign in to the Quail mainnet beta.\n\nNonce: ${nonce}`
    );
    formData.append("signature", signature);

    const inviteCode = localStorage.getItem(localStorageStates.code);
    if (inviteCode && inviteCode.length == 5) {
      formData.append("inviteCode", inviteCode);
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const joinPotApi = createAsyncThunk<any, any>(
//   "wallet/joinPotApi",
//   async ({ potNo, walletAddress }, { rejectWithValue }) => {
//     try {
//       const response = await fetch(
//         process.env.REACT_APP_API_URL + `/join-pot/${potNo}/${walletAddress}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//           },
//         }
//       );
//       const data = await response.json();

//       if (!response.ok) {
//         return rejectWithValue(data);
//       }

//       return data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const syncPotApi = createAsyncThunk<any, any>(
  "wallet/syncPotApi",
  async ({ potNo }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/sync-pot/${potNo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restartPotApi = createAsyncThunk<any, any>(
  "wallet/restartPotApi",
  async ({ newPotId, currentPotId }, { rejectWithValue }) => {
    try {
      const formData = new URLSearchParams();
      formData.append("existingPotId", currentPotId);
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/sync-pot/${newPotId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
          body: formData,
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const archiveApi = createAsyncThunk<any, any>(
  "wallet/archiveApi",
  async ({ potId, groupChatId }, { rejectWithValue }) => {
    try {
      const tempToken = localStorage.getItem(localStorageStates.token);
      const authToken = `Bearer ${tempToken}`;
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/pot/archive/${potId}/${groupChatId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const {
  setWalletConnect,
  setRewardPoints,
  logout,
  setIsClaimedFunds,
  setClaimFundError,
  resetClaimFund,
  setIsSelectWinnerLoading,
  setIsSelectedWinner,
  setSelectWinnerError,
  resetSelectWinner,
  setResetSyncedSelectWinner,
  setIsLeavePotLoading,
  setIsLeavePot,
  resetLeavePot,
  setLeavePotError,
  setIsClaimLoading,
  setIsCreatePotLoading,
  setIsCreatedPot,
  resetCreatePot,
  setCreatePotError,
  setIsDepositedLoading,
  setIsDeposited,
  setIsLeavePotModalOpen,
  setIsArchived,
  removeChat,
  setIsRestarted,
  resetChats,
  emptyChatData,
  setIsJoinedParticpantLoading,
  setJoinedParticipants,
  resetDeposit,
  resetIsJoined,
  setPotDetail,
  setIsPotDetailLoading,
  setDepositError,
  setIsSigningMessage,
  setIsRegistered,
  setPotCode,
} = walletSlice.actions;

export const store = configureStore({
  reducer: {
    wallet: walletSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HoverButton from "../Button/HoverButton";
// import WalletConnectingModal from "./Modal/WalletConnectingModal";

const CreatedProfile: React.FC = () => {
  const navigate = useNavigate();
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);

  const handleConnectWalletClick = () => {
    navigate("/createPot");
  };
  const closeWalletModal = () => {
    setWalletModalOpen(false);
  };

  const handleAccount = useCallback((accountProp: any) => {
    if (accountProp) {
      closeWalletModal();
    }
  }, []);

  return (
    <Container>
      <MainHeading>Successfully Created Profile</MainHeading>
      <SubHeading>You have successfully created quail profile.</SubHeading>
      <ButtonBox>
        <ConnectWalletButton onClick={handleConnectWalletClick}  height="45px" padding="0 30px">
          <span>{"Next"}</span>
        </ConnectWalletButton>
      </ButtonBox>
      {/* {isWalletModalOpen && (
        <WalletConnectingModal
          onClose={closeWalletModal}
          handleAccount={handleAccount}
        />
      )} */}
    </Container>
  );
};

export default CreatedProfile;

const Container = styled.div`
  margin: 130px auto;
  margin-bottom: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const MainHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 33px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 11px;
  margin-top: 0;
  max-width: 1200px;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 19px;
    text-align: left;
  }
`;

export const SubHeading = styled.h4`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 17px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-top: 0;
  margin-bottom: 18px;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    text-align: left;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 45px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.large};
  letter-spacing: 0.32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 54px;
    padding: 9px 24px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 52px;
    padding: 9px 22px;
    font-size: 14px;
  }
`;

export const ConnectWalletButton = styled(HoverButton)<{}>`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.dark};
  pointer-events: ${(props) => "auto"};
  /* box-shadow: ${(props) => props.theme.boxShadow.button}; */

  /* &:hover {
    background: ${(props) => "darker shade of primary color"};
  } */
  /* &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  } */
`;

import { ethers } from "ethers";
import QuailABI from "../abi/quailAbi.json";
import QuailUserAbi from "../abi/quailUserAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

interface SendEtherProps {
  amount: string;
}

export const sendEth = async ({ amount }: SendEtherProps) => {
  const Quail_Finance_Contract_Address =
    process.env.REACT_APP_Quail_Finance_User_Contract_Address;

  if (Quail_Finance_Contract_Address) {
    try {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(
        Quail_Finance_Contract_Address,
        QuailUserAbi,
        signer
      );

      // Convert the amount to Wei
      const amountInWei = ethers.parseEther(amount);

      const tx = await contract.sendEther({
        value: amountInWei,
      });

      console.log("Transaction hash:", tx.hash);

      // if (shouldSync)
      //   localStorage.setItem(
      //     "pendingSendEtherTx",
      //     JSON.stringify({
      //       transactionHash: tx.hash,
      //     })
      //   );

      const receipt = await tx.wait();
      if (receipt && receipt.status === 1) {
        console.log("Transaction successfully mined and confirmed");
        localStorage.removeItem("pendingSendEtherTx");
      } else if (receipt && receipt.status === 0) {
        localStorage.removeItem("pendingSendEtherTx");
        throw new Error("Transaction failed and was reverted by the EVM");
      }
    } catch (error: any) {
      const parsedEthersError = getParsedEthersError(error);
      console.log("parsedEthersError", parsedEthersError);
      if(parsedEthersError.errorCode=="REJECTED_TRANSACTION"){
        throw new Error("You rejected the transaction");
      }
      throw new Error(parsedEthersError.context);
    }
  }
};
// import { ethers } from "ethers";
// import QuailABI from "../abi/quailAbi.json";

// interface TransferEthTxProps {
//   recipientAddress: string;
//   amountEth: string;
// }

// // Function to send ETH
// export const sendEth = async ({
//   recipientAddress,
//   amountEth,
// }: TransferEthTxProps) => {
//   if (!window.ethereum) {
//     throw new Error(
//       "Ethereum object not found in window. Please install Metamask."
//     );
//   }
//   try {
//     const ethersProvider = new ethers.BrowserProvider(window.ethereum as any);
//     const signer = await ethersProvider.getSigner();

//     // Define the transaction parameters
//     const tx = {
//       to: recipientAddress,
//       value: ethers.parseEther(amountEth),
//       gasLimit: BigInt("21000"),
//     };

//     // Sending the transaction
//     const transactionResponse = await signer.sendTransaction(tx);
//     localStorage.setItem(
//       "pendingTransferEth",
//       JSON.stringify({
//         transactionHash: transactionResponse.hash,
//       })
//     );
//     console.log("Transaction hash:", transactionResponse.hash);
//     const receipt = await transactionResponse.wait();
//     if (receipt && receipt.status === 1) {
//       console.log("Transaction successfully mined and confirmed");
//       localStorage.removeItem("pendingTransferEth");
//     } else if (receipt && receipt.status === 0) {
//       localStorage.removeItem("pendingTransferEth");
//       throw new Error("Transaction failed and was reverted by the EVM");
//     } else {
//       throw new Error(
//         "Failed to retrieve transaction receipt, or receipt is null"
//       );
//     }
//     return transactionResponse.hash;
//   } catch (error) {
//     console.error("Error sending ETH:", error);
//     throw error;
//   }

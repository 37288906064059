import React, { useEffect, useState } from "react";
import { ReactComponent as AvatarForActivities } from "../../assets/Avatar_activites.svg";
import NewCoinImg from "../../assets/newCoinImg.svg";
import DefaultImage from "../../assets/default_image.svg";
import Notification_empty from "../../assets/Notification_empty.svg";
import Notification_reminder from "../../assets/notif-reminder.svg";
import { fetchNotifications } from "../../utils/chatApi/chatApi";
import Skeleton from "../Skeleton/Skeleton";
import styled from "styled-components";
import ThreeDotsIcon from "../../assets/three_dot.svg";
import ThreeDotsModalContent from "../Notification/NotificationThreeDotsDropdown";
import RemoveEmailNotificationModal from "../Notification/RemoveNotificationModal";
import EmailNotificationModal from "../Notification/EmailNotificationModal";
import EmailSubscriptionSucessModal from "../Notification/EmailSubscriptionSucessModal";

import CheckEmailIcon from "../../assets/blackMail.svg";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { timeAgo } from "../../utils/helper";

const NotificationModal: React.FC<{
  onClose: () => void;
  notificationRef: any;
}> = ({ onClose, notificationRef }) => {
  const navigate = useNavigate();
  const [activitiesData, setActivitiesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDivOpen, setIsDivOpen] = useState(false);
  const [isEmailNotificationModalOpen, setEmailNotificationModalOpen] =
    useState(false);
  const [isRemoveEmailModalOpen, setRemoveEmailModalOpen] = useState(false);
  const [isSubscriptionEmailModalOpen, setSubscriptionEmailModalOpen] =
    useState(false);

  useEffect(() => {
    if (isSubscriptionEmailModalOpen) {
      setTimeout(() => setSubscriptionEmailModalOpen(false), 5000);
    }
  }, [isSubscriptionEmailModalOpen]);

  useEffect(() => {
    setIsLoading(true);

    // Fetch notifications data from the API
    fetchNotifications()
      .then((data) => {
        setTimeout(() => {
          if (data?.data) {
            setActivitiesData(data.data);
          }
          setIsLoading(false);
        }, 500); // Adjust the delay duration as needed
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        if (error) {
          toast.error(error || "Error fetching notifications:", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIsLoading(false);
      });
  }, []);

  const handleThreeDotsClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsDivOpen(!isDivOpen);
  };

  const handleEmailNotificationClick = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation(); // Prevent event propagation to the backdrop
    setRemoveEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setRemoveEmailModalOpen(false);
  };

  const handleRemoveClick = () => {
    setRemoveEmailModalOpen(true);
  };

  const handleEmailClose = () => {
    setEmailNotificationModalOpen(false);
  };

  const handleEmailClick = () => {
    setSubscriptionEmailModalOpen(true);
    // setEmailNotificationModalOpen(false);
  };
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  const pad = (number: number) => {
    return number < 10 ? "0" + number : number;
  };

  const depositNotice = (text: any) => {
    const sentence = text;
    if (sentence.includes(":")) {
      const textAfterColon = sentence.split(":")[1].trim();
      return textAfterColon
    }

  };

  const openNotificationModal = (index: number, chatId: any) => {
    // setClickedRowIndex(index);
    // setIsNotificationModalOpen(true);
    navigate(`/dashboard/${chatId}`);
    handleClose();
  };

  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <ModalTopBar>
          <ModalTitle>Notification</ModalTitle>
          {/* <ImageWrapper onClick={handleThreeDotsClick}>
            <img src={ThreeDotsIcon} alt={"three dots"} />
          </ImageWrapper> */}
        </ModalTopBar>

        <BorderBottom />
        {isLoading ? (
          <SkeletonWrapper>
            <Skeleton />
            <Skeleton />
          </SkeletonWrapper>
        ) : (
          <ActivitiesContainer>
            {activitiesData && activitiesData.length > 0 ? (
              // {
              activitiesData.map((activity: any, index: any) => (
                <ActivityRow
                  key={`${index}-activitiesData`}
                  visible={activity?.isRead}
                  isLast={index === activitiesData.length - 1}
                  onClick={() => {openNotificationModal(index, activity?.pot?.groupChat);}}
                >
                  <ActivityRowLine>
                    <ActivityColumn>
                      <NotificationReminer src={Notification_reminder} />
                      <ActivityTitle>{activity?.reminderType}</ActivityTitle>
                    </ActivityColumn>
                    <ActivityHighlightSubtitle>
                      {timeAgo(activity?.createdAt)}
                    </ActivityHighlightSubtitle>
                  </ActivityRowLine>
                  <ActivityRowLine>
                    <ActivityColumn>
                      {activity?.recipient[0]?.profilePicture ? (
                        <NotificationProfile
                          src={activity?.recipient[0]?.profilePicture}
                          alt={"Profile pic"}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = DefaultImage;
                          }}
                        />
                      ) : (
                        <NotificationProfile src={DefaultImage} />
                      )}
                      <ActivitySubtitle>
                        #{activity?.pot?.name}
                      </ActivitySubtitle>
                      {activity?.amount && (
                        <NotificationAmountCoin src={NewCoinImg} />
                      )}
                      <ActivityAmount>{activity?.amount}</ActivityAmount>
                    </ActivityColumn>
                    <ActivityRemainingTime>
                      {" "}
                      <ActivityHeighlight>
                        {depositNotice(activity?.content)}
                      </ActivityHeighlight>
                      {"  "}
                      {/* Notice */}
                    </ActivityRemainingTime>
                  </ActivityRowLine>
                </ActivityRow>
              ))
            ) : (
              // }
              <EmptyNotificationBox>
                <EmptyNotification src={Notification_empty} />
                <EmptyNotificationText>
                  Currently, nothing to report!
                </EmptyNotificationText>
                <EmptyNotificationSubText>
                  This area will light up with new notifications once there’s
                  activity in your POTs
                </EmptyNotificationSubText>
              </EmptyNotificationBox>
            )}
          </ActivitiesContainer>
        )}

        {/* <BottomSection onClick={() => setEmailNotificationModalOpen(true)}>
          <img
            style={{ marginRight: "0", width: "20px", height: "20px" }}
            src={CheckEmailIcon}
            alt={"CheckEmailIcon"}
          />
          <BottomSectionText>Email Notification</BottomSectionText>
        </BottomSection> */}
      </ModalContainer>
      {isDivOpen && (
        <ThreeDotsModalContent
          handleRemove={() => setRemoveEmailModalOpen(true)}
          onClose={() => setRemoveEmailModalOpen(false)}
        />
      )}
      {isEmailNotificationModalOpen && (
        <EmailNotificationModal
          onClose={handleEmailClose}
          onConfirm={handleEmailClick}
        />
      )}
      {isRemoveEmailModalOpen && (
        <RemoveEmailNotificationModal
          onClose={handleCloseEmailModal}
          handleRemove={handleRemoveClick}
          email={"hello"}
        />
      )}
      {isSubscriptionEmailModalOpen && (
        <EmailSubscriptionSucessModal
          onClose={() => setSubscriptionEmailModalOpen(false)}
        />
      )}
    </ModalBackdrop>
  );
};

export default NotificationModal;

const ModalBackdrop = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  position: fixed;
  padding-top: 76px;
  padding-right: 150px;
  top: 0px;
  right: 0px;
  width: 120vw;
  height: 100vh;
  z-index: 1000;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 768px) {
    width: 50vw;
    width: 80%;
    padding-top: 76px;
    padding-right: 0;
  }
  @media (max-width: 475px) {
    padding-top: 70px;
    padding-right: 20px;
  }
`;

const SkeletonWrapper = styled.div`
  overflow-y: auto;
  min-width: 330px;
  width: 100%;
  @media (max-width: 370px) {
    min-width: 290px;
  }
  @media (max-width: 330px) {
    min-width: 275px;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 370px;
  max-height: 570px;
  max-width: 340px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  /* Custom scrollbar */

  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495920; /* Color of the scrollbar thumb */
    border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the scrollbar track */
  }

  /* Hide scrollbar track when not hovering */

  ::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  /* Hide scrollbar corner */

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const ModalTitle = styled.h2`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: left;
  text-shadow: 0px 0px 14px rgba(214, 232, 81, 0.4);
  margin: 16px 12px;
`;

const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(233, 246, 208, 0.12);
`;

const ActivitiesContainer = styled.div`
  max-height: 76vh;
  min-height: 36vh;
  overflow-y: auto;
`;

const ActivityRow = styled.div<{ visible: boolean; isLast: boolean }>`
  display: flex;
  padding: 18px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-bottom: ${({ isLast }) => (isLast ? "none" : "0.5px solid #e9f6d0")};
  opacity: ${({ visible }) => (!visible ? 1 : 0.4)};
  cursor: pointer;
`;

const ActivityRowLine = styled.div`
  display: flex;
  width: 316px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 370px) {
    width: 280px;
  }
  @media (max-width: 330px) {
    width: 265px;
  }
`;

const ActivityTitle = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
`;

const ActivityHighlightSubtitle = styled.div`
  color: #b1dd40;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
`;
const ActivitySubtitle = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
`;

const ActivityColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationReminer = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 100px;
  margin-right: 6px;
`;

const NotificationProfile = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin-right: 6px;
`;

const NotificationAmountCoin = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 100px;
  margin-right: 2px;
  margin-left: 10px;
`;

const ActivityAmount = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
`;

const ActivityRemainingTime = styled.div`
  color: #e9f6d0;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  display: flex;
`;

const ActivityHeighlight = styled.div`
  margin-right: 4px;
  color: #b1dd40;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
`;

const Avatar = styled.div`
  // Define your avatar styles here
`;
const ProfileImage = styled.img`
  width: 100%;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;
const EmptyNotificationBox = styled.div`
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;
const EmptyNotification = styled.img`
  width: 100px;
  height: 100px;
`;
const EmptyNotificationText = styled.div`
  color: #fff;
  text-align: center;

  /* desktop/body/large--title */
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  text-transform: uppercase;
  opacity: 0.9;
`;

const EmptyNotificationSubText = styled.div`
  max-width: 288px;
  color: #fff;
  text-align: center;

  /* desktop/chat/message */
  font-family: "Space Grotesk";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
  letter-spacing: 0.3px;
  opacity: 0.9;
`;
const ActivityBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const ModalTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  cursor: pointer;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  /* background: #545947; */
  cursor: pointer;
  &:hover {
    border-radius: 100px;
    background: rgba(233, 246, 208, 0.12);
  }
  &:active {
    border-radius: 100px;
    background: #545947;
  }
`;

const ActivityText = styled.span`
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
`;
const TimePeriod = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  opacity: 0.56;
`;

const BottomSection = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #b1dd40;
  border-radius: 0 0 8px 8px;
`;

const BottomSectionText = styled.div`
  color: #13160b;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";

const StartPotModal: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  chatDataProps?: any;
  startWinnerRef?: any;
}> = ({ onClose, onConfirm, isLoading, chatDataProps, startWinnerRef }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        {/* <CloseIcon onClick={handleClose} /> */}
        <ModalTitle>
          {" "}
          Select Winner For Round {chatDataProps?.pot?.currentRound}{" "}
        </ModalTitle>
        <ModalDescription>
          Welcome to the winner selection process for “Round
          {chatDataProps?.pot?.currentRound}”. Thank you for your participation.
        </ModalDescription>
        {/* <ModalTitle>Start selected pot</ModalTitle>
        <ModalDescription>
        Pot will start and lorem ipsum. Are you sure you want ot start the pot {chatDataProps?.name} ?
        </ModalDescription> */}
        <ButtonRow>
          {/* <ActionButton onClick={onClose}>don’t start</ActionButton> */}
          <ConfirmButton onClick={onConfirm} cancel disabled={isLoading}>
            {isLoading ? <Loader /> : "Select"}
          </ConfirmButton>
        </ButtonRow>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default StartPotModal;

// Styled Components

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 90px;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  padding: 40px;
  /* padding: 32px 24px 24px 24px; */
  background: #13160b;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  /* modal glow */
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
  @media (max-width: 475px) and (min-height: 678px) and (max-height: 715px) {
    bottom: 80px;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 20px;
  font-weight: 900;
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-right: auto;
`;

const ModalDescription = styled.p`
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  text-align: left;
  margin-bottom: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

const ActionButton = styled.button<{ cancel?: boolean }>`
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  gap: 8px;
  color: ${(props) => props.theme.colors.primary};
  /* border-radius: 4px;
  font-family: "Space Grotesk", sans-serif;
  background: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px; */
  color: var(--dark, #13160b);
  text-align: center;

  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  cursor: pointer;
  border: none;
  &:hover {
    background: #b9ff04;
    box-shadow: ${(props) => props.theme.boxShadow.button};
  }
`;

const ConfirmButton = styled(ActionButton)`
  width: 100px;
  display: flex;
  justify-content: center;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #e9f6d0;
  width: 13px;
  height: 13px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Highlight = styled(ModalDescription)`
  color: var(--neutral-white, #fff);
  /* desktop/body/medium--700 */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.16px;
`;

import { useEffect, useState } from "react";
import { localStorageStates } from "../utils";
import { checkUserSigned } from "../utils/helper";
import { Navigate } from "react-router-dom";

interface RouteProps {
    children: React.ReactNode;
}

export const IsRedeemedButNotConnected: React.FC<RouteProps> = ({
                                                                    children,
                                                                }) => {
    // const registered = localStorage.getItem(localStorageStates.isRegistered);
    const redeemed = localStorage.getItem(localStorageStates.redeemed);
    const twitterUsername = localStorage.getItem(
        localStorageStates.twitterUsername
    );
    const discordUsername = localStorage.getItem(
        localStorageStates.discordUsername
    );
    if (!checkUserSigned()) {
        return <Navigate to="/" replace />;
    } else {
        if (redeemed == "true" && (!twitterUsername || !discordUsername)) {
            return <Navigate to="/early-access" replace />;
        } else if (redeemed == "true" && twitterUsername && discordUsername) {
            return <Navigate to="/" replace />;
        }
    }

    return <>{children}</>;
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";

const CancelPotModal: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  cancelPotModalRef?: any;
}> = ({ onClose, onConfirm, cancelPotModalRef }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300); // Match this duration to the transition duration
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <ModalBackdrop visible={visible} onClick={handleBackdropClick}>
      <ModalContainer visible={visible}>
        <CloseIcon onClick={handleClose} />
        <ModalTitle>Cancel selected pot</ModalTitle>
        <ModalDescription>
          Pot will be canceled and everyone will get their money back. Are you
          sure you want to cancel the pot Boawaah Pot?
        </ModalDescription>
        <ButtonRow>
          <ActionButton onClick={handleClose}>don’t cancel</ActionButton>
          <ActionButton onClick={onConfirm} cancel>
            {"Yes, cancel"}
          </ActionButton>
        </ButtonRow>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default CancelPotModal;

// Styled Components

const ModalBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  @media (max-width: 475px) {
    bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
`;

const ModalContainer = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 405px;
  padding: 40px;
  background: #13160b;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid rgba(233, 246, 208, 0.16);
  background: linear-gradient(
      0deg,
      rgba(233, 246, 208, 0.08) 0%,
      rgba(233, 246, 208, 0.08) 100%
    ),
    #13160b;
  transform: translateY(${({ visible }) => (visible ? "0" : "20px")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  /* modal glow */
  box-shadow: 0px 0px 50px 0px rgba(233, 246, 208, 0.04);
  @media (max-width: 475px) {
    border-radius: 20px 20px 0 0;
  }
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  color: #e9f6d0;
  font-family: Orbitron;
  font-size: 20px;
  font-weight: 900;
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-right: auto;
`;

const ModalDescription = styled.p`
  color: rgba(233, 246, 208, 0.7);
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  text-align: left;
  margin-bottom: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

const ActionButton = styled.button<{ cancel?: boolean }>`
  display: flex;
  height: 40px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  font-family: "Space Grotesk", sans-serif;
  background: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  cursor: pointer;
  border: none;
  &:hover {
    opacity: 0.8;
  }
`;

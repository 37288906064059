import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AccountInfo from "../components/Profile/AccountInfo";
import { useWeb3React } from "@web3-react/core";
import { ReactComponent as DiscordLogo } from "../assets/discord-logo.svg";
import { ReactComponent as XLogo } from "../assets/x-logo.svg";
import { ReactComponent as PersonAdd } from "../assets/person_add.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CheckCircle } from "../assets/bx-check-circle.svg";
import { AppDispatch, getProfileData, RootState } from "../store";
import { localStorageStates } from "../utils";
import Skeleton from "../components/Skeleton/Skeleton";
import { toast } from "react-toastify";
import HoverButton from "../components/Button/HoverButton";

const Dashoboards: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    getProfileData: { loading },
  } = useSelector((state: RootState) => state.wallet);
  const domain = window.location.hostname;
  const { account } = useWeb3React();
  const [userProfile, setUserProfile] = useState({
    username: "",
    points: 0,
    profilePicture: "",
    twitterInfo: null,
    discordInfo: null,
    generatedInviteCodes: [],
    isBlacklisted: false,
    rank: 0,
  });
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setError("");
    (async () => {
      // if (account) {
      //   const userInfoInLoacalStorage = JSON.parse(
      //     localStorage.getItem(localStorageStates.userInfo) || "{}"
      //   );
      //   console.log("userInfoInLoacalStorage", userInfoInLoacalStorage);
      //   setUserProfile(userInfoInLoacalStorage);
      // }
      if (account) {
        const gotProfileData = await dispatch(getProfileData(account));
        if (gotProfileData.meta.requestStatus === "fulfilled") {
          if (gotProfileData?.payload?.username) {
            setUserProfile(gotProfileData.payload);
            localStorage.setItem(
              localStorageStates.userInfo,
              JSON.stringify(gotProfileData.payload)
            );
            // localStorage.setItem(localStorageStates.isRegistered, "true");
          }
          if (gotProfileData.payload?.msg) {
            setError(gotProfileData.payload?.msg);
          }
        }
        if (gotProfileData.meta.requestStatus === "rejected") {
          toast.error(
            gotProfileData?.payload?.message || "Failed to get profile data",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setError(gotProfileData.payload?.message);
        }
        //  else {
        //   setError(gotProfileData.payload?.msg);
        //   // localStorage.setItem(localStorageStates.isRegistered, "false");
        // }
      }
    })();
  }, [account]);

  const copyToClipboard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
    } catch (error: any) {
      console.error("Failed to copy: ", error);
      if (error) {
        toast.error(error || "Failed to copy: ", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const openTwitter = () => {
    const TWITTER_URL = process.env.REACT_APP_TWITTER_URL;
    if (TWITTER_URL) window.open(TWITTER_URL, "_blank", "noopener,noreferrer");
  };
  const openDiscord = () => {
    const DISCORD_URL = process.env.REACT_APP_DISCORD_URL;
    window.open(DISCORD_URL, "_blank", "noopener,noreferrer");
  };

  return (
    <Container>
      {loading ? (
        <div style={{ marginBottom: "36px" }}>
          <Skeleton />
        </div>
      ) : (
        <AccountInfo
          rewardPoints={userProfile?.points}
          profilePic={userProfile.profilePicture}
          userName={userProfile.username}
          ranking={userProfile.rank}
        ></AccountInfo>
      )}
      <Box>
        <FirstColumn>
          <SocialButtonBox>
            <SocialButton onClick={openTwitter}>
              <XLogo />
              Follow us on twitter
            </SocialButton>
            <SocialButton onClick={openDiscord}>
              <DiscordLogo />
              Join our discord channel
            </SocialButton>
          </SocialButtonBox>
          <SubHeading>
            Start earning rewards points to qualify for airdrops & more.
          </SubHeading>
        </FirstColumn>
        <SecondColumn>
          <RightColumnHeading>INVITES</RightColumnHeading>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ContainerList>
            {loading ? (
              <Skeleton />
            ) : (
              userProfile?.generatedInviteCodes?.map((item: any) => (
                <InviteItemBox>
                  <DetailBox>
                    <PersonImageBox>
                      {" "}
                      <PersonAdd />
                    </PersonImageBox>
                    <ItemName>Invite Available</ItemName>
                  </DetailBox>
                  <LinkBox>
                    <LinkText>{`${domain}/${item?.code}`}</LinkText>
                    {item?.isUsed ? (
                      <CheckCircle />
                    ) : (
                      <CopyButton
                        onClick={() =>
                          copyToClipboard(`${domain}/${item?.code}`)
                        }
                      >
                        Copy Invite
                      </CopyButton>
                    )}
                  </LinkBox>
                </InviteItemBox>
              ))
            )}
          </ContainerList>
        </SecondColumn>
      </Box>
    </Container>
  );
};

export default Dashoboards;

const Container = styled.div`
  padding: 45px 20px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 480px) {
    // flex-direction: column;
    // align-items: flex-start;
    margin-bottom: 40px;
  }
`;

export const BaseButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  color: ${(props) => props.theme.colors.dark};

  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: 38px;
    padding: 10px 26px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 32px;
    padding: 10px 24px;
    font-size: 14px;
  }
`;

export const CreateAPotButton = styled(HoverButton)`
  background: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.boxShadow.button};
  color: ${(props) => props.theme.colors.dark};
  /* &:hover {
    padding: 0px 17px;
    background: #afdc3b;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  }
  &:active {
    padding: 0px 16px;
    color: grey;
    box-shadow: 0px 0px 18px rgba(214, 232, 81, 0.4);
  } */
`;

export const JoinAPotButton = styled(HoverButton)`
  /* backgorund: none; */
  border-radius: 8px;
  border: ${(props) => props.theme.borders.steps2};
  color: ${(props) => props.theme.colors.light};
`;

export const Box = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 92%;

  @media (max-width: 850px) {
    width: 96%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 96%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  padding-right: 64px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 48px;
  width: 35%;

  @media (max-width: 1050px) {
    width: 100%;
    padding-right: 55px;
  }

  @media (max-width: 980px) {
    padding-right: 48px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  padding-left: 64px;
  width: 65%;
  flex-direction: column;
  align-items: flex-start;
  border-left: ${(props) => props.theme.borders.light};

  @media (max-width: 1050px) {
    width: 100%;
    padding-left: 55px;
  }

  @media (max-width: 980px) {
    width: 100%;
    padding-left: 48px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 56px;
  }

  @media (max-width: 480px) {
    margin-top: 48px;
    align-items: center;
  }
`;

export const RewardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    gap: 20px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    gap: 16px;
    margin-bottom: 32px;
  }
`;

export const RewardValue = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 45px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.small};
  letter-spacing: 4.5px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 38px;
  }

  @media (max-width: 480px) {
    font-size: 31px;
  }
`;

export const RewardHeading = styled.span`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-shadow: ${(props) => props.theme.textShadow};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const SocialButton = styled.button`
  display: flex;
  cursor: pointer;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: transparent;
  //   box-shadow: ${(props) => props.theme.boxShadow.button};
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  // line-height: ${(props) => props.theme.lineHeights.large};
  text-transform: uppercase;
  border: 2px solid rgba(233, 246, 208, 0.2);

  @media (max-width: 768px) {
    padding: 8px 20px;
    height: 46px;
  }

  @media (max-width: 480px) {
    padding: 6px 16px;
    height: 44px;
  }
`;

export const SocialButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 18px;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 24px;
  }
`;

export const SubHeading = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const RightColumnHeading = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 34px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  line-height: ${(props) => props.theme.lineHeights.small};
  text-transform: uppercase;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    margin-bottom: 16px;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  min-height: 100vh;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InviteItemBox = styled.div`
  display: flex;
  padding: 24px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: ${(props) => props.theme.borders.light};

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DetailBox = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 17px;
  }
`;

export const LinkBox = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const PersonImageBox = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Ensures circular shape */
  background: ${(props) => props.theme.colors.softGray};
  overflow: hidden; /* Prevents content from affecting shape */
  margin-right: 32px;

  svg {
    max-width: 100%; /* SVG will not exceed the box's width */
    max-height: 100%; /* SVG will not exceed the box's height */
  }

  @media (max-width: 1120px) {
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    margin-right: 32px;
  }

  @media (max-width: 480px) {
    margin-right: 22px;
  }
`;

export const ItemName = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 18px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${(props) => props.theme.lineHeights.medium};

  @media (max-width: 768px) {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const LinkText = styled.span`
  color: ${(props) => props.theme.colors.light};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.medium};
  margin-right: 32px;
  opacity: 0.7;
  @media (max-width: 1120px) {
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
    margin-right: 32px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-right: 22px;
  }
`;

export const CopyButton = styled.button`
  display: flex;
  cursor: pointer;
  height: 44px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.boxShadow.button};
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  // line-height: ${(props) => props.theme.lineHeights.large};
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 0px 22px;
  }

  @media (max-width: 480px) {
    padding: 0px 20px;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 20px;
  color: ${(props) => props.theme.colors.light};
  font-size: 16px;
  text-align: center;
`;

import { useEffect } from "react";

const usePageSEO = ({
  title,
  description,
  keywords = [
    "Quail Finance, Blast Blockchain, SocialFi, Web3, Consumer Product, Liquidity, Collateral-free",
  ],
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterUrl,
}) => {
  useEffect(() => {
    document.title = title;
    setMetaTag("name", "description", description);
    setMetaTag("name", "keywords", keywords);
    setMetaTag("property", "og:title", ogTitle || title);
    setMetaTag("property", "og:description", ogDescription || description);
    setMetaTag("property", "og:image", ogImage);
    setMetaTag("property", "og:url", ogUrl || window.location.href);
    setMetaTag("name", "twitter:title", twitterTitle || title);
    setMetaTag(
      "name",
      "twitter:description",
      twitterDescription || description
    );
    setMetaTag("name", "twitter:image", twitterImage);
    setMetaTag("name", "twitter:url", twitterUrl || window.location.href);
    return () => {
      // do any kind of clean up
    };
  }, [
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterUrl,
  ]);
  const setMetaTag = (attr, key, content) => {
    if (content) {
      let element = document.querySelector(`meta[${attr}="${key}"]`);
      if (!element) {
        element = document.createElement("meta");
        element.setAttribute(attr, key);
        document.head.appendChild(element);
      }
      element.setAttribute("content", content);
    }
  };
};

export default usePageSEO;
